import React, { useState } from "react";

import moment from "moment";

interface propsType {
  data: {
    file_list: string[];
    idx: number;
    question_board: string;
    question_category: number;
    question_datetime: string;
    question_idx: number;
    question_title: string;
    question_type: boolean;
    user_idx: number;
    user_name: string;
    user_phone: string;
    admin_board?: string;
    admin_datetime?: string | null;
    admin_name?: string | null;
  };
  callback: (arg: any, arg2: any) => void;
}

const MobileTypeInquiry = ({ data, callback }: propsType) => {
  const [showInquiry, setShowInquiry] = useState<number | "">("");

  const [word, setWord] = useState<string>("");

  return (
    <figure
      className="py-[8px] px-[12px]"
      style={{
        boxShadow: "0px 3px 8px 0px rgba(108, 108, 108, 0.12)",
      }}
      onClick={() => {
        if (showInquiry === "") {
          setShowInquiry(data.question_idx);
        } else if (showInquiry !== data.question_idx) {
          setShowInquiry(data.question_idx);
        } else {
          setShowInquiry("");
        }
      }}
    >
      <div className="flex flex-col gap-[4px]">
        <li className="-text--label-12b font-700 -text--color-neutral50">
          {String(data.idx).padStart(6, "0")}
        </li>
        <li className="block overflow-hidden text-ellipsis whitespace-nowrap -text--body16m font-500 -text--color-neutral100">
          {data.question_title}
        </li>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-[4px]">
            <li className="-text--body-14r font-400 -text--color-neutral50">
              <span className="mr-[4px]">{data.user_name}</span>
              <span>{data.user_phone}</span>
            </li>
            <li className="-text--label-12r font-400 -text--color-neutral34">
              {moment(data.question_datetime).format("YYYY.MM.DD HH:mm:ss")}
            </li>
          </div>
          <div
            className={`${
              data.question_type === true
                ? "-bg--color-neutral6 -text--color-neutral50"
                : "-bg--color-primary-surface -text--color-primary-main"
            } border-none rounded-[999px] px-[10px] py-[6px] -text--label-12b font-700`}
          >
            {data.question_type === true ? "답변완료" : "답변대기"}
          </div>
        </div>
      </div>

      {showInquiry === data.question_idx && (
        // 애니메이션 효과 줘야 함
        <section className="">
          <div className="my-[12px] border-t border-solid -border--color-neutral6"></div>
          <div className="flex flex-col gap-[8px]">
            <span className="-text--body-14b font-700 -text--color-neutral50">
              내용
            </span>
            <pre>
              <div
                className="whitespace-normal break-words"
                dangerouslySetInnerHTML={{
                  __html: data.question_board.replace(/\n/g, "<br>"),
                }}
                style={{
                  fontFamily: "Pretendard",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              />
            </pre>
          </div>
          <div className="mt-[16px] mb-[4px] flex flex-col gap-[8px]">
            {data.file_list.length > 0 && (
              <span className="-text--body-14b font-700 -text--color-neutral50">
                첨부파일
              </span>
            )}
            <div className="flex flex-row items-start gap-[16px] w-[321px] overflow-y-hidden">
              {data.file_list.map((idx: any, num: number) => {
                const fileExtension = idx.match(/\.([^.]+)$/)?.[1]; // 확장자 추출
                return (
                  <div className="w-[100px] h-[100px]" key={num}>
                    <a
                      href={idx}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-block w-[100px] h-[100px]"
                    >
                      {fileExtension === "pdf" ? (
                        <span className="_sprite_ _sprite_8_5_"></span>
                      ) : (
                        <img
                          className="w-full h-full rounded-[8px]"
                          src={idx}
                          alt=""
                        />
                      )}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="my-[12px] border-t border-solid -border--color-neutral6"></div>
          <div className="py-[4px] flex flex-col gap-[8px]">
            {data.admin_board && (
              <>
                <div className="flex flex-row justify-between">
                  <span className="-text--body-14b font-700 -text--color-neutral50">
                    답변
                  </span>
                  <span className="-text--body-14r font-400 -text--color-neutral50">
                    {`${data.admin_board.length}`}/5000
                  </span>
                </div>
                <pre>
                  <div
                    className="whitespace-normal break-words"
                    dangerouslySetInnerHTML={{
                      __html: data.admin_board.replace(/\n/g, "<br>"),
                    }}
                    style={{
                      fontFamily: "Pretendard",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  />
                </pre>
              </>
            )}
            {!data.admin_board && (
              <>
                <div className="flex flex-row justify-between">
                  <span className="-text--body-14b font-700 -text--color-neutral50">
                    답변
                  </span>
                  <span className="-text--body-14r font-400 -text--color-neutral50">
                    {`${word.length}`}/5000
                  </span>
                </div>
                <textarea
                  className="p-[16px] border border-solid -border--color-neutral12 rounded-[8px] resize-none placeholder:-text--body-14r placeholder:font-400 placeholder:-text--color-neutral20 min-h-[118px]"
                  placeholder="답변을 입력해 주세요. (최소 10자)"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setWord(e.target.value)}
                ></textarea>
                <button
                  disabled={word.length < 10}
                  className="py-[16px] right-0 flex items-center justify-end"
                  onClick={() => {
                    callback(data.question_idx, word);
                  }}
                >
                  <span
                    className={` -text--body-16b font-700 -text--color-primary-main ${
                      word.length < 10 ? "opacity-50" : "opacity-100"
                    }`}
                  >
                    답변등록
                  </span>
                </button>
              </>
            )}
          </div>
        </section>
      )}
    </figure>
  );
};

export default MobileTypeInquiry;
