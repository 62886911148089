import React from "react";

const PdfHeader = React.forwardRef(({ props }: any, ref: any) => {
  return (
    <div
      ref={ref}
      className="w-[595px] h-[90px] plr-24 pt-[24px] mb-[24px] flex flex-col text-B18R justify-around"
    >
      <div className="h-[10px] flex flex-row justify-end text-B12R border-t-[2px] border-solid -border--color-neutral100 "></div>
      <div className="flex flex-row justify-start font-700">
        <span>건축상담 보고서</span>
      </div>
      <div className="flex flex-row text-B10R justify-between">
        <span className="-text--color-neutral50">
          {`접수일자 ${props && props?.process?.process_datetime.slice(0, 10)}`}
        </span>
        <div className="flex flex-row gap-[8px] items-center relative">
          {props?.process?.process_check === 9 ? (
            <div className="absolute top-[85%] w-full -bg--color-neutral100 h-[1px]"></div>
          ) : (
            ""
          )}

          <span className="font-700">단계</span>
          <span>
            {props?.process?.process_type === 1
              ? "상담"
              : props?.process?.process_type === 2
                ? "계약"
                : props?.process?.process_type === 3
                  ? "공사"
                  : "-"}
          </span>
          <span>|</span>
          <span className="font-700">담당자</span>
          <span>
            {props?.process?.admin_name ? props?.process.admin_name : "미배정"}
          </span>
          <span>|</span>
          <span className="font-700">상태</span>
          <span>
            {props?.process?.process_check === 0
              ? "상담대기"
              : props?.process?.process_check === 1
                ? "진행 중"
                : props?.process?.process_check === 2
                  ? "상담완료"
                  : props?.process?.process_check === 9
                    ? "상담취소"
                    : "-"}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-end h-[10px] border-b border-solid -border--color-neutral100 "></div>
    </div>
  );
});

export default PdfHeader;
