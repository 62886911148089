import React, { useState } from "react";

import moment from "moment";
import { useRecoilState } from "recoil";
import { tempEndDate, tempStartDate } from "@/stores/common/calendar";

const CouncelFilterCalendarMobile = () => {
  const [currentDate, setCurrentDate] = useState(moment());

  // 이전 달
  const handlePrevMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, "month"));
  };

  // 다음 달
  const handleNextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, "month"));
  };

  const [selectedStartDate, setSelectedStartDate] =
    useRecoilState(tempStartDate);
  const [selectedEndDate, setSelectedEndDate] = useRecoilState(tempEndDate);

  const handleDayClick = (current) => {
    if (!selectedStartDate && !selectedEndDate) {
      setSelectedStartDate(current);
      setSelectedEndDate(current);
    } else if (current >= selectedEndDate) {
      setSelectedEndDate(current);
    } else if (current <= selectedStartDate) {
      setSelectedStartDate(current);
    } else {
      setSelectedStartDate(undefined);
      setSelectedEndDate(undefined);
    }
  };

  // 달력 생성
  const renderCalendar = () => {
    // 해당 월의 첫째 주가 1년 중 몇 번째 주인지
    const startWeek = currentDate.clone().startOf("month").week();

    // 해당 월의 마지막 주가 1년 중 몇 번째 주인지
    const endWeek =
      currentDate.clone().endOf("month").week() === 1
        ? 53
        : currentDate.clone().endOf("month").week();

    let calendar = [];

    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div className="grid grid-cols-7 mb-[2px]" key={week}>
          {Array(7)
            .fill(0)
            .map((v, i) => {
              // 배열 생성 후 i 값을 더해서 날짜 표현
              let current = currentDate
                .clone()
                .week(week)
                .startOf("week")
                .add(v + i, "day");

              // 날짜가 선택된 범위 내에 있는지 확인
              const isInRange =
                selectedStartDate &&
                selectedEndDate &&
                current.isAfter(selectedStartDate) &&
                current.isBefore(selectedEndDate);

              let isSelected =
                selectedStartDate?.format("YYYYMMDD") ===
                  current.format("YYYYMMDD") ||
                selectedEndDate?.format("YYYYMMDD") ===
                  current.format("YYYYMMDD")
                  ? "selected"
                  : "";

              //   let isRounded = selectedEndDate === null ? "rounded-full" : "";

              // 해당 월이 아닌 다른 월의 경우 회색으로 표현
              let notThisMonth =
                current.format("MM") !== currentDate.format("MM")
                  ? "grayed"
                  : "";

              const isWeekend = current.day() === 0 || current.day() === 6;

              // 추가된 부분: 선택된 날짜와의 비교를 통해 어떤 스타일을 적용할지 결정
              let roundedStyle = "";
              if (selectedStartDate && selectedEndDate) {
                if (
                  current.isSame(selectedStartDate) &&
                  current.isSame(selectedEndDate)
                ) {
                  // 선택된 범위의 시작일과 종료일이 동일한 경우
                  roundedStyle = "rounded-full";
                } else if (current.isSame(selectedStartDate)) {
                  // 선택된 범위의 시작일인 경우 (왼쪽에만 rounded 스타일 적용)
                  roundedStyle = "rounded-l-full";
                } else if (current.isSame(selectedEndDate)) {
                  // 선택된 범위의 종료일인 경우 (오른쪽에만 rounded 스타일 적용)
                  roundedStyle = "rounded-r-full";
                }
              }

              return (
                <div
                  className={`${notThisMonth} ${
                    isInRange
                      ? "-text--color-primary-main -bg--color-primary-surface"
                      : ""
                  } ${
                    isSelected
                      ? "-text--color-primary-main selected -bg--color-primary-surface"
                      : ""
                  } ${roundedStyle} ${
                    isWeekend && "-text--color-danger-main"
                  } text-center pt-[11px] pb-[11px]`}
                  key={i}
                  onClick={() => handleDayClick(current)}
                >
                  <span className="text">{current.format("D")}</span>
                </div>
              );
            })}
        </div>
      );
    }
    return calendar;
  };

  const [selectYrMth, setSelectYrMth] = useState(false);
  const [cloneCurrentDate, setCloneCurrentDate] = useState(currentDate.clone());
  const mthArray = [
    { title: "1월", value: 0 },
    { title: "2월", value: 1 },
    { title: "3월", value: 2 },
    { title: "4월", value: 3 },
    { title: "5월", value: 4 },
    { title: "6월", value: 5 },
    { title: "7월", value: 6 },
    { title: "8월", value: 7 },
    { title: "9월", value: 8 },
    { title: "10월", value: 9 },
    { title: "11월", value: 10 },
    { title: "12월", value: 11 },
  ];

  return (
    <div className="relative plr-16 ptb-24 flex flex-col items-center">
      <div className="w-full m-auto">
        <div className="flex justify-between items-center mb-[24px]">
          <button className="p-[10px]" onClick={handlePrevMonth}>
            <span className="_sprite_ _sprite_17_3_"></span>
          </button>
          <button
            className="p-[10px] text-B16B"
            onClick={() => {
              setSelectYrMth(true);
            }}
          >
            {currentDate.format("YYYY. MMMM ")}
          </button>
          <button className="p-[10px]" onClick={handleNextMonth}>
            <span className="_sprite_ _sprite_17_4_"></span>
          </button>
        </div>
        <div className="grid grid-cols-7 mb-[8px]">
          <div className="text-center pt-[11px] pb-[11px] -text--color-danger-main">
            일
          </div>
          <div className="text-center pt-[11px] pb-[11px]">월</div>
          <div className="text-center pt-[11px] pb-[11px]">화</div>
          <div className="text-center pt-[11px] pb-[11px]">수</div>
          <div className="text-center pt-[11px] pb-[11px]">목</div>
          <div className="text-center pt-[11px] pb-[11px]">금</div>
          <div className="text-center pt-[11px] pb-[11px] -text--color-danger-main">
            토
          </div>
        </div>
        {renderCalendar()}
      </div>
      {selectYrMth && (
        <div className="fixed rounded-[4px] -bg--color-neutral0 p-[20px] flex flex-col gap-[30px] shadow-custom-shadow">
          <div className="flex flex-row items-center justify-between">
            <button
              className="p-[10px]"
              onClick={() => {
                setCloneCurrentDate(
                  cloneCurrentDate.clone().subtract(1, "year")
                );
              }}
            >
              <span className="_sprite_ _sprite_17_3_"></span>
            </button>
            <span className="text-B16B">{cloneCurrentDate.format("YYYY")}</span>
            <button
              className="p-[10px]"
              onClick={() => {
                setCloneCurrentDate(cloneCurrentDate.clone().add(1, "year"));
              }}
            >
              <span className="_sprite_ _sprite_17_4_"></span>
            </button>
          </div>
          <div className="grid grid-cols-3 gap-[6px]">
            {mthArray.map((idx, num) => {
              return (
                <button
                  key={num}
                  className={`py-[10px] px-[36px] -bg--color-neutral3 text-B14B -text--color-neutral50 focus:-text--color-primary-main`}
                  onClick={() => {
                    setCloneCurrentDate(
                      cloneCurrentDate.clone().month(idx.value)
                    );
                  }}
                >
                  {idx.title}
                </button>
              );
            })}
          </div>
          <div className="">
            <button
              className="w-1/2 py-[8px] -text--color-neutral50 hover:-text--color-primary-main text-B14B"
              onClick={() => setSelectYrMth(false)}
            >
              닫기
            </button>
            <button
              className="w-1/2 py-[8px] -text--color-neutral50 hover:-text--color-primary-main text-B14B"
              onClick={() => {
                setSelectYrMth(false);
                setCurrentDate(cloneCurrentDate);
              }}
            >
              적용
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CouncelFilterCalendarMobile;
