import http from "@/utils/api/http/http";

export async function getCouncelDetailList(id: any) {
  return await http.get(`/api/councel/detail/list`, {
    params: { process_idx: id?.process },
  });
}

export async function getCouncelDetailResponse(id: any, listParams: any) {
  return await http.post(`/api/councel/detail`, {
    admin_idx: localStorage.getItem("admin_idx"),
    user_idx: id.user,
    process_idx: id.process,
    address_idx_list: listParams,
  });
}
