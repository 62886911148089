import React from "react";

import { closePortal } from "@/utils/func/common/modalController";
import { childrenType } from "@/types/common";

interface propsType extends childrenType {
  value: string;
  callback: (arg: any) => void;
}

const ModalBackgroundLayout = ({ children, value, callback }: propsType) => {
  const handlePortal = () => {
    if (
      value === "calendar-fix-schedule-web" ||
      value === "user" ||
      value === "councel-assign-manager-web" ||
      value === "councel-fix-userinfo-web" ||
      value === "councel-filter-calendar-mobile" ||
      value === "councel-councelnote-calendar-mobile" ||
      value === "entity-password-web" ||
      value === "councel-filtering-web" ||
      value === "councel-field-web" ||
      value === "calendar-show-schedule-web"
    ) {
      return null;
    }

    closePortal(callback);
  };

  const dynamicClassName =
    !(
      value === "councel-add-councelnote-mobile" ||
      value === "councel-fix-councelnote-mobile"
    ) && "-bg--color-black-opacity";

  return (
    <section
      className={`fixed left-0 right-0 bottom-0  flex items-center justify-center z-50 h-[calc(100vh)] ${dynamicClassName}`}
      onClick={() => {
        handlePortal();
      }}
    >
      {children}
    </section>
  );
};

export default ModalBackgroundLayout;
