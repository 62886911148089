import React from "react";

import { useRecoilState } from "recoil";

import moment from "moment";

import { selectedDetailCouncelNote } from "@/stores/page/councelDetail";
import { moveCouncelNoteList } from "@/stores/common/Mobile";

const MobileCouncelNoteList = ({ data, num, reverseCount }: any) => {
  // 상담 노트 리스트 중 선택한 상담 노트 정보
  const [selectedCouncelNote, setSelectedCouncelNote] = useRecoilState(
    selectedDetailCouncelNote
  );

  // 상담 노트 리스트 중 클릭했을 시
  const [showNote, setShowNote] = useRecoilState(moveCouncelNoteList);

  const addAltToImageTag = (html: any, alt: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const image = doc.querySelector("img");

    if (image) {
      const textNode = doc.createTextNode(alt); // 대체 텍스트를 생성
      // image.parentNode.replaceChild(textNode, image); // 이미지 태그를 대체

      const parent = image.parentNode;

      if (parent) {
        parent.replaceChild(textNode, image); // 이미지 태그를 대체
      }
    }

    return doc.body.innerHTML;
  };

  return (
    <article
      className={`border-solid border-l-[8px] rounded-[8px] ptb-8 pl-[12px] pr-[12px]
      ${data.councel_type === 1 && "-border-l--color-etc-1"}
      ${data.councel_type === 2 && "-border-l--color-etc-2"}
      ${data.councel_type === 3 && "-border-l--color-etc-3"}
      `}
    >
      <p className="flex flex-row justify-between items-center pt-[4px] pb-[4px]">
        <span
          className={`-text--label-12b font-700
        ${data.councel_type === 1 && "-text--color-etc-1"}
        ${data.councel_type === 2 && "-text--color-etc-2"}
        ${data.councel_type === 3 && "-text--color-etc-3"}`}
        >
          {data.councel_type === 1 && "상담"}
          {data.councel_type === 2 && "계약"}
          {data.councel_type === 3 && "공사"}- {reverseCount}
        </span>
        <span className="-text--label-12b font-400 -text--color-neutral34">
          {moment(data.councel_datetime).add(9, "hours").format("YYYY.MM.DD")}
        </span>
      </p>
      <pre
        className="overflow-hidden h-[24px] whitespace-nowrap text-ellipsis -text--body-14m font-500 pt-[4px] pb-[4px]"
        dangerouslySetInnerHTML={{
          __html: addAltToImageTag(data.councel_detail, "이미지 파일 / "),
        }}
      ></pre>
      <p className="flex flex-row gap-[4px] items-center -text--color-neutral50 pt-[4px] pb-[4px]">
        <span className="-text--body-14b font-700">담당자</span>
        <span className="-text--body-14m font-500">{data.admin_name}</span>
      </p>
    </article>
  );
};

export default MobileCouncelNoteList;
