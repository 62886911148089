import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";

import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil";

import http from "@/utils/api/http/http";

import {
  responseData,
  responseDataInfinite,
  additionalResponseData,
} from "@/stores/common/data";
import { pageState, pageStateInCouncelPage } from "@/stores/common/navigation";

import { moveCouncelNoteList } from "@/stores/common/Mobile";
import {
  councelNoteAddressIdx,
  selectedDetailCouncelNote,
  selectedDetailCouncelNoteIndex,
  selectedNote,
} from "@/stores/page/councelDetail";

import MobileUserInfoLayout from "@/layout/MobileUserInfoLayout";
import MobileUploadFileLayout from "@/layout/MobileUploadFileLayout";
import MobileCouncelNoteLayout from "@/layout/MobileCouncelNoteLayout";
import MobileCommentLayout from "@/layout/MobileCommentLayout";

import MobileStatusInfo from "@/components/cards/MobileStatusInfo";
import MobileCustomerInfo from "@/components/cards/MobileCustomerInfo";
import MobileLandInfo from "@/components/cards/MobileLandInfo";
import MobileCouncelInfo from "@/components/cards/MobileCouncelInfo";
import MobileUploadFile from "@/components/cards/MobileUploadFile";
import MobileCouncelNoteList from "@/components/cards/MobileCouncelNoteList";
import MobileSelectedCouncelNote from "@/components/cards/MobileSelectedCouncelNote";
import MobileComment from "@/components/cards/MobileComment";

import BackButton from "@/components/buttons/BackButton";
import FixUserInfo from "@/components/buttons/FixUserInfo";
import DownloadPdf from "@/components/buttons/DownloadPdf";
import AddCouncelNote from "@/components/buttons/AddCouncelNote";
import PrevCouncelNote from "@/components/buttons/PrevCouncelNote";
import NextCouncelNote from "@/components/buttons/NextCouncelNote";

import Comment from "@/components/inputs/Comment";

import CouncelDetailFooter from "@/components/footer/CouncelDetailFooter";
import {
  getCouncelDetailCouncelData,
  getCouncelDetailData,
} from "@/utils/api/pages/councelDetailMobile";

export default function CouncelDetailMobile() {
  const id: any = useParams();

  const resetInfiniteData = useResetRecoilState(responseDataInfinite);

  const resetData = useResetRecoilState(responseData);
  const [data, setData] = useRecoilState(responseData);

  const [additionalData, setAdditionalData] = useRecoilState(
    additionalResponseData
  );

  // 상담 노트 내 푸터에서 제어하는 페이지 상태 값
  const footerPageState = useRecoilValue(pageStateInCouncelPage);
  const [, setSelectPage] = useRecoilState(pageState);

  const [, setSelectedNoteInfo] = useRecoilState(selectedNote);

  // 상담 노트 리스트 중 선택한 상담 노트 정보
  const [selectedCouncelNote, setSelectedCouncelNote] = useRecoilState(
    selectedDetailCouncelNote
  );

  const [, setAddressIdxList] = useRecoilState(councelNoteAddressIdx);

  /**
   * @description 모바일 버전 상담노트 클릭 시 무한 스크롤, data 초기화
   *
   * @param id.process
   */
  useEffect(() => {
    setSelectPage("councel");

    setSelectedNoteInfo((prev) => {
      return {
        ...prev,
        user: id.user,
        process: id.process,
      };
    });

    resetInfiniteData();
    resetData();

    try {
      // let parameter = { process_idx: id.process };

      getCouncelDetailCouncelData({ params: { process_idx: id.process } }).then(
        (res) => {
          setAddressIdxList(res.data.address_idx_list);

          getCouncelDetailData({
            user_idx: id.user,
            process_idx: id.process,
            address_idx_list: [...res.data.address_idx_list],
            admin_idx: localStorage.getItem("admin_idx"),
          }).then((res: any) => {
            setData(res.data.data);
          });
        }
      );

      http
        .get(`/api/councel/detail/list`, {
          params: { process_idx: id.process },
        })
        .then((res) => {
          setAdditionalData(res.data.data);
        });
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  const refHeader = useRef();
  const refBody1 = useRef();
  const refBody2 = useRef();

  const [selectedNum, setSelectedNum] = useRecoilState(
    selectedDetailCouncelNoteIndex
  );

  // 상담 노트 리스트 중 클릭했을 시
  const [showNote, setShowNote] = useRecoilState(moveCouncelNoteList);

  const goDetailCouncelNotePage = (num: any) => {
    setSelectedNum(num);
    setShowNote(!showNote);

    setSelectedCouncelNote(additionalData.councel[num]);
  };

  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    setSelectedCouncelNote(additionalData?.councel[selectedNum]);
  }, [selectedNum]);

  return (
    <>
      {footerPageState === 1 && (
        <>
          <BackButton />
          <MobileUserInfoLayout>
            <MobileStatusInfo data={data.process} />
            <MobileCustomerInfo data={data.user} />
            <MobileLandInfo data={data.address_jibun} />
            <MobileCouncelInfo data={data.user} />
            <section className="pt-[8px] pb-[12px] plr-16 -bg--color-neutral0 gap-[8px] flex flex-col">
              <FixUserInfo />
              <DownloadPdf
                refHeader={refHeader}
                refBody1={refBody1}
                refBody2={refBody2}
              />
            </section>
          </MobileUserInfoLayout>
        </>
      )}

      {footerPageState === 3 && (
        <>
          <MobileCommentLayout commentLength={data?.comment?.length}>
            {data?.comment?.map((item: any, index: any) => {
              return <MobileComment item={item} key={index} />;
            })}
            {data?.comment?.length === 0 && (
              <article className="w-full h-[calc(100vh-48px-44px-76px)] flex flex-col items-center justify-center">
                <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -text--body-14r font-400 -text--color-neutral50">
                  댓글이 없어요.
                </span>
              </article>
            )}
          </MobileCommentLayout>
          <Comment />
        </>
      )}

      {footerPageState === 4 && (
        <MobileUploadFileLayout fileLength={data?.resource?.length}>
          {data.resource?.map((data: any, num: number) => {
            return <MobileUploadFile data={data} index={num} key={num} />;
          })}
          {data.resource?.length === 0 && (
            <article className="w-full h-[calc(100vh-48px-44px-76px)] flex flex-col items-center justify-center">
              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -text--body-14r font-400 -text--color-neutral50">
                첨부파일이 없어요.
              </span>
            </article>
          )}
        </MobileUploadFileLayout>
      )}

      {footerPageState === 2 && (
        <>
          <MobileCouncelNoteLayout>
            {!showNote &&
              additionalData?.councel?.map((data: any, num: number) => {
                let count = additionalData?.councel.filter(
                  (item: any, index: any) => {
                    return (
                      item.councel_type === data.councel_type && index <= num
                    );
                  }
                ).length;

                let reverseCount =
                  additionalData?.councel.filter((item: any, index: any) => {
                    return item.councel_type === data.councel_type;
                  }).length -
                  count +
                  1;

                return (
                  <div
                    className="flex flex-col gap-[12px] overflow-scroll"
                    onClick={() => goDetailCouncelNotePage(num)}
                    style={{
                      boxShadow: "0px 3px 8px 0px rgba(108, 108, 108, 0.12)",
                    }}
                  >
                    <MobileCouncelNoteList
                      data={data}
                      num={num}
                      reverseCount={reverseCount}
                      key={num}
                    />
                  </div>
                );
              })}

            {!!showNote && (
              <>
                <MobileSelectedCouncelNote
                  data={selectedCouncelNote}
                  state={buttonState}
                  callback={setButtonState}
                />
              </>
            )}

            {!!showNote && buttonState === true && (
              <>
                <PrevCouncelNote
                  data={additionalData}
                  num={selectedNum}
                  callback={setSelectedNum}
                />
                <NextCouncelNote
                  data={additionalData}
                  num={selectedNum}
                  callback={setSelectedNum}
                />
              </>
            )}

            {additionalData?.councel?.length === 0 && (
              <>
                <div className="py-[22px]"></div>
                <article className="w-full h-[calc(100vh-48px-44px-76px)] flex flex-col items-center justify-center">
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -text--body-14r font-400 -text--color-neutral50">
                    작성된 상담노트가 없어요.
                  </span>
                </article>
              </>
            )}
          </MobileCouncelNoteLayout>
          <AddCouncelNote />
        </>
      )}

      <CouncelDetailFooter />
    </>
  );
}
