import React, { useEffect, useState } from "react";

import { useRecoilValue, useSetRecoilState } from "recoil";

import { showPortal } from "@/utils/func/common/modalController";

import MakePdf from "@/utils/func/pdf/makePdf";

import { responseData } from "@/stores/common/data";
import { modalInfo } from "@/stores/common/modal";

import styled from "styled-components";

const UserInfo = ({ refHeader, refBody1, refBody2 }) => {
  const data = useRecoilValue(responseData);

  let fileName = data?.user?.user_name;

  const setModal = useSetRecoilState(modalInfo);

  const [hovered, setHovered] = useState(false);

  // 상담 정보 수정 버튼 === 1, 상담 이력 다운로드 === 2 / string보다 num이 가볍기 때문에 num으로 str 대체
  const [isHover, setIsHover] = useState(null);

  const [hiddenSection, setHiddenSection] = useState(false);

  const [position, setPosition] = useState({ x: -140, y: -100 });

  // const handleMouseMove = (e) => {
  //   setPosition({ x: e.clientX, y: e.clientY });
  // };

  // useEffect(() => {
  //   document.addEventListener("mousemove", handleMouseMove);
  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  return (
    <main className="fixed flex flex-col top-[24px] h-[calc(100%-48px)] max-h-[1032px]">
      <section className="border border-solid -border--color-neutral6 h-[calc(100%-44px-44px-8px)] flex flex-col gap-[24px] py-[16px] rounded-[12px] w-[264px] mb-[8px] overflow-y-auto custom-scrollbar">
        <div className="px-[16px] flex flex-col gap-[16px]">
          <header className="-text--color-primary-main text-B16B">
            고객 정보
          </header>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">기본정보</span>
            <div className="flex flex-col gap-[8px]">
              {data?.user?.user_name === null && (
                <span className={`text-B16M  -text--color-neutral100`}>
                  비회원
                </span>
              )}
              {data?.user?.user_name !== null && (
                <>
                  <span
                    className={`text-B16M ${
                      data?.user?.user_name
                        ? "-text--color-neutral100"
                        : "-text--color-neutral20"
                    }`}
                  >
                    {data?.user?.user_name
                      ? data?.user?.user_name
                      : "내용 없음"}
                  </span>
                  <span
                    className={`text-B16M ${
                      data?.user?.user_phone
                        ? "-text--color-neutral100"
                        : "-text--color-neutral20"
                    }`}
                  >
                    {data?.user?.user_phone
                      ? data?.user?.user_phone
                      : "내용 없음"}
                  </span>
                  <span
                    className={`text-B16M ${
                      data?.user?.user_id
                        ? "-text--color-neutral100"
                        : "-text--color-neutral20"
                    }`}
                  >
                    {data?.user?.user_id ? data?.user?.user_id : "내용 없음"}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">프로젝트명</span>
            <span
              className={`text-B16M truncate ${
                !data?.process?.process_name && "-text--color-neutral20"
              }`}
            >
              {data?.process?.process_name
                ? data?.process?.process_name
                : "내용 없음"}
            </span>
          </div>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">건축 용도</span>
            <span
              className={`text-B16M ${
                !data?.user?.detail_use && "-text--color-neutral20"
              }`}
            >
              {data?.user?.detail_use ? data?.user?.detail_use : "내용 없음"}
            </span>
          </div>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">건축 규모</span>
            <span
              className={`text-B16M ${
                !data?.user?.detail_size && "-text--color-neutral20"
              }`}
            >
              {data?.user?.detail_size ? data?.user?.detail_size : "내용 없음"}
            </span>
          </div>
        </div>
        <div className="px-[16px] flex flex-col gap-[16px]">
          <header className="flex flex-row justify-between">
            <span className="-text--color-primary-main text-B16B">
              필지 정보
            </span>
            <button
              className="flex flex-row gap-[2px] items-center"
              onClick={() => {
                showPortal(setModal, true, "councel-field-web");
              }}
            >
              <span className="text-B14B -text--color-neutral50">
                자세히 보기
              </span>
              <span className="_sprite_ _sprite_3_4_"></span>
            </button>
          </header>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">입력주소</span>
            <div
              className="text-B16M flex flex-row justify-between items-center"
              onClick={() => setHiddenSection(!hiddenSection)}
            >
              <span>{data.address_jibun && data?.address_jibun[0]}</span>
              {data?.address_jibun?.length > 1 && (
                <span
                  className={`_sprite_  ${
                    !hiddenSection ? "_sprite_4_4_" : "_sprite_4_1_"
                  }`}
                ></span>
              )}
            </div>
            {hiddenSection &&
              data.address_jibun.map((v, num) => {
                if (num === 0) {
                  return null;
                }
                return (
                  <span>{data.address_jibun && data?.address_jibun[num]}</span>
                );
              })}

            <span className="-text--color-neutral50 text-B16M">{`총 ${
              data.address_jibun && data?.address_jibun.length
            }개`}</span>
          </div>
        </div>
        <div className="px-[16px] flex flex-col gap-[16px]">
          <header className="flex flex-row justify-between">
            <span className="-text--color-primary-main text-B16B">
              상담 정보
            </span>
            <button
              className="flex flex-row gap-[2px] items-center"
              onClick={() => window.open(data.process.history_file, "_blank")}
            >
              <span className="text-B14B -text--color-neutral50">
                보고서 보기
              </span>
              <span className="_sprite_ _sprite_3_4_"></span>
            </button>
          </header>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">
              고객 적극도
            </span>
            <span
              className={`text-B16M ${
                !data?.user?.detail_positive && "-text--color-neutral20"
              }`}
            >
              {data?.user?.detail_positive === 1 && "상"}
              {data?.user?.detail_positive === 2 && "중"}
              {data?.user?.detail_positive === 3 && "하"}
              {data?.user?.detail_positive === null && "내용 없음"}
            </span>
          </div>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">대출 연계</span>
            <span
              className={`text-B16M ${
                !data?.user?.detail_loans && "-text--color-neutral20"
              }`}
            >
              {data?.user?.detail_loans === true && "필요"}
              {data?.user?.detail_loans === false && "불필요"}
              {data?.user?.detail_loans === null && "내용 없음"}
            </span>
          </div>
          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">예산액</span>
            <span
              className={`text-B16M ${
                !data?.user?.detail_price && "-text--color-neutral20"
              }`}
            >
              {data?.user?.detail_price !== null
                ? `${data?.user?.detail_price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`
                : "내용 없음"}
            </span>
          </div>

          <div className="flex flex-col gap-[4px]">
            <span className="-text--color-neutral50 text-B12B">
              희망 연면적
            </span>
            <span
              className={`text-B16M ${
                !data?.user?.detail_needs && "-text--color-neutral20"
              }`}
            >
              {data?.user?.detail_needs !== null
                ? `${data?.user?.detail_needs
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}평`
                : "내용 없음"}
            </span>
          </div>
        </div>
      </section>
      <section className="flex flex-col gap-[8px]">
        {data?.process?.process_check === 2 ||
        data?.process?.process_check === 9 ? (
          <button
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={`relative w-full rounded-[4px] py-[10px] flex flex-row gap-[2px] items-center justify-center -bg--color-primary-surface -text--color-primary-main cursor-default`}
          >
            <span>상담 정보 수정</span>
            <span className={`_sprite_ _sprite_16_6_`}></span>
            <BubbleBox hovered={hovered} top={position.y} left={position.x}>
              취소/완료된 상담은 수정할 수 없어요.
            </BubbleBox>
          </button>
        ) : (
          <button
            onClick={() => {
              showPortal(setModal, true, "councel-fix-userinfo-web");
            }}
            className={`relative w-full rounded-[4px] py-[10px] flex flex-row gap-[2px] items-center justify-center -bg--color-primary-surface -text--color-primary-main`}
          >
            <span>상담 정보 수정</span>
            <span className={`_sprite_ _sprite_16_6_`}></span>
          </button>
        )}

        <button
          onClick={() => {
            MakePdf(refHeader, refBody1, refBody2, fileName);
          }}
          className={`w-full rounded-[4px] py-[10px] flex flex-row gap-[2px] items-center justify-center border border-solid -border--color-neutral12 ${
            isHover === 2 &&
            "-bg--color-primary-surface -text--color-primary-main"
          }`}
        >
          <span>상담 이력 다운로드</span>
          <span
            className={`_sprite_ ${
              isHover === 2 ? "_sprite_16_14_" : "_sprite_8_7_"
            }`}
          ></span>
        </button>
      </section>
    </main>
  );
};

export default UserInfo;

export const BubbleBox = styled.div`
  display: ${(props) => (props.hovered ? "block" : "none")};
  /* top: ${(props) => `${props.top + 10}px`}; */
  /* left: ${(props) => `${props.left + 10}px`}; */
  /* position: fixed; */
  top: -50px;
  position: absolute;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  pointer-events: none;
  z-index: 99;
  color: #111;
  font-size: 12px;
  font-weight: 400;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  &:after {
    border-top: 10px solid #fff; //말풍선 길이
    border-left: 10px solid transparent;
    border-right: 10px solid transparent; //말풍선 너비 조정
    border-bottom: 0px solid transparent;
    content: "";
    position: absolute;
    top: 33px; //말풍선 꼬리 위치 조정(width+90px)
    left: 50%; //말풍선 꼬리 위치 조정
    translate: -50%;
    /* border-radius: 20px; */
  }
`;
