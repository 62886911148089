import { atom } from "recoil";

export const mobileData = atom({
  key: "mobileData",
  default: {
    user: {
      user_name: "",
      user_phone: "",
      counsel_type: "",
    },
    address: {
      address_jibun: [],
    },
    councel: {},
    history_file: "",
  },
});

export const mobileDetailData = atom({
  key: "mobileDetailData",
  default: "",
});

export const mobileDataSurplus = atom({
  key: "mobileDataSurplus",
  default: "",
});

// 위 부분은 summary용으로 만든 전역 변수

// 상담노트 상세 정보 중 user 관련 정보만
export const tempUserResponseData = atom({
  key: "tempUserResponseData",
  default: "",
});

// 모바일 버전 내 상담 노트 등록 시 선택한 날짜
export const selectDate = atom({
  key: "selectDate",
  default: null,
});

// 모바일 버전 내 상담 노트 등록 시 선택한 날짜
export const tempSelectDate = atom({
  key: "tempSelectDate",
  default: null,
});

// 선택한 상담 노트 상세 정보 수정 시 임의로 저장하는 상태 값
export const tempSelectedDetailCouncelNote = atom({
  key: "tempSelectedDetailCouncelNote",
  default: {
    // 선택한 상담 노트 인덱스 값
    process_idx: "",
    // 상담, 계약, 공사 단계
    councel_type: "",
    // 상담 내용
    councel_detail: "",
    // 상담 날짜
    councel_datetime: "",
  },
});

// 상담 등록 수정 후 저장 시 상담 노트 리스트로 이동하기 위한 상태 값
export const moveCouncelNoteList = atom({
  key: "moveCouncelNoteList",
  default: false,
});
