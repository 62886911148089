import { childrenType } from "@/types/common";
import React from "react";

interface extChildrenType extends childrenType {
  commentLength: number | undefined;
}

const MobileCommentLayout = ({ children, commentLength }: extChildrenType) => {
  return (
    <main className="absolute -bg--color-neutral0 w-full mt-[48px] flex flex-col min-h-[calc(100%-48px-76px)] pb-[76px]">
      <section
        className={`w-full flex flex-col px-[16px] ${
          commentLength !== 0 && "pb-[61px]"
        }`}
      >
        <header className="w-full pt-[16px] pb-[8px] -bg--color-neutral0">
          <p className="flex flex-row gap-[8px] items-center">
            <span className="-text--body-14b font-700 -text--color-neutral50">
              댓글
            </span>
            <span className="-text--label-12m font-500 -text--color-primary-main">
              {`총 ${commentLength}개`}
            </span>
          </p>
        </header>
        {children}
      </section>
    </main>
  );
};

export default MobileCommentLayout;
