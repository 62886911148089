import React from "react";

import { useRecoilValue } from "recoil";

import { getInquiry } from "@/utils/api/pages/inquiry";

import InquiryFilter from "@/components/filter/InquiryFilter";
import InquiryHeader from "@/components/header/InquiryHeader";
import InquiryBody from "@/components/body/InquiryBody";
import InquiryFooter from "@/components/footer/InquiryFooter";

import { inquiryParameter } from "@/stores/common/parameter";

import { useQuery } from "@tanstack/react-query";

const Inquiry = () => {
  const parameter = useRecoilValue(inquiryParameter);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["InquiryData", parameter],
    queryFn: () => getInquiry(parameter),
    refetchOnWindowFocus: true,
  });

  return (
    <>
      <div className="custom-scrollbar relative w-full h-full flex flex-row overflow-auto">
        <div className="h-full flex w-[1128px] relative max-[1696px]:mt-0 max-[1696px]:mb-0 max-[1696px]:ml-[calc(248px+36px)] max-[1696px]:mr-[36px] min-[1697px]:my-0 min-[1697px]:mx-auto">
          <InquiryFilter data={data} />
          <InquiryHeader />
          <InquiryBody
            data={data}
            isError={isError}
            isLoading={isLoading}
            refetch={refetch}
          />
          <InquiryFooter data={data} />
        </div>
      </div>
    </>
  );
};

export default Inquiry;
