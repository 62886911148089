import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { modalInfo } from "@/stores/common/modal";

import { showPortal } from "@/utils/func/common/modalController";

interface propsType {
  data: any;
}

const MobileLandInfo = ({ data }: propsType) => {
  // 필지 정보 보여주기 위한 리스트 상태 제어 목적
  const [fieldList, setFieldList] = useState(false);

  const [, setModal] = useRecoilState(modalInfo);

  return (
    <section className="ptb-16 plr-16 -bg--color-neutral0 gap-[16px] flex flex-col mb-[12px]">
      <header className="flex flex-row items-center justify-between">
        <span className="-text--color-primary-main -text--header-16b font-700">
          필지 정보
        </span>
        <div
          className="flex flex-row gap-[2px] items-center"
          onClick={() => {
            showPortal(setModal, true, "councel-field-mobile");
          }}
        >
          <span className="-text--body-14b font-700 -text--color-neutral50">
            자세히 보기
          </span>
          <span className="_sprite_ _sprite_3_4_"></span>
        </div>
      </header>
      <article className="flex flex-col gap-[4px]">
        <span className="-text--color-neutral50 -text--label-12b font-700">
          입력주소
        </span>
        <div className="flex flex-col">
          <button
            className="flex flex-row justify-between"
            onClick={() => setFieldList(!fieldList)}
          >
            <span className="-text--body-16m font-500">
              {data?.length > 0 ? data[0] : ""}
            </span>
            <span
              className={`_sprite_ ${
                fieldList ? "_sprite_4_1_" : "_sprite_4_4_"
              }`}
            ></span>
          </button>
          {fieldList && (
            <>
              {data?.map((idx: any, num: number) => {
                if (num === 0) {
                  return null;
                }
                return <div className="mt-[4px] -text--body-16r">{idx}</div>;
              })}
              <div className="-text--body-14m font-500 mt-[4px] -text--color-neutral50">{`총 ${data.length}개`}</div>
            </>
          )}
        </div>
      </article>
    </section>
  );
};

export default MobileLandInfo;
