import React from "react";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import moment from "moment";

import { dateController } from "@/utils/func/common/date";

import LoadingMsg from "@/components/common/LoadingMsg";
import ErrorMsg from "@/components/common/ErrorMsg";

const Charts = (props) => {
  const {
    data,
    isLoading,
    isError,
    graphPeriod,
    setGraphPeriod,
    chartYr,
    setChartYr,
  } = props;

  const dataYr = [];
  const dataMon = [
    {
      label: "01",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "02",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "03",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "04",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "05",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "06",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "07",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "08",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "09",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "10",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "11",
      process_count: 0,
      done_count: 0,
    },
    {
      label: "12",
      process_count: 0,
      done_count: 0,
    },
  ];

  if (graphPeriod === false) {
    dataMon.forEach((item, index) => {
      const found = data?.process.find(
        (processItem) => processItem.label.slice(5, 7) === item.label
      );

      if (found) {
        dataMon[index].process_count = found.count;
        dataMon[index].label = found.label.slice(5, 7);
      }

      const foundAno = data?.done.find(
        (processItem) => processItem.label.slice(5, 7) === item.label
      );

      if (foundAno) {
        dataMon[index].done_count = foundAno.count;
      }

      // 추가된 부분: 문자열을 정수로 변환 후 다시 문자열로 변환하여 0을 제거함
      dataMon[index].label = `${parseInt(item.label, 10).toString()}월`;
    });
  } else if (graphPeriod === true) {
    data?.process.forEach((processObj) => {
      const label = processObj.label;
      // const label = `${processObj.label}년`;

      const processCount = processObj.count;

      const doneObj = data?.done.find((doneObj) => doneObj.label === label) || {
        count: 0,
      };

      const doneCount = doneObj.count;

      dataYr.push({
        label: label,
        process_count: processCount,
        done_count: doneCount,
      });
    });

    data?.done.forEach((doneObj) => {
      const label = doneObj.label;
      const processObj = data?.process.find(
        (processObj) => processObj.label === label
      ) || { count: 0 };
      const processCount = processObj.count;
      const existingObj = dataYr.find((obj) => obj.label === label);
      if (!existingObj) {
        dataYr.push({
          label,
          process_count: processCount,
          done_count: doneObj.count,
        });
      }
    });

    dataYr.sort((a, b) => a.label.localeCompare(b.label));

    dataYr.map((idx) => {
      idx.label = `${idx.label}년`;
    });
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && graphPeriod === false) {
      const selectedChart = dataMon.find(
        (idx) => idx.label === payload[0].payload.label
      );

      const prevSelectedChart = dataMon.find(
        (idx) =>
          String(Number(idx.label.replace("월", "")) + 1 + "월") ===
          payload[0].payload.label
      );

      const gapProcess =
        selectedChart?.process_count - prevSelectedChart?.process_count;

      const gapDone = selectedChart?.done_count - prevSelectedChart?.done_count;

      return (
        <div className="custom-tooltip">
          <p className="-text--color-neutral50 text-B12R">
            {chartYr.year()}.{payload[0].payload.label}
          </p>
          <p className="flex flex-row items-center h-[26px]">
            <span className="text-B12R pr-[4px]">신청</span>
            <span className="mr-[6px] text-B16B">
              {selectedChart?.process_count}
            </span>
            {gapProcess !== 0 && !isNaN(gapProcess) && (
              <span
                className={
                  gapProcess > 0
                    ? "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-primary-main bg-opacity-10 rounded-[36px] -text--color-primary-main text-B10B"
                    : "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-danger-main bg-opacity-10 rounded-[36px] -text--color-danger-main  text-B10B"
                }
              >
                {gapProcess > 0 ? `+${gapProcess}` : `${gapProcess}`}
              </span>
            )}
          </p>
          <p className="flex flex-row items-center h-[26px]">
            <span className="text-B12R pr-[4px]">완료</span>
            <span className="mr-[6px] text-B16B">
              {selectedChart?.done_count}
            </span>
            {gapDone !== 0 && !isNaN(gapDone) && (
              <span
                className={
                  gapDone > 0
                    ? "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-primary-main bg-opacity-10 rounded-[36px] -text--color-primary-main text-B10B"
                    : "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-danger-main bg-opacity-10 rounded-[36px] -text--color-danger-main  text-B10B"
                }
              >
                {gapDone > 0 ? `+${gapDone}` : `${gapDone}`}
              </span>
            )}
          </p>
        </div>
      );
    } else if (active && graphPeriod === true) {
      const label = payload[0].payload.label;

      const selectedChart = dataYr.find((idx) => idx.label === label);

      const prevSelectedChart = dataYr.find(
        (idx) =>
          String(Number(idx.label.replace("년", "")) + 1 + "년") === label
      );

      const gapProcess =
        selectedChart?.process_count - prevSelectedChart?.process_count;
      const gapDone = selectedChart?.done_count - prevSelectedChart?.done_count;

      return (
        <div className="custom-tooltip">
          <p className="-text--color-neutral50 text-B12R">{label}</p>
          <p className="flex flex-row items-center h-[26px]">
            <span className="text-B12R pr-[4px]">신청</span>
            <span className="mr-[6px]">{selectedChart?.process_count}</span>
            {gapProcess !== 0 && !isNaN(gapProcess) && (
              <span
                className={
                  gapProcess > 0
                    ? "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-primary-main bg-opacity-10 rounded-[36px] -text--color-primary-main"
                    : "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-danger-main bg-opacity-10 rounded-[36px] -text--color-danger-main"
                }
              >
                {gapProcess > 0 ? `+${gapProcess}` : `${gapProcess}`}
              </span>
            )}
          </p>
          <p className="flex flex-row items-center h-[26px]">
            <span className="text-B12R pr-[4px]">완료</span>
            <span className="mr-[6px]">{selectedChart?.done_count}</span>
            {gapDone !== 0 && !isNaN(gapDone) && (
              <span
                className={
                  gapDone > 0
                    ? "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-primary-main bg-opacity-10 rounded-[36px] -text--color-primary-main"
                    : "pt-[3px] pb-[3px] pl-[11px] pr-[11px] flex items-center justify-center -bg--color-danger-main bg-opacity-10 rounded-[36px] -text--color-danger-main"
                }
              >
                {gapDone > 0 ? `+${gapDone}` : `${gapDone}`}
              </span>
            )}
          </p>
        </div>
      );
    }

    return null;
  };

  if (isError) {
    return (
      <div className="border border-solid -border--color-neutral6 w-[744px] h-[350px] pt-[6px] pb-[16px] plr-16 -bg--color-neutral0 rounded-[12px] flex items-center justify-center">
        <ErrorMsg />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="border border-solid -border--color-neutral6 w-[744px] h-[350px] pt-[6px] pb-[16px] plr-16 -bg--color-neutral0 rounded-[12px] flex items-center justify-center">
        <LoadingMsg />
      </div>
    );
  }

  return (
    <div className="border border-solid -border--color-neutral6 w-[744px] h-[350px] pt-[6px] pb-[16px] plr-16 -bg--color-neutral0 rounded-[12px]">
      <div className="flex flex-row justify-between items-center h-[44px]">
        <div className="gap-[6px] flex flex-row items-center">
          <span className="text-H18B">상담처리 현황</span>
          {graphPeriod === false ? (
            <div className="flex flex-row items-center">
              <button
                className="flex w-[44px] h-[44px] items-center justify-center"
                type=""
                onClick={() => {
                  let value = chartYr;
                  let func = (v) => {
                    setChartYr(moment(v).subtract(1, "year"));
                  };
                  dateController(value, func);
                }}
              >
                <span className="_sprite_ _sprite_17_3_"></span>
              </button>
              <span className="-text--color-neutral50 text-B14B">
                {chartYr.year()}
              </span>
              <button
                className={
                  chartYr.year() === moment().year()
                    ? "flex w-[44px] h-[44px] items-center justify-center opacity-40 pointer-events-none"
                    : "flex w-[44px] h-[44px] items-center justify-center"
                }
                type=""
                onClick={() => {
                  let value = chartYr;
                  let func = (v) => {
                    setChartYr(moment(v).add(1, "year"));
                  };
                  dateController(value, func);
                }}
              >
                <span className="_sprite_ _sprite_17_4_"></span>
              </button>
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <p className="-text--color-neutral50 text-B14B w-[112px] h-[44px] flex items-center justify-center gap-[8px]">
                <span>{dataYr[0]?.label.slice(0, 4)}</span>
                <span>-</span>
                <span>{dataYr[dataYr.length - 1]?.label.slice(0, 4)}</span>
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-row gap-[16px] items-center">
          <div className="text-B12R flex flex-row items-center gap-[4px]">
            <div className="h-[5px] w-[5px] -bg--color-primary-main rounded-full"></div>
            <span className="-text--color-primary-main">신청</span>
          </div>
          <div className="text-B12R flex flex-row items-center gap-[4px]">
            <div className="h-[5px] w-[5px] -bg--color-etc-1 rounded-full"></div>
            <span className="-text--color-etc-1">완료</span>
          </div>
          <div className="relative flex flex-col items-center  overflow-hidden">
            <div className="flex">
              <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={graphPeriod}
                  readOnly
                />
                <div
                  onClick={() => setGraphPeriod(!graphPeriod)}
                  className=" w-[130px] h-[36px] -bg--color-neutral3 rounded-[14px] peer peer-checked:after:translate-x-full peer-checked:after:border-white after:absolute  after:bg-white after:border-white after:border after:rounded-[12px] after:shadow-md after:h-[30px] after:w-[62px] after:transition-all peer-checked:-bg--color-neutral3 z-20 flex items-center"
                  style={{ padding: "3px" }}
                >
                  <span
                    className={
                      graphPeriod
                        ? "-text--color-neutral50 absolute z-30 text-B14M left-[21px]"
                        : "-text--color-neutral100 absolute z-30 text-B14M left-[21px]"
                    }
                  >
                    월별
                  </span>
                  <span
                    className={
                      !graphPeriod
                        ? "-text--color-neutral50 absolute z-30 text-B14M right-[21px]"
                        : "-text--color-neutral100 absolute z-30 text-B14M right-[21px]"
                    }
                  >
                    연별
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <AreaChart
        width={702}
        height={283}
        data={graphPeriod === false ? dataMon : dataYr}
        margin={{ top: 15, right: 30, left: -10, bottom: 15 }}
      >
        <defs>
          <linearGradient id="process_color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#2456d0" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#2456d0" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="done_color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3ed2d2" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#3ed2d2" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="label"
          style={{
            fill: "#a5a5a5",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15.02px",
          }}
          axisLine={false}
          tickLine={false}
          tick={{ dy: 15 }}
        />
        <YAxis
          style={{
            fill: "#a5a5a5",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15.02px",
            width: 60,
          }}
          axisLine={false}
          tickLine={false}
          tick={{ dx: -15 }}
        />
        <CartesianGrid stroke="#ccc" strokeWidth={0.3} vertical={false} />
        <Tooltip
          content={<CustomTooltip />}
          viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
        />
        <Area
          type="monotone"
          dataKey="process_count"
          stroke="#2456d0"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#process_color)"
          animationBegin={0}
          animationDuration={2000}
          key={Math.random()}
          activeDot={{ r: 8 }}
        />
        <Area
          type="monotone"
          dataKey="done_count"
          stroke="#3ed2d2"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#done_color)"
          animationBegin={0}
          animationDuration={2500}
          key={Math.random()}
          activeDot={{ r: 8 }}
        />
      </AreaChart>
    </div>
  );
};

export default Charts;
