// 삭제 예정

import { atom } from "recoil";
import moment from "moment";

// 날짜, 카테고리, 내 상담 체크 시 필터
export const parameterCalendar = atom({
  key: "parameterCalendar",
  default: {
    category: "",
    select_month: moment().format(),
    mycheck: false,
  },
});

// 백엔드로부터 전달받는 저장된 모든 캘린더 데이터
export const responseCalendar = atom({
  key: "responseCalendar",
  default: "",
});

// 스케쥴 데이터를 받아서 깊은 복사 및 변경 (위 모든 캘린더 데이터를 복사)
export const copiedResponseCalendar = atom({
  key: "copiedResponseCalendar",
  default: [],
});

// copiedResponseCalendar 중 선택한 데이터 (show, fix, delete 모달에 사용)
export const selectedResponseCalendar = atom({
  key: "selectedResponseCalendar",
  default: "",
});

// 수정 시 한번 더 담아줄 상태 값 (body, footer 컴포넌트가 분리되어 있기 때문에)
// 캘린더 추가 시 기본 값 불러오기 위해
export const defaultCalendarData = atom({
  key: "defaultCalendarData",
  default: {
    calender_category: "서울사무소",
    calender_location: "",
    calender_start: moment().local().set({ hour: 12, minute: 0 }).format(),
    calender_end: moment().local().set({ hour: 12, minute: 30 }).format(),
    calender_name: "", // 고객 이름
    calender_phone: "",
    admin_idx: [], // 참여자 식별 정보 (idx만)
    admin_list: [],
    calender_detail: "",
  },
});

// 참석자 선택 모달에서 선택한 참석자를 담는 변수 / 확인을 누르면, defaultCalendarData로 전달 되도록
export const calendarAttendee = atom({
  key: "calendarAttendee",
  default: [],
});

// 고객 검색 위해 던지는 파람
export const userSearchParamCalendar = atom({
  key: "userSearchParamCalendar",
  default: "",
});

// 고객 정보 받는 변수
export const userResponseCalendar = atom({
  key: "userResponseCalendar",
  default: "",
});

// 참석 명단 체크하기 위해 상담자 리스트
export const councelResponseCalendar = atom({
  key: "councelResponseCalendar",
  default: [],
});
