import React, { useState, useEffect } from "react";

import { useRecoilState } from "recoil";

import { useLocation, useNavigate } from "react-router-dom";

import {
  mobileData,
  mobileDataSurplus,
  mobileDetailData,
} from "@/stores/common/Mobile";
import { tempQueryParameter } from "@/stores/common/route";
import { modalInfo } from "@/stores/common/modal";

import { showPortal } from "@/utils/func/common/modalController";

import http from "@/utils/api/http/http";

import styled from "styled-components";

const CouncelSummary = () => {
  const [, setModal] = useRecoilState(modalInfo);

  const [isOpenAddressArea, setIsOpenAddressArea] = useState(false);

  const [reportData, setReportData] = useRecoilState(mobileData);

  const [, setReportDetailData] = useRecoilState(mobileDetailData);

  const [, setResDataCouncel] = useRecoilState(mobileDataSurplus);

  const paramsOne = window.location.pathname.split("/")[3];
  const paramsTwo = window.location.pathname.split("/")[4];

  const setCouncelData = (list, detail) => {
    const tempReportData = {
      user: {
        user_name: detail.user.user_name,
        user_phone: detail.user.user_phone,
        counsel_type: detail.user.detail_type,
      },
      address: {
        address_jibun: detail.address_jibun,
      },
      councel: {
        detail_appendix: detail.user.detail_appendix,
        detail_use: detail.user.detail_use,
        detail_size: detail.user.detail_size,
      },
      history_file: detail.process.history_file,
    };

    setReportData(tempReportData);

    setReportDetailData(detail);
  };

  // Android & Kakao 접속 시 외부 연결
  const navigator = window.navigator;
  const useragt = navigator.userAgent.toLowerCase();
  const target_url = window.location.href;

  const location = useLocation();

  const [, setQueryParams] = useRecoilState(tempQueryParameter);

  const navigate = useNavigate();

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  /**
   * @description 카카오톡 인앱뷰, ios가 아닌 다른 브라우저의 경우 외부 링크로 이동
   */
  if (useragt.match(/kakaotalk/i) && !isIOS) {
    window.location.href =
      "kakaotalk://web/openExternal?url=" + encodeURIComponent(target_url);
  }

  useEffect(() => {
    async function getCouncelDetail() {
      try {
        const getCouncelListPromise = async () => {
          return await http.get(`/api/councel/detail/list`, {
            params: { process_idx: paramsTwo },
          });
        };

        const resCouncelList = (await getCouncelListPromise()).data.data;

        setResDataCouncel(resCouncelList);

        const addressIdxList = resCouncelList.address_idx_list;

        const resPost = (
          await http.post(`/api/councel/detail`, {
            user_idx: paramsOne,
            process_idx: paramsTwo,
            address_idx_list: addressIdxList,
            admin_idx: localStorage.getItem("admin_idx"),
          })
        ).data.data;

        setCouncelData(resCouncelList, resPost);
      } catch (err) {
        console.error(err);
      }
    }

    getCouncelDetail();
  }, []);

  return (
    <>
      <Main className="-bg--color-neutral0">
        <Section>
          <h2 className="text-B16B -text--color-primary-main">고객 정보</h2>
          <div className="flex flex-col gap-1">
            <span className="text-B12B -text--color-neutral50">기본정보</span>
            <div className="flex gap-2">
              <span>{reportData.user.user_name}</span>
              <span>
                {reportData.user.user_phone &&
                  reportData.user.user_phone.replace(
                    /(\d{3})(\d{4})(\d{4})/,
                    "$1-$2-$3"
                  )}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-B12B -text--color-neutral50">문의유형</span>
            <span>{reportData.user.counsel_type}</span>
          </div>
        </Section>
        <div className="-bg--color-neutral3 h-[12px]"></div>
        <Section>
          <div className="flex justify-between w-full">
            <h2 className="text-B16B -text--color-primary-main">필지 정보</h2>
            <PrimaryIconButton
              className="flex items-center gap-[2px]"
              onClick={() => {
                showPortal(setModal, true, "councel-field-mobile");
              }}
            >
              <span className="text-B14B -text--color-neutral50">
                자세히 보기
              </span>
              <span className="_sprite_ _sprite_3_4_"></span>
            </PrimaryIconButton>
          </div>
          <div className="relative flex flex-col w-full gap-1">
            <span className="text-B12B -text--color-neutral50">입력주소</span>
            <div className="relative">
              <span className="text-B16M">
                {reportData.address.address_jibun[0]}
              </span>
              {reportData.address.address_jibun.length > 1 && (
                <>
                  <input
                    id="isOpenAddress"
                    type="checkbox"
                    className="hidden"
                    onChange={() => setIsOpenAddressArea(!isOpenAddressArea)}
                  />
                  <label
                    htmlFor="isOpenAddress"
                    className={`absolute bottom-0 right-0`}
                  >
                    <span
                      className={`_sprite_ ${
                        isOpenAddressArea ? "_sprite_4_1_" : "_sprite_4_4_"
                      }`}
                    ></span>
                  </label>
                </>
              )}
            </div>
            {reportData.address.address_jibun.length > 1 &&
              isOpenAddressArea && (
                <div className="flex flex-col gap-1">
                  {reportData.address.address_jibun.map((address, idx) => {
                    if (idx === 0) return null;
                    return <span key={idx}>{address}</span>;
                  })}
                </div>
              )}
          </div>
        </Section>
        <div className="-bg--color-neutral3 h-[12px]"></div>
        <SectionLast>
          <h2 className="text-B16B -text--color-primary-main">상담 정보</h2>
          <div className="flex w-full">
            <div className="flex flex-col gap-1 grow">
              <span className="text-B12B -text--color-neutral50">건축규모</span>
              <span>{reportData.councel.detail_size}</span>
            </div>
            <div className="flex flex-col gap-1 grow">
              <span className="text-B12B -text--color-neutral50">건축용도</span>
              <span>{reportData.councel.detail_use}</span>
            </div>
          </div>
          <div className="flex flex-col gap-1 grow">
            <span className="text-B12B -text--color-neutral50">접수 내용</span>
            <pre className="text-ellipsis whitespace-break-spaces">
              {reportData.councel.detail_appendix}
            </pre>
          </div>
        </SectionLast>
      </Main>
      <div className="absolute -bg--color-neutral0 bottom-0 w-full px-[16px] pb-[12px] pt-[8px]">
        <button
          className={
            "p-[14px] w-full rounded-[6px] -text--color-neutral0 text-B16M -bg--color-primary-main cursor-pointer"
          }
          onClick={() => {
            navigate("/login");
            setQueryParams(location.pathname);
          }}
        >
          로그인
        </button>
      </div>
    </>
  );
};

const Main = styled.main`
  padding: 48px 0 0 0;
  height: 100%;
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  max-width: 640px;
  margin: 0 auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Section = styled.section`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  background: #ffffff;
  position: relative;
`;

const SectionLast = styled.section`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  background: #ffffff;
  position: relative;
`;

const PrimaryIconButton = styled.button`
  display: flex;

  align-items: center;
  gap: 2px;
  border-radius: 999px;
`;

export default CouncelSummary;
