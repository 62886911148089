import React from "react";

interface propsType {
  data: any;
}

const MobileStatusInfo = ({ data }: propsType) => {
  return (
    <section className="ptb-16 plr-16 -bg--color-neutral0 mb-[12px]">
      <header className="mb-[16px] -text--body-16b font-700 -text--color-primary-main">
        상태 정보
      </header>
      <article className="flex flex-row items-center mb-[6px]">
        <span
          className={`inline-block border border-solid -text--color-neutral0 -text--label-12b pl-[4px] pr-[4px] pt-[6px] pb-[6px] rounded-[4px] mr-[8px]
            ${data?.process_type === 1 && "-bg--color-etc-1"}
            ${data?.process_type === 2 && "-bg--color-etc-2"}
            ${data?.process_type === 3 && "-bg--color-etc-3"}`}
        >
          {data?.process_type === 1 && "상담"}
          {data?.process_type === 2 && "계약"}
          {data?.process_type === 3 && "공사"}
        </span>
        <span className="-text--body-16m">{data?.process_name}</span>
      </article>
      <article className="flex flex-row items-center">
        <span className="mr-[4px] text-label-12m font-500">담당자</span>
        <span className="mr-[8px] text-body-16m font-500">
          {data?.admin_name ? data?.admin_name : "미배정"}
        </span>
        <span
          className={`inline-block -text--label-12b pt-[4px] pb-[4px] pl-[6px] pr-[6px] rounded-full border font-700
            ${
              data?.process_check === 0 &&
              "-bg--color-primary-surface -text--color-primary-main"
            }
            ${
              data?.process_check === 1 &&
              "-bg--color-success-surface -text--color-success-main"
            }
            ${
              data?.process_check === 2 &&
              "-bg--color-neutral6 -text--color-neutral50"
            }
            ${
              data?.process_check === 9 &&
              "-bg--color-danger-surface -text--color-danger-main"
            }
            `}
        >
          {data?.process_check === 0 && "상담대기"}
          {data?.process_check === 1 && "진행 중"}
          {data?.process_check === 2 && "상담완료"}
          {data?.process_check === 9 && "상담취소"}
        </span>
      </article>
    </section>
  );
};

export default MobileStatusInfo;
