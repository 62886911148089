import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { useRecoilValue } from "recoil";

import { login } from "@/utils/api/auth/auth";

import LoginLayoutMobile from "@/layout/LoginLayoutMobile";

import { tempQueryParameter } from "@/stores/common/route";

import "@/styles/sprite.css";

const LoginMobile = () => {
  const location = useLocation();

  // css 변경 목적으로 사용 (로그인 실패 시)
  const [isLogin, setIsLogin] = useState(true);

  // 비밀번호 보이기
  const [showPassword, setShowPassword] = useState(false);

  // parameter에 아이디 비번 담으면 자동 로그인 되도록 하기 위함
  const urlSearchParams = new URLSearchParams(window.location.search);

  const id = urlSearchParams.get("id");
  const pw = urlSearchParams.get("pw");

  useEffect(() => {
    if (pw && id) {
      setUserAuth((prev) => ({ ...prev, username: id, userpassword: pw }));

      login({
        username: id,
        userpassword: pw,
        setIsLogin,
        location,
      });
    }
  }, []);

  const [userAuth, setUserAuth] = useState({
    username: "",
    userpassword: "",
  });

  // 쿼리 파라미터 값 포함해서 접근하는 경우 쿼리 값 임시 저장
  const queryParams = useRecoilValue(tempQueryParameter);

  const submitLogin = () => {
    if (queryParams === "") {
      login({
        ...userAuth,
        setIsLogin,
        location,
        undefined,
      });
    } else {
      // 쿼리 파라미터 값으로 로그인 하는 경우, 로그인 후 해당 페이지로 리다이렉팅
      login({
        ...userAuth,
        setIsLogin,
        undefined,
        queryParams,
      });
    }
  };

  return (
    <LoginLayoutMobile>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="pb-[24px]">
          <span className="_sprite_ _sprite_12_1_"></span>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col gap-[10px]">
            <span>아이디</span>
            <input
              className={`-text--body-16r font-400 w-full px-[16px] py-[12px] mb-[16px] box-border border border-solid rounded-[6px] focus:-border--color-primary-main ${
                !isLogin
                  ? "-border--color-danger-main"
                  : "-border--color-neutral50"
              }`}
              type="text"
              name="username"
              placeholder="아이디 입력"
              onChange={(e) =>
                setUserAuth((prev) => ({ ...prev, username: e.target.value }))
              }
              autoComplete="on"
            />
          </div>
          <div className="flex flex-col gap-[10px] relative">
            <span>비밀번호</span>
            <input
              className={`-text--body-16r font-400 w-full px-[16px] py-[12px] mb-[20px] box-border border border-solid rounded-[6px] focus:-border--color-primary-main ${
                !isLogin
                  ? "-border--color-danger-main"
                  : "-border--color-neutral50"
              }`}
              type={showPassword ? "text" : "password"}
              name="userpassword"
              placeholder="비밀번호 입력"
              autoComplete="on"
              onChange={(e) =>
                setUserAuth((prev) => ({
                  ...prev,
                  userpassword: e.target.value,
                }))
              }
            />
            <button
              className="absolute right-[12px] bottom-[32px]"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <span
                className={`_sprite_ ${
                  showPassword ? "_sprite_15_5_" : "_sprite_15_6_"
                }`}
              ></span>
            </button>
          </div>
          <div
            className={`w-full h-[46px] mb-[8px] ${
              !isLogin
                ? "flex items-center pl-[12px] pr-[16px] py-[10px] box-border border border-solid -border--color-danger-surface -bg--color-danger-surface rounded-[6px] -text--color-danger-main gap-[6px]"
                : ""
            }`}
          >
            {!isLogin && (
              <>
                <span className="_sprite_ _sprite_15_8_"></span>
                <span>아이디 또는 비밀번호가 일치하지 않습니다.</span>
              </>
            )}
          </div>
          <button
            className={
              userAuth.username !== "" && userAuth.userpassword !== ""
                ? "w-full p-[14px] rounded-[6px] -text--color-neutral0 text-B16M -bg--color-primary-main cursor-pointer mb-[24px]"
                : "w-full p-[14px] rounded-[6px] -text--color-neutral0 text-B16M -bg--color-primary-main cursor-pointer mb-[24px] opacity-50"
            }
            disabled={!(userAuth.username && userAuth.userpassword)}
            onClick={() => submitLogin()}
          >
            로그인
          </button>
        </div>
        <span className="-text--color-neutral50 text-B14R">
          담당자 : 김범석 (kbs@kcmodular.co.kr)
        </span>
      </div>
    </LoginLayoutMobile>
  );
};

export default LoginMobile;
