import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import {
  copiedManagerList,
  managerList,
  modalInfo,
} from "@/stores/common/modal";

import { defaultCalendarData } from "@/stores/page/calendar";

import http from "@/utils/api/http/http";

import CouncelRecoverCouncelWeb from "@/components/body/modal/web/CouncelRecoverCouncelWeb";

import CouncelFilterMobile from "@/components/body/modal/mobile/CouncelFilterMobile";

import CouncelAssignManagerWeb from "@/components/body/modal/web/CouncelAssignManagerWeb";

import CouncelFixCouncelnoteMobile from "@/components/body/modal/mobile/CouncelFixCouncelnoteMobile";
import CouncelAddCommentMobile from "@/components/body/modal/mobile/CouncelAddCommentMobile";

import EntityPasswordWeb from "@/components/body/modal/web/EntityPasswordWeb";

import CouncelAlertDangerWeb from "@/components/body/modal/web/CouncelAlertDangerWeb";

import CouncelStepWeb from "@/components/body/modal/web/CouncelStepWeb";
import CouncelFieldWeb from "@/components/body/modal/web/CouncelFieldWeb";
import CalendarShowScheduleWeb from "@/components/body/modal/web/CalendarShowScheduleWeb";
import CalendarFixScheduleWeb from "@/components/body/modal/web/CalendarFixScheduleWeb";
import CalendarAddScheduleWeb from "@/components/body/modal/web/CalendarAddScheduleWeb";
import CouncelFilteringWeb from "@/components/body/modal/web/CouncelFilteringWeb";
import CouncelFixUserWeb from "@/components/body/modal/web/CouncelFixUserWeb";
import EntityProgressCommon from "@/components/body/modal/common/EntityProgressCommon";
import CouncelAddCouncelnoteMobile from "@/components/body/modal/mobile/CouncelAddCouncelnoteMobile";
import CouncelDeleteCommentWeb from "@/components/body/modal/web/CouncelDeleteCommentWeb";

import CouncelFieldMobile from "@/components/body/modal/mobile/CouncelFieldMobile";
import CouncelUserinfoMobile from "@/components/body/modal/mobile/CouncelUserinfoMobile";

import CouncelNoteDetailCalendarWeb from "@/components/body/modal/web/CouncelNoteDetailCalendarWeb";

import CouncelNoteDeleteFileWeb from "@/components/body/modal/web/CouncelNoteDeleteFileWeb";

import Alert from "@/components/body/modal/web/Alert";

const ModalBody = () => {
  // 모달 & 모달 내 모달 상태 관리 (open & value)
  const [modal, setModal] = useRecoilState(modalInfo);

  const [councelList, setCouncelList] = useRecoilState(managerList);
  // 위 데이터를 깊은 복사
  const [searchSelectedManager, setSearchSelectedManager] =
    useRecoilState(copiedManagerList);
  // 백엔드로부터 전달받은 담당자 리스트 선택한 담당자

  // 캘린더 관련 상태 값 (추가 시 default로 호출하여 태그 채우고 / 수정 시 select data를 덮어쓰는 식으로)
  const [tempStates, setTempStates] = useRecoilState(defaultCalendarData);

  useEffect(() => {
    if (modal.value === "councel-assign-manager-web") {
      http.get(`/api/admin/search/manage`).then((res) => {
        setCouncelList(res.data.data.admin);
        setSearchSelectedManager(res.data.data.admin);
      });
    }
  }, [modal.value]);

  // 시작일이 마감일 이후의 날이면 마감일 변경
  useEffect(() => {
    if (tempStates.startDate > tempStates.endDate) {
      setTempStates((prev) => {
        return {
          ...prev,
          endDate: tempStates.startDate,
        };
      });
    }
  }, [tempStates.startDate, tempStates.endDate]);

  return (
    <React.Fragment>
      {/* 공통 */}
      {modal.value === "entity-progress-common" && <EntityProgressCommon />}
      {/* 웹 */}
      {modal.value === "councel-filtering-web" && <CouncelFilteringWeb />}
      {modal.value === "councel-field-web" && <CouncelFieldWeb />}
      {modal.value === "calendar-show-schedule-web" && (
        <CalendarShowScheduleWeb />
      )}
      {modal.value === "calendar-fix-schedule-web" && (
        <CalendarFixScheduleWeb />
      )}
      {modal.value === "councel-fix-userinfo-web" && <CouncelFixUserWeb />}
      {modal.value === "councel-step-web" && <CouncelStepWeb />}
      {modal.value === "calendar-add-schedule-web" && (
        <CalendarAddScheduleWeb />
      )}
      {modal.value === "councel-delete-comment-web" && (
        <CouncelDeleteCommentWeb />
      )}
      {modal.value === "councelnote-detail-calendar-web" && (
        <CouncelNoteDetailCalendarWeb />
      )}
      {modal.value === "councelnote-delete-file-web" && (
        <CouncelNoteDeleteFileWeb />
      )}

      {/* 모바일 */}
      {modal.value === "councel-filter-mobile" && <CouncelFilterMobile />}
      {modal.value === "councel-field-mobile" && <CouncelFieldMobile />}
      {modal.value === "councel-userinfo-mobile" && <CouncelUserinfoMobile />}
      {modal.value === "councel-add-councelnote-mobile" && (
        <CouncelAddCouncelnoteMobile />
      )}
      {modal.value === "councel-fix-councelnote-mobile" && (
        <CouncelFixCouncelnoteMobile />
      )}
      {modal.value === "councel-add-comment-mobile" && (
        <CouncelAddCommentMobile />
      )}

      {modal.value === "entity-password-web" && <EntityPasswordWeb />}
      {modal.value === "councel-assign-manager-web" && (
        <CouncelAssignManagerWeb />
      )}
      {modal.value === "councel-alert-danger-web" && <CouncelAlertDangerWeb />}
      {modal.value === "councel-recover-councel-web" && (
        <CouncelRecoverCouncelWeb />
      )}
      {modal.value === "alert-texteditor-case" && <Alert />}
    </React.Fragment>
  );
};

export default ModalBody;
