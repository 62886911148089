import React from "react";
import { useRecoilState } from "recoil";

import moment from "moment";

import { modalInModalInfo } from "@/stores/common/modal";
import { showPortal } from "@/utils/func/common/modalController";
import { tempEndDate, tempStartDate } from "@/stores/common/calendar";
import { tempCouncelParameter } from "@/stores/common/parameter";

const CouncelFilterMobile = () => {
  const [modal, setModal] = useRecoilState(modalInModalInfo);

  const [tempMobileFilter, setTempMobileFilter] =
    useRecoilState(tempCouncelParameter);

  const [selectedStartDate, setSelectedStartDate] =
    useRecoilState(tempStartDate);
  const [selectedEndDate, setSelectedEndDate] = useRecoilState(tempEndDate);

  let siteList = [
    "전지역",
    "서울",
    "부산",
    "대구",
    "인천",
    "광주",
    "대전",
    "울산",
    "세종",
    "경기",
    "강원",
    "충북",
    "충남",
    "전북",
    "전남",
    "경북",
    "경남",
    "제주",
  ];

  const handleCheckedSiteList = (e, v) => {
    if (e.currentTarget.checked && v === "전지역") {
      setTempMobileFilter((prev) => {
        return {
          ...prev,
          location: [""],
        };
      });
    }

    // 2. 전체를 제외한 나머지 선택 시 / 전체 false
    if (e.currentTarget.checked && v !== "전지역") {
      setTempMobileFilter((prev) => {
        // ""를 제거한 새로운 배열 생성
        const updatedLocation = prev.location.filter((item) => item !== "");

        // v 추가 (만약 v가 빈 문자열이 아닌 경우)
        if (v !== "") {
          updatedLocation.push(v);
        }

        return {
          ...prev,
          location: updatedLocation,
        };
      });
    }

    // 3. 체크 해제 시
    if (!e.currentTarget.checked) {
      setTempMobileFilter((prev) => {
        const checkedList = prev.location.filter((item) => item !== v);

        // 전부 check 해제 시 default 값으로 전체가 선택되도록
        return {
          ...prev,
          location: checkedList.length ? checkedList : [""],
        };
      });
    }
  };

  let stateList = ["전체", "상담대기", "상담 중", "상담완료", "상담취소"];

  const handleCheckedStepList = (e, v) => {
    setTempMobileFilter((prev) => {
      return {
        ...prev,
        process_type: v,
      };
    });
  };

  let statePeriod = ["1주", "1개월", "3개월", "6개월", "1년"];

  const handleCkeckedPeriodList = (v) => {
    if (v === "1주") {
      setSelectedStartDate(
        moment()
          .hour(0)
          .minute(0)
          .second(0)
          .subtract(6, "days")
          .locale("ko")
          .format()
      );
      setSelectedEndDate(
        moment().hour(0).minute(0).second(0).locale("ko").format()
      );
      setTempMobileFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment()
            .hour(0)
            .minute(0)
            .second(0)
            .subtract(6, "days")
            .locale("ko")
            .format(),
          endDate: moment().hour(0).minute(0).second(0).locale("ko").format(),
        };
      });
    }
    if (v === "1개월") {
      setSelectedStartDate(
        moment()
          .hour(0)
          .minute(0)
          .second(0)
          .subtract(1, "months")
          .locale("ko")
          .format()
      );
      setSelectedEndDate(
        moment().hour(0).minute(0).second(0).locale("ko").format()
      );
      setTempMobileFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment()
            .hour(0)
            .minute(0)
            .second(0)
            .subtract(1, "months")
            .locale("ko")
            .format(),
          endDate: moment().hour(0).minute(0).second(0).locale("ko").format(),
        };
      });
    }
    if (v === "3개월") {
      setSelectedStartDate(
        moment()
          .hour(0)
          .minute(0)
          .second(0)
          .subtract(3, "months")
          .locale("ko")
          .format()
      );
      setSelectedEndDate(
        moment().hour(0).minute(0).second(0).locale("ko").format()
      );
      setTempMobileFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment()
            .hour(0)
            .minute(0)
            .second(0)
            .subtract(3, "months")
            .locale("ko")
            .format(),
          endDate: moment().hour(0).minute(0).second(0).locale("ko").format(),
        };
      });
    }
    if (v === "6개월") {
      setSelectedStartDate(
        moment()
          .hour(0)
          .minute(0)
          .second(0)
          .subtract(6, "months")
          .locale("ko")
          .format()
      );
      setSelectedEndDate(
        moment().hour(0).minute(0).second(0).locale("ko").format()
      );
      setTempMobileFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment()
            .hour(0)
            .minute(0)
            .second(0)
            .subtract(6, "months")
            .locale("ko")
            .format(),
          endDate: moment().hour(0).minute(0).second(0).locale("ko").format(),
        };
      });
    }
    if (v === "1년") {
      setSelectedStartDate(
        moment()
          .hour(0)
          .minute(0)
          .second(0)
          .subtract(1, "years")
          .locale("ko")
          .format()
      );
      setSelectedEndDate(
        moment().hour(0).minute(0).second(0).locale("ko").format()
      );
      setTempMobileFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment()
            .hour(0)
            .minute(0)
            .second(0)
            .subtract(1, "years")
            .locale("ko")
            .format(),
          endDate: moment().hour(0).minute(0).second(0).locale("ko").format(),
        };
      });
    }
  };

  return (
    <React.Fragment>
      <div className="gap-[24px]">
        <div className="plr-16 ptb-16 flex flex-col gap-[8px]">
          <span className="text-B16B">지역</span>
          <div className="grid grid-cols-6 gap-[8px]">
            {siteList.map((v, num) => {
              const filterChecked = () => {
                if (
                  v === "전지역" &&
                  (tempMobileFilter.location.includes("") ||
                    tempMobileFilter.location.length === 0)
                ) {
                  return true;
                } else if (tempMobileFilter.location.indexOf(v) !== -1) {
                  return true;
                }
              };

              let checked = filterChecked();

              return (
                <label
                  key={num}
                  htmlFor={`site-${v}`}
                  className={
                    checked
                      ? "ptb-8 plr-12 border border-solid text-center rounded-[24px] -text--color-primary-main -border--color-primary-border -bg--color-primary-surface -text--body-14m font-500"
                      : "ptb-8 plr-12 border border-solid text-center rounded-[24px] -text--color-neutral50 -border--color-neutral12 -text--body-14m font-500"
                  }
                >
                  {v}
                  <input
                    id={`site-${v}`}
                    type="checkbox"
                    value={v}
                    checked={tempMobileFilter.location === v}
                    className="hidden"
                    onChange={(e) => handleCheckedSiteList(e, v)}
                  />
                </label>
              );
            })}
          </div>
        </div>
        <div className="plr-16 ptb-16 flex flex-col gap-[8px]">
          <span className="text-B16B">상태</span>
          <div className="grid grid-cols-4 gap-[8px]">
            {stateList.map((v, num) => {
              const checked = tempMobileFilter.process_type === v;
              return (
                <label
                  key={num}
                  htmlFor={`state-${v}`}
                  className={
                    checked
                      ? "ptb-8 plr-12 border border-solid text-center rounded-[24px] -text--color-primary-main -border--color-primary-border -bg--color-primary-surface"
                      : "ptb-8 plr-12 border border-solid text-center rounded-[24px] -text--color-neutral50 -border--color-neutral12"
                  }
                >
                  {v}
                  <input
                    id={`state-${v}`}
                    type="radio"
                    value={v}
                    checked={checked}
                    className="hidden"
                    onChange={(e) => handleCheckedStepList(e, v)}
                  />
                </label>
              );
            })}
          </div>
        </div>
        <div className="plr-16 ptb-16 flex flex-col gap-[8px]">
          <span className="text-B16B">기간</span>
          <div className="flex flex-row gap-[8px]">
            {statePeriod.map((v, num) => {
              const checked = tempMobileFilter.period === v;
              return (
                <>
                  <label
                    key={num}
                    htmlFor={v}
                    className={
                      checked
                        ? "font-700 ptb-8 pl-[12px] pr-[12px]  text-center -text--color-primary-main -border--color-primary-border -bg--color-primary-surface"
                        : "font-700 ptb-8 pl-[12px] pr-[12px]  text-center -text--color-neutral50 -border--color-neutral12"
                    }
                  >
                    {v}
                    <input
                      id={v}
                      type="radio"
                      value={v}
                      name="period"
                      checked={checked}
                      className="hidden"
                      onChange={() => handleCkeckedPeriodList(v)}
                    />
                  </label>
                </>
              );
            })}
          </div>
        </div>
        <div className="plr-16 ptb-8 flex flex-col gap-[10px]">
          <span className="text-B14M">날짜를 선택해 주세요</span>
          <div className="border border-solid -border--color-neutral12 plr-16 ptb-12 flex flex-row justify-center">
            <button
              onClick={() =>
                showPortal(setModal, true, "councel-filter-calendar-mobile")
              }
              className={
                tempMobileFilter.startDate === undefined
                  ? "-text--color-neutral20"
                  : "-text--color-neutral100"
              }
            >
              {tempMobileFilter.startDate === undefined
                ? moment().subtract(1, "years").format().slice(0, 10)
                : moment(selectedStartDate).format().slice(0, 10)}
            </button>
            <span className="-text--color-neutral50">&nbsp;-&nbsp;</span>
            <button
              onClick={() =>
                showPortal(setModal, true, "councel-filter-calendar-mobile")
              }
              className={
                tempMobileFilter.endDate === undefined
                  ? "-text--color-neutral20"
                  : "-text--color-neutral100"
              }
            >
              {tempMobileFilter.endDate === undefined
                ? moment().format().slice(0, 10)
                : moment(selectedEndDate).format().slice(0, 10)}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CouncelFilterMobile;
