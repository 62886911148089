import React from "react";
import http from "@/utils/api/http/http";
import { closePortal } from "@/utils/func/common/modalController";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  copiedResponseCalendar,
  responseCalendar,
  selectedResponseCalendar,
} from "@/stores/page/calendar";
import {
  modalInfo,
  modalInModalInfo,
  selectedFileList,
} from "@/stores/common/modal";
import moment from "moment";
import { Axios } from "axios";
import {
  councelNoteAddressIdx,
  selectedCommentIndex,
  urlParameterCouncel,
} from "@/stores/page/councelDetail";
import { additionalResponseData, responseData } from "@/stores/common/data";

const ModalFooterDeleteType = () => {
  const tempData = useRecoilValue(selectedResponseCalendar);

  const [modal, setModal] = useRecoilState(modalInfo);
  const [modal2, setModal2] = useRecoilState(modalInModalInfo);
  const [userCaldata, setUserCalData] = useRecoilState(responseCalendar);

  const [copiedData, setCopiedData] = useRecoilState(copiedResponseCalendar);

  const [selectedItems, setSelectedItems] = useRecoilState(selectedFileList);

  const copingDataFunc = (e) => {
    let array = JSON.parse(JSON.stringify(e.calender));

    array.forEach((element) => {
      // Calendar event에서 key값을 start, end로 받아야 해서 추가
      element.start = moment.utc(element.calender_start).local().format();
      element.end = moment.utc(element.calender_end).local().format();

      element.title = `${moment(element.start).format("HH:mm")} ${
        element.admin_list.length > 0 ? element.admin_list[0].admin_name : ""
      } ${
        element.admin_list.length > 1 ? `+${element.admin_list.length - 1}` : ""
      }`;
    });

    setCopiedData(array);
  };

  const [data, setData] = useRecoilState(responseData);
  const [additionalData, setAdditionalData] = useRecoilState(
    additionalResponseData
  );
  const id = useRecoilValue(urlParameterCouncel);
  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  async function getData() {
    try {
      const res = await http.get(`/api/councel/detail/list`, {
        params: { process_idx: id.process },
      });

      setAdditionalData({
        councel: [...res.data.data.councel],
      });

      const userRes = await http.post(`/api/councel/detail`, {
        user_idx: id.user,
        process_idx: id.process,
        admin_idx: localStorage.getItem("admin_idx"),
        address_idx_list: addressIdxList,
      });

      setData(userRes.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function deleteResource() {
    // case1 단일 파라미터로 전달
    try {
      const response = await http.delete(`/api/councel/resource`, {
        params: { resource_idx: selectedItems.join() },
      });
    } catch (err) {
      console.error(err);
    }

    setSelectedItems([]);
    getData();
  }

  async function fixUploadFile(e) {
    try {
      const response = await http.put(`/api/councel/resource`, null, {
        params: { resource_idx: e },
      });

      getData();
    } catch (err) {
      console.error(err);
    }
  }

  async function deleteScheduleData(idx) {
    try {
      await http.delete(`/api/calender`, {
        params: {
          calender_idx: idx,
        },
      });

      await http
        .get(`/api/calender`, { params: { select_month: new Date() } })
        .then((res) => {
          setUserCalData(res.data.data);

          copingDataFunc(res.data.data);
        });
    } catch (err) {
      console.error(err);
    }
  }

  const [selectedComment, setSelectedComment] =
    useRecoilState(selectedCommentIndex);

  async function deleteComment() {
    try {
      const response = await http.delete(`/api/councel/comment`, {
        params: { comment_idx: selectedComment },
      });

      getData();
    } catch (err) {
      console.error(err);
    }
  }

  const handleDelete = () => {
    if (modal.value === "councel-delete-comment-web") {
      deleteComment();
    }
    if (modal.value === "councelnote-delete-file-web") {
      deleteResource();
    } else if (modal2.value === "calendar-delete-schedule-web") {
      deleteScheduleData(tempData.calender_idx);
    }
  };

  return (
    <section className="w-full flex flex-row items-center justify-center">
      <button
        className="w-1/2 py-[14px] text-B16B -text--color-neutral50"
        onClick={() => {
          closePortal(setModal);
          closePortal(setModal2);
        }}
      >
        닫기
      </button>
      <button
        className="w-1/2 py-[14px] text-B16B -text--color-danger-main"
        onClick={() => {
          handleDelete();
          closePortal(setModal);
          closePortal(setModal2);
        }}
      >
        삭제
      </button>
    </section>
  );
};

export default ModalFooterDeleteType;
