import React from "react";
import { useRecoilState } from "recoil";
import { modalInModalInfo } from "@/stores/common/modal";

import CalendarSearchUserWeb from "@/components/body/modal/web/CalendarSearchUserWeb";
import CalendarSearchAttendeeWeb from "@/components/body/modal/web/CalendarSearchAttendeeWeb";
import CalendarDeleteScheduleWeb from "@/components/body/modal/web/CalendarDeleteScheduleWeb";

import CouncelFilterCalendarMobile from "@/components/body/modal/mobile/CouncelFilterCalendarMobile";
import CouncelCouncelnoteCalendarMobile from "@/components/body/modal/mobile/CouncelCouncelnoteCalendarMobile";

export default function ModalInModalBody() {
  const [modal, setModal] = useRecoilState(modalInModalInfo);

  return (
    <React.Fragment>
      {/* 공통 */}
      {/* 웹 */}
      {modal.value === "calendar-search-user-web" && <CalendarSearchUserWeb />}
      {modal.value === "calendar-search-attendee-web" && (
        <CalendarSearchAttendeeWeb />
      )}
      {modal.value === "calendar-delete-schedule-web" && (
        <CalendarDeleteScheduleWeb />
      )}
      {/* 모바일 */}
      {modal.value === "councel-filter-calendar-mobile" && (
        <CouncelFilterCalendarMobile />
      )}
      {modal.value === "councel-councelnote-calendar-mobile" && (
        <CouncelCouncelnoteCalendarMobile />
      )}
    </React.Fragment>
  );
}
