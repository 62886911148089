import axios from "@/utils/api/default/default";

export default {
  get: function (url: any, config?: any) {
    return axios.get(url, config);
  },
  post: function (url: any, body: any, config?: any) {
    return axios.post(url, body, config);
  },
  put: function (url: any, body: any, config?: any) {
    return axios.put(url, body, config);
  },
  delete: function (url: any, config?: any) {
    return axios.delete(url, config);
  },
};
