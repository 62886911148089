import React, { useEffect, useRef, useState } from "react";

import { Map as OlMap, View } from "ol";

import geoSource from "@/Assets/GeoJson/ctp_rvn.json";

import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

import { fromLonLat, get as getProjection, transformExtent } from "ol/proj";

import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";

// import axios from "axios";

import Feature from "ol/Feature";
import { Point } from "ol/geom";

import { Select } from "ol/interaction";
import { pointerMove } from "ol/events/condition";
import { Fill, Stroke, Style, Text, Icon } from "ol/style";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import point_icon from "@/Assets/Images/point_icon.svg";

import { navValue } from "@/stores/common/navigation";

import { pageController } from "@/utils/func/common/page";
import { councelParameter } from "@/stores/common/parameter";

const Map = (props) => {
  const { data, isLoading, isError } = props;

  const [mapObject, setMapObject] = useState({});

  const navigate = useNavigate();

  const setNavState = useSetRecoilState(navValue);

  const setDetailCouncelParams = useSetRecoilState(councelParameter);

  const mapRef = useRef();

  let geoData = geoSource;

  let geoVectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geoData),
  });

  let defaultStyle = new Style({
    fill: new Stroke({
      color: "#D0DEFE",
    }),
    stroke: new Stroke({
      color: "#ffffff",
    }),
  });

  let hoverStyle = new Style({
    fill: new Stroke({
      color: "#7594ff",
    }),
    stroke: new Stroke({
      color: "#ffffff",
    }),
  });

  let hoverStyle2 = (pointValue) =>
    new Style({
      image: new Icon({
        src: point_icon,
        scale: 1,
      }),
      text: new Text({
        text: pointValue.toString(),
        font: "16px sans-serif",
        fill: new Fill({
          color: "#7594ff",
        }),
        stroke: new Stroke({
          color: "#ffffff",
          width: 1,
        }),
        offsetY: "2",
      }),
    });

  let geojsonVectorLayer = new VectorLayer({
    source: geoVectorSource,
    style: defaultStyle,
  });

  const hoverSelect = new Select({
    condition: pointerMove,

    style: (features) => {
      const geometryType = features.getGeometry().getType();
      const pointValue = features.get("count");

      if (geometryType !== "Point") {
        return hoverStyle;
      } else if (geometryType === "Point") {
        return hoverStyle2(pointValue);
      }
    },
  });

  const points = [
    { location: "서울", position: [14135879.644205164, 4542088.840669091] },
    { location: "인천", position: [14102123.582654849, 4547951.022142988] },
    { location: "경기", position: [14184084.613999171, 4477396.250285246] },
    { location: "강원", position: [14286075.53146397, 4539777.491045849] },
    { location: "충남", position: [14089173.61614883, 4384731.795224985] },
    { location: "충북", position: [14231840.675549489, 4430066.603703072] },
    { location: "세종", position: [14166496.134453835, 4395078.493395961] },
    { location: "대전", position: [14206715.263708659, 4355724.53254041] },
    { location: "경북", position: [14364945.390691005, 4407290.615435153] },
    { location: "대구", position: [14317434.232020436, 4303028.59303405] },
    { location: "울산", position: [14402226.288157672, 4265515.301303602] },
    { location: "경남", position: [14277737.701603552, 4205829.929599487] },
    { location: "부산", position: [14353361.144194975, 4203576.3380044] },
    { location: "전북", position: [14148440.113047168, 4259351.426328686] },
    { location: "광주", position: [14111559.965747356, 4210863.6815560325] },
    { location: "전남", position: [14105003.247739632, 4118083.237357269] },
    { location: "제주", position: [14089997.380380698, 3947865.0743742846] },
  ];

  useEffect(() => {
    mapRef.current = new OlMap({
      target: "jsonMap",
      layers: [geojsonVectorLayer],
      view: new View({
        projection: getProjection("EPSG:3857"),
        center: fromLonLat(
          [127.7050238469822, 35.9548913137558],
          getProjection("EPSG:3857")
        ),
        zoom: 6.8,
        maxZoom: 7.8,
        minZoom: 6.8,
        extent: transformExtent(
          [125.5, 33, 130, 38.5],
          "EPSG:4326",
          "EPSG:3857"
        ),
      }),
      controls: [],
    });

    mapRef.current.on("click", function (e) {
      let feature = mapRef.current.forEachFeatureAtPixel(
        e.pixel,
        function (feature) {
          let sidebarChecked = 1;
          let url = `/councel`;
          let func = {
            func1: (e) => setNavState(e),
            func2: (v) => navigate(v),
            func3: () =>
              setDetailCouncelParams((prev) => {
                return {
                  ...prev,
                  location: feature.values_.CTP_KOR_NM,
                  // process_type: 1,
                  mycheck: false,
                };
              }),
          };

          return pageController(sidebarChecked, url, func);
        }
      );
    });

    mapRef.current.addInteraction(hoverSelect);

    setMapObject({ mapRef });

    return () => mapRef.current.setTarget(undefined);
  }, [mapRef]);

  useEffect(() => {
    const matchedPoints = points.filter((point) => {
      const foundPoint =
        data && data.find((temp) => temp.location === point.location);

      if (foundPoint) {
        foundPoint.count = foundPoint.count || 0;
        point.count = foundPoint.count;
      }

      return Boolean(foundPoint);
    });

    const pointStyle = (point) =>
      new Style({
        image: new Icon({
          src: point_icon,
          scale: 1,
        }),
        text: new Text({
          text: point.count.toString(),
          font: "10px sans-serif",
          fill: new Fill({
            color: "#688ad7",
          }),
          stroke: new Stroke({
            color: "#ffffff",
            width: 1,
          }),
          offsetY: "2",
        }),
      });

    const pointFeatures = matchedPoints.map((point) => {
      const feature = new Feature({
        geometry: new Point(point.position),
      });

      feature.set("location", point.location);
      feature.set("count", point.count);

      feature.setStyle(pointStyle(point));

      return feature;
    });

    let pointSource = new VectorSource({
      features: pointFeatures,
    });

    let pointLayer = new VectorLayer({
      source: pointSource,
      zIndex: 30,
    });

    mapRef.current.addLayer(pointLayer);
  }, [data]);

  return (
    <div className="w-[360px] h-[622px] -bg--color-neutral0 rounded-[12px] border border-solid -border--color-neutral6">
      <div className="plr-16 ptb-16 text-H18B">지역별 진행 중인 상담 건수</div>
      <div
        style={{
          height: "550px",
          width: "360px",
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div
          id="jsonMap"
          value={mapObject}
          style={{ height: "100%", width: "100%" }}
        ></div>
      </div>
    </div>
  );
};
export default Map;
