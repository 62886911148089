import React from "react";

import { useRecoilState } from "recoil";

import { makeSiteList, makeStepList } from "@/utils/func/common/modalFunc";

import { tempCouncelParameter } from "@/stores/common/parameter";
import { tempEndDate, tempStartDate } from "@/stores/common/calendar";
import { showPortal } from "@/utils/func/common/modalController";
import moment from "moment";
import { modalInModalInfo, modalInfo } from "@/stores/common/modal";

// 건축 상담 내 필터 모달
const FilterBody = () => {
  const [tempFilter, setTempFilter] = useRecoilState(tempCouncelParameter);

  let statePeriod = ["1주", "1개월", "3개월", "6개월", "1년"];

  const [selectedStartDate, setSelectedStartDate] =
    useRecoilState(tempStartDate);
  const [selectedEndDate, setSelectedEndDate] = useRecoilState(tempEndDate);

  const [modal, setModal] = useRecoilState(modalInModalInfo);

  const handleCkeckedPeriodList = (v) => {
    if (v === "1주") {
      setSelectedStartDate(
        moment().hour(0).minute(0).second(0).subtract(6, "days")
      );
      setSelectedEndDate(moment().hour(0).minute(0).second(0));
      setTempFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment().hour(0).minute(0).second(0).subtract(6, "days"),
          endDate: moment().hour(0).minute(0).second(0),
        };
      });
    }
    if (v === "1개월") {
      setSelectedStartDate(
        moment().hour(0).minute(0).second(0).subtract(1, "months")
      );
      setSelectedEndDate(moment().hour(0).minute(0).second(0));
      setTempFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment().hour(0).minute(0).second(0).subtract(1, "months"),
          endDate: moment().hour(0).minute(0).second(0),
        };
      });
    }
    if (v === "3개월") {
      setSelectedStartDate(
        moment().hour(0).minute(0).second(0).subtract(3, "months")
      );
      setSelectedEndDate(moment().hour(0).minute(0).second(0));
      setTempFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment().hour(0).minute(0).second(0).subtract(3, "months"),
          endDate: moment().hour(0).minute(0).second(0),
        };
      });
    }
    if (v === "6개월") {
      setSelectedStartDate(
        moment().hour(0).minute(0).second(0).subtract(6, "months")
      );
      setSelectedEndDate(moment().hour(0).minute(0).second(0));
      setTempFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment().hour(0).minute(0).second(0).subtract(6, "months"),
          endDate: moment().hour(0).minute(0).second(0),
        };
      });
    }
    if (v === "1년") {
      setSelectedStartDate(
        moment().hour(0).minute(0).second(0).subtract(1, "years")
      );
      setSelectedEndDate(moment().hour(0).minute(0).second(0));
      setTempFilter((prev) => {
        return {
          ...prev,
          period: v,
          startDate: moment().hour(0).minute(0).second(0).subtract(1, "years"),
          endDate: moment().hour(0).minute(0).second(0),
        };
      });
    }
  };

  return (
    <div className="overflow-y-auto overflow-x-hidden max-h-[calc(100vh-216px+60px+27px-85px-165px)] custom-scrollbar py-[16px] flex flex-col gap-[16px]">
      <div className="px-[24px]">
        <p className="pb-[8px] text-B16B">지역</p>
        <div className="gap-[8px] grid grid-cols-5">
          {makeSiteList(tempFilter, setTempFilter)}
        </div>
      </div>
      <div className="px-[24px]">
        <p className="pb-[8px] text-B16B">진행상태</p>
        <div className="gap-[8px] grid grid-cols-5">
          {makeStepList(tempFilter, setTempFilter)}
        </div>
      </div>

      <div className="px-[24px]">
        <div className="flex flex-col gap-[8px]">
          <span className="text-B16B">기간</span>
          <div className="flex flex-row gap-[8px]">
            {statePeriod.map((v, num) => {
              const checked = tempFilter.period === v;
              return (
                <label
                  key={num}
                  htmlFor={v}
                  className={
                    checked
                      ? "font-700 ptb-8 pl-[12px] pr-[12px]  text-center -text--color-primary-main"
                      : "font-700 ptb-8 pl-[12px] pr-[12px]  text-center -text--color-neutral50"
                  }
                >
                  {v}
                  <input
                    id={v}
                    type="radio"
                    value={v}
                    name="period"
                    checked={checked}
                    className="hidden"
                    onChange={() => handleCkeckedPeriodList(v)}
                  />
                </label>
              );
            })}
          </div>
        </div>
        <div className="py-[8px] flex flex-col gap-[10px]">
          <span className="text-B14M">날짜를 선택해 주세요</span>
          <div className="border border-solid -border--color-neutral12 plr-16 ptb-12 flex flex-row justify-center">
            <button
              onClick={() =>
                showPortal(setModal, true, "councel-filter-calendar-mobile")
              }
              className={
                tempFilter.startDate === undefined
                  ? "-text--color-neutral20"
                  : "-text--color-neutral100"
              }
            >
              {tempFilter.startDate === undefined
                ? moment().subtract(1, "years").format("YYYY.MM.DD")
                : moment(selectedStartDate).format("YYYY.MM.DD")}
            </button>
            <span className="-text--color-neutral50">&nbsp;-&nbsp;</span>
            <button
              onClick={() =>
                showPortal(setModal, true, "councel-filter-calendar-mobile")
              }
              className={
                tempFilter.endDate === undefined
                  ? "-text--color-neutral20"
                  : "-text--color-neutral100"
              }
            >
              {tempFilter.endDate === undefined
                ? moment().format("YYYY.MM.DD")
                : moment(selectedEndDate).format("YYYY.MM.DD")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBody;
