import React from "react";

import moment from "moment";

import { useNavigate } from "react-router-dom";

import { useSetRecoilState } from "recoil";

import { navValue } from "@/stores/common/navigation";

import { dateController } from "@/utils/func/common/date";

import { pageController } from "@/utils/func/common/page";
import LoadingMsg from "../common/LoadingMsg";
import ErrorMsg from "../common/ErrorMsg";

const Calendar = (props) => {
  const { scheduleDate, setScheduleDate, data, isLoading, isError } = props;

  const setNavState = useSetRecoilState(navValue);

  const navigate = useNavigate();

  const startDay = moment(scheduleDate)
    .clone()
    .startOf("month")
    .startOf("week");

  const endDay = moment(scheduleDate).clone().endOf("month").endOf("week");

  const day = startDay.clone().subtract(1, "day");

  const calendar = [];

  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }

  if (isLoading) {
    return (
      <div className="border border-solid -border--color-neutral6 w-[360px] h-[350px] rounded-[12px] -bg--color-neutral0 flex items-center justify-center">
        <LoadingMsg />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="border border-solid -border--color-neutral6 w-[360px] h-[350px] rounded-[12px] -bg--color-neutral0 flex items-center justify-center">
        <ErrorMsg />
      </div>
    );
  }

  return (
    <div className="border border-solid -border--color-neutral6 w-[360px] h-[350px] rounded-[12px] -bg--color-neutral0">
      <div className="flex items-center justify-center ptb-12 plr-18">
        <div className="w-full">
          <div className="w-full h-[44px] flex flex-row justify-between items-center">
            <button
              onClick={() => {
                let value = scheduleDate;
                let func = (v) =>
                  setScheduleDate(moment(v).subtract(1, "months"));
                dateController(value, func);
              }}
              style={{
                width: "44px",
                height: "44px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="_sprite_ _sprite_17_3_"></span>
            </button>
            <span className=" text-B16B">{`${moment(scheduleDate).format(
              "YYYY.M"
            )}월`}</span>
            <button
              onClick={() => {
                let value = scheduleDate;
                let func = (v) => setScheduleDate(moment(v).add(1, "months"));
                dateController(value, func);
              }}
              style={{
                width: "44px",
                height: "44px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="_sprite_ _sprite_17_4_"></span>
            </button>
          </div>
          <div>
            <div className="grid grid-cols-7 h-[40px] text-B14R">
              <span className="flex items-center justify-center -text--color-danger-main">
                일
              </span>
              <span className="flex items-center justify-center">월</span>
              <span className="flex items-center justify-center">화</span>
              <span className="flex items-center justify-center">수</span>
              <span className="flex items-center justify-center">목</span>
              <span className="flex items-center justify-center">금</span>
              <span className="flex items-center justify-center  -text--color-danger-main">
                토
              </span>
            </div>
            {calendar.map((row, index) => {
              return (
                <div
                  key={index}
                  className="grid grid-cols-7 h-[40px] items-center text-B14R"
                >
                  {row.map((day, index) => {
                    const isPreviousMonth = day.isBefore(scheduleDate, "month");
                    const isNextMonth = day.isAfter(scheduleDate, "month");

                    let classNames = "flex items-center justify-center";

                    if (isPreviousMonth) {
                      classNames += " opacity-40";
                    } else if (isNextMonth) {
                      classNames += " opacity-40";
                    }

                    if (day.day() === 0 || day.day() === 6) {
                      classNames += " -text--color-danger-main";
                    }

                    return (
                      <button
                        key={index}
                        className="relative w-[44px] h-[44px] flex flex-col items-center justify-center"
                        onClick={() => {
                          let sidebarChecked = 2;
                          let url = `/calendar`;
                          let func = {
                            func1: (e) => setNavState(e),
                            func2: (v) => navigate(v),
                          };
                          pageController(sidebarChecked, url, func);
                        }}
                      >
                        <span className={classNames}>{day.format("D")}</span>
                        <div className="flex flex-row justify-center gap-[2px] w-full overflow-hidden">
                          <div className="flex flex-row justify-center gap-[2px]">
                            {data &&
                              data.calender.map((idx, num) => {
                                let bgClass;

                                if (idx.calender_category === "서울사무소") {
                                  bgClass = "-bg--color-office-seoul";
                                } else if (
                                  idx.calender_category === "여주전시관"
                                ) {
                                  bgClass = "-bg--color-showroom-yeoju";
                                } else if (
                                  idx.calender_category === "여주공장"
                                ) {
                                  bgClass = "-bg--color-factory-yeoju";
                                } else if (
                                  idx.calender_category === "제주전시관"
                                ) {
                                  bgClass = "-bg--color-showroom-jeju";
                                } else if (idx.calender_category === "기타") {
                                  bgClass = "-bg--color-etc";
                                } else {
                                  bgClass = "-bg--color-etc";
                                }

                                if (
                                  day.isSameOrAfter(
                                    idx.calender_start,
                                    "day"
                                  ) &&
                                  day.isSameOrBefore(idx.calender_end, "day")
                                ) {
                                  return (
                                    <React.Fragment key={num}>
                                      <span
                                        className={`w-[4px] h-[4px] rounded-full ${bgClass}`}
                                      ></span>
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      </button>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
