import React from "react";

import SideBarMobile from "@/components/common/SideBarMobile";

export default function PagesMobileLayout() {
  return (
    <div className="h-screen">
      <SideBarMobile />
    </div>
  );
}
