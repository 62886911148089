import React, { useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import { useRecoilState } from "recoil";

import { navValue } from "@/stores/common/navigation";

const SideBarReport = () => {
  const [navState, setNavState] = useRecoilState(navValue);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("councel")) {
      setNavState(1);
    }
  });

  return <>{<Outlet />}</>;
};

export default SideBarReport;
