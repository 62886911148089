import React from "react";

import { closePortal, showPortal } from "@/utils/func/common/modalController";
import { useRecoilState } from "recoil";
import { modalInfo } from "@/stores/common/modal";
import http from "@/utils/api/http/http";

import { responseData } from "@/stores/common/data";

const ModalFooterRecover = () => {
  const [modal, setModal] = useRecoilState(modalInfo);

  const [userData, setUserData] = useRecoilState(responseData);

  const closeModal = () => {
    closePortal(setModal);
  };

  async function recoverCouncel() {
    try {
      showPortal(setModal, true, "entity-progress-common");

      await http
        .put(`/api/councel/process/revert`, null, {
          params: { process_idx: userData.process.process_idx },
        })
        .then((res) => {
          if (res.status === 200) {
            closePortal(setModal);
            window.location.reload();
          }
        });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <div className="grid grid-cols-2 pt-[14px] pb-[14px] text-B16B">
        <button
          // className=" -text--color-neutral50 hover:-text--color-primary-main"
          className="-text--color-neutral50"
          onClick={() => {
            closeModal();
          }}
        >
          닫기
        </button>
        <button
          // className=" -text--color-neutral50 hover:-text--color-primary-main"
          className="-text--color-primary-main"
          onClick={() => {
            recoverCouncel();
          }}
        >
          복구하기
        </button>
      </div>
    </>
  );
};

export default ModalFooterRecover;
