import React from "react";

const CouncelRecoverCouncelWeb = () => {
  return (
    <div className="flex flex-col items-center justify-center ptb-24 pl-[40px] pr-[40px]">
      <span className="text-H21B">상담을 복구할까요?</span>
      <span className="text-B14R -text--color-neutral50">
        취소/완료된 상담을 진행 상태로 복구할 수 있어요
      </span>
    </div>
  );
};

export default CouncelRecoverCouncelWeb;
