import React from "react";

interface propsType {
  title: string;
  data: {
    title: string;
    params: number | string;
    count?: number | null;
  }[];
  callback: (arg: number) => void;
  parameter: any;
}

const MobileCategory = ({ title, data, parameter, callback }: propsType) => {
  return (
    <section className="relative flex flex-row items-center justify-start gap-[16px] w-full px-[16px] py-[16px] -bg--color-neutral0 z-20">
      {data?.map((idx: any, num: number) => {
        return (
          <li
            key={num}
            className={
              title === "councel" && idx.params === parameter.detail_type
                ? "h-[44px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : title === "inquiry" && idx.params === parameter.category
                  ? "h-[44px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                  : "h-[44px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => {
              callback(idx.params);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <p className="text-H18B flex items-center">
              <span className="mr-[8px]">{idx.title}</span>
              {idx.count && (
                <span
                  className={
                    title === "councel" && idx.params === parameter.detail_type
                      ? "-text--color-primary-main py-[2px] px-[6px] border-none rounded-[999px] -bg--color-primary-surface -text--label-12b font-700"
                      : title === "inquiry" && idx.params === parameter.category
                        ? "-text--color-primary-main py-[2px] px-[6px] border-none rounded-[999px] -bg--color-primary-surface -text--label-12b font-700"
                        : "-text--color-neutral50 -text--label-12b font-700"
                  }
                >
                  {idx.count}
                </span>
              )}
            </p>
          </li>
        );
      })}
    </section>
  );
};

export default MobileCategory;
