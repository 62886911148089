import http from "../http/http";

export async function getCouncelDetailData(v: any) {
  return await http.post(`/api/councel/detail`, v);
}

export async function getCouncelDetailCouncelData(v: any) {
  try {
    const { data } = await http.get(`/api/councel/detail/list`, v);
    return data;
  } catch (e) {
    // throw e;
    console.error(e);
  }
}

// 댓글 작성
export async function addCouncelDetailComment(v: any) {
  return await http.post(`/api/councel/comment`, v);
}

// 댓글 수정
export async function fixCouncelDetailComment(v: any) {
  return await http.put(`/api/councel/comment`, v);
}
