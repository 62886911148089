import http from "@/utils/api/http/http";

interface getCouncelProps {
  councel_type?: string | undefined;
  detail_type?: string;
  start_datetime?: Date | undefined;
  end_datetime?: Date | undefined;
  location?: string[] | undefined;
  import_check?: boolean;
  mycheck?: boolean;
  pageno: number;
  process_type: null | number;
  search?: string;
}

export async function getCouncel(val: getCouncelProps) {
  return await http.get(`/api/councel`, { params: val });
}
