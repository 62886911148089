import React from "react";

import { useRecoilState } from "recoil";

import { errState, inputValueManager } from "@/stores/common/modal";

import { closePortal } from "@/utils/func/common/modalController";

interface propsType {
  value: string;
  callback: (arg: any) => void;
}

const ModalHeader = ({ value, callback }: propsType) => {
  // modal, modalinmodal에 대하여 중복되게 사용하기 때문에, 전역 호출이 아닌 props로 전달

  //담당자 배정 모달에서 사용하기 위해 만든 상태
  const [, setAlertErr] = useRecoilState(errState);
  const [, setManagerValue] = useRecoilState(inputValueManager);

  const closeModal = () => {
    closePortal(callback);

    // 담당자 배정 모달
    if (value === "councel-assign-manager-web") {
      setAlertErr(false);
      setManagerValue("");
    }
  };

  const handleTitle = (value: string) => {
    return (
      <section className="w-[100%] border-b border-solid -border--color-neutral12 flex items-center justify-between pl-[24px] pr-[11.75px] py-[12px] relative">
        <span className="text-H21B">{value}</span>
        <button className="" onClick={() => closeModal()}>
          <span className="_sprite_ _sprite_6_2_"></span>
        </button>
      </section>
    );
  };

  return (
    <>
      {/* 공통 */}
      {value === "entity-progress-common" && null}
      {value === "alert-texteditor-case" && null}
      {/* 웹 */}
      {value === "councel-step-web" && handleTitle("상담 단계 전환")}
      {value === "councel-recovalueer-councel-web" && null}
      {value === "councel-alert-danger-web" && null}
      {value === "councel-filtering-web" && handleTitle("필터")}
      {value === "councel-fix-userinfo-web" && handleTitle("정보 수정")}
      {value === "councel-field-web" && handleTitle("자세히 보기")}
      {value === "calendar-add-schedule-web" && handleTitle("일정 추가")}
      {value === "calendar-fix-schedule-web" && handleTitle("일정 수정")}
      {value === "calendar-show-schedule-web" && handleTitle("일정")}
      {value === "calendar-search-user-web" && handleTitle("회원 검색")}
      {value === "calendar-search-attendee-web" && handleTitle("참석자")}
      {value === "entity-password-web" && handleTitle("정보 수정")}
      {value === "councel-assign-manager-web" && handleTitle("담당자 변경")}
      {/* 모바일 */}
      {value === "councel-filter-calendar-mobile" && null}
      {value === "councel-councelnote-calendar-mobile" && null}
      {value === "councel-add-councelnote-mobile" && null}
      {value === "councel-fix-councelnote-mobile" && null}
      {value === "councel-add-comment-mobile" && null}
      {value === "councel-filter-mobile" && handleTitle("필터")}
      {value === "councel-field-mobile" && handleTitle("자세히 보기")}
      {value === "councel-userinfo-mobile" && handleTitle("상담 정보 수정")}
    </>
  );
};

export default ModalHeader;
