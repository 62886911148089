import React, { useState } from "react";

import { useRecoilState } from "recoil";

import moment from "moment";

import Charts from "@/components/chart/Charts";
import Calendar from "@/components/dashboard/Calendar";
import Map from "@/components/dashboard/Map";
import PrefType from "@/components/dashboard/PrefType";
import NotAssigned from "@/components/dashboard/NotAssigned";

import { councelParameter } from "@/stores/common/parameter";

import {
  getGraphData,
  getMapData,
  getNotAssignedData,
  getPreferenceData,
  getScheduleData,
} from "@/utils/api/pages/dashboard";

import { useQueries } from "@tanstack/react-query";

const Dashboard = () => {
  const [, setDetailCouncelParams] = useRecoilState(councelParameter);

  const [chartYr, setChartYr] = useState(moment());

  const [graphPeriod, setGraphPeriod] = useState(false);

  const [scheduleDate, setScheduleDate] = useState(moment());

  const [adjustNotAssigned, setAdjustNotAssigned] = useState(false);

  const query = useQueries({
    queries: [
      {
        // queryKey에서 객체 처리 '{}'하면 순서 보장 X
        queryKey: ["graphData", { graphPeriod, chartYr }],
        queryFn: () => getGraphData({ val: graphPeriod, evt: chartYr }),
        staleTime: 1000 * 60 * 60,
      },
      {
        queryKey: ["scheduleData", scheduleDate],
        queryFn: () => getScheduleData(scheduleDate.format()),
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["mapData"],
        queryFn: () => getMapData(),
        staleTime: 1000 * 60 * 60,
      },
      {
        queryKey: ["prefData"],
        queryFn: () => getPreferenceData(),
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["notassignedData", adjustNotAssigned],
        queryFn: () => getNotAssignedData(adjustNotAssigned),
        refetchOnWindowFocus: true,
      },
    ],
  });

  const graphQuery = query[0];
  const calendarQuery = query[1];
  const mapQuery = query[2];
  const prefQuery = query[3];
  const assignedQuery = query[4];

  return (
    <main className="relative  h-full flex flex-col w-[1128px] max-[1696px]:mt-0 max-[1696px]:mb-0 max-[1696px]:ml-[calc(248px+36px)] max-[1696px]:mr-[36px] min-[1697px]:my-0 min-[1697px]:mx-auto">
      <section className="fixed top-[0px] w-[1128px] h-[24px] flex items-center justify-between z-[3] -bg--color-neutral0"></section>
      <section className="flex flex-row gap-[24px] top-[24px] relative mb-[24px]">
        <Charts
          data={graphQuery?.data?.data.data}
          isLoading={graphQuery.isLoading}
          isError={graphQuery.isError}
          graphPeriod={graphPeriod}
          setGraphPeriod={setGraphPeriod}
          chartYr={chartYr}
          setChartYr={setChartYr}
        />
        <Calendar
          scheduleDate={scheduleDate}
          setScheduleDate={setScheduleDate}
          data={calendarQuery?.data?.data.data}
          isLoading={calendarQuery.isLoading}
          isError={calendarQuery.isError}
        />
      </section>
      <section className="flex flex-row gap-[24px] top-[24px] relative">
        <Map
          data={mapQuery?.data?.data.data.project}
          isLoading={mapQuery.isLoading}
          isError={mapQuery.isError}
        />
        <PrefType
          data={prefQuery?.data?.data.data.favorite}
          isLoading={prefQuery.isLoading}
          isError={prefQuery.isError}
        />
        <NotAssigned
          adjustNotAssigned={adjustNotAssigned}
          setAdjustNotAssigned={setAdjustNotAssigned}
          setDetailCouncelParams={setDetailCouncelParams}
          data={assignedQuery?.data?.data.data.councel}
          isLoading={assignedQuery.isLoading}
          isError={assignedQuery.isError}
        />
      </section>
    </main>
  );
};

export default Dashboard;
