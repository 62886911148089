/**
 * @description 검색 등의 이벤트에 사용되는 디바운스 함수
 * @param {*} callback 검색 실행 함수
 * @param {*} delay time
 * @returns
 */
export const debounceFunction = (callback, delay) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => callback(...args), delay);
  };
};
