import http from "../http/http";

interface graphDataPropsType {
  val: boolean;
  evt: any;
}

// 상담처리 현황 데이터

export async function getGraphData(props: graphDataPropsType) {
  const { val, evt } = props;

  if (val === false) {
    return await http.get(`/api/admin/dashboard/councel`, {
      params: {
        period_type: 0,
        year_month: evt.year(),
      },
    });
  } else if (val === true) {
    return await http.get(`/api/admin/dashboard/councel`, {
      params: {
        period_type: 1,
      },
    });
  }
}

// 일정 등록 데이터
export async function getScheduleData(val: Date) {
  return await http.get(`/api/calender`, {
    params: { select_month: val, category: "", mycheck: false },
  });
}

// 지역별 진행 중 상담 건수 데이터
export async function getMapData() {
  return await http.get(`/api/admin/dashboard/project`, null);
}

// 선호 타입 데이터
export async function getPreferenceData() {
  return await http.get(`/api/admin/dashboard/favorite`, null);
}

// 미배정 상담 데이터
export async function getNotAssignedData(val: boolean) {
  return await http.get(`/api/admin/dashboard/unallocated`, {
    params: { mycheck: val },
  });
}
