import React from "react";

// react-query isError 시 보여줄 메세지

const ErrorMsg = () => {
  return (
    <div className="z-20 -bg--color-neutral0 transform rounded-[12px] flex flex-col items-center justify-center gap-[8px]">
      <span className="_sprite_ _sprite_15_8_"></span>
      <span className="text-H21B -text--color-danger-main">
        시스템 오류로 이용이 불가능해요
      </span>
      <p className="flex flex-col items-center text-B14R -text--color-neutral50">
        <span>시스템 처리 중 오류가 발생했어요.</span>
        <span>새로고침 버튼을 클릭하거나 관리자에게 문의하세요.</span>
      </p>
    </div>
  );
};

export default ErrorMsg;
