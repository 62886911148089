import { debounceFunction } from "./debounce";

/**
 * @description 상담 노트 내 내 상담만 보기 클릭 시 필터링 함수
 * @param e input check
 * @param callback parameter 변경 callback함수
 */
export function mycheckController(e: any, callback: any) {
  callback((prev: any) => {
    return {
      ...prev,
      mycheck: e.target.checked,
      pageno: 1,
    };
  });
}

interface filterControllerType {
  value: string;
  e: number | string;
  callback: (arg: any) => void;
}

/**
 * @description 페이지 상단 카테고리 영역 선택 시 실행 함수
 * @param value 고객정보 페이지 or 상담 노트 페이지
 * @param e category or detail_type
 * @param callback 검색 실행 함수
 */
export function filterController({ value, e, callback }: filterControllerType) {
  // 각 페이지 마다 카테고리 선택 시 변경되는 parameter의 key값이 달라서 조건문 생성함
  if (value === "inq") {
    callback((prev: any) => {
      return {
        ...prev,
        category: e,
        pageno: 1,
      };
    });
  } else if (value === "coun") {
    callback((prev: any) => {
      return {
        ...prev,
        detail_type: e,
        pageno: 1,
      };
    });
  }
}

export const searchController = debounceFunction((e: any, callback: any) => {
  let value = e.target.value;

  callback((prev: any) => {
    return { ...prev, search: value, pageno: 1 };
  });
}, 300);
