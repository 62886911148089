import React from "react";

import { useRecoilState, useSetRecoilState } from "recoil";

import { modalInfo } from "@/stores/common/modal";
import { responseData } from "@/stores/common/data";

import { showPortal } from "@/utils/func/common/modalController";

const CouncelInfo = () => {
  const setModal = useSetRecoilState(modalInfo);

  const [data, setData] = useRecoilState(responseData);

  return (
    <div className="w-[840px] flex flex-row px-[16px] pr-[24px] -bg--color-neutral0 rounded-tl-[8px] rounded-tr-[8px] justify-between items-center border-x border-t border-solid -border--color-neutral6">
      <div className="flex flex-row py-[12px] gap-[16px] items-center">
        <span
          className={`inline-block py-[4px] px-[6px] text-B12B border border-solid -text--color-neutral0 rounded-[6px]
            ${data?.process?.process_type === 1 && "-bg--color-etc-1"}
            ${data?.process?.process_type === 2 && "-bg--color-etc-2"}
            ${data?.process?.process_type === 3 && "-bg--color-etc-3"}
          `}
        >
          {data?.process?.process_type === 1 && "싱딤"}
          {data?.process?.process_type === 2 && "계약"}
          {data?.process?.process_type === 3 && "공사"}
        </span>
        <span className="truncate block max-w-[370px] overflow-hidden pointer-events-none text-B16M">
          {data?.process?.process_name}
        </span>
        <p className="h-full flex flex-row items-center gap-[4px]">
          <span className="text-B12R">담당자</span>
          <span
            className={`text-B16M ${
              data?.process?.admin_name === null && "-text--color-danger-main"
            }`}
          >
            {data?.process?.admin_name ? data?.process?.admin_name : "미배정"}
          </span>
        </p>
        <span
          className={`px-[10px] rounded-full border text-center py-[6px] text-B12B
              ${
                data?.process?.process_check === 0 &&
                "-text--color-neutral0 -bg--color-primary-main"
              }
              ${
                data?.process?.process_check === 1 &&
                "-text--color-primary-main -bg--color-primary-surface"
              }
              ${
                data?.process?.process_check === 2 &&
                "-text--color-neutral50 -bg--color-neutral6"
              }
              ${
                data?.process?.process_check === 9 &&
                "-text--color-danger-main -bg--color-danger-surface"
              }
              
          `}
        >
          {data?.process?.process_check === 0 && "상담대기"}
          {data?.process?.process_check === 1 && "진행 중"}
          {data?.process?.process_check === 2 && "상담완료"}
          {data?.process?.process_check === 9 && "상담취소"}
        </span>
      </div>
      <div className="flex flex-row gap-[8px] py-[8px]">
        {data?.process?.process_check === 2 ||
        data?.process?.process_check === 9 ? (
          <button
            className="text-B14B border border-solid -border--color-primary-main -text--color-primary-main py-[8px] px-[12px] rounded-[4px]"
            onClick={() => {
              showPortal(setModal, true, "councel-recover-councel-web");
            }}
          >
            상담 복구
          </button>
        ) : (
          <>
            <button
              className="text-B14B border border-solid -border--color-neutral12 -text--color-neutral50 py-[8px] px-[12px] rounded-[4px]"
              onClick={() => {
                showPortal(setModal, true, "councel-step-web");
              }}
            >
              상담 단계 전환
            </button>
            <button
              className="text-B14B border border-solid -border--color-primary-main -text--color-primary-main py-[8px] px-[12px] rounded-[4px]"
              onClick={() => {
                showPortal(setModal, true, "councel-assign-manager-web");
              }}
            >
              담당자 배정
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CouncelInfo;
