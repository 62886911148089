import { modalInfo } from "@/stores/common/modal";

import React from "react";
import { useRecoilState } from "recoil";
import {
  ctrlCancelStep,
  ctrlNextStep,
  ctrlPrevStep,
} from "@/utils/func/common/modalFunc";
import { additionalResponseData, responseData } from "@/stores/common/data";
import { councelNoteAddressIdx } from "@/stores/page/councelDetail";

const CouncelStepWeb = () => {
  const [userData, setUserData] = useRecoilState(responseData);

  const [resDataCouncel, setResDataCouncel] = useRecoilState(
    additionalResponseData
  );

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  const [modal, setModal] = useRecoilState(modalInfo);

  return (
    <>
      <div className="relative flex flex-col items-center">
        <div className="flex flex-col">
          <div className="flex flex-col items-start px-[24px] py-[16px]">
            <p className="pb-[8px]">
              <span className="text-H16B pb-[8px]">현재 단계: </span>
              <span
                className={`inline-block py-[4px] px-[6px] text-B12B border border-solid -text--color-neutral0 rounded-[6px]
                  ${userData?.process?.process_type === 1 && "-bg--color-etc-1"}
                  ${userData?.process?.process_type === 2 && "-bg--color-etc-2"}
                  ${userData?.process?.process_type === 3 && "-bg--color-etc-3"}
                `}
              >
                {userData?.process?.process_type === 1 && "싱딤"}
                {userData?.process?.process_type === 2 && "계약"}
                {userData?.process?.process_type === 3 && "공사"}
              </span>
            </p>
            <span className="text-B14R -text--color-neutral50 pb-[8px]">
              {`단계는 상담->계약->공사로 이루어져 있어요.`}
            </span>
            <p className="flex flex-row gap-[8px] items-center">
              <span className="_sprite_ _sprite_5_1_"></span>
              <span className="text-B12R -text--color-neutral50">
                단계 전환 시 담당자가 미배정으로 설정됩니다.
              </span>
            </p>
          </div>
          <div className="flex flex-col px-[16px] py-[12px]">
            {userData?.process?.process_type === 1 && (
              <button
                className="w-[343px] h-[44px] rounded-[6px] -bg--color-primary-main -text--color-neutral0 mb-[8px] flex flex-row gap-[2px] justify-center items-center"
                onClick={() => {
                  ctrlNextStep({
                    userData,
                    setUserData,
                    addressIdxList,
                    setModal,
                  });
                }}
              >
                <span className="text-B16B">다음 단계</span>
                <span className="_sprite_ _sprite_3_10_"></span>
              </button>
            )}

            {userData?.process?.process_type === 2 && (
              <div className="flex flex-row gap-[8px]">
                <button
                  className="w-[167.5px] h-[44px] rounded-[6px] -bg--color-primary-surface -text--color-primary-main mb-[8px] flex flex-row gap-[2px] justify-center items-center"
                  onClick={() => {
                    ctrlPrevStep({
                      userData,
                      setUserData,
                      addressIdxList,
                      setModal,
                    });
                  }}
                >
                  <span className="_sprite_ _sprite_3_7_"></span>
                  <span className="text-B16B">이전 단계</span>
                </button>
                <button
                  className="w-[167.5px] h-[44px] rounded-[6px] -bg--color-primary-main -text--color-neutral0 mb-[8px] flex flex-row gap-[2px] justify-center items-center"
                  onClick={() => {
                    ctrlNextStep({
                      userData,
                      setUserData,
                      addressIdxList,
                      setModal,
                    });
                  }}
                >
                  <span className="text-B16B">다음 단계</span>
                  <span className="_sprite_ _sprite_3_10_"></span>
                </button>
              </div>
            )}

            {userData?.process?.process_type === 3 && (
              <div className="flex flex-row gap-[8px]">
                <button
                  className="w-[167.5px] h-[44px] rounded-[6px] -bg--color-primary-surface -text--color-primary-main mb-[8px] flex flex-row gap-[2px] justify-center items-center"
                  onClick={() => {
                    ctrlPrevStep({
                      userData,
                      setUserData,
                      addressIdxList,
                      setModal,
                    });
                  }}
                >
                  <span className="_sprite_ _sprite_3_7_"></span>
                  <span className="text-B16B">이전 단계</span>
                </button>
                <button
                  className="w-[167.5px] h-[44px] rounded-[6px] -bg--color-primary-main -text--color-neutral0 mb-[8px] flex flex-row gap-[2px] justify-center items-center"
                  onClick={() => {
                    ctrlNextStep({
                      userData,
                      setUserData,
                      addressIdxList,
                      setModal,
                    });
                  }}
                >
                  <span className="text-B16B">상담 완료</span>
                  <span className="_sprite_ _sprite_3_10_"></span>
                </button>
              </div>
            )}

            <button
              className="w-[343px] h-[44px] rounded-[6px] -bg--color-neutral0 -text--color-neutral50 flex flex-row gap-[2px] justify-center items-center text-B14B"
              onClick={() => {
                ctrlCancelStep({
                  userData,
                  setUserData,
                  addressIdxList,
                  setModal,
                });
              }}
            >
              상담 취소하려면 [여기]를 누르세요
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouncelStepWeb;
