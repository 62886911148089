import { useRecoilState, useRecoilValue } from "recoil";

import React, { useEffect, useState } from "react";
import { modalInfo, tempReferVal } from "@/stores/common/modal";
import {
  handleDetailTypeChange,
  submitDetailUserData,
} from "@/utils/func/common/modalFunc";
import {
  filterNum,
  formatPhoneNumber,
} from "@/utils/func/common/regExpression";
import { additionalResponseData, responseData } from "@/stores/common/data";
import {
  councelNoteAddressIdx,
  requiredInfo,
} from "@/stores/page/councelDetail";

const CouncelFixUserWeb = () => {
  const additionalData = useRecoilValue(additionalResponseData);

  const [data, setData] = useRecoilState(responseData);

  // 선택한 유저의 값 받아오기
  const [referVal, setReferVal] = useRecoilState(tempReferVal);

  const [modal, setModal] = useRecoilState(modalInfo);

  const [openSizeTap, setOpenSizeTap] = useState(false);
  const [selectedSize, setSelectedSize] = useState("선택 없음");

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  const [textareaHeight, setTextareaHeight] = useState(120);

  const textareaHeightScope = (e) => {
    const { scrollHeight } = e.target;

    if (scrollHeight <= 120) {
      setTextareaHeight(120);
    } else if (scrollHeight > 120 && scrollHeight <= 220) {
      setTextareaHeight(scrollHeight);
      // setTextareaHeight(220);
    } else {
      setTextareaHeight(220);
    }
  };

  // const [textareaBytes, setTextareaBytes] = useState(0);

  // const textLengthScope = (val) => {
  //   const encoder = new TextEncoder();
  //   const encodedBytes = encoder.encode(val);

  //   if (val?.length > 0) {
  //     setTextareaBytes(encodedBytes.length);
  //   } else if (val?.length === 0) {
  //     setTextareaBytes(0);
  //   }
  // };

  // useEffect(() => {
  //   textLengthScope(referVal.detail_appendix);
  // }, [referVal.detail_appendix]);

  const [essentialInfo, setEssentialInfo] = useRecoilState(requiredInfo);

  return (
    <div className="overflow-y-auto max-h-[calc(100vh-216px+60px+27px-85px-165px)] custom-scrollbar w-[403px]">
      {data.user.user_privilege === 0 && (
        <div className="flex flex-col px-[24px] py-[16px] border-b-[12px] border-solid -border--color-neutral3">
          <span className="mb-[16px] text-B16B -text--color-primary-main">
            고객 정보
          </span>
          <label className="flex flex-col gap-[10px] mb-[16px]" htmlFor="">
            <div className="text-B14M">
              <span>이름</span>
              <span className="-text--color-danger-main">*</span>
            </div>
            <input
              required
              className={`outline-none full h-[48px] plr-16 ptb-12 box-border border border-solid rounded-[6px] text-B16R placeholder:text-B16R placeholder:-text--color-neutral50 
              ${
                essentialInfo === 1
                  ? "focus:-border--color-danger-main -border--color-danger-main"
                  : "focus:-border--color-primary-main -border--color-neutral12"
              }
              `}
              type="text"
              placeholder="홍길동(필수)"
              onChange={(e) => {
                const regex = /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]*$/;
                if (regex.test(e.target.value)) {
                  setReferVal((prev) => ({
                    ...prev,
                    user_name: e.target.value,
                  }));
                  setEssentialInfo("");
                } else {
                  e.target.value = e.target.value.replace(
                    /[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]/g,
                    ""
                  );
                  alert("한글과 영어만 입력이 가능합니다.");
                  setEssentialInfo(1);
                }
              }}
              onKeyDown={(e) =>
                submitDetailUserData(
                  e,
                  referVal,
                  data,
                  setData,
                  addressIdxList,
                  setModal,
                  setEssentialInfo
                )
              }
              value={referVal && referVal.user_name}
            />
          </label>
          <label className="flex flex-col gap-[10px] mb-[16px]" htmlFor="">
            <div className="text-B14M">
              <span>휴대폰</span>
              <span className="-text--color-danger-main">*</span>
            </div>
            <input
              required
              className={`outline-none w-full h-[48px] plr-16 ptb-12 box-border border border-solid rounded-[6px] text-B16R placeholder:text-B16R placeholder:-text--color-neutral50 
              ${
                essentialInfo === 1
                  ? "focus:-border--color-danger-main -border--color-danger-main"
                  : "focus:-border--color-primary-main -border--color-neutral12"
              }`}
              type="text"
              placeholder="010-0000-0000(필수)"
              onChange={(e) => {
                const inputValue = e.target.value;
                const result = formatPhoneNumber(inputValue);

                if (result !== false) {
                  setReferVal((prev) => ({
                    ...prev,
                    user_phone: result,
                  }));
                  setEssentialInfo("");
                } else {
                  alert(
                    "숫자만 입력이 가능하며 최대 15자리까지 입력 가능합니다."
                  );
                  setEssentialInfo(2);
                }
              }}
              onKeyDown={(e) =>
                submitDetailUserData(
                  e,
                  referVal,
                  data,
                  setData,
                  addressIdxList,
                  setModal,
                  setEssentialInfo
                )
              }
              value={referVal && referVal.user_phone}
            />
          </label>
          <label className="flex flex-col gap-[10px] mb-[16px]" htmlFor="">
            <div>
              <span>이메일</span>
            </div>
            <input
              className="outline-none w-full h-[48px] plr-16 ptb-12 box-border border border-solid -border--color-neutral12 rounded-[6px] text-B16R placeholder:text-B16R placeholder:-text--color-neutral50 focus:-border--color-primary-main"
              type="text"
              placeholder="example@naver.com(선택)"
              onChange={(e) => {
                setReferVal((prev) => ({
                  ...prev,
                  user_id: e.target.value,
                }));
              }}
              onKeyDown={(e) =>
                submitDetailUserData(
                  e,
                  referVal,
                  data,
                  setData,
                  addressIdxList,
                  setModal
                )
              }
              value={referVal.user_id ? referVal.user_id : ""}
            />
          </label>
          <label className="flex flex-col gap-[10px] mb-[16px]" htmlFor="">
            <span className="pb-[4px] text-B14M">프로젝트명</span>
            <input
              className="outline-none w-full h-[48px] plr-16 ptb-12 box-border border-solid -border--color-neutral12 border-[1px] rounded-[6px] text-B16R placeholder:-text--color-neutral50 focus:-border--color-primary-main"
              placeholder="프로젝트명을 입력하세요"
              type="text"
              onChange={(e) =>
                handleDetailTypeChange("project", e, setReferVal)
              }
              value={referVal.process_name ? referVal.process_name : ""}
            />
          </label>
          <label className="flex flex-col gap-[10px] mb-[16px]" htmlFor="">
            <span className="pb-[4px] text-B14M">건축용도</span>
            <div className="relative">
              <div
                className={`
                ${selectedSize === "선택 없음" && "-text--color-neutral50"}
                ${openSizeTap && "-border--color-primary-main"}
                 h-[48px] w-full border border-solid -border--color-neutral12 rounded-[6px] flex items-center plr-16 ptb-12 text-B16R -text--color-neutral100`}
                onClick={() => {
                  setOpenSizeTap(!openSizeTap);
                }}
              >
                {referVal.detail_use ? referVal.detail_use : "선택 안함"}
              </div>
              <div className="absolute top-0 right-0 ptb-12 pr-[10px]">
                <span
                  className={`_sprite_ ${
                    openSizeTap ? "_sprite_4_1_" : "_sprite_4_4_"
                  }`}
                ></span>
              </div>

              <div
                className={`absolute top-[52px] -bg--color-neutral0 w-full rounded-tl-[6px] rounded-tr-[4px] rounded-br-[4px] rounded-bl-[6px] z-10 ${
                  openSizeTap
                    ? "border border-solid -border--color-neutral12 animate_slide_down"
                    : "hidden"
                }`}
              >
                <li
                  className="plr-16 pt-[10px] pb-[10px] hover:-bg--color-neutral3"
                  onClick={() => {
                    setSelectedSize("선택 안함");
                    setOpenSizeTap(false);
                    handleDetailTypeChange("user", "선택 안함", setReferVal);
                  }}
                >
                  선택 안함
                </li>
                <li
                  className="plr-16 pt-[10px] pb-[10px] hover:-bg--color-neutral3"
                  onClick={() => {
                    setSelectedSize("단독주택");
                    setOpenSizeTap(false);
                    handleDetailTypeChange("user", "단독주택", setReferVal);
                  }}
                >
                  단독주택
                </li>
                <li
                  className="plr-16 pt-[10px] pb-[10px] hover:-bg--color-neutral3"
                  onClick={() => {
                    setSelectedSize("기숙사");
                    setOpenSizeTap(false);
                    handleDetailTypeChange("user", "기숙사", setReferVal);
                  }}
                >
                  기숙사
                </li>
                <li
                  className="plr-16 pt-[10px] pb-[10px] hover:-bg--color-neutral3"
                  onClick={() => {
                    setSelectedSize("타운하우스");
                    setOpenSizeTap(false);
                    handleDetailTypeChange("user", "타운하우스", setReferVal);
                  }}
                >
                  타운하우스
                </li>
                <li
                  className="plr-16 pt-[10px] pb-[10px] hover:-bg--color-neutral3"
                  onClick={() => {
                    setSelectedSize("기타");
                    setOpenSizeTap(false);
                    handleDetailTypeChange("user", "기타", setReferVal);
                  }}
                >
                  기타
                </li>
              </div>
            </div>
          </label>
          <label className="flex flex-col gap-[10px]" htmlFor="">
            <span className="pb-[4px] text-B14M">건축규모</span>
            <input
              className="outline-none w-full h-[48px] plr-16 ptb-12 box-border border-solid -border--color-neutral12 border-[1px] rounded-[6px] text-B16R  placeholder:-text--color-neutral50 focus:-border--color-primary-main"
              placeholder="직접 입력"
              type="text"
              onChange={(e) =>
                handleDetailTypeChange("size", e.target.value, setReferVal)
              }
              value={referVal.detail_size ? referVal.detail_size : ""}
              maxLength={12}
            />
          </label>
          {selectedSize === "기타" && (
            <div className="mt-[4px] text-B14R -text--color-neutral50">
              *추가 메세지란에 설명
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col px-[24px] py-[16px]">
        <span className="mb-[16px] text-B16B -text--color-primary-main">
          상담 정보
        </span>
        <div className="pb-[16px] flex flex-col">
          <span className="pb-[8px] text-B14M">중요 상담 여부</span>
          <div className="flex flex-row text-B16R items-center gap-[8px]">
            <input
              id="important-councel"
              type="checkbox"
              name="important-councel"
              // className="custom_checkbox_rounded"
              className="w-[18px] h-[18px]"
              checked={referVal.important_check === true}
              onChange={() =>
                setReferVal((prev) => {
                  return {
                    ...prev,
                    important_check: !prev.important_check,
                  };
                })
              }
            />
            <label
              htmlFor="important-councel"
              className="pr-[22px]  flex items-center"
            >
              중요
            </label>
          </div>
        </div>
        <div className="pb-[16px] flex flex-col">
          <span className="pb-[8px] text-B14M">유입경로</span>
          <div className="flex flex-row text-B16R">
            <input
              id="input_homepage"
              type="radio"
              name="incomming"
              className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
              onChange={() =>
                handleDetailTypeChange("type", "홈페이지", setReferVal)
              }
              checked={referVal.detail_type === "홈페이지"}
            />
            <label
              htmlFor="input_homepage"
              className="pr-[22px]  flex items-center"
            >
              홈페이지
            </label>
            <input
              id="input_phone"
              type="radio"
              name="incomming"
              className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
              onChange={() =>
                handleDetailTypeChange("type", "유선상담", setReferVal)
              }
              checked={referVal.detail_type === "유선상담"}
            />
            <label
              htmlFor="input_phone"
              className="pr-[22px]  flex items-center"
            >
              유선상담
            </label>
            <input
              id="input_etc"
              type="radio"
              name="incomming"
              className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
              onChange={() =>
                handleDetailTypeChange("type", "기타", setReferVal)
              }
              checked={referVal.detail_type === "기타"}
            />
            <label htmlFor="input_etc" className="pr-[22px]  flex items-center">
              기타
            </label>
          </div>
        </div>
        <div className="pb-[16px] flex flex-col">
          <span className="pb-[8px] text-B14M">고객적극도</span>
          <div className="flex flex-row text-B16R">
            <input
              id="input_high"
              type="radio"
              name="grade"
              className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
              onChange={() => handleDetailTypeChange("grade", 1, setReferVal)}
              checked={referVal.detail_positive === 1}
            />
            <label
              htmlFor="input_high"
              className="pr-[22px]  flex items-center"
            >
              상
            </label>
            <input
              id="input_middle"
              type="radio"
              name="grade"
              className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
              onChange={() => handleDetailTypeChange("grade", 2, setReferVal)}
              checked={referVal.detail_positive === 2}
            />
            <label
              htmlFor="input_middle"
              className="pr-[22px]  flex items-center"
            >
              중
            </label>
            <input
              id="input_low"
              type="radio"
              name="grade"
              className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
              onChange={() => handleDetailTypeChange("grade", 3, setReferVal)}
              checked={referVal.detail_positive === 3}
            />
            <label htmlFor="input_low" className="pr-[22px]  flex items-center">
              하
            </label>
          </div>
        </div>
        <div className="flex flex-col pb-[18px]">
          <span className="pb-[8px] text-B14M">대출 연계</span>
          <div className="flex flex-row text-B16R">
            <label
              htmlFor="input_necessary"
              className="pr-[20px] flex items-center"
            >
              <input
                id="input_necessary"
                type="radio"
                name="loan"
                className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
                onChange={() =>
                  handleDetailTypeChange("loans", true, setReferVal)
                }
                checked={referVal.detail_loans === true}
              />
              필요
            </label>
            <label
              htmlFor="input_unnecessary"
              className="pr-[20px] flex items-center"
            >
              <input
                id="input_unnecessary"
                type="radio"
                name="loan"
                className="mr-[8px] -accent--color-primary-main w-[18px] h-[18px]"
                onChange={() =>
                  handleDetailTypeChange("loans", false, setReferVal)
                }
                checked={referVal.detail_loans === false}
              />
              불필요
            </label>
          </div>
        </div>
        <div className="pb-[16px]">
          <label className="flex flex-col" htmlFor="">
            <span className="pb-[8px] text-B14M">예산액 (원)</span>
            <input
              className="outline-none w-full h-[48px] plr-16 ptb-12 box-border border-solid -border--color-neutral12 border-[1px] rounded-[6px] text-B16R placeholder:-text--color-neutral50 focus:-border--color-primary-main"
              placeholder="숫자로 입력"
              type="text"
              onInput={filterNum}
              onChange={(e) => handleDetailTypeChange("price", e, setReferVal)}
              value={
                referVal.detail_price
                  ? referVal.detail_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
            />
          </label>
        </div>
        <div className="pb-[16px]">
          <label className="flex flex-col" htmlFor="">
            <span className="pb-[8px] text-B14M">희망 연면적 (평)</span>
            <input
              className="outline-none w-full h-[48px] plr-16 ptb-12 box-border border-solid -border--color-neutral12 border-[1px] rounded-[6px] text-B16R  placeholder:-text--color-neutral50 focus:-border--color-primary-main"
              placeholder="숫자로 입력"
              type="text"
              onInput={filterNum}
              onChange={(e) => handleDetailTypeChange("needs", e, setReferVal)}
              value={
                referVal.detail_needs
                  ? referVal.detail_needs
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
            />
          </label>
        </div>

        <div className="relative flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <span className="pb-[8px] text-B14M">접수 내용</span>
            <span className="flex flex-row justify-end -text--color-neutral50 text-B14R">
              {referVal?.detail_appendix?.length}/150
            </span>
          </div>
          <textarea
            className={`resize-none focus:outline-none w-full -bg--color-neutral0 pl-[16px] pt-[12px] pr-[8px] pb-[12px] overflow-auto text-B16R -text--color-neutral100 placeholder:-text--color-neutral20 rounded-tl-[6px] rounded-tr-[4px] rounded-br-[4px] rounded-bl-[6px] border  -border--color-neutral12 focus:-border--color-primary-main overflow-hidden ${
              textareaHeight > 199 && "custom-scrollbar overflow-y-auto"
            }`}
            style={{ height: `${textareaHeight}px` }}
            placeholder="내용을 입력해 주세요."
            autoComplete="off"
            onChange={(e) => {
              handleDetailTypeChange("appendix", e, setReferVal);
              textareaHeightScope(e);
            }}
            defaultValue={
              referVal.detail_appendix ? referVal.detail_appendix : ""
            }
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default CouncelFixUserWeb;
