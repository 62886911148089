import React from "react";
import { handleTimeToKr } from "@/utils/func/common/convertTime";

const PdfBodyContent = React.forwardRef((props: any, ref: any) => {
  const { data, additionalData } = props;

  return (
    <div
      ref={additionalData && additionalData.councel?.length > 0 ? ref : null}
    >
      {additionalData && additionalData.councel.length > 0 && (
        <div className="flex flex-col pr-[24px] pl-[24px] pb-[24px]">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col pb-[16px] gap-[16px]">
              <div className="flex flex-row gap-[4px]">
                <div className="w-[80px] text-B14R font-700">상담 노트</div>
                <div className="flex flex-col">
                  {additionalData &&
                    [...additionalData.councel].reverse().map((idx, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="flex flex-col plr-8 ptb-8 gap-[12px] text-B10R w-[447px] break-words whitespace-pre-wrap trigger">
                            <div className="flex -flex-row">
                              <span className="font-700 mr-[12px]">{`<${
                                idx.councel_type === 3
                                  ? "공사"
                                  : idx.councel_type === 2
                                    ? "계약"
                                    : "상담"
                              }-${index + 1}>`}</span>
                              <span className="font-700 mr-[6px]">담당자</span>
                              <span className="mr-[16px]">
                                {idx.admin_name}
                              </span>
                              <span className="font-700 mr-[6px]">
                                상담 진행일
                              </span>
                              <span className="mr-[16px]">
                                {handleTimeToKr(idx.councel_datetime).slice(
                                  0,
                                  10
                                )}
                              </span>
                            </div>
                            <div
                              className="give_line_hight"
                              dangerouslySetInnerHTML={{
                                __html: idx.councel_detail,
                              }}
                            ></div>
                          </div>
                          <div className="border-b border-solid -border--color-neutral12 mt-[4px] mb-[4px]"></div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col pr-[24px] pl-[24px] pb-[24px] trigger">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col pb-[16px] gap-[16px]">
            {data && data.comment?.length > 0 && (
              <div className="flex flex-row gap-[4px]">
                <div className="w-[80px] text-B14R font-700">상담 댓글</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {data &&
                    [...data.comment].map((idx, index) => {
                      return (
                        <div
                          className="flex flex-col p-[8px] gap-[12px] text-B10R w-[447px] border-b border-solid -border--color-neutral12 break-words whitespace-pre-wrap trigger"
                          key={index}
                        >
                          <div className="flex flex-row">
                            <span className="font-700 mr-[12px]">
                              {idx.admin_name}
                            </span>
                            <span>{idx.comment_datetime.slice(0, 10)}</span>
                          </div>
                          <div
                            className="give_line_hight"
                            dangerouslySetInnerHTML={{
                              __html: idx.comment_detail,
                            }}
                          ></div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {data && data.resource?.length > 0 && (
              <div className="flex flex-row gap-[4px]">
                <div className="w-[80px] text-B14R font-700">첨부 파일</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {data &&
                    [...data.resource].map((idx, index) => {
                      return (
                        <div
                          className="flex flex-col p-[8px] gap-[12px] text-B10R w-[447px] border-b border-solid -border--color-neutral12 break-words whitespace-pre-wrap"
                          key={index}
                        >
                          <div className="flex flex-row">
                            <span className="font-700 mr-[6px]">담당자</span>
                            <span className="mr-[16px]">{idx.admin_name}</span>
                            <span className="font-700 mr-[6px]">
                              업로드 시간
                            </span>
                            <span>
                              {idx.resource_datetime
                                .replace("T", " ")
                                .slice(0, 16)}
                            </span>
                          </div>
                          <div>{idx.resource_name}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PdfBodyContent;
