import moment from "moment";

/**
 * @description api post 시 utc로 변경하는 기능 함수 (-9 시간)
 * @param {*} v ko-kr datetime
 * @returns -9 시간 값
 */
export const handleTimeToUtc = (v) => {
  return moment(v).subtract(9, "hours").format("YYYY-MM-DD HH:mm:ss");
};

/**
 * @description api get 시 +9(현지 시간)로 추가하는 기능 함수
 * @param {*} v utc datetime
 * @returns +9 시간 값
 */
export const handleTimeToKr = (v) => {
  return moment(v).add(9, "hours").format("YYYY.MM.DD HH:mm:ss");
};
