import React, { useEffect } from "react";

import { useRecoilState } from "recoil";

import { closePortal } from "@/utils/func/common/modalController";

import {
  defaultCalendarData,
  userResponseCalendar,
  userSearchParamCalendar,
} from "@/stores/page/calendar";

import { modalInModalInfo } from "@/stores/common/modal";
import http from "@/utils/api/http/http";

const CalendarSearchUserWeb = () => {
  const [searchParam, setSearchParam] = useRecoilState(userSearchParamCalendar);

  const [userData, setUserData] = useRecoilState(userResponseCalendar);

  const [tempStates, setTempStates] = useRecoilState(defaultCalendarData);

  const [modal, setModal] = useRecoilState(modalInModalInfo);

  useEffect(() => {
    if (modal.value === "calendar-search-user-web") {
      http
        .get(`/api/admin/search/councel`, {
          params: { search: searchParam },
        })
        .then((res) => {
          setUserData(res);
        });
    }
  }, [modal.value, searchParam]);

  return (
    <div className="plr-32 pt-[16px] h-[584px] overflow-y-auto custom-scrollbar">
      <input
        required
        className="outline-none w-[324px] h-[48px] plr-16 ptb-12 box-border border border-solid -border--color-neutral6 rounded-[6px] text-B16R placeholder:text-B16R placeholder:-text--color-neutral50 focus:-border--color-primary-main mb-[8px]"
        type="text"
        placeholder="고객명, 휴대폰 번호 또는 주소 검색"
        defaultValue=""
        onChange={(e) => {
          setSearchParam(e.target.value);
        }}
      />
      {userData && userData.data.data.councel.length === 0 ? (
        <div className="h-[40px] flex items-center">
          <span>검색 결과 없음</span>
        </div>
      ) : (
        userData &&
        userData.data.data.councel.map((idx) => {
          return (
            <div
              className="ptb-16 border-b border-solid -border--color-neutral6 flex flex-col gap-[6px]"
              onClick={() => {
                setTempStates((prev) => {
                  return {
                    ...prev,
                    calender_name: idx.user_name,
                  };
                });
                setTempStates((prev) => {
                  return {
                    ...prev,
                    calender_phone: idx.user_phone,
                  };
                });
                closePortal(setModal);
              }}
            >
              <span className="text-B14R border rounded-[30px] -bg--color-neutral6 pt-[3px] pb-[3px] pl-[11px] pr-[11px] w-fit">
                {idx.process_name}
              </span>
              <p>
                <span>{idx.user_name}&nbsp;</span>
                <span>
                  {idx.user_phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}
                </span>
              </p>
              <span>{idx.address_jibun}</span>
            </div>
          );
        })
      )}
    </div>
  );
};

export default CalendarSearchUserWeb;
