import React from "react";

const CouncelDeleteCommentWeb = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-[4px] py-[24px] px-[100px]">
      <span className="text-H21B">댓글을 삭제할까요?</span>
      <span className="text-B14R -text--color-neutral50">
        삭제하면 복구가 불가능해요.
      </span>
    </div>
  );
};

export default CouncelDeleteCommentWeb;
