import React from "react";

import wait_icon from "@/Assets/Images/wait_icon.svg";

const EntityProgressCommon = () => {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="z-20 -bg--color-neutral0 transform w-[311px] h-[112px] rounded-[12px] flex flex-col items-center justify-center">
        <img
          src={wait_icon}
          alt=""
          className="animate-spin mb-[16px] w-[24px] h-[24px]"
        />
        <span>처리 중입니다. 잠시만 기다려주세요.</span>
      </div>
    </div>
  );
};

export default EntityProgressCommon;
