import { councelParameter } from "@/stores/common/parameter";
import React from "react";
import { useRecoilState } from "recoil";

interface propsType {
  callback: (arg: any) => void;
}

const MobileSearch = ({ callback }: propsType) => {
  const [parameter, setParameter] = useRecoilState(councelParameter);

  return (
    <section className="relative pb-[16px] px-[16px] flex flex-row w-full -bg--color-neutral0">
      <div className="w-full flex flex-row border border-solid rounded-full -border--color-neutral12">
        <input
          type="textarea"
          defaultValue={parameter.search}
          placeholder="상담번호, 이름 또는 휴대폰 검색"
          className="rounded-full w-full -placeholder--color-neutral50 focus:outline-none text-B16R py-[13px] pl-[18px]"
          onChange={(e) => callback(e)}
        />
        <button className="right-0 pl-[10px] pr-[16px] py-[13px]">
          <span className="_sprite_ _sprite_7_13_"></span>
        </button>
      </div>
    </section>
  );
};

export default MobileSearch;
