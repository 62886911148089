/**
 * @description 영역에 있는 page
 * @param {*} value 5로 나눈 page 값, total page 값
 * @param {*} callback 선택한 page, 그에 따른 page period, parameter handler 함수
 */
export function paginationController(value, callback) {
  callback.func1(value.val1);
  callback.func2(value.val2);
  callback.func3(value.val2);
}

// 대시보드 페이지에서 컴포넌트 클릭 이벤트 시 페이지 이동
/**
 * @description 페이지 이동 함수
 * @param {*} e navState 값 변경 상태 값
 * @param {*} value  url
 * @param {*} callback
 */
export function pageController(e, value, callback) {
  callback.func1(e);
  callback.func2(value);

  if (callback.func3) {
    callback.func3();
  }
}
