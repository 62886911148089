import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { modalInModalInfo, modalInfo } from "@/stores/common/modal";
import { closePortal } from "@/utils/func/common/modalController";
import { tempStartDate, tempEndDate } from "@/stores/common/calendar";
import { selectDate, tempSelectDate } from "@/stores/common/Mobile";
import { tempCouncelParameter } from "@/stores/common/parameter";
import {
  pickedCouncelNoteDate,
  tempPickedCouncelNoteDate,
} from "@/stores/page/councelDetail";

const ModalFooterApply = () => {
  const [modal, setModal] = useRecoilState(modalInfo);
  const [modalInModal, setModalInModal] = useRecoilState(modalInModalInfo);

  const [, setTempMobileFilter] = useRecoilState(tempCouncelParameter);

  // 데이트 피커 창에서 임의로 선택한 날짜를 담은 상태 값 -> tempParameter로 전달하는데 사용
  const selectedStartDate = useRecoilValue(tempStartDate);
  const selectedEndDate = useRecoilValue(tempEndDate);

  const [dateState, setDateState] = useRecoilState(selectDate);
  const [tempDateState, setTempDateState] = useRecoilState(tempSelectDate);

  // 상담노트 작성 시 pc에서 사용하는 상태 값
  const [pickedDate, setPickedDate] = useRecoilState(pickedCouncelNoteDate);
  const [tempPickedDate, setTempPickedDate] = useRecoilState(
    tempPickedCouncelNoteDate
  );

  const handleApply = () => {
    if (modalInModal.value === "councel-councelnote-calendar-mobile") {
      setDateState(tempDateState);
    }
    if (modal.value === "councelnote-detail-calendar-web") {
      setPickedDate(tempPickedDate);
      closePortal(setModal);
    } else {
      setTempMobileFilter((prev) => {
        return {
          ...prev,
          startDate:
            selectedStartDate === undefined
              ? undefined
              : selectedStartDate.format(),
          endDate:
            selectedEndDate === undefined
              ? undefined
              : selectedEndDate.format(),
        };
      });
    }

    closePortal(setModalInModal);
  };

  const handleClose = () => {
    if (modal.value === "councelnote-detail-calendar-web") {
      setTempDateState("");
      closePortal(setModal);
    } else {
      closePortal(setModalInModal);
    }
  };

  return (
    <section className="plr-16 pb-[16px] grid grid-cols-2">
      <button
        className="pl-[59.28px] pr-[59.28px] ptb-8 text-B14B -text--color-neutral50 focus:-text--color-primary-main"
        onClick={() => handleClose()}
      >
        닫기
      </button>
      <button
        className="pl-[59.28px] pr-[59.28px] ptb-8 text-B14B -text--color-neutral50 focus:-text--color-primary-main"
        onClick={() => handleApply()}
      >
        적용
      </button>
    </section>
  );
};

export default ModalFooterApply;
