import React from "react";

interface propsType {
  data: any;
}

const MobileCouncelInfo = ({ data }: propsType) => {
  return (
    <section className="ptb-16 plr-16 -bg--color-neutral0 gap-[16px] flex flex-col mb-[12px]">
      <header className="-text--color-primary-main -text--header-16b font-700">
        상담 정보
      </header>
      <article className="grid grid-rows-2 grid-cols-2 gap-y-[16px]">
        <div className="flex flex-col">
          <span className="-text--color-neutral50 -text--label-12b font-700 mb-[4px]">
            고객 적극도
          </span>
          <span>
            {data?.detail_positive === 1
              ? "상"
              : data?.detail_positive === 2
              ? "중"
              : data?.detail_positive === 3
              ? "하"
              : "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="-text--color-neutral50 -text--label-12b font-700 mb-[4px]">
            대출 연계
          </span>
          <span>
            {data?.detail_loans === true
              ? "필요"
              : data?.detail_loans === false
              ? "불필요"
              : "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="-text--color-neutral50 -text--label-12b font-700 mb-[4px]">
            예산액
          </span>
          <span>
            {data?.detail_price === null ? "-" : `${data?.detail_price} 원`}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="-text--color-neutral50 -text--label-12b font-700 mb-[4px]">
            희망 연면적
          </span>
          <span>
            {data?.detail_needs === null ? "-" : `${data?.detail_needs} 평`}
          </span>
        </div>
      </article>
      <article className="flex flex-col">
        <span className="-text--color-neutral50 -text--label-12b font-700 mb-[4px]">
          접수 내용
        </span>
        <pre className="break-words whitespace-pre-wrap">
          {data?.detail_appendix === null ? "-" : data?.detail_appendix}
        </pre>
      </article>
    </section>
  );
};

export default MobileCouncelInfo;
