import http from "@/utils/api/http/http";

interface getInquiryProps {
  category?: number;
  search?: string | null;
  pageno?: number;
}

export async function getInquiry(val: getInquiryProps) {
  return await http.get(`/api/admin/question`, { params: val });
}

interface postInquiryProps {
  idx?: number;
  contents?: string;
}

export async function postInquiry(props: postInquiryProps) {
  const { idx, contents } = props;

  const body = {
    question_idx: idx,
    admin_board: contents,
  };

  return await http.post(`/api/admin/question`, body, null);
}
