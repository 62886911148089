import React, { useEffect, useState } from "react";

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  choicedManager,
  errState,
  inputValueManager,
  modalInfo,
  passwordValue,
} from "@/stores/common/modal";
import { urlParameterCouncel } from "@/stores/page/councelDetail";

import { changePassword } from "@/utils/api/auth/auth";

import http from "@/utils/api/http/http";

import { closePortal, showPortal } from "@/utils/func/common/modalController";

import moment from "moment";
import {
  responseCalendar,
  defaultCalendarData,
  calendarAttendee,
  copiedResponseCalendar,
} from "@/stores/page/calendar";
import { additionalResponseData, responseData } from "@/stores/common/data";
import { councelNoteAddressIdx } from "@/stores/page/councelDetail";

const ModalConfirmFooter = () => {
  const [modal, setModal] = useRecoilState(modalInfo);

  // 비밀번호 변경 시 사용
  const [password, setPassword] = useRecoilState(passwordValue);

  const resetPassword = useResetRecoilState(passwordValue);

  // 담당자 변경
  const [userData, setUserData] = useRecoilState(responseData);
  const id = useRecoilValue(urlParameterCouncel);

  const [selectedManager, setSelectedManager] = useRecoilState(choicedManager);
  const resDataCouncel = useRecoilValue(additionalResponseData);

  // 일정 등록
  const [emptyData, setEmptyData] = useRecoilState(defaultCalendarData);
  const resetEmptyData = useResetRecoilState(defaultCalendarData);

  const [tempAttendee, setTempAttendee] = useRecoilState(calendarAttendee);

  const [calResData, setCalResData] = useRecoilState(responseCalendar);
  const [copiedData, setCopiedData] = useRecoilState(copiedResponseCalendar);

  const [alertErr, setAlertErr] = useRecoilState(errState);
  const [managerValue, setManagerValue] = useRecoilState(inputValueManager);

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  async function confirmPassword() {
    try {
      const success = await changePassword({ password, setModal });

      if (success.status === 200) {
        resetPassword();
        setModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // 담당자 변경
  async function confirmCouncelor() {
    if (
      selectedManager.managerName !== managerValue ||
      selectedManager.managerName === "선택 없음" ||
      selectedManager.managerIndex === ""
    ) {
      setAlertErr(true);
    }

    if (
      selectedManager.managerName === managerValue &&
      selectedManager.managerName !== "선택 없음" &&
      selectedManager.managerIndex !== ""
    ) {
      showPortal(setModal, true, "entity-progress-common");
      setAlertErr(false);
      setManagerValue("");

      try {
        await http.put(`/api/councel/manage`, null, {
          params: {
            process_idx: userData.process.process_idx,
            admin_idx: selectedManager.managerIndex,
          },
        });

        await http
          .post(`/api/councel/detail`, {
            user_idx: id.user,
            process_idx: id.process,
            address_idx_list: addressIdxList,
          })
          .then((res) => {
            setUserData(res.data.data);
          });

        closePortal(setModal);
      } catch (err) {
        console.error(err);
      }
    }

    if (
      selectedManager.managerName === null ||
      selectedManager.managerIndex === null
    ) {
      showPortal(setModal, true, "entity-progress-common");
      setAlertErr(false);
      setManagerValue("");

      try {
        await http.put(`/api/councel/manage`, null, {
          params: {
            process_idx: userData.process.process_idx,
          },
        });

        await http
          .post(`/api/councel/detail`, {
            user_idx: id.user,
            process_idx: id.process,
            address_idx_list: addressIdxList,
          })
          .then((res) => {
            setUserData(res.data.data);
          });

        closePortal(setModal);
      } catch (err) {
        console.error(err);
      }
    }
  }

  const copingDataFunc = (e) => {
    let array = JSON.parse(JSON.stringify(e.calender));

    array.forEach((element) => {
      // Calendar event에서 key값을 start, end로 받아야 해서 추가
      element.start = moment.utc(element.calender_start).local().format();
      element.end = moment.utc(element.calender_end).local().format();

      element.title = `${moment(element.start).format("HH:mm")} ${
        element.admin_list.length > 0 ? element.admin_list[0].admin_name : ""
      } ${
        element.admin_list.length > 1 ? `+${element.admin_list.length - 1}` : ""
      }`;
    });

    setCopiedData(array);
  };

  // 일정 등록 함수
  async function enrollSchedule() {
    const filterIdx = tempAttendee.map((idx) => idx.admin_idx);

    const body = {
      calender_category: emptyData.calender_category,
      calender_location: emptyData.calender_location,
      calender_start: moment(emptyData.calender_start).utc().format(),
      calender_end: moment(emptyData.calender_end).utc().format(),
      calender_name: emptyData.calender_name,
      calender_phone: emptyData.calender_phone,
      admin_idx: filterIdx,
      calender_detail: emptyData.calender_detail,
    };

    try {
      await http.post(`/api/calender`, body);

      // 일정 등록 후 여기서 get를 하는게 맞나..?
      await http
        .get(`/api/calender`, { params: { select_month: new Date() } })
        .then((res) => {
          setCalResData(res.data.data);

          copingDataFunc(res.data.data);
          resetEmptyData();
          closePortal(setModal);
        });
    } catch (err) {
      console.error(err);
    }
  }

  const fixSchedule = async () => {
    const filterIdx = [];

    emptyData.admin_list.map((idx) => {
      filterIdx.push(idx.admin_idx);
    });

    try {
      await http.put(`/api/calender`, {
        calender_idx: emptyData.calender_idx,
        calender_category: emptyData.calender_category,
        calender_location: emptyData.calender_location,
        calender_start: moment(emptyData.start),
        calender_end: moment(emptyData.end),
        calender_name: emptyData.calender_name,
        calender_phone: emptyData.calender_phone,
        admin_idx: filterIdx,
        calender_detail: emptyData.calender_detail,
      });

      await http
        .get(`/api/calender`, { params: { select_month: new Date() } })
        .then((res) => {
          // setResData(res.data.data);
          setCalResData(res.data.data);
          copingDataFunc(res.data.data);

          closePortal(setModal);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const ctrlClickEvent = async () => {
    switch (modal.value) {
      case "entity-password-web":
        return confirmPassword();
      case "councel-assign-manager-web":
        return confirmCouncelor();
      case "calendar-add-schedule-web":
        return enrollSchedule();
      case "calendar-fix-schedule-web":
        return fixSchedule();
      default:
        return null;
    }
  };

  // 비밀번호 변경 시 button 태그 제어 목적
  const [btnState, setBtnState] = useState(false);

  useEffect(() => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=*])[A-Za-z\d@#$%^&+=*]{8,}$/;

    if (
      regex.test(password.value) &&
      regex.test(password.confirmValue) &&
      password.value === password.confirmValue
    ) {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
  }, [password.value, password.confirmValue]);

  return (
    <section className="flex justify-end pt-[12px] pr-[16px] pb-[16px]">
      <button
        className="w-[128px] h-[44px] -bg--color-primary-main rounded-[6px] -text--color-neutral0 disabled:opacity-50"
        onClick={() => ctrlClickEvent()}
        disabled={modal.value === "entity-password-web" && !btnState}
      >
        적용
      </button>
    </section>
  );
};

export default ModalConfirmFooter;
