import React from "react";

import SideBar from "@/components/common/SideBar";

const PagesLayout = () => {
  return (
    <div className="h-screen custom-scrollbar overflow-auto">
      <SideBar />
    </div>
  );
};

export default PagesLayout;
