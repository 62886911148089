import React from "react";

import { useRecoilState } from "recoil";

import { showPortal } from "@/utils/func/common/modalController";

import { modalInfo } from "@/stores/common/modal";

// 모바일 버전 상담 노트 추가 버튼
const AddCouncelNote = () => {
  const [, setModal] = useRecoilState(modalInfo);

  return (
    <button
      onClick={() => {
        showPortal(setModal, true, "councel-add-councelnote-mobile");
      }}
      className="fixed right-0 bottom-[76px] mb-[12px] mr-[12px] w-[44px] h-[44px] flex items-center justify-center -bg--color-primary-main z-20 rounded-[4px]"
    >
      <span className="_sprite_ _sprite_18_1_"></span>
    </button>
  );
};

export default AddCouncelNote;
