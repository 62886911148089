import {
  commentContent,
  councelNoteAddressIdx,
} from "@/stores/page/councelDetail";
import React, { useEffect, useRef } from "react";

import { responseData } from "@/stores/common/data";
import { useRecoilState } from "recoil";
import {
  addCouncelDetailComment,
  getCouncelDetailCouncelData,
  getCouncelDetailData,
} from "@/utils/api/pages/councelDetailMobile";

const Comment = () => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [commentState, setCommentState] = useRecoilState(commentContent);

  const [data, setData] = useRecoilState(responseData);

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "0px";

      const scrollHeight = textareaRef.current.scrollHeight;
      const maxHeight = 80;

      if (scrollHeight > maxHeight) {
        textareaRef.current.style.overflowY = "hidden";
        textareaRef.current.style.height = maxHeight + "px";
      } else {
        textareaRef.current.style.overflowY = "hidden";
        textareaRef.current.style.height = scrollHeight + "px";
      }
    }
  }, [commentState]);

  async function addComment() {
    try {
      addCouncelDetailComment({
        process_idx: data.process.process_idx,
        comment_detail: commentState,
      });

      getCouncelDetailCouncelData({
        params: { process_idx: data.process.process_idx },
      }).then((res) => {
        getCouncelDetailData({
          user_idx: data.user.user_idx,
          process_idx: data.process.process_idx,
          address_idx_list: addressIdxList,
          admin_idx: localStorage.getItem("admin_idx"),
        }).then((res) => {
          setData(res.data.data);
          setCommentState("");
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <section className="fixed bottom-[76px] w-full py-[10px] px-[16px] -bg--color-neutral0 flex flex-row gap-[6px] -text--body-14r border-t border-solid -border--color-neutral6">
      <textarea
        rows={1}
        className="resize-none border border-solid py-[10px] px-[16px] w-full rounded-[20px] overflow-y-hidden h-auto"
        ref={textareaRef}
        value={commentState}
        placeholder="댓글을 입력하세요"
        onChange={(e) => {
          setCommentState(e.target.value);
        }}
      ></textarea>
      <button onClick={() => addComment()}>
        <span
          className={`_sprite_ ${
            commentState.length > 0 ? "_sprite_16_7_" : "_sprite_7_24_"
          }`}
        ></span>
      </button>
    </section>
  );
};

export default Comment;
