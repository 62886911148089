import React, { useEffect } from "react";
import { useRecoilState } from "recoil";

import {
  calendarAttendee,
  councelResponseCalendar,
  userSearchParamCalendar,
} from "@/stores/page/calendar";

import { ctrlCheckedAttendee } from "@/utils/func/page/schedule";

import { modalInModalInfo } from "@/stores/common/modal";

import http from "@/utils/api/http/http";

const CalendarSearchAttendeeWeb = () => {
  // 백엔드로부터 참석자 명단 받아오기
  const [councelList, setCouncelList] = useRecoilState(councelResponseCalendar);
  // 선택 값
  const [tempAttendee, setTempAttendee] = useRecoilState(calendarAttendee);

  const [modal, setModal] = useRecoilState(modalInModalInfo);

  const [searchParam, setSearchParam] = useRecoilState(userSearchParamCalendar);

  useEffect(() => {
    if (modal.value === "calendar-search-attendee-web") {
      http.get(`/api/admin/search/manage`).then((res) => {
        setCouncelList(res.data.data.admin);
      });
    }
  }, [modal.value, searchParam]);

  return (
    <div className="w-[260px] max-h-[248px] overflow-y-auto custom-scrollbar flex flex-col items-start border-b border-solid -border--color-neutral6">
      {councelList &&
        councelList.map((idx) => {
          const checked =
            tempAttendee &&
            tempAttendee.some((item) => item.admin_idx === idx.admin_idx);
          return (
            <>
              <form className="h-[44px] plr-18 ptb-12 flex justify-center items-center gap-[10px] text-B16R">
                <input
                  type="checkbox"
                  name="filter_councel"
                  value={idx.admin_idx}
                  className="custom_checkbox"
                  onChange={(e) =>
                    ctrlCheckedAttendee(e, idx, tempAttendee, setTempAttendee)
                  }
                  checked={checked}
                />
                <div className="ptb-16 flex flex-col gap-[6px]">
                  <span className="text-B14R  pt-[3px] pb-[3px] pl-[11px] pr-[11px] w-fit text-B16R">
                    {idx.admin_name}
                  </span>
                </div>
              </form>
            </>
          );
        })}
    </div>
  );
};

export default CalendarSearchAttendeeWeb;
