import React, { useEffect, useRef } from "react";

import { useRecoilState } from "recoil";

import { showPortal } from "@/utils/func/common/modalController";
import {
  fixCouncelDetailComment,
  getCouncelDetailCouncelData,
  getCouncelDetailData,
} from "@/utils/api/pages/councelDetailMobile";

import {
  commentContent,
  commentFixContent,
  councelNoteAddressIdx,
  selectedCommentIndex,
} from "@/stores/page/councelDetail";
import { modalInfo } from "@/stores/common/modal";
import { responseData } from "@/stores/common/data";

import moment from "moment";

// 댓글 등록 카드
const MobileComment = ({ item }: any) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [selectedComment, setSelectedComment] =
    useRecoilState(selectedCommentIndex);

  const [fixCommentState, setFixCommentState] =
    useRecoilState(commentFixContent);

  const [commentState, setCommentState] = useRecoilState(commentContent);

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  // 3. 댓글 수정 기능 (작성인 본인 일 시))
  // 수정 버튼 클릭 시 화면 상태 제어 목적
  const showFixComment = (e: any) => {
    setSelectedComment(e.comment_idx);
    setFixCommentState(e.comment_detail);
  };

  const [modal, setModal] = useRecoilState(modalInfo);

  const [data, setData] = useRecoilState(responseData);

  async function fixComment() {
    try {
      const fixData = await fixCouncelDetailComment({
        comment_idx: selectedComment,
        comment_detail: fixCommentState,
      });

      setSelectedComment("");
      setFixCommentState("");

      const getDetailData = await getCouncelDetailCouncelData({
        params: { process_idx: data.process.process_idx },
      });

      const getData = await getCouncelDetailData({
        user_idx: data.user.user_idx,
        process_idx: data.process.process_idx,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      });

      setData(getData.data.data);
      setCommentState("");
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "0px";

      const scrollHeight = textareaRef.current.scrollHeight;
      const maxHeight = 80;

      if (scrollHeight > maxHeight) {
        textareaRef.current.style.overflowY = "hidden";
        textareaRef.current.style.height = maxHeight + "px";
      } else {
        textareaRef.current.style.overflowY = "hidden";
        textareaRef.current.style.height = scrollHeight + "px";
      }
    }
  }, [fixCommentState]);

  return (
    <article className="py-[8px] gap-[16px] flex flex-col">
      <div className="flex flex-col gap-[4px]">
        <div className="flex flex-row items-center py-[8px] justify-between">
          <p className="flex items-center gap-[4px]">
            <span className="-text--body-14b font-700 -text--color-neutral">
              {item.admin_name}
            </span>
            <span className="-text--label-12b font-500 -text--color-neutral34">
              {moment(item.comment_datetime)
                .add(9, "hours")
                .format("YYYY.MM.DD HH:mm:ss")}
            </span>
          </p>
          <div className="flex flex-row gap-[12px] -text--body-14b font-700 -text--color-neutral50">
            <button
              onClick={() => {
                showFixComment(item);
              }}
              className={item.comment_idx === selectedComment ? "hidden" : ""}
            >
              수정
            </button>
            <button
              onClick={() => {
                setSelectedComment(item.comment_idx);

                showPortal(setModal, true, "councel-add-comment-mobile");
              }}
            >
              삭제
            </button>
          </div>
        </div>
        {item.comment_idx === selectedComment ? (
          <div className="w-fullpx-[16px] py-[10px] -bg--color-neutral0 flex flex-row gap-[6px] -text--body-14r">
            <textarea
              rows={1}
              className="resize-none border border-solid py-[10px] px-[16px] w-full rounded-[20px] overflow-y-hidden h-auto"
              ref={textareaRef}
              value={fixCommentState}
              placeholder="댓글을 입력하세요"
              onChange={(e) => {
                setFixCommentState(e.target.value);
              }}
            ></textarea>
            <button onClick={() => fixComment()}>
              <span className="_sprite_ _sprite_7_24_"></span>
            </button>
          </div>
        ) : (
          <p
            className="-text--body-14r font-400 -text--color-neutral100 whitespace-normal break-words overflow-y-hidden"
            dangerouslySetInnerHTML={{
              __html: item.comment_detail.replace(/\n/g, "<br>"),
            }}
          ></p>
        )}
      </div>
      <div className="border-b border-solid -border--color-neutral6"></div>
    </article>
  );
};

export default MobileComment;
