import React from "react";

import { useRecoilState, useRecoilValue } from "recoil";

import { device } from "@/stores/common";
import { modalInfo } from "@/stores/common/modal";

import ModalHeader from "@/components/header/ModalHeader";
import ModalBody from "@/components/body/ModalBody";
import ModalFooter from "@/components/footer/ModalFooter";

const ModalLayout = () => {
  const [modal, setModal] = useRecoilState(modalInfo);

  const currentDevice = useRecoilValue(device);

  let dynamicClassName =
    currentDevice === "mobile" &&
    !!(
      modal.value === "councel-add-councelnote-mobile" ||
      modal.value === "councel-fix-councelnote-mobile"
    ) &&
    "w-full absolute bottom-0 rounded-b-none h-[calc(var(--vh,1vh)*100-48px)]";

  let dynamicClassName2 =
    currentDevice === "mobile" &&
    !(
      modal.value === "councel-add-councelnote-mobile" ||
      modal.value === "councel-fix-councelnote-mobile"
    ) &&
    "w-full absolute bottom-0 rounded-b-none h-real-screen min-h-real-screen";

  return (
    <main
      className={`div-modal-layout-animate animate-fade-in-up  ${dynamicClassName} ${dynamicClassName2}`}
      onClick={(e) => e.stopPropagation()}
    >
      <ModalHeader value={modal.value} callback={setModal} />
      <ModalBody />
      <ModalFooter v={modal.value} />
    </main>
  );
};

export default ModalLayout;
