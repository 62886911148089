import React from "react";

import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil";
import {
  modalInfo,
  modalInModalInfo,
  tempReferVal,
} from "@/stores/common/modal";

import { closePortal } from "@/utils/func/common/modalController";

import http from "@/utils/api/http/http";
import moment from "moment";
import { calendarAttendee, defaultCalendarData } from "@/stores/page/calendar";

import { device } from "@/stores/common/index";
import { tempResponseData, tempUserResponseData } from "@/stores/common/Mobile";
import { tempEndDate, tempStartDate } from "@/stores/common/calendar";
import { responseData } from "@/stores/common/data";
import {
  councelParameter,
  tempCouncelParameter,
} from "@/stores/common/parameter";

import {
  requiredInfo,
  selectedDetailCouncelNote,
} from "@/stores/page/councelDetail";

const ModalFooterResetType = () => {
  const [modal, setModal] = useRecoilState(modalInfo);
  const [modal2, setModal2] = useRecoilState(modalInModalInfo);

  const [detailCouncelParams, setDetailCouncelParams] =
    useRecoilState(councelParameter);

  const tempFilterState = useRecoilValue(tempCouncelParameter);

  const resetFilterValue = useResetRecoilState(tempCouncelParameter);

  // 고객 정보 변경 임시 상태
  const [referVal, setReferVal] = useRecoilState(tempReferVal);

  const [userData, setUserData] = useRecoilState(responseData);

  const [tempStates, setTempStates] = useRecoilState(defaultCalendarData);
  const [tempAttendee, setTempAttendee] = useRecoilState(calendarAttendee);
  const resetTempAttendee = useResetRecoilState(calendarAttendee);

  const resetSelectedStartDate = useResetRecoilState(tempStartDate);
  const resetSelectedEndDate = useResetRecoilState(tempEndDate);

  const resetTempUserData = useResetRecoilState(tempUserResponseData);
  const [tempUserData, setTempUserData] = useRecoilState(tempUserResponseData);
  const [resultData, setResultData] = useRecoilState(responseData);

  const [essentialInfo, setEssentialInfo] = useRecoilState(requiredInfo);

  const resetValue = () => {
    if (modal.value === "councel-filtering-web") {
      resetFilterValue();
    }
    if (modal.value === "user") {
      // setReferVal(JSON.parse(JSON.stringify(userData.user)));
      setReferVal((prev) => ({ ...prev, ...userData?.user }));
      setReferVal((prev) => ({
        ...prev,
        ...{ process_name: userData?.process.process_name },
      }));
      setReferVal((prev) => ({
        ...prev,
        ...{ important_check: userData?.process.important_check },
      }));
    }
    if (modal2.value === "calendar-search-attendee-web") {
      resetTempAttendee();
    }

    if (modal.value === "councel-filter-mobile") {
      resetFilterValue();
      resetSelectedStartDate();
      resetSelectedEndDate();
    }

    if (modal.value === "councel-userinfo-mobile") {
      // resetTempUserData();
      setTempUserData(JSON.parse(JSON.stringify(resultData)));
    }
  };

  function submitFilterParam() {
    if (tempFilterState.location.length === 0) {
      setDetailCouncelParams((prev) => {
        return { ...prev, location: [], pageno: 1 };
      });
    } else {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          location: tempFilterState.location.join(),
          pageno: 1,
        };
      });
    }

    setDetailCouncelParams((prev) => {
      return { ...prev, process_type: tempFilterState.step, pageno: 1 };
    });

    if (
      tempFilterState.startDate !== undefined &&
      tempFilterState.endDate !== undefined
    ) {
      const startUtc = moment(tempFilterState.startDate).utc().format();
      const endUtc = moment(tempFilterState.endDate).utc().format();

      setDetailCouncelParams((prev) => {
        return { ...prev, start_datetime: startUtc };
      });
      setDetailCouncelParams((prev) => {
        return { ...prev, end_datetime: endUtc };
      });
    } else {
      setDetailCouncelParams((prev) => {
        return { ...prev, start_datetime: undefined };
      });
      setDetailCouncelParams((prev) => {
        return { ...prev, end_datetime: undefined };
      });
    }

    closePortal(setModal);
  }

  function submitMobileFilterParam() {
    if (tempFilterState.location[0] === "") {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          location: [""],
          pageno: 1,
        };
      });
    } else {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          // 배열 유지하고, api 통신 시에만 배열을 문자열로 변환
          // location: tempFilterState.location.join(),
          location: String(tempFilterState.location),
          pageno: 1,
        };
      });
    }

    if (tempFilterState.process_type === "전체") {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          process_type: null,
        };
      });
    }
    if (tempFilterState.process_type === "상담대기") {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          process_type: 0,
        };
      });
    }
    if (tempFilterState.process_type === "상담 중") {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          process_type: 1,
        };
      });
    }
    if (tempFilterState.process_type === "상담완료") {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          process_type: 2,
        };
      });
    }
    if (tempFilterState.process_type === "상담취소") {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          process_type: 9,
        };
      });
    }

    if (tempFilterState.period !== "") {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          start_datetime: moment(tempFilterState.startDate).utc().format(),
          end_datetime: moment(tempFilterState.endDate)
            .utc()
            .add(1, "days")
            .format(),
        };
      });
    }

    if (
      tempFilterState.startDate !== undefined ||
      tempFilterState.endDate !== undefined
    ) {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          start_datetime: moment(tempFilterState.startDate).utc().format(),
          end_datetime: moment(tempFilterState.endDate)
            .utc()
            .add(1, "days")
            .format(),
        };
      });
    }

    if (
      tempFilterState.startDate === undefined ||
      tempFilterState.endDate === undefined
    ) {
      setDetailCouncelParams((prev) => {
        return {
          ...prev,
          start_datetime: undefined,
          end_datetime: undefined,
        };
      });
    }

    closePortal(setModal);
  }

  const [selectedCard, setSelectedCard] = useRecoilState(
    selectedDetailCouncelNote
  );

  // 고객 정보 수정 후 실행
  async function submitUserData() {
    // 상담 정보가 바뀌었을 때
    // 상담 정보는 바뀌지 않을 떄

    if (referVal.detail_appendix !== userData?.user.detail_appendix) {
      setSelectedCard({
        additional_councel: "user_info",
        councel_datetime: userData?.process.process_datetime,
        councel_detail: referVal.detail_appendix,
      });
    }

    if (
      referVal.user_name === null ||
      referVal.user_phone === null ||
      referVal.user_name.length <= 0 ||
      referVal.user_phone.length === 0
    ) {
      // alert("이름과 전화번호는 필수 항목입니다");
      setEssentialInfo(1);
    } else if (
      referVal.user_name === null ||
      referVal.user_phone === null ||
      referVal.user_name.length <= 0 ||
      referVal.user_phone.length < 10
    ) {
      alert("숫자만 입력이 가능하며 최대 15자리까지 입력 가능합니다.");
    } else if (referVal.detail_type === "기타") {
      try {
        // user_privilege === 0 => 관리자가 등록한 상담 건

        if (userData?.user.user_privilege === 0) {
          // 필수 정보 변화가 없으면 추가 정보만 호출
          if (
            userData?.user.user_name === referVal.user_name &&
            userData?.user.user_phone === referVal.user_phone &&
            userData?.user.user_id === referVal.user_id
          ) {
            const response = await http.put(`/api/councel/refer`, {
              process_idx: userData?.process.process_idx,
              detail_type: referVal.detail_type,
              detail_positive: referVal.detail_positive,
              detail_needs: referVal.detail_needs,
              detail_loans: referVal.detail_loans,
              detail_price: referVal.detail_price,
              detail_size: referVal.detail_size,
              detail_use: referVal.detail_use,
              detail_appendix: referVal.detail_appendix,
              project_name: referVal.process_name,
              important_check: referVal.important_check,
            });

            setUserData({
              ...userData,
              user: {
                ...userData?.user,
                ...response.data.data,
              },
              process: {
                ...userData?.process,
                process_name: response.data.data.project_name,
                important_check: response.data.data.important_check,
              },
            });
            // 추가 정보 변화가 없으면 필수 정보만 호출
          } else if (
            userData?.user.detail_appendix === referVal.detail_appendix &&
            userData?.user.detail_loans === referVal.detail_loans &&
            userData?.user.detail_needs === referVal.detail_needs &&
            userData?.user.detail_positive === referVal.detail_positive &&
            userData?.user.detail_price === referVal.detail_price &&
            userData?.user.detail_size === referVal.detail_size &&
            userData?.user.detail_type === referVal.detail_type &&
            userData?.user.detail_use === referVal.detail_use &&
            userData?.process.process_name === referVal.process_name &&
            userData?.process.important_check === referVal.important_check
          ) {
            const responseMain = await http.put(`/api/councel/temp`, {
              process_idx: userData?.process.process_idx,
              temp_name: referVal.user_name,
              temp_phone: referVal.user_phone,
              temp_email: referVal.user_id,
            });

            setUserData({
              ...userData,
              user: {
                ...userData?.user,
                user_name: responseMain.data.data.temp_name,
                user_id: responseMain.data.data.temp_email,
                user_phone: responseMain.data.data.temp_phone,
              },
              process: {
                ...userData?.process,
              },
            });
          } else {
            const responseMain = await http.put(`/api/councel/temp`, {
              process_idx: userData?.process.process_idx,
              temp_name: referVal.user_name,
              temp_phone: referVal.user_phone,
              temp_email: referVal.user_id,
            });

            const response = await http.put(`/api/councel/refer`, {
              process_idx: userData?.process.process_idx,
              detail_type: referVal.detail_type,
              detail_positive: referVal.detail_positive,
              detail_needs: referVal.detail_needs,
              detail_loans: referVal.detail_loans,
              detail_price: referVal.detail_price,
              detail_size: referVal.detail_size,
              detail_use: referVal.detail_use,
              detail_appendix: referVal.detail_appendix,
              project_name: referVal.process_name,
              important_check: referVal.important_check,
            });

            setUserData({
              ...userData,
              user: {
                ...userData?.user,
                user_name: responseMain.data.data.temp_name,
                user_id: responseMain.data.data.temp_email,
                user_phone: responseMain.data.data.temp_phone,
                ...response.data.data,
              },
              process: {
                ...userData?.process,
                process_name: response.data.data.project_name,
                important_check: response.data.data.important_check,
              },
            });
          }
        } else {
          const response = await http.put(`/api/councel/refer`, {
            process_idx: userData?.process.process_idx,
            detail_type: referVal.detail_type,
            detail_positive: referVal.detail_positive,
            detail_needs: referVal.detail_needs,
            detail_loans: referVal.detail_loans,
            detail_price: referVal.detail_price,
            detail_size: referVal.detail_size,
            detail_use: referVal.detail_use,
            detail_appendix: referVal.detail_appendix,
            project_name: referVal.process_name,
            important_check: referVal.important_check,
          });

          setUserData({
            ...userData,
            user: Object.assign({ ...userData?.user }, response.data.data),
            process: {
              ...userData?.process,
              process_name: response.data.data.project_name,
              important_check: response.data.data.important_check,
            },
          });
        }

        // func.closeModal();
        closePortal(setModal);
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        if (userData?.user.user_privilege === 0) {
          // 필수 정보 변화가 없으면 추가 정보만 호출
          if (
            userData?.user.user_name === referVal.user_name &&
            userData?.user.user_phone === referVal.user_phone &&
            userData?.user.user_id === referVal.user_id
          ) {
            const response = await http.put(`/api/councel/refer`, {
              process_idx: userData?.process.process_idx,
              detail_type: referVal.detail_type,
              detail_positive: referVal.detail_positive,
              detail_needs: referVal.detail_needs,
              detail_loans: referVal.detail_loans,
              detail_price: referVal.detail_price,
              detail_size: referVal.detail_size,
              detail_use: referVal.detail_use,
              detail_appendix: referVal.detail_appendix,
              project_name: referVal.process_name,
              important_check: referVal.important_check,
            });

            setUserData({
              ...userData,
              user: {
                ...userData?.user,
                ...response.data.data,
              },
              process: {
                ...userData?.process,
                process_name: response.data.data.project_name,
                important_check: response.data.data.important_check,
              },
            });
            // 추가 정보 변화가 없으면 필수 정보만 호출
          } else if (
            userData?.user.detail_appendix === referVal.detail_appendix &&
            userData?.user.detail_loans === referVal.detail_loans &&
            userData?.user.detail_needs === referVal.detail_needs &&
            userData?.user.detail_positive === referVal.detail_positive &&
            userData?.user.detail_price === referVal.detail_price &&
            userData?.user.detail_size === referVal.detail_size &&
            userData?.user.detail_type === referVal.detail_type &&
            userData?.user.detail_use === referVal.detail_use &&
            userData?.process.process_name === referVal.process_name &&
            userData?.process.important_check === referVal.important_check
          ) {
            const responseMain = await http.put(`/api/councel/temp`, {
              process_idx: userData?.process.process_idx,
              temp_name: referVal.user_name,
              temp_phone: referVal.user_phone,
              temp_email: referVal.user_id,
            });

            setUserData({
              ...userData,
              user: {
                ...userData?.user,
                user_name: responseMain.data.data.temp_name,
                user_id: responseMain.data.data.temp_email,
                user_phone: responseMain.data.data.temp_phone,
              },
              process: {
                ...userData?.process,
              },
            });
          } else {
            const responseMain = await http.put(`/api/councel/temp`, {
              process_idx: userData?.process.process_idx,
              temp_name: referVal.user_name,
              temp_phone: referVal.user_phone,
              temp_email: referVal.user_id,
            });

            const response = await http.put(`/api/councel/refer`, {
              process_idx: userData?.process.process_idx,
              detail_type: referVal.detail_type,
              detail_positive: referVal.detail_positive,
              detail_needs: referVal.detail_needs,
              detail_loans: referVal.detail_loans,
              detail_price: referVal.detail_price,
              detail_size: referVal.detail_size,
              detail_use: referVal.detail_use,
              detail_appendix: referVal.detail_appendix,
              project_name: referVal.process_name,
              important_check: referVal.important_check,
            });

            setUserData({
              ...userData,
              user: {
                ...userData?.user,
                user_name: responseMain.data.data.temp_name,
                user_id: responseMain.data.data.temp_email,
                user_phone: responseMain.data.data.temp_phone,
                ...response.data.data,
              },
              process: {
                ...userData?.process,
                process_name: response.data.data.project_name,
                important_check: response.data.data.important_check,
              },
            });
          }
        } else {
          const response = await http.put(`/api/councel/refer`, {
            process_idx: userData?.process.process_idx,
            detail_type: referVal.detail_type,
            detail_positive: referVal.detail_positive,
            detail_needs: referVal.detail_needs,
            detail_loans: referVal.detail_loans,
            detail_price: referVal.detail_price,
            detail_size: referVal.detail_size,
            detail_use: referVal.detail_use,
            detail_appendix: referVal.detail_appendix,
            project_name: referVal.process_name,
            important_check: referVal.important_check,
          });

          setUserData({
            ...userData,
            user: Object.assign({ ...userData?.user }, response.data.data),
            process: {
              ...userData?.process,
              process_name: response.data.data.project_name,
              important_check: response.data.data.important_check,
            },
          });
        }

        closePortal(setModal);
      } catch (err) {
        console.error(err);
      }
    }
  }

  const submitAttendee = () => {
    setTempStates((prev) => {
      return {
        ...prev,
        admin_list: [...tempAttendee],
      };
    });

    closePortal(setModal2);
  };

  const submitMobileUserData = async () => {
    // 이름과 휴대폰 번호는 필수 값
    if (
      tempUserData.user.user_name === "비회원" ||
      tempUserData.user.user_phone === "등록된 정보 없음" ||
      tempUserData.user.user_name === null ||
      tempUserData.user.user_phone === null
    ) {
      alert("이름과 전화번호는 필수 항목입니다");
    } else {
    }

    // 관리자가 등록한 유저 건 / user_privilege === 0
    if (tempUserData.user.user_privilege === 0) {
      const responseMain = await http.put(`/api/councel/temp`, {
        process_idx: tempUserData.process.process_idx,
        temp_name: tempUserData.user.user_name,
        temp_phone: tempUserData.user.user_phone,
        temp_email: tempUserData.user.user_id,
      });

      const response = await http.put(`/api/councel/refer`, {
        process_idx: tempUserData.process.process_idx,
        detail_type: tempUserData.user.detail_type,
        detail_positive: tempUserData.user.detail_positive,
        detail_needs: tempUserData.user.detail_needs,
        detail_loans: tempUserData.user.detail_loans,
        detail_price: tempUserData.user.detail_price,
        detail_size: tempUserData.user.detail_size,
        detail_use: tempUserData.user.detail_use,
        detail_appendix: tempUserData.user.detail_appendix,
        project_name: tempUserData.process.process_name,
        important_check: tempUserData.user.important_check,
      });

      setResultData({
        ...resultData,
        user: {
          ...resultData.user,
          user_name: responseMain.data.data.temp_name,
          user_id: responseMain.data.data.temp_email,
          user_phone: responseMain.data.data.temp_phone,
          ...response.data.data,
        },
        process: {
          ...resultData.process,
          process_name: response.data.data.project_name,
          important_check: response.data.data.important_check,
        },
      });
    }

    if (tempUserData.user.user_privilege !== 0) {
      const response = await http.put(`/api/councel/refer`, {
        process_idx: tempUserData.process.process_idx,
        detail_type: tempUserData.user.detail_type,
        detail_positive: tempUserData.user.detail_positive,
        detail_needs: tempUserData.user.detail_needs,
        detail_loans: tempUserData.user.detail_loans,
        detail_price: tempUserData.user.detail_price,
        detail_size: tempUserData.user.detail_size,
        detail_use: tempUserData.user.detail_use,
        detail_appendix: tempUserData.user.detail_appendix,
        project_name: tempUserData.process.process_name,
        important_check: tempUserData.user.important_check,
      });

      setUserData({
        ...resultData,
        user: {
          ...resultData.user,
          ...response.data.data,
        },
        process: {
          ...resultData.process,
          process_name: response.data.data.project_name,
          important_check: response.data.data.important_check,
        },
      });
    }

    closePortal(setModal);
  };

  const submitInfo = () => {
    if (modal.value === "councel-filtering-web") {
      submitFilterParam();
    }
    if (modal.value === "councel-fix-userinfo-web") {
      submitUserData();
    }
    if (modal2.value === "calendar-search-attendee-web") {
      submitAttendee();
    }

    if (modal.value === "councel-filter-mobile") {
      submitMobileFilterParam();
    }

    if (modal.value === "councel-userinfo-mobile") {
      submitMobileUserData();
    }
  };

  const currentDevice = useRecoilValue(device);

  return (
    <section
      className={`${
        currentDevice === "mobile" ? "fixed" : "sticky"
      } w-[calc(100%)] bottom-0 flex flex-row plr-16 pt-[12px] pb-[16px] gap-[8px] items-center justify-between border-t border-solid -border--color-neutral6 -bg--color-neutral0`}
    >
      <button
        type="reset"
        className="flex flex-row gap-[2px] items-center justify-center border border-solid rounded-[4px] -border--color-neutral12 py-[10px] pl-[16.3px] pr-[22.3px]"
        onClick={() => {
          resetValue();
        }}
      >
        <span className="_sprite_ _sprite_7_12_"></span>
        <span className="-text--body-16b font-700 -text--color-neutral50">
          초기화
        </span>
      </button>
      <button
        className="py-[10px] flex-1 -text--color-neutral0 -bg--color-primary-main rounded-[6px] -text--body-16b"
        onClick={() => submitInfo()}
      >
        <span>적용</span>
      </button>
    </section>
  );
};

export default ModalFooterResetType;
