// 삭제 예정

import { atom } from "recoil";

// 댓글
// 삭제, 수정 시 댓글 index 값
export const selectedCommentIndex = atom({
  key: "selectedCommentIndex",
  default: "",
});
// 댓글 작성 중인 내용
export const commentContent = atom({
  key: "commentContent",
  default: "",
});
// 댓글 수정시 작성 중인 내용
export const commentFixContent = atom({
  key: "commentFixContent",
  default: "",
});

// 상담노트 리스트 중
// 선택한 상담 노트 상세 정보
export const selectedDetailCouncelNote = atom<any>({
  key: "selectedDetailCouncelNote",
  default: "",
});
export const selectedDetailCouncelNoteIndex = atom<number>({
  key: "selectedDetailCouncelNoteIndex",
  default: undefined,
});
// 상담노트 리스트 중 선택 한 상담노트 -> 모바일 버전에서 수정 시 사용함
export const selectedNote = atom({
  key: "selectedNote",
  default: {
    user: "",
    process: "",
  },
});
// read, create, fix, null, disabled
// null : 데이터 없음 / disabled : 상담 취소/완료
export const editorState = atom({
  key: "editorState",
  default: "read",
});

export const pickedCouncelNoteDate = atom({
  key: "pickedCouncelNoteDate",
  default: "",
});

export const tempPickedCouncelNoteDate = atom({
  key: "tempPickedCouncelNoteDate",
  default: "",
});

// 건축상담 문의 상세페이지 url param값 (상세 건축 상담 문의 구분하는 상태 값)
export const urlParameterCouncel = atom({
  key: "urlParameterCouncel",
  default: "",
});
export const councelNoteAddressIdx = atom({
  key: "councelNoteAddressIdx",
  default: "",
});

// 상담 정보 수정 시 이름, 연락처 누락 경고 감지용
export const requiredInfo = atom({
  key: "requiredInfo",
  default: undefined,
});
