import React from "react";

import pref_icon from "@/Assets/Images/pref_icon.svg";

const PrefType = (props) => {
  const { data, isLoading, isError } = props;

  return (
    <div className="h-[622px] w-[360px] -bg--color-neutral0 rounded-[12px] border border-solid -border--color-neutral6">
      <div className="plr-16 pt-[16px] text-H18B">선호 타입</div>
      <div className="plr-16 ptb-16 flex flex-col items-center justify-start gap-[16px]">
        {data &&
          data.map((idx, num) => (
            <div
              className="relative border-none overflow-hidden rounded-[12px]"
              key={num}
            >
              <img className="w-[360px] h-[173px]" src={idx.imgs_url} alt="" />
              <div className="absolute z-10 top-0 right-[29px] flex items-center justify-center">
                <img src={pref_icon} alt="" />
                <div className="absolute text-H21B -text--color-neutral0">
                  {idx.idx}
                </div>
              </div>
              <p className="absolute -text--color-neutral0 bottom-[8px] z-20 flex flex-col left-[8px]">
                <span className="text-B12R pb-[2px]">{idx.house_name}</span>
                <span className="text-H18B">{idx.house_area}평형</span>
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PrefType;
