import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "persistAtom",
  storage: localStorage,
});

// 좌측 네비게이션 바 선택 값을 세션스토리지에 저장 (PC) -> pageState로 변경 예정
export const navValue = atom({
  key: "navValue",
  default: 3,
  effects_UNSTABLE: [persistAtom],
});

type PageStateType = string;

// 우측 네비게이션 바 선택 값 (모바일)
export const pageState = atom<PageStateType>({
  key: "pageState",
  default: "dashboard",
  effects_UNSTABLE: [persistAtom],
});

type PageStateInCouncelType = number;

// 모바일 버전 상담노트 페이지 내 상세 페이지
export const pageStateInCouncelPage = atom<PageStateInCouncelType>({
  key: "pageStateInCouncelPage",
  default: 1,
});
