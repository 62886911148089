import React from "react";

interface propsType {
  data: any;
  num: number;
  callback: (arg: any) => void;
}

const PrevCouncelNote = ({ data, num, callback }: propsType) => {
  return (
    <button
      onTouchStart={(e) => {
        e.stopPropagation();
      }}
      onClick={() => {
        if (num < data.councel.length - 1) {
          callback(num + 1);
        }
      }}
      className="fixed left-0 translate-y-1/2 bottom-1/2 w-[36px] h-[190px] flex items-center justify-center -bg--color-neutral50 rounded-tr-[999px] rounded-br-[999px] opacity-50 z-20"
    >
      <span className="_sprite_ _sprite_17_3_"></span>
    </button>
  );
};

export default PrevCouncelNote;
