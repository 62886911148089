import React, { useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";

import { modalInfo, passwordValue } from "@/stores/common/modal";

const EntityPasswordWeb = () => {
  const [password, setPassword] = useRecoilState(passwordValue);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passPassword, setPassPassword] = useState(null);
  const [passConfirmPassword, setPassConfirmPassword] = useState(null);

  function handlePassword(e) {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=*])[A-Za-z\d@#$%^&+=*]{8,}$/;

    if (e.target.value.length > 0 && regex.test(e.target.value)) {
      setPassPassword(true);
    } else if (e.target.value.length > 0 && !regex.test(e.target.value)) {
      setPassPassword(false);
    } else if (e.target.value.length === 0) {
      setPassPassword(null);
    }

    setPassword((prev) => {
      return {
        ...prev,
        value: e.target.value,
      };
    });
  }

  function handleConfirmPassword(e) {
    if (e.target.value.length > 0 && e.target.value === password.value) {
      setPassConfirmPassword(true);
    } else if (e.target.value.length > 0 && e.target.value !== password.value) {
      setPassConfirmPassword(false);
    } else if (e.target.value.length === 0) {
      setPassConfirmPassword(null);
    }

    setPassword((prev) => {
      return {
        ...prev,
        confirmValue: e.target.value,
      };
    });
  }

  return (
    <div className="py-[16px] px-[24px] flex flex-col border-solid border-b -border--color-neutral6">
      <span className="text-B16B pb-[16px]">비밀번호 변경</span>
      <div>
        <div className="flex flex-col">
          <span className="text-B14M pb-[10px]">새 비밀번호</span>
          <div className="relative">
            <input
              className={`w-[343px] border border-solid rounded-[6px] ptb-12 pl-[16px] text-B16R mb-[8px] focus:outline-none 
              ${
                passPassword === false
                  ? "-border--color-danger-main"
                  : "-border--color-neutral50"
              }`}
              type={showPassword ? "text" : "password"}
              placeholder="새 비밀번호 입력"
              value={password.value}
              onChange={(e) => handlePassword(e)}
            />
            <button
              className="absolute right-[12px] top-[12px]"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <span
                className={`_sprite_ ${
                  showPassword ? "_sprite_15_5_" : "_sprite_15_6_"
                }`}
              ></span>
            </button>
          </div>
          <div className="flex flex-row gap-[4px] items-center pb-[16px] text-B12R">
            <span
              className={`_sprite_ 
            ${passPassword === null && "_sprite_1_1_"}
            ${passPassword === true && "_sprite_1_3_"}
            ${passPassword === false && "_sprite_1_4_"}
            `}
            ></span>
            <span
              className={`
              ${passPassword === null && "-text--color-neutral50"}
              ${passPassword === true && "-text--color-success-main"}
              ${passPassword === false && "-text--color-danger-main"}
            `}
            >
              8자리 이상의 영문+숫자+특수문자 조합
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-B14M pb-[10px]">새 비밀번호 확인</span>
          <div className="relative">
            <input
              className={`w-[343px] border border-solid rounded-[6px] ptb-12 pl-[16px] text-B16R mb-[8px] focus:outline-none 
              ${
                passConfirmPassword === false
                  ? "-border--color-danger-main"
                  : "-border--color-neutral50"
              }`}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="새 비밀번호 확인"
              value={password.confirmValue}
              onChange={(e) => handleConfirmPassword(e)}
            />
            <button
              className="absolute right-[12px] top-[12px]"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              <span
                className={`_sprite_ ${
                  showConfirmPassword ? "_sprite_15_5_" : "_sprite_15_6_"
                }`}
              ></span>
            </button>
          </div>
          <div className="flex flex-row gap-[4px] items-center text-B12R">
            <span
              className={`_sprite_ 
            ${passConfirmPassword === null && "_sprite_1_1_"}
            ${passConfirmPassword === true && "_sprite_1_3_"}
            ${passConfirmPassword === false && "_sprite_1_4_"}
            `}
            ></span>
            <span
              className={`
              ${passConfirmPassword === null && "-text--color-neutral50"}
              ${passConfirmPassword === true && "-text--color-success-main"}
              ${passConfirmPassword === false && "-text--color-danger-main"}
            `}
            >
              비밀번호 일치
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityPasswordWeb;
