import { useRecoilState } from "recoil";
import { showPortal } from "@/utils/func/common/modalController";
import React from "react";
import { modalInfo } from "@/stores/common/modal";

// 사용자 정보 수정 버튼
const FixUserInfo = () => {
  const [, setModal] = useRecoilState(modalInfo);

  return (
    <button
      className="pt-[14px] pb-[14px] justify-center items-center flex flex-row gap-[4px] border border-solid -border--color-neutral12 -bg--color-neutral0 -text--color-neutral50 focus:-border--color-primary-surface focus:-bg--color-primary-surface focus:-text--color-primary-main rounded-[4px]"
      onClick={() => {
        showPortal(setModal, true, "councel-userinfo-mobile");
      }}
    >
      <span className="-text--body-16b font-700">상담 정보 수정</span>
      <span className="_sprite_ _sprite_8_1_"></span>
    </button>
  );
};

export default FixUserInfo;
