import { childrenType } from "@/types/common";
import React from "react";

const LoginLayoutMobile = ({ children }: childrenType) => {
  return (
    <div className="w-full h-screen flex items-center justify-center -bg--color-neutral0 plr-16">
      {children}
    </div>
  );
};

export default LoginLayoutMobile;
