import React, { useState } from "react";

import { useRecoilValue } from "recoil";

import pdf_icon from "@/Assets/Images/pdf_icon.svg";

import { postInquiry } from "@/utils/api/pages/inquiry";
import { handleTimeToKr } from "@/utils/func/common/convertTime";

import { inquiryParameter } from "@/stores/common/parameter";

import { useQueryClient, useMutation } from "@tanstack/react-query";

const InquiryBody = (props) => {
  const { data, isError, isLoading, refetch } = props;

  const [isOpen, setIsOpen] = useState("");

  const [word, setWord] = useState("");

  const showContent = (e) => {
    setIsOpen(() => (isOpen !== e ? e : ""));
  };

  const { mutate } = useMutation({
    mutationKey: ["postInquiryData"],
    mutationFn: () => postInquiry({ idx: isOpen, contents: word }),
    onSuccess: (data) => {
      refetch();
    },
    onError: (error) => {
      console.error("error", error);
    },
  });

  return (
    <React.Fragment>
      <div className="absolute w-[1128px] flex flex-col items-center text-B12R -text--color-neutral50 top-[147px] min-h-[calc(100%-24px-50px-24px-48px-1px)] -bg--color-neutral0 pb-[81px] z-[2] border-l border-r border-solid -border--color-neutral6">
        {/* 상담 내역 존재 */}
        {data?.data?.data.question &&
          data?.data?.data.question.length !== 0 && (
            <React.Fragment>
              {data?.data?.data.question.map((idx, num) => (
                <React.Fragment key={num}>
                  <div
                    className="w-[1128px] max-h-[380px] flex flex-row items-center justify-between text-B16R -text--color-neutral50 border-solid cursor-pointer break-words hover:-bg--color-neutral3 px-[16px]"
                    onClick={() => showContent(idx.question_idx)}
                  >
                    <div className="h-[60px] flex flex-row items-center">
                      <div className="w-[126px] h-[48px] pl-[8px] flex items-center -text--color-neutral50 text-B16B">
                        <li>{String(idx.idx).padStart(6, "0")}</li>
                      </div>
                      <div className="w-[298px] h-[48px] flex items-center overflow-hidden text-ellipsis whitespace-nowrap -text--color-neutral100 text-B16M">
                        <li className="block overflow-hidden text-ellipsis whitespace-nowrap">
                          {idx.question_title}
                        </li>
                      </div>
                      <div className="w-[158px] h-[48px] pl-[8px] flex items-center justify-center text-B16R">
                        <div className="">
                          <li className="flex text-B16R -text--color-neutral50">
                            {idx.user_name}
                          </li>
                        </div>
                      </div>
                      <div className="w-[158px] h-[48px] pl-[8px] flex items-center justify-center text-B16R">
                        <li className="flex text-B16R -text--color-neutral50">
                          {idx.user_phone === null ? "" : idx.user_phone}
                        </li>
                      </div>
                      <div className="w-[158px] h-[48px] pl-[8px] flex items-center justify-center text-B14B">
                        <div
                          className={
                            idx.question_type
                              ? "px-[12px] py-[6px] flex items-center justify-center rounded-[30px]  -text--color-neutral100 -bg--color-neutral6 text-B14R"
                              : "px-[12px] py-[6px] flex items-center justify-center rounded-[30px]  -text--color-primary-main -bg--color-primary-main bg-opacity-10 text-B14R"
                          }
                        >
                          <li className="flex text-B14B">
                            {idx.question_type ? "답변완료" : "답변대기"}
                          </li>
                        </div>
                      </div>
                      <div className="w-[158px] h-[48px] pl-[8px] flex items-center text-B14R">
                        <li>{handleTimeToKr(idx.question_datetime)}</li>
                      </div>
                    </div>
                    <div className="w-[40px] h-[44px] flex items-center justify-center">
                      {isOpen === idx.question_idx ? (
                        <span className="_sprite_ _sprite_3_2_"></span>
                      ) : (
                        <span className="_sprite_ _sprite_3_1_"></span>
                      )}
                    </div>
                  </div>
                  {isOpen === idx.question_idx && (
                    // 기본 리스트 보여주는 탭
                    <React.Fragment>
                      <div className="w-[1128px] flex flex-col overflow-y-auto overflow-x-hidden border-solid border-t border-l border-r -border--color-neutral6">
                        <div className="custom-scrollbar w-[1128px] flex flex-col max-h-[380px] p-[24px] gap-[8px] overflow-y-auto overflow-x-hidden -bg--color-neutral0 border-solid border-b -border--color-neutral6">
                          <span className="text-B16B">내용</span>
                          <div className="max-h-[380px] scrollbar-[8px] -scrollbar-thumb--color-danger-main -scrollbar-track--color-neutral3">
                            <div
                              className="whitespace-normal break-words text-B16R -text--color-neutral100 "
                              dangerouslySetInnerHTML={{
                                __html: idx.question_board.replace(
                                  /\n/g,
                                  "<br>"
                                ),
                              }}
                            />
                            {/* 첨부파일 유무 */}
                            {idx.file_list.length !== 0 && (
                              <div className="w-[1064px] flex flex-col ptb-24 gap-[8px]">
                                <p className="mr-[8px] text-B16B -text--color-neutral100 ">
                                  첨부파일
                                </p>
                                <div className="flex flex-row items-center justify-start gap-[15px]">
                                  {idx.file_list.map((idx, num) => {
                                    const fileExtension =
                                      idx.match(/\.([^.]+)$/)?.[1]; // 확장자 추출

                                    if (
                                      fileExtension === "jpg" ||
                                      fileExtension === "jpeg" ||
                                      fileExtension === "png" ||
                                      fileExtension === "gif" ||
                                      fileExtension === "heic" ||
                                      fileExtension === "tiff"
                                    ) {
                                      return (
                                        <div
                                          key={num}
                                          className="w-[120px] h-[120px] flex items-center justify-center"
                                        >
                                          <a
                                            className="w-[120px] h-[120px]"
                                            href={idx}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              className="w-[120px] h-[120px]"
                                              src={idx}
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          key={num}
                                          className="w-[120px] h-[120px] flex items-center justify-center rounded-[6px] -bg--color-neutral6"
                                        >
                                          <a
                                            className="w-[120px] h-[120px] flex justify-center items-center relative"
                                            href={idx}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              className="w-[80px] h-[60px]"
                                              src={pdf_icon}
                                              alt=""
                                            />
                                            <div className="absolute w-[48px] h-[26px] -bg--color-neutral6 bottom-[20px] right-[8px] rounded-[30px] flex items-center justify-center">
                                              <span className="-text--color-neutral100 text-H18B">
                                                {fileExtension}
                                              </span>
                                            </div>
                                          </a>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="custom-scrollbar overflow-y-auto w-[1128px] max-h-[380px] flex flex-col -bg--color-neutral0 overflow-hidden">
                          {/* 답변 존재 글 */}
                          {idx.admin_board && (
                            <div className="p-[24px] flex flex-col gap-[8px] border-b border-solid -border--color-neutral6">
                              <div className="flex flex-row justify-between items-center">
                                <span className="text-B16B">답변</span>
                                <div className="flex flex-row gap-[8px] -text--color-neutral50 items-center">
                                  <span className="text-B16R">
                                    {idx.user_name}
                                  </span>
                                  <span className="text-B14R">
                                    {handleTimeToKr(idx.admin_datetime)}
                                  </span>
                                </div>
                              </div>
                              <pre>
                                <div
                                  className="text-B16R -text--color-neutral100 whitespace-normal break-words"
                                  dangerouslySetInnerHTML={{
                                    __html: idx.admin_board.replace(
                                      /\n/g,
                                      "<br>"
                                    ),
                                  }}
                                  style={{
                                    fontFamily: "Pretendard",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                  }}
                                />
                              </pre>
                            </div>
                          )}
                          {/* 답변 미존재 글 */}
                          {!idx.admin_board && (
                            <form className="h-[380px] py-[24px] flex flex-col gap-[8px]">
                              <div className="flex flex-row justify-between items-center px-[24px]">
                                <span className="text-B16B">답변</span>
                                <span className="-text--color-neutral50 text-B14R-text--color-neutral50 items-center">
                                  {word.length} / 5000 byte
                                </span>
                              </div>
                              <textarea
                                className="custom-scrollbar resize-none focus:outline-none h-[288px] w-full break-words text-B16R -text--color-neutral100 -placeholder--color-neutral20 px-[24px]"
                                placeholder="답변을 작성해 주세요. (최소 10자 이상)"
                                autoComplete="off"
                                onChange={(e) => setWord(e.target.value)}
                              ></textarea>
                              <div className="flex flex-row items-center justify-end px-[24px]">
                                <button
                                  disabled={word.length < 10}
                                  className={`text-B16B py-[8px] -text--color-primary-main ${
                                    word?.length < 10 && "opacity-40"
                                  }`}
                                  type="button"
                                  onClick={mutate}
                                >
                                  답변 등록
                                </button>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        {/* 보여줄 상담 없을 때 */}
        {data?.data?.data.question &&
          data?.data?.data.question.length === 0 && (
            <React.Fragment>
              <div className="w-[1128px] flex flex-col items-center text-B12R -text--color-neutral50 top-[120px] min-h-[calc(100%-16px-44px-16px-48px)] -bg--color-neutral0 pb-[64px] z-[2] border-x border-solid -border--color-neutral6">
                <div className="w-[1128px] h-[832px] flex flex-col justify-center items-center gap-[4px]">
                  <div className="w-[223px] flex flex-col items-center justify-center">
                    <span className="_sprite_ _sprite_7_13_"></span>
                    <p className="text-H21B -text--color-neutral100 mb-[4px] pt-[14px]">
                      일치하는 결과가 없습니다
                    </p>
                    <p className="text-B14R">검색 범위를 넓혀 보세요</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
      </div>
    </React.Fragment>
  );
};

export default InquiryBody;
