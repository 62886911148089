import { atom } from "recoil";

interface ModalType {
  isOpen: boolean;
  value: string;
}

// portal 태그 기능 제어
export const modalInfo = atom<ModalType>({
  key: "modalInfo",
  default: {
    isOpen: false,
    value: "",
  },
});

// portal2 태그 기능 제어
export const modalInModalInfo = atom<ModalType>({
  key: "modalInModalInfo",
  default: {
    isOpen: false,
    value: "",
  },
});

// 비밀번호 변경 시 사용 변수
export const passwordValue = atom({
  key: "passwordValue",
  default: {
    value: "",
    confirmValue: "",
  },
});

// 고객 정보 수정 시 임시 저장 변수
export const tempReferVal = atom({
  key: "tempReferVal",
  default: "",
});

// 담당자 변경 시 담당자 리스트 담는 변수
export const managerList = atom({
  key: "managerList",
  default: [],
});

export const choicedManager = atom({
  key: "choicedManager",
  default: {
    managerName: "선택 없음",
    managerIndex: "",
  },
});

export const copiedManagerList = atom({
  key: "copiedManagerList",
  default: [],
});

////////////////////// 담당자 변경 실패 시 에러 border처리를 위한 목적으로 만든 state
export const errState = atom({
  key: "errState",
  default: false,
});

////////////////////// 담당자 변경 시 input value값도 에러 처리에 활용해야 해서 만든 state
export const inputValueManager = atom({
  key: "inputValueManager",
  default: "",
});

export const selectedFileList = atom({
  key: "selectedFileList",
  default: [],
});
