import React from "react";

const PdfBodyInfo = React.forwardRef(({ props }: any, ref: any) => {
  return (
    <div
      className="flex flex-col pr-[24px] pl-[24px] pb-[24px]"
      ref={props?.user?.wfs.length > 0 ? ref : null}
    >
      <div className="flex flex-col pb-[16px] gap-[16px]">
        <div className="flex flex-row gap-[4px] w-[292px] trigger">
          <div className="w-[80px] text-B14M font-700">상담자 정보</div>
          <div className="flex flex-col pt-[8px] gap-[8px] text-B10R">
            <span className="font-700">{props?.user?.user_name}</span>
            <span>{props?.user?.user_id}</span>
            <span>{props?.user?.user_phone}</span>
          </div>
        </div>
        <div className="flex flex-row gap-[4px] w-[292px] trigger">
          <div className="w-[80px] text-B14M font-700">담당자 정보</div>
          <div className="flex flex-row pt-[8px] gap-[8px] text-B10R">
            <span className="font-700">
              {props?.process?.process_type === 1
                ? "상담"
                : props?.process?.process_type === 2
                  ? "계약"
                  : props?.process?.process_type === 3
                    ? "공사"
                    : "-"}
            </span>
            <span>
              {props?.process?.admin_name
                ? props?.process.admin_name
                : "미배정"}
            </span>
          </div>
        </div>
        <div className="flex flex-row gap-[4px] w-[292px] trigger">
          <div className="w-[80px] text-B14M font-700">필지 정보</div>
          <div className="w-[205px]">
            {props?.user?.wfs.map((idx: any, num: any) => {
              return (
                <React.Fragment key={num}>
                  <div className="flex flex-col pt-[8px] gap-[8px] text-B10R">
                    <span className="font-700">{idx.wfs_jibun}</span>
                    <span>{idx.wfs_area_name}</span>
                    <p>
                      <span className="font-700 mr-[6px]">대지면적</span>
                      <span>
                        {idx.wfs_lndpcl}m<sup>2</sup>
                      </span>
                    </p>
                  </div>
                  {props?.user?.wfs.length > 1 ? (
                    <div className="border-b border-solid pb-[4px] mt-[4px] mb-[4px] -border--color-neutral12"></div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="absolute right-[24px] h-[143px] w-[216px]">
        <img
          src={props?.process?.address_img}
          alt="이미지 없음"
          className="w-full h-full object-cover rounded-[12px]"
          // onError={(event) => (event.target.style.display = "none")}
        />
      </div>
      <div className="flex flex-row gap-[4px] trigger">
        <div className="w-[80px] text-B14R font-700">추가 정보</div>
        <div className="flex flex-col pt-[8px] text-B10R gap-[6px]">
          <div className="flex flex-row gap-[6px]">
            <p className="flex flex-col gap-[8px]">
              <span className="font-700">문의유형</span>
              <span className="font-700">고객 적극도</span>
              <span className="font-700">예산액 (원)</span>
              <span className="font-700">희망 연면적</span>
              <span className="font-700">건축규모</span>
              <span className="font-700">대출 연계</span>
              <span className="font-700">건축 용도</span>
              <span className="font-700">추가 메시지</span>
            </p>
            <p className="flex flex-col gap-[8px]">
              <span>
                {props?.user?.detail_type ? props?.user.detail_type : "-"}
              </span>
              <span>
                {props?.user?.detail_positive === 1
                  ? "상"
                  : props?.user?.detail_positive === 2
                    ? "중"
                    : props?.user?.detail_positive === 3
                      ? "하"
                      : "-"}
              </span>
              <span>
                {props?.user?.detail_price !== null
                  ? `${props?.user?.detail_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  : "-"}
              </span>
              <span>
                {props?.user?.detail_needs ? props?.user.detail_needs : "-"}평
              </span>
              <span>
                {props?.user?.detail_size === null
                  ? "-"
                  : props?.user?.detail_size}
              </span>
              <span>
                {props?.user?.detail_loans === true
                  ? "필요"
                  : props?.user?.detail_loans === false
                    ? "불필요"
                    : "-"}
              </span>
              <span>{props?.user?.detail_use}</span>
            </p>
          </div>
          <div className="flex flex-row gap-[4px]">
            <p className="pt-[12px] w-[447px] break-words whitespace-pre-wrap text-B10R">
              {props?.user?.detail_appendix !== null
                ? props?.user?.detail_appendix
                : "-"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PdfBodyInfo;
