import React, { useState } from "react";
import moment from "moment";

import { useRecoilState } from "recoil";
import { selectDate, tempSelectDate } from "@/stores/common/Mobile";

const CouncelCouncelnoteCalendarMobile = () => {
  const [currentDate, setCurrentDate] = useState(moment().format());

  const [tempPickedDate, setTempPickedDate] = useRecoilState(tempSelectDate);

  function nextMonth() {
    setCurrentDate(moment(currentDate).add(1, "month").format());
  }

  function prevMonth() {
    setCurrentDate(moment(currentDate).subtract(1, "month").format());
  }

  function handleDateClick(currentDate) {
    const selectedDay = currentDate?.format();

    const day = moment(currentDate).format("DD");

    if (day === null) {
      alert("날짜를 제대로 선택하세요.");
    } else if (
      moment(selectedDay).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")
    ) {
      setTempPickedDate(selectedDay);
    } else {
      alert("금일 이후의 날은 선택하실 수 없습니다.");
    }
  }

  function renderMonth(date) {
    const year = moment(date).year();

    const monthName = moment(date).format("MM");

    const firstDay = moment(date).startOf("month").day();

    const daysInMonth = moment(date).endOf("month").date();

    const days = [];

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    const blanks = Array(firstDay).fill(null);
    const allDays = [...blanks, ...days];
    const rows = [];

    while (allDays.length) {
      rows.push(allDays.splice(0, 7));
    }

    return (
      <table className="w-full">
        <thead>
          <tr style={{ height: "44px" }}>
            <th>
              <button
                onClick={prevMonth}
                className="w-[44px] h-[44px] flex items-center justify-center"
              >
                <span className="_sprite_ _sprite_17_3_"></span>
              </button>
            </th>
            <th colSpan="5">
              <h2>
                {year}.{monthName}
              </h2>
            </th>
            <th>
              <button
                onClick={nextMonth}
                className="w-[44px] h-[44px] flex items-center justify-center"
              >
                <span className="_sprite_ _sprite_17_4_"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ height: "44px" }}>
            <td>일</td>
            <td>월</td>
            <td>화</td>
            <td>수</td>
            <td>목</td>
            <td>금</td>
            <td>토</td>
          </tr>
          {rows.map((row, index) => (
            <tr key={index} style={{ height: "44px" }}>
              {row.map((day, index) => {
                const currentDate = moment(date).date(day);

                const isStartDate =
                  moment(currentDate).format("YYYY-MM-DD") ===
                  moment(tempPickedDate)?.format("YYYY-MM-DD");

                return (
                  <td
                    key={index}
                    className={
                      isStartDate &&
                      "-bg--color-primary-main rounded-[25px] -text--color-neutral0"
                    }
                    onClick={() => {
                      if (day !== null) {
                        handleDateClick(currentDate);
                      }
                    }}
                  >
                    {day}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  return (
    <div className="plr-16 ptb-24 flex flex-col gap-[24px]">
      {renderMonth(currentDate)}
    </div>
  );
};

export default CouncelCouncelnoteCalendarMobile;
