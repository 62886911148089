import React from "react";

const Alert = () => {
  return (
    <section className="flex flex-col px-[32px] py-[24px] items-center justify-center">
      <span className="text-H21B mb-[8px] -text--color-neutral100 ">
        상담일지를 작성 중이에요.
      </span>
      <span className="text-B14R -text--color-neutral50">
        작업하신 상담일지를 취소나 저장하고 이동해주세요.
      </span>
    </section>
  );
};

export default Alert;
