import React, { useEffect, useState } from "react";

import moment from "moment";
import { useRecoilState } from "recoil";

import { tempPickedCouncelNoteDate } from "@/stores/page/councelDetail";

const CouncelNoteDetailCalendarWeb = () => {
  const [currentDate, setCurrentDate] = useState(moment());

  // 이전 달
  const handlePrevMonth = () => {
    setCurrentDate(currentDate.clone().subtract(1, "month"));
  };

  // 다음 달
  const handleNextMonth = () => {
    setCurrentDate(currentDate.clone().add(1, "month"));
  };

  const [selectedDate, setSelectedDate] = useRecoilState(
    tempPickedCouncelNoteDate
  );

  const handleDayClick = (current) => {
    if (
      moment().add(1, "days").format("YYYY-MM-DD") >
      current.format("YYYY-MM-DD")
    ) {
      setSelectedDate(current);
    } else {
      return;
      // alert("금일 이후의 날은 선택하실 수 없습니다.");
    }
  };

  useEffect(() => {
    setSelectedDate(moment());
  }, []);

  // 달력 생성
  const renderCalendar = () => {
    // 해당 월의 첫째 주가 1년 중 몇 번째 주인지
    const startWeek = currentDate.clone().startOf("month").week();

    // 해당 월의 마지막 주가 1년 중 몇 번째 주인지
    const endWeek =
      currentDate.clone().endOf("month").week() === 1
        ? 53
        : currentDate.clone().endOf("month").week();

    let calendar = [];

    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div className="grid grid-cols-7 mb-[2px]" key={week}>
          {Array(7)
            .fill(0)
            .map((v, i) => {
              // 배열 생성 후 i 값을 더해서 날짜 표현
              let current = currentDate
                .clone()
                .week(week)
                .startOf("week")
                .add(v + i, "day");

              // 해당 월이 아닌 다른 월의 경우 회색으로 표현
              // let notThisMonth =
              //   current.format("MM") !== currentDate.format("MM") &&
              //   "opacity-40";

              // 해당 월이 아니고, 금일 이후의 날인 경우
              let notThisMonth =
                (current.format("MM") !== currentDate.format("MM") &&
                  "opacity-40") ||
                (current.format("YYYYMMDD") > moment().format("YYYYMMDD") &&
                  "opacity-40");

              const isWeekend = current.day() === 0 || current.day() === 6;

              // 추가된 부분: 선택된 날짜와의 비교를 통해 어떤 스타일을 적용할지 결정
              let roundedStyle = "";

              if (
                moment(selectedDate).format("YYYYMMDD") ===
                current.format("YYYYMMDD")
              ) {
                roundedStyle = true;
              }

              return (
                <div
                  className={`${notThisMonth} ${
                    roundedStyle &&
                    "border -bg--color-primary-surface rounded-full -text--color-primary-main"
                  } ${
                    isWeekend && "-text--color-danger-main"
                  } text-center pt-[11px] pb-[11px] w-[46px]`}
                  key={i}
                  onClick={() => handleDayClick(current)}
                >
                  <span className="text">{current.format("D")}</span>
                </div>
              );
            })}
        </div>
      );
    }
    return calendar;
  };

  return (
    <div className="plr-16 ptb-24 flex flex-col items-center">
      <div className="w-full m-auto">
        <div className="flex justify-between items-center mb-[24px]">
          <button className="p-[10px]" onClick={handlePrevMonth}>
            <span className="_sprite_ _sprite_17_3_"></span>
          </button>
          <span className="p-[10px] text-B16B">
            {currentDate.format("YYYY. MMMM ")}
          </span>
          <button className="p-[10px]" onClick={handleNextMonth}>
            <span className="_sprite_ _sprite_17_4_"></span>
          </button>
        </div>
        <div className="grid grid-cols-7 mb-[8px]">
          <div className="text-center pt-[11px] pb-[11px] -text--color-danger-main">
            일
          </div>
          <div className="text-center pt-[11px] pb-[11px]">월</div>
          <div className="text-center pt-[11px] pb-[11px]">화</div>
          <div className="text-center pt-[11px] pb-[11px]">수</div>
          <div className="text-center pt-[11px] pb-[11px]">목</div>
          <div className="text-center pt-[11px] pb-[11px]">금</div>
          <div className="text-center pt-[11px] pb-[11px] -text--color-danger-main">
            토
          </div>
        </div>
        {renderCalendar()}
      </div>
    </div>
  );
};

export default CouncelNoteDetailCalendarWeb;
