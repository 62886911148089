import React from "react";

import { useRecoilState } from "recoil";

import { modalInfo } from "@/stores/common/modal";
import { councelParameter } from "@/stores/common/parameter";

import { showPortal } from "@/utils/func/common/modalController";

// 상담 노트 페이지 내 필터 버튼
const FilterButton = () => {
  const [, setModal] = useRecoilState(modalInfo);

  const [parameter, setParameter] = useRecoilState(councelParameter);

  const areObjectsEqual = (objA: object, objB: object) => {
    const keyA = Object.keys(objA);

    const valueA = Object.values(objA);
    const valueB = Object.values(objB);

    for (let i = 0; i < valueA.length; i++) {
      if (keyA[i] === "location") {
        if (typeof valueA[i] !== typeof valueB[i]) {
          return false;
        }
      } else {
        if (valueA[i] !== valueB[i]) {
          return false;
        }
      }
    }

    return true;
  };

  const prototypeParameter = {
    location: [""],
    detail_type: "전체",
    councel_type: 0,
    start_datetime: undefined,
    end_datetime: undefined,
    mycheck: false,
    search: "",
    pageno: 1,
    process_type: null,
    important_check: false,
  };

  return (
    <button
      className={`border border-solid py-[8px] px-[17.5px] rounded-[4px] -text--color-neutral50 text-B14B ${
        areObjectsEqual(parameter, prototypeParameter) === false
          ? "-border--color-primary-border -text--color-primary-main"
          : ""
      }`}
      onClick={() => {
        showPortal(setModal, true, "councel-filter-mobile");
      }}
    >
      필터
    </button>
  );
};

export default FilterButton;
