import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import MobileTypeTwo from "@/components/cards/MobileTypeTwo";
import MobileTypeOne from "@/components/cards/MobileTypeOne";

import { useRecoilState, useResetRecoilState } from "recoil";

import { pageState } from "@/stores/common/navigation";
import { councelParameter } from "@/stores/common/parameter";
import { responseData } from "@/stores/common/data";

import { getNotAssignedData } from "@/utils/api/pages/dashboard";
import { pageHandler } from "@/utils/func/common/navigation";

import "@/styles/sprite.css";

interface dataType {
  idx: number;
  process_idx: number;
  process_name: string;
  process_type: number;
  user_idx: number;
  user_name: string;
  user_phone: string;
}

const DashboardMobile = () => {
  const navigate = useNavigate();

  const [data, setData] = useRecoilState(responseData);

  const [, setSelectedPage] = useRecoilState(pageState);

  const resetParameter = useResetRecoilState(councelParameter);

  /**
   * @description 대시보드 페이지 내에서 미배정 상담건에 대한 호출
   *
   * @param boolean
   */
  useEffect(() => {
    getNotAssignedData(false).then((res: any) => {
      setData(res.data.data.councel);
    });
  }, []);

  function navigateTo(e: any) {
    pageHandler(e, navigate);

    setSelectedPage(e);
    resetParameter();
  }

  return (
    <main className="absolute -bg--color-neutral0 w-full mt-[48px] flex flex-col min-h-[calc(100%-48px)]">
      <section className="-bg--color-neutral0 w-full py-[16px] px-[16px] flex flex-col gap-[16px]">
        <header className="-text--title-21b font-700 -text--color-neutral100">
          상담처리 현황
        </header>
        <MobileTypeTwo name="councel" callback={navigateTo} />
        <MobileTypeTwo name="inquiry" callback={navigateTo} />
      </section>
      <section className="w-full -bg--color-neutral3 h-[12px]"></section>
      <section className="-bg--color-neutral0 w-full py-[16px] px-[16px] flex flex-col">
        <header className="mb-[12px]">
          <div className="flex flex-row gap-[8px] items-center mb-[4px]">
            <span className="-text--title-21b font-700 -text--color-neutral100">
              미배정 상담
            </span>
            <span className="-text--body-14m font-500 -text--color-primary-main">
              {`총 ${data?.length}건`}
            </span>
          </div>
          <span className="-text--body-14r font-400 -text--color-neutral50">
            담당자를 기다리고 있어요. 상담 배정이 필요해요.
          </span>
        </header>
        <section className="flex flex-col gap-[12px]">
          {data &&
            data?.map((data: dataType, num: number) => {
              return (
                <MobileTypeOne data={data} callback={navigateTo} key={num} />
              );
            })}
          {data && data?.length === 0 && (
            <div className="h-[calc(100vh-48px-256px-12px-56px-12px-32px)] flex flex-col items-center justify-center gap-[4px]">
              <span className="-text--body-14r font-400 -text--color-neutral50">
                현재 미배정 상담건이 없어요!
              </span>
            </div>
          )}
        </section>
      </section>
    </main>
  );
};

export default DashboardMobile;
