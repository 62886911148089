import { childrenType } from "@/types/common";
import React from "react";

const MobileCouncelNoteLayout = ({ children }: childrenType) => {
  return (
    <main className="absolute -bg--color-neutral0 w-full mt-[48px] flex flex-col min-h-[calc(100%-48px-76px)] pb-[76px]">
      <section className="-bg--color-neutral0 w-full flex flex-col px-[16px] py-[16px] gap-[12px]">
        {children}
      </section>
    </main>
  );
};

export default MobileCouncelNoteLayout;
