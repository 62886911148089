import React, { useRef, useState } from "react";

import wait_icon from "@/Assets/Images/wait_icon.svg";

import { useRecoilState, useRecoilValue } from "recoil";
import { additionalResponseData, responseData } from "@/stores/common/data";

import { urlParameterCouncel } from "@/stores/page/councelDetail";

import { handleTimeToKr } from "@/utils/func/common/convertTime";
import { councelNoteAddressIdx } from "@/stores/page/councelDetail";

import http from "@/utils/api/http/http";
import { showPortal } from "@/utils/func/common/modalController";
import { modalInfo, selectedFileList } from "@/stores/common/modal";
import { disable } from "ol/rotationconstraint";

const FileUpload = () => {
  const [data, setData] = useRecoilState(responseData);
  const [additionalData, setAdditionalData] = useRecoilState(
    additionalResponseData
  );

  const [modal, setModal] = useRecoilState(modalInfo);

  const fileInput = useRef(null);

  const [selectedItems, setSelectedItems] = useRecoilState(selectedFileList);

  const [showLoading, setShowLoading] = useState(false);

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  const handleCheckboxChange = (event) => {
    const resourceIdx = event.target.value;
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, parseInt(resourceIdx)]);
    } else {
      setSelectedItems((prev) =>
        prev.filter((idx) => idx !== parseInt(resourceIdx))
      );
    }
  };

  const id = useRecoilValue(urlParameterCouncel);

  const handleButtonClick = (e) => {
    fileInput.current.click();
  };

  async function getData() {
    try {
      const res = await http.get(`/api/councel/detail/list`, {
        params: { process_idx: id.process },
      });

      setAdditionalData({
        councel: [...res.data.data.councel],
      });

      const userRes = await http.post(`/api/councel/detail`, {
        user_idx: id.user,
        process_idx: id.process,
        admin_idx: localStorage.getItem("admin_idx"),
        address_idx_list: addressIdxList,
      });

      setData(userRes.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleChange(e) {
    try {
      let tempUploadFile = e.target.files;

      const formData = new FormData();

      // 허용 가능한 확장자 및 파일 크기 설정
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "heic",
        "tiff",
        "pdf",
        "dwg",
        "xlsx",
        "xls",
      ];
      const maxSize = 30 * 1024 * 1024; // 30MB

      for (let i = 0; i < tempUploadFile.length; i++) {
        const file = tempUploadFile[i];

        // 파일 확장자 및 파일 크기 체크
        let fileExtension = file.name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          // 허용되지 않은 확장자일 경우 에러 처리

          alert(`.${fileExtension} 확장자 파일은 업로드할 수 없습니다.`);

          throw new Error(
            `File with extension .${fileExtension} is not allowed.`
          );
        }
        if (file.size > maxSize) {
          // 파일 크기가 허용 범위를 초과할 경우 에러 처리
          alert(`30MB를 초과하는 파일은 업로드할 수 없습니다.`);

          throw new Error(`File size exceeds the limit of 30MB.`);
        }

        fileExtension = fileExtension.toLowerCase(); // 확장자를 소문자로 변경

        const newFileName = file.name.replace(
          new RegExp(`.${fileExtension}$`, "i"),
          `.${fileExtension}`
        );

        formData.append("imgs", new File([file], newFileName));

        const response = await http.put(`/api/admin/upload-imgs`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        const uploadResponse = await http.post(`/api/councel/resource`, {
          process_idx: data.process.process_idx,
          resource_name: newFileName,
          resource_file: response.data.data.img_url_list[i],
        });
      }

      getData();
    } catch (err) {
      console.error(err);
    }
  }

  async function fixUploadFile(e) {
    try {
      const response = await http.put(`/api/councel/resource`, null, {
        params: { resource_idx: e },
      });

      getData();
    } catch (err) {
      console.error(err);
    }
  }

  const downloadFile = async (resource, name) => {
    try {
      const response = await http.get(resource, { responseType: "blob" });

      const fileName = name;

      const blob = new Blob([response.data]);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");

      link.href = url;
      link.download = fileName;
      link.style.display = "none";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error downloading file:", err);
    }
  };

  if (showLoading) {
    return (
      <>
        <div className="modal_overlay">
          <div className="z-20 -bg--color-neutral0 transform w-[311px] h-[112px] rounded-[12px] flex flex-col items-center justify-center">
            <img
              src={wait_icon}
              alt=""
              className="animate-spin mb-[16px] w-[24px] h-[24px]"
            />
            <span>처리 중입니다. 잠시만 기다려주세요.</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="w-[840px] flex flex-col -bg--color-neutral0 rounded-[12px] -text--color-neutral34 mb-[24px] border border-solid -border--color-neutral6">
        <div className="border-b border-solid -border--color-neutral6 flex flex-row gap-[8px] items-center px-[24px] pt-[16px] pb-[8px]">
          <span className="text-B14B -text--color-neutral50">첨부파일</span>
          <span className="text-B12R -text--color-primary-main">{`총 ${data?.resource?.length}개`}</span>
        </div>
        {data &&
          data?.resource?.map((idx, num) => {
            return (
              <div
                className="py-[8px] px-[24px] flex flex-row items-center gap-[8px] border-b border-solid -border-b--color-neutral6 -text--color-neutral100 "
                key={num}
              >
                <input
                  type="checkbox"
                  className="w-[18px] h-[18px]"
                  value={idx.resource_idx}
                  onChange={handleCheckboxChange}
                  disabled={idx.is_resource === false}
                  checked={selectedItems.includes(idx.resource_idx)}
                />

                <div
                  className="w-[441px] px-[8px] overflow-hidden text-ellipsis whitespace-nowrap hover:underline"
                  onClick={() =>
                    downloadFile(idx.resource_file, idx.resource_name)
                  }
                >
                  {idx.resource_name}
                </div>
                <p className="w-[58px] px-[8px]">{idx.admin_name}</p>
                <p className="w-[149px] px-[8px] text-B14R">
                  {handleTimeToKr(idx.resource_datetime).replace(/-/g, ".")}
                </p>
                <div
                  className="w-[44px] h-[44px] flex items-center justify-center"
                  onClick={() => fixUploadFile(idx.resource_idx)}
                >
                  <span
                    className={`_sprite_ ${
                      idx.resource_check ? "_sprite_9_2_" : "_sprite_9_1_"
                    }`}
                  ></span>
                </div>
                <div className="w-[44px] h-[44px] flex items-center justify-center">
                  <button
                    onClick={() =>
                      downloadFile(idx.resource_file, idx.resource_name)
                    }
                  >
                    <span className="_sprite_ _sprite_8_7_"></span>
                  </button>
                </div>
              </div>
            );
          })}

        <div className="h-[48px] flex flex-row items-center gap-[8px] justify-between px-[24px] py-[20px]">
          <p>
            <span className="-text--color-neutral34 text-B14R mr-[8px]">
              1개당 최대
            </span>
            <span className="-text--color-neutral34 text-B14R mr-[8px]">
              30MB
            </span>
            <span className="-text--color-neutral34 mr-[8px]">|</span>
            <span className="-text--color-neutral34 text-B14R mr-[8px]">
              지원 가능한 확장자
            </span>
            <span className="-text--color-neutral34 text-B14M">
              jpg/jpeg/png/heic/tiff/pdf/dwg/xlsx/xls
            </span>
          </p>
          <div className="flex flex-row gap-[8px]">
            <button
              className={`-text--color-neutral50 text-B16B py-[9px] px-[8px]  ${
                selectedItems.length > 0
                  ? "hover:-text--color-primary-main"
                  : "opacity-50"
              }`}
              onClick={() =>
                showPortal(setModal, true, "councelnote-delete-file-web")
              }
              disabled={selectedItems.length === 0}
            >
              삭제
            </button>
            <button
              // className="-text--color-neutral50 text-B16B py-[9px] px-[8px] hover:-text--color-primary-main"
              className="text-B16B py-[9px] px-[8px] -text--color-primary-main"
              onClick={handleButtonClick}
            >
              업로드
            </button>
            <input
              type="file"
              className="hidden"
              ref={fileInput}
              onChange={handleChange}
              multiple
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
