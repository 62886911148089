import React from "react";

import { useRecoilState } from "recoil";

import { paginationController } from "@/utils/func/common/page";

import { bindPage, clickedPage } from "@/stores/common/Pagination";

import { councelParameter } from "@/stores/common/parameter";

const CouncelFooter = (props) => {
  const { data } = props;

  const [, setParameter] = useRecoilState(councelParameter);

  const slicePage = 5;

  // 선택한 페이지
  const [selectPage, setSelectPage] = useRecoilState(clickedPage);

  // 5씩 끊어주는 단위 페이지 1,6,10...
  const [periodPage, setPeriodPage] = useRecoilState(bindPage);

  // 백엔드로부터 전달받는 총 페이지 개수
  const totalPages = data?.data.data.total_pageno;

  // periodPage 최대 값
  const maximumPeriodPage = Math.ceil(totalPages / slicePage);

  return (
    <>
      <div className="fixed none w-[1128px] h-[calc(48px+33px)] bottom-0 -bg--color-neutral0 z-[3]">
        <nav className="fixed w-[1128px] h-[48px] flex flex-row items-center justify-center bottom-[32px] -bg--color-neutral0  rounded-b-[12px] border border-solid -border--color-neutral6 z-30">
          <button
            className={
              periodPage <= 1
                ? "w-[44px] h-[44px] flex items-center justify-center opacity-40"
                : "w-[44px] h-[44px] flex items-center justify-center"
            }
            onClick={() => {
              let value = {
                val1: 1,
                val2: 1,
              };
              let func = {
                func1: (v) => setPeriodPage(v),
                func2: (v) => setSelectPage(v),
                func3: (v) =>
                  setParameter((prev) => {
                    return { ...prev, pageno: v };
                  }),
              };

              paginationController(value, func);
            }}
            disabled={periodPage <= 1}
          >
            <span className="_sprite_ _sprite_17_5_"></span>
          </button>
          <button
            className={
              periodPage <= 1
                ? "w-[44px] h-[44px] flex items-center justify-center opacity-40"
                : "w-[44px] h-[44px] flex items-center justify-center"
            }
            onClick={() => {
              let value = {
                val1: periodPage,
                val2: periodPage,
              };
              let func = {
                func1: (v) => setPeriodPage(v - 1),
                func2: (v) => setSelectPage((v - 2) * 5 + 1),
                func3: (v) =>
                  setParameter((prev) => {
                    return { ...prev, pageno: (v - 2) * 5 + 1 };
                  }),
              };

              paginationController(value, func);
            }}
            disabled={periodPage <= 1}
          >
            <span className="_sprite_ _sprite_17_3_"></span>
          </button>

          {totalPages === 0 && (
            <>
              <button
                disabled
                className={"w-[44px] h-[44px] -text--color-neutral50"}
              >
                1
              </button>
            </>
          )}
          {Array.from({ length: slicePage }).map((_, index) => {
            const itemNumber = (periodPage - 1) * slicePage + index + 1;
            if (itemNumber > totalPages) return null;
            return (
              <button
                key={index}
                onClick={() => {
                  let value = {
                    val1: itemNumber,
                    val2: itemNumber,
                  };
                  let func = {
                    func1: () => null,
                    func2: (v) => setSelectPage(v),
                    func3: (v) =>
                      setParameter((prev) => {
                        return { ...prev, pageno: v };
                      }),
                  };

                  paginationController(value, func);
                }}
                className={
                  selectPage === itemNumber
                    ? "w-[44px] h-[44px] -text--color-neutral100 "
                    : "w-[44px] h-[44px] -text--color-neutral50"
                }
              >
                {itemNumber}
              </button>
            );
          })}

          <button
            className={
              totalPages / slicePage <= periodPage
                ? "w-[44px] h-[44px] flex items-center justify-center opacity-40"
                : "w-[44px] h-[44px] flex items-center justify-center"
            }
            onClick={() => {
              let value = {
                val1: periodPage,
                val2: periodPage,
              };
              let func = {
                func1: (v) => setPeriodPage(v + 1),
                func2: (v) => setSelectPage(v * 5 + 1),
                func3: (v) =>
                  setParameter((prev) => {
                    return { ...prev, pageno: v * 5 + 1 };
                  }),
              };

              paginationController(value, func);
            }}
            disabled={totalPages / slicePage <= periodPage}
          >
            <span className="_sprite_ _sprite_17_4_"></span>
          </button>
          <button
            className={
              totalPages / slicePage <= periodPage
                ? "w-[44px] h-[44px] flex items-center justify-center opacity-40"
                : "w-[44px] h-[44px] flex items-center justify-center"
            }
            onClick={() => {
              let value = {
                val1: maximumPeriodPage,
                val2: totalPages,
              };
              let func = {
                func1: (v) => setPeriodPage(v),
                func2: (v) => setSelectPage(v),
                func3: (v) =>
                  setParameter((prev) => {
                    return { ...prev, pageno: v };
                  }),
              };

              paginationController(value, func);
            }}
            disabled={totalPages / slicePage <= periodPage}
          >
            <span className="_sprite_ _sprite_17_6_"></span>
          </button>
        </nav>

        <div className="none fixed w-[1128px] h-[33px] bottom-[0px] -bg--color-neutral0"></div>
      </div>
    </>
  );
};

export default CouncelFooter;
