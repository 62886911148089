import React from "react";

import { useRecoilState } from "recoil";

import { selectedResponseCalendar } from "@/stores/page/calendar";

import position_icon from "@/Assets/Images/position_icon.svg";

import moment from "moment";

const CalendarShowScheduleWeb = () => {
  const [data, setdata] = useRecoilState(selectedResponseCalendar);

  let colorClass;
  if (data.calender_category === "서울사무소") {
    colorClass = "-bg--color-office-seoul";
  } else if (data.calender_category === "여주전시관") {
    colorClass = "-bg--color-showroom-yeoju";
  } else if (data.calender_category === "여주공장") {
    colorClass = "-bg--color-factory-yeoju";
  } else if (data.calender_category === "제주전시관") {
    colorClass = "-bg--color-showroom-jeju";
  } else if (data.calender_category === "기타") {
    colorClass = "-bg--color-etc";
  } else {
    colorClass = "-bg--color-etc";
  }

  const filterCategory = () => {
    switch (data.calender_category) {
      case "서울사무소":
        return data.calender_category;
      case "여주전시관":
        return data.calender_category;
      case "여주공장":
        return data.calender_category;
      case "제주전시관":
        return data.calender_category;
      default:
        return "기타";
    }
  };

  const filterEtcAddress = () => {
    switch (data.calender_category) {
      case "서울사무소":
        return "";
      case "여주전시관":
        return "";
      case "여주공장":
        return "";
      case "제주전시관":
        return "";
      default:
        return (
          <div className="flex flex-row">
            <img src={position_icon} alt="" className="mr-[4px]" />
            <span className="text-B16R -text--color-neutral50">
              {data.calender_location}
            </span>
          </div>
        );
    }
  };

  const showDate = () => {
    const start = moment(data.start);
    const end = moment(data.end);

    const formattedStartFr = start.format("YYYY-MM-DD");
    const formattedStartBa = start.format("HH:mm");

    const formattedEndFr = end.format("YYYY-MM-DD");
    const formattedEndBa = end.format("HH:mm");

    const startDayOfWeek = start.format("dd");
    const endDayOfWeek = end.format("dd");

    return (
      <>
        <div>{`${formattedStartFr} (${startDayOfWeek}) ${formattedStartBa} - ${formattedEndFr} (${endDayOfWeek}) ${formattedEndBa}`}</div>
      </>
    );
  };

  return (
    <>
      <div className="w-[100%] border-b border-solid -border--color-neutral3 flex flex-col items-start gap-[8px]  -text--color-neutral100 max-h-[591px] overflow-y-auto custom-scrollbar w-[481px]">
        <div className="ptb-16 pl-[32px] border-b border-solid -border--color-neutral6 flex flex-col gap-[12px] w-full">
          <div className="flex flex-row items-center">
            <span className="pr-[24px] text-B16B">분류</span>
            <div
              className={`w-[20px] h-[20px] rounded-[4px] ${colorClass} mr-[8px]`}
            ></div>
            <span className="pr-[24px]">{filterCategory()}</span>
          </div>
          {filterEtcAddress()}
        </div>
        <div className="ptb-16 pl-[32px] border-b border-solid -border--color-neutral6 flex flex-col gap-[12px] w-full">
          <div className="flex flex-row items-center">
            <span className="pr-[24px] text-B16B">등록자</span>
          </div>
          <div>{data.admin_name}</div>
        </div>
        <div className="ptb-16 pl-[32px] border-b border-solid -border--color-neutral6 flex flex-col gap-[12px] w-full">
          <div className="flex flex-row items-center">
            <span className="pr-[24px] text-B16B">일정</span>
          </div>
          <div>{showDate()}</div>
        </div>
        <div className="ptb-16 pl-[32px] border-b border-solid -border--color-neutral6 flex flex-col gap-[12px] w-full">
          <div className="flex flex-row items-center">
            <span className="pr-[24px] text-B16B">고객정보</span>
          </div>
          <div className="flex gap-[4px]">
            <span>{data.calender_name}</span>
            <span>
              {data.calender_phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}
            </span>
          </div>
        </div>
        <div className="ptb-16 pl-[32px] border-b border-solid -border--color-neutral6 flex flex-col gap-[12px] w-full">
          <div className="flex flex-row items-center">
            <span className="pr-[24px] text-B16B">참석자</span>
          </div>
          <div className="flex gap-[4px]">
            {data.admin_list.map((idx, num) => {
              return (
                <React.Fragment key={num}>
                  <div className="w-[77px] h-[44px] flex items-center justify-center border border-solid -border--color-neutral50 rounded-[30px]">
                    {idx.admin_name}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="ptb-16 plr-32 flex flex-col gap-[12px]">
          <div className="flex flex-row items-center">
            <span className="pr-[24px] text-B16B">추가 메시지</span>
          </div>
          <div className="flex gap-[4px] whitespace-pre-line">
            {data.calender_detail}
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarShowScheduleWeb;
