import React from "react";
import ModalFooterApply from "@/components/footer/ModalFooterApply";
import ModalFooterDeleteType from "@/components/footer/ModalFooterDeleteType";
import ModalFooterResetType from "@/components/footer/ModalFooterResetType";
import ModalFooterFixType from "@/components/footer/ModalFooterFixType";
import ModalConfirmFooter from "@/components/footer/ModalConfirmFooter";
import ModalFooterRecover from "@/components/footer/ModalFooterRecover";

interface propsType {
  v: string;
}

const ModalFooter = ({ v }: propsType) => {
  return (
    <>
      {/* 공통 */}
      {v === "entity-progress-common" && null}
      {/* 웹 */}
      {v === "councel-field-web" && null}
      {v === "councel-step-web" && null}
      {v === "councel-filtering-web" && <ModalFooterResetType />}
      {v === "councel-fix-userinfo-web" && <ModalFooterResetType />}
      {v === "councel-recover-councel-web" && <ModalFooterRecover />}
      {v === "councel-delete-comment-web" && <ModalFooterDeleteType />}
      {v === "councel-assign-manager-web" && <ModalConfirmFooter />}
      {v === "calendar-search-user-web" && null}
      {v === "calendar-show-schedule-web" && <ModalFooterFixType />}
      {v === "calendar-add-schedule-web" && <ModalConfirmFooter />}
      {v === "calendar-fix-schedule-web" && <ModalConfirmFooter />}
      {v === "calendar-search-attendee-web" && <ModalFooterResetType />}
      {v === "calendar-delete-schedule-web" && <ModalFooterDeleteType />}
      {v === "entity-password-web" && <ModalConfirmFooter />}
      {v === "councelnote-detail-calendar-web" && <ModalFooterApply />}
      {v === "councelnote-delete-file-web" && <ModalFooterDeleteType />}

      {/* 모바일 */}
      {v === "councel-add-councelnote-mobile" && null}
      {v === "councel-fix-councelnote-mobile" && null}
      {v === "councel-add-comment-mobile" && null}
      {v === "councel-field-mobile" && null}
      {v === "councel-filter-mobile" && <ModalFooterResetType />}
      {v === "councel-userinfo-mobile" && <ModalFooterResetType />}
      {v === "councel-filter-calendar-mobile" && <ModalFooterApply />}
      {v === "councel-councelnote-calendar-mobile" && <ModalFooterApply />}

      {/* 실행 안되는 중 */}

      {v === "councel-alert-danger-web" && null}
      {v === "alert-texteditor-case" && null}
    </>
  );
};

export default ModalFooter;
