import MakePdf from "@/utils/func/pdf/makePdf";
import React from "react";

const DownloadPdf = ({ refHeader, refBody1, refBody2 }: any) => {
  return (
    <button
      className="pt-[14px] pb-[14px] justify-center items-center flex flex-row gap-[4px] border border-solid -border--color-neutral12 -bg--color-neutral0 -text--color-neutral50 focus:-border--color-primary-surface focus:-bg--color-primary-surface focus:-text--color-primary-main rounded-[4px]"
      onClick={() => {
        MakePdf(refHeader, refBody1, refBody2);
      }}
    >
      <span className="-text--body-16b font-700 ">상담 이력 다운로드</span>
      <span className="_sprite_ _sprite_8_7_"></span>
    </button>
  );
};

export default DownloadPdf;
