import React from "react";

import moment from "moment";

import http from "@/utils/api/http/http";

interface propsType {
  data?: {
    admin_idx: number | undefined;
    admin_name: string | undefined;
    is_resource: boolean | undefined;
    resource_check: boolean | undefined;
    resource_datetime: string | undefined;
    resource_file: string | undefined;
    resource_idx: number | undefined;
    resource_name: string | undefined;
  };
  index?: number;
}

const MobileUploadFile = ({ data, index }: propsType) => {
  const downloadFile = async (file: any, name: any) => {
    try {
      const response = await http.get(file, { responseType: "blob" });

      const fileName = name;

      const blob = new Blob([response.data]);

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");

      link.href = url;
      link.download = fileName;
      link.style.display = "none";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <article
      className="px-[16px] py-[16px] flex flex-row justify-between items-center mb-[12px]"
      style={{
        boxShadow: "0px 3px 8px 0px rgba(108, 108, 108, 0.12)",
      }}
      key={index}
    >
      <div className="flex flex-col gap-[4px]">
        <span className="-text--label-12b font-700 -text--color-neutral50">
          {data?.admin_name}
        </span>
        <span className="-text--body-14b font-700 -text--color-neutral100">
          {data?.resource_name}
        </span>
        <span className="-text--label-12m font-500 -text--color-neutral50">
          {moment(data?.resource_datetime).format("YYYY.MM.DD HH:mm")}
        </span>
      </div>
      <button
        className="w-[24px] h-[24px]"
        onClick={() => downloadFile(data?.resource_file, data?.resource_name)}
      >
        <span className="_sprite_ _sprite_8_7_"></span>
      </button>
    </article>
  );
};

export default MobileUploadFile;
