import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { login } from "@/utils/api/auth/auth";

import LoginLayout from "@/layout/LoginLayout";

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);

  const [username, setUsername] = useState("");
  const [userpassword, setUserpassword] = useState("");

  const location = useLocation();

  const idHandler = (e) => {
    setUsername(e.target.value);
  };

  const passwordHandler = (e) => {
    setUserpassword(e.target.value);
  };

  const submitLogin = (e) => {
    e.preventDefault();

    login({
      username,
      userpassword,
      setIsLogin,
      location,
    });
  };

  const urlSearchParams = new URLSearchParams(window.location.search);

  const id = urlSearchParams.get("id");
  const pw = urlSearchParams.get("pw");

  useEffect(() => {
    if (pw && id) {
      setUsername(id);
      setUserpassword(pw);

      login({
        username: id,
        userpassword: pw,
        setIsLogin,
        location,
      });
    }
  }, []);

  return (
    <LoginLayout>
      <form
        className="flex flex-col items-center justify-start w-[328px]"
        onSubmit={submitLogin}
      >
        <span className="_sprite_ _sprite_12_1_ mb-[24px]"></span>
        <div className="flex flex-col items-start mb-[16px] w-full">
          <span className="mb-[10px] text-B14M">아이디</span>
          <input
            className="plr-16 ptb-12 box-border border border-solid -border--color-neutral50 rounded-[6px] focus:-border--color-primary-main w-full"
            type="text"
            name="username"
            placeholder="아이디 입력"
            onChange={(e) => idHandler(e)}
            autoComplete="on"
          />
        </div>
        <div className="flex flex-col items-start mb-[24px] w-full">
          <span className="mb-[10px] text-B14M">비밀번호</span>
          <input
            className="plr-16 ptb-12 box-border border border-solid -border--color-neutral50 rounded-[6px] focus:-border--color-primary-main w-full"
            type="password"
            name="userpassword"
            placeholder="비밀번호 입력"
            onChange={(e) => passwordHandler(e)}
            autoComplete="on"
          />
        </div>
        {!isLogin && (
          <div
            className={`w-full px-[12px] py-[10px] mb-[8px] flex flex-row items-center gap-[6px] -text--color-danger-main text-B14M border border-solid -border--color-danger-border -bg--color-danger-surface rounded-[8px]`}
          >
            <span className="_sprite_ _sprite_15_8_"></span>
            <span>아이디 또는 비밀번호가 일치하지 않습니다.</span>
          </div>
        )}
        <button
          className={`w-full h-[52px] rounded-[6px] -text--color-neutral0 text-B16M -bg--color-primary-main cursor-pointer mb-[24px] ${
            (!username || !userpassword) && "opacity-50"
          }`}
          disabled={!(username && userpassword)}
          // onClick={() => submitLogin()}
          type="submit"
        >
          로그인
        </button>
        <span className="-text--color-neutral50 text-B16R">
          담당자 : 김범석 (kbs@kcmodular.co.kr)
        </span>
      </form>
    </LoginLayout>
  );
};

export default Login;
