import React from "react";

import { useRecoilState } from "recoil";

import { modalInModalInfo } from "@/stores/common/modal";

import ModalHeader from "@/components/header/ModalHeader";
import ModalInModalBody from "@/components/body/ModalInModalBody";
import ModalFooter from "@/components/footer/ModalFooter";

export default function ModalInModalLayout() {
  const [modal, setModal] = useRecoilState(modalInModalInfo);

  return (
    <main
      className="div-modal-layout-animate animate-fade-in-up"
      onClick={(e) => e.stopPropagation()}
    >
      <ModalHeader value={modal.value} callback={setModal} />
      <ModalInModalBody />
      <ModalFooter v={modal.value} />
    </main>
  );
}
