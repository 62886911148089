import React from "react";

import { useRecoilState } from "recoil";

import { responseData } from "@/stores/common/data";

const CouncelFieldWeb = () => {
  const [data, setData] = useRecoilState<any>(responseData);

  const openWindow = (e: any, e2: any) => {
    window.open(
      `https://www.eum.go.kr/web/ar/lu/luLandDet.jsp?selSido=11&selSgg=710&selUmd=0101&selRi=00&landGbn=1&bobn=175&bubn=1&pnu=${e}&scale=&isNoScr=script&chk=0&mode=search&selGbn=umd`,
      "_blank"
    );

    window.open(`https://map.naver.com/p/search/${e2}`, "_blank");
  };

  return (
    <div className="overflow-y-auto max-h-[calc(100vh-216px+60px+27px-85px-165px)] custom-scrollbar flex flex-col overflow-x-hidden w-[387px]">
      {/* temp로 변경 안되는가? */}
      <div className="px-[24px] py-[16px] gap-[16px] flex flex-col">
        {data.user.wfs.map((idx: any, num: number) => {
          return (
            <div
              key={num}
              className="block border border-solid -border--color-neutral6 rounded-[6px] pt-[15px] pb-[15px] plr-16 flex flex-col cursor-pointer"
              style={{
                boxShadow: "0px 3px 8px 0px rgba(108, 108, 108, 0.12)",
              }}
              onClick={() => openWindow(idx.wfs_pnu, idx.wfs_jibun)}
            >
              <div className="mb-[8px]">
                <span className="py-[6px] px-[10px] -bg--color-neutral6 rounded-[30px] text-B12B mb-[3px] -text--color-neutral50">
                  {idx.wfs_area_name}
                </span>
              </div>
              <span className="text-B16M mb-[8px]">{idx.wfs_jibun}</span>
              <p className="flex flex-row gap-[4px] items-center -text--color-neutral50 mb-[8px]">
                <span className="text-B12R font-500">대지면적 </span>
                <span className="text-B14M">
                  {idx.wfs_lndpcl}m<sup className="text-[10px]">2</sup>
                </span>
              </p>
              <div className="flex flex-row gap-[16px] -text--color-neutral50">
                <p className="flex flex-row gap-[4px] items-center">
                  <span className="text-B12R font-500">최대 건폐율</span>
                  <span className="text-B14M">{idx.wfs_blr} %</span>
                </p>
                <p className="flex flex-row gap-[4px] items-center">
                  <span className="text-B12R font-500">최대 용적률</span>
                  <span className="text-B14M">{idx.wfs_far} %</span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="px-[16px] py-[12px] border-t border-solid -border--color-neutral12">
        <span className="w-full border border-solid -border--color-neutral12 flex justify-center py-[10px] text-B16B -text--color-neutral34">
          주소를 누르면 토지이용계획 열람이 가능해요.
        </span>
      </div>
    </div>
  );
};

export default CouncelFieldWeb;
