import React from "react";

import { useRecoilValue } from "recoil";

import CouncelFilter from "@/components/filter/CouncelFilter";
import CouncelHeader from "@/components/header/CouncelHeader";
import CouncelBody from "@/components/body/CouncelBody";
import CouncelFooter from "@/components/footer/CouncelFooter";

import { councelParameter } from "@/stores/common/parameter";

import { useQuery } from "@tanstack/react-query";
import { getCouncel } from "@/utils/api/pages/councel";

const Councel = () => {
  const parameter = useRecoilValue(councelParameter);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["councelData", parameter],
    queryFn: () => getCouncel(parameter),
  });

  return (
    <>
      <div className="custom-scrollbar relative w-full h-full flex flex-row overflow-auto">
        <div className="h-full flex w-[1128px] relative max-[1696px]:mt-0 max-[1696px]:mb-0 max-[1696px]:ml-[calc(248px+36px)] max-[1696px]:mr-[36px] min-[1697px]:my-0 min-[1697px]:mx-auto">
          <CouncelFilter />
          <CouncelHeader />
          <CouncelBody data={data} isError={isError} isLoading={isLoading} />
          <CouncelFooter data={data} />
        </div>
      </div>
    </>
  );
};

export default Councel;
