import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className="fixed z-50 ml-[10px] mt-[2px]"
      onClick={() => {
        // 대시보드 미배정으로 접근 후 클릭 시 에러 발생
        navigate(-1);
        // navigate("/councel");
      }}
    >
      <span className="_sprite_ _sprite_11_9_"></span>
    </button>
  );
};

export default BackButton;
