import React, { useEffect, useState } from "react";

import { Redirect, useNavigate } from "react-router-dom";

import { useSetRecoilState, useRecoilValue } from "recoil";

import { modalInfo } from "@/stores/common/modal";
import { device } from "@/stores/common/index";

import { checkAthenticated } from "@/utils/api/auth/auth";

const NavBarReport = () => {
  const navigate = useNavigate();

  const setModal = useSetRecoilState(modalInfo);
  const currentDevice = useRecoilValue(device);

  const navHandler = () => {
    if (checkAthenticated()) {
      // 접근한 url로 리 다이렉팅
      navigate("/dashboard");
    }

    if (!checkAthenticated()) {
      window.location.href = "/";
    }
  };

  return (
    <header className="fixed top-0 z-10 flex justify-center w-full border-solid -bg--color-neutral0 border-b-[1px] border-b-[#F0F0F0] max-w-[640px] left-1/2 -translate-x-1/2">
      <div className="w-full md:h-[60px] h-[48px] md:px-[41px] py-[14px] flex items-center md:justify-between justify-center gap-1">
        <button onClick={() => navHandler()} disabled>
          <span className="cursor-pointer -text--color-primary-main md:text-H24B text-H21B">
            BUILD SIMPLY
          </span>
        </button>
        {currentDevice === "web" ? (
          <div className="relative flex flex-row justify-center items-center h-full">
            {/* 버튼 클릭 시 같은 페이지로 이동 (pc ver) */}
            <button
              className="flex flex-row"
              onClick={() => {
                navHandler();
              }}
              disabled
            >
              <p className="mr-[8px] text-H18B">PC 화면으로 보기</p>
            </button>
          </div>
        ) : (
          <div className="relative flex flex-row justify-center items-center h-full">
            <div className="flex flex-row items-start h-full">
              <p className="text-B10B -text--color-neutral50">ADMIN</p>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default NavBarReport;
