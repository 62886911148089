import { useRecoilState } from "recoil";
import React from "react";

import { moveCouncelNoteList } from "@/stores/common/Mobile";
import { pageStateInCouncelPage } from "@/stores/common/navigation";

const CouncelDetailFooter = () => {
  const [pageState, setPageState] = useRecoilState(pageStateInCouncelPage);

  // 푸터 상담노트 클릭하면 초기화 -> 상담 노트 상세 내역 보려고 할 때 상태 값
  const [, setShowNote] = useRecoilState(moveCouncelNoteList);

  const handleCouncelNotePage = (e: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowNote(false);
    setPageState(e);
  };

  return (
    <footer className="fixed bottom-0 pt-[12px] pb-[16px] plr-16 flex flex-row items-center justify-between w-full -bg--color-neutral6">
      <button
        className="flex flex-col items-center justify-between pl-[13px] pr-[13px] gap-[8px]"
        onClick={() => {
          handleCouncelNotePage(1);
        }}
      >
        <span
          className={`_sprite_ ${
            pageState === 1 ? "_sprite_16_12_" : "_sprite_7_8_"
          }`}
        ></span>
        <span
          className={`-text--label-10m ${
            pageState === 1
              ? "-text--color-neutral100"
              : "-text--color-neutral50"
          }`}
        >
          상담정보
        </span>
      </button>
      <button
        className="flex flex-col items-center justify-between pl-[13px] pr-[13px] gap-[8px]"
        onClick={() => {
          handleCouncelNotePage(2);
        }}
      >
        <span
          className={`_sprite_ ${
            pageState === 2 ? "_sprite_16_10_" : "_sprite_7_20_"
          }`}
        ></span>
        <span
          className={`-text--label-10m ${
            pageState === 2
              ? "-text--color-neutral100"
              : "-text--color-neutral50"
          }`}
        >
          상담노트
        </span>
      </button>
      <button
        className="flex flex-col items-center justify-between pl-[13px] pr-[13px] gap-[8px]"
        onClick={() => {
          handleCouncelNotePage(3);
        }}
      >
        <span
          className={`_sprite_ ${
            pageState === 3 ? "_sprite_16_13_" : "_sprite_7_23_"
          }`}
        ></span>
        <span
          className={`-text--label-10m ${
            pageState === 3
              ? "-text--color-neutral100"
              : "-text--color-neutral50"
          }`}
        >
          댓글
        </span>
      </button>
      <button
        className="flex flex-col items-center justify-between pl-[13px] pr-[13px] gap-[8px]"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setPageState(4);
        }}
      >
        <span
          className={`_sprite_ ${
            pageState === 4 ? "_sprite_16_11_" : "_sprite_8_4_"
          }`}
        ></span>
        <span
          className={`-text--label-10m ${
            pageState === 4
              ? "-text--color-neutral100"
              : "-text--color-neutral50"
          }`}
        >
          첨부파일
        </span>
      </button>
    </footer>
  );
};

export default CouncelDetailFooter;
