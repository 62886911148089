import axios from "axios";
import { refreshAccessToken } from "../auth/auth";

/**
 * @description interceptors를 통해 request 시 헤더 추가, 에러 헨들링, 요청 및 응답 처리
 *
 * @param config url, mothod, headers, params, data 등... 포함
 * @returns token 값 존재 시 config header 영역에 토큰 값
 * @returns /kakao url 접근 시 header auth영역에 kakao api 키 값
 * @return token 값 인증 실패 시 localstorage에 있는 refresh_token 값
 */
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");

    if (token) config.headers["Authorization"] = `Bearer ${token}`;

    if (config.url === "/kakao") {
      config.headers[
        "Authorization"
      ] = `KakaoAK ${process.env.REACT_APP_KAKAO_API}`;
    } else if (config.url !== "/api/jwt/refresh") {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "refresh_token"
      )}`;
    }

    return config;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err);
  }
);

/**
 * @description interceptors를 통해 response 시 에러 코드에 대한 예외처리
 *
 * @params err 시 config 값을 가지고 refresh token 실행
 * @return 기본 값은 response 값 / 에러 시 err config 값
 */

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  async function (err) {
    const errorApi = err.config;

    /**
     * @description 에러 처리 분기
     * @todo 추후 {40101, 40102, 40103, 40104, 40105, 40106, 40201, 40301, 40302, 40601, 40602, 40603, 40604}에 대한 예외처리
     */
    if (
      err.response.status === 401 &&
      !errorApi.retry &&
      localStorage.getItem("refresh_token") !== null
    ) {
      errorApi.retry = true;

      const refresh_token = await localStorage.getItem("refresh_token");

      axios.defaults.headers["Authorization"] = `Bearer ${refresh_token}`;

      await refreshAccessToken().then((res) => {
        errorApi.headers = { ...errorApi.headers };
        errorApi.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "refresh_token"
        )}`;
      });

      return axios(errorApi);
    }

    // if (err.response.status === 500) {
    //   window.location = "/error";
    // }

    return Promise.reject(err);
  }
);

export default axios;
