import { childrenType } from "@/types/common";
import ReactDom from "react-dom";

const Portal = ({ children }: childrenType) => {
  const ele: any = document.querySelector("#portal");

  return ReactDom.createPortal(children, ele);
};

export default Portal;
