import { useRecoilState } from "recoil";
import {
  choicedManager,
  copiedManagerList,
  errState,
  inputValueManager,
  managerList,
} from "@/stores/common/modal";
import React, { useEffect, useState } from "react";
import { responseData } from "@/stores/common/data";

const CouncelAssignManagerWeb = () => {
  // 백엔드로부터 전달받은 담당자 리스트
  const [councelList, setCouncelList] = useRecoilState(managerList);

  // 백엔드로부터 전달받은 담당자 리스트 선택한 담당자
  const [selectedManager, setSelectedManager] = useRecoilState(choicedManager);

  const [data, setData] = useRecoilState(responseData);

  // 위 데이터를 깊은 복사
  const [searchSelectedManager, setSearchSelectedManager] =
    useRecoilState(copiedManagerList);

  // 단순 담당자 배정 input value 관리를 위한 상태 값
  const [councelListInputTag, setCouncelListInputTag] =
    useRecoilState(inputValueManager);

  // 담당자 미선택 & 변경 실패 시 css 처리 용도
  const [alertErr, setAlertErr] = useRecoilState(errState);

  const searchManagerName = (e) => {
    setSelectedManager((prev) => {
      return {
        ...prev,
        managerName: "",
        managerIndex: "",
      };
    });

    setCouncelListInputTag(e.target.value);
    const searchText = e.target.value.toLowerCase().replace(/\s/g, ""); // Remove spaces from the input text

    const filteredManagerList = councelList.filter((item) =>
      item.admin_name.toLowerCase().includes(searchText)
    );

    if (searchText) {
      // 1. 값이 있을 경우 일치하는 값 보여주기
      setSearchSelectedManager(filteredManagerList);
    } else {
      // 2. 비어 있을 경우 / 모두 보여주기
      setSearchSelectedManager(councelList);
    }
  };

  const selectedManagerName = (idx) => {
    setAlertErr(false);
    const inputElement = document.querySelector('input[type="text"]');

    if (inputElement) {
      // input 엘리먼트에 포커스 설정
      inputElement.focus();
    }

    setCouncelListInputTag(idx.admin_name);
    setSelectedManager((prev) => {
      return {
        ...prev,
        managerName: idx.admin_name,
        managerIndex: idx.admin_idx,
      };
    });
  };

  const resetManagerName = (v) => {
    setAlertErr(false);
    const inputElement = document.querySelector('input[type="text"]');

    if (inputElement) {
      // input 엘리먼트에 포커스 설정
      inputElement.focus();
    }

    setCouncelListInputTag(v);

    setSelectedManager((prev) => {
      return {
        ...prev,
        managerName: null,
        managerIndex: null,
      };
    });
  };

  const [showCouncelorList, setShowCouncelorList] = useState(false);

  useEffect(() => {
    if (data?.process?.admin_name !== null) {
      setCouncelListInputTag(data?.process?.admin_name);
    }
  }, []);

  return (
    <div className="relative w-[387px] max-h-[264px] border-b border-solid -border--color-neutral6 flex flex-col items-start py-[16px] px-[24px]">
      <div className="w-[100%] text-B16R flex flex-row justify-between mb-[8px]">
        <span className="text-B16B">담당자</span>
      </div>
      <button
        className={`border border-solid w-full px-[16px] py-[16px] -border--color-neutral20 rounded-[4px] flex flex-row justify-between ${
          showCouncelorList && "-border--color-primary-border"
        }`}
        onClick={() => setShowCouncelorList(!showCouncelorList)}
      >
        <span
          className={`text-B16R ${
            councelListInputTag ? "" : "-text--color-neutral50"
          }`}
        >
          {councelListInputTag ? councelListInputTag : "상담자를 지정하세요"}
        </span>
        <span
          className={`_sprite_ ${
            showCouncelorList ? "_sprite_3_1_" : "_sprite_3_2_"
          }`}
        ></span>
      </button>
      {showCouncelorList && (
        <div className="fixed top-[calc(50%+40px)] z-20 -bg--color-neutral0 border border-solid -border--color-neutral20 w-[339px] max-h-[256px] overflow-y-scroll custom-scrollbar rounded-[8px]">
          <li
            onClick={() => {
              resetManagerName("미배정으로 전환");
              setShowCouncelorList(false);
            }}
            className="w-[calc(100%)] px-[16px] py-[12px] flex items-center hover:-bg--color-neutral3 text-B16R"
          >
            미배정
          </li>
          {searchSelectedManager &&
            searchSelectedManager?.map((idx, num) => (
              <li
                key={num}
                onClick={() => {
                  selectedManagerName(idx);
                  setShowCouncelorList(false);
                }}
                className="w-[calc(100%)] px-[16px] py-[12px] flex items-center hover:-bg--color-neutral3 text-B16R"
              >
                {idx.admin_name}
              </li>
            ))}
        </div>
      )}
    </div>
  );
};

export default CouncelAssignManagerWeb;
