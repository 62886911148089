import React, { useEffect, useRef, useState } from "react";

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { responseData } from "@/stores/common/data";
import { modalInfo } from "@/stores/common/modal";

import { urlParameterCouncel } from "@/stores/page/councelDetail";
import {
  commentContent,
  councelNoteAddressIdx,
  selectedCommentIndex,
} from "@/stores/page/councelDetail";

import { handleTimeToKr } from "@/utils/func/common/convertTime";
import { showPortal } from "@/utils/func/common/modalController";

import http from "@/utils/api/http/http";

const Comment = () => {
  const [data, setData] = useRecoilState(responseData);

  const [modal, setModal] = useRecoilState(modalInfo);

  const id = useRecoilValue(urlParameterCouncel);

  // 댓글 창 글 상태 값
  const [commentState, setCommentState] = useRecoilState(commentContent);
  const resetCommentState = useResetRecoilState(commentContent);

  // 수정, 삭제 시 index 값
  const [selectedComment, setSelectedComment] =
    useRecoilState(selectedCommentIndex);
  const resetSelectedComment = useResetRecoilState(selectedCommentIndex);

  const [state, setState] = useState("read");

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  async function getData() {
    try {
      const res = await http.get(`/api/councel/detail/list`, {
        params: { process_idx: id.process },
      });

      const userRes = await http.post(`/api/councel/detail`, {
        user_idx: id.user,
        process_idx: id.process,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      });

      setData(userRes.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function addComment(e) {
    e.preventDefault();

    try {
      const response = await http.post(`/api/councel/comment`, {
        process_idx: data.process.process_idx,
        comment_detail: commentState,
      });

      resetCommentState();
      getData();
    } catch (err) {
      console.error(err);
    }
  }

  async function reviseData() {
    if (commentState.length !== 0) {
      try {
        const response = await http.put(`/api/councel/comment`, {
          comment_idx: selectedComment,
          comment_detail: commentState,
        });
        getData();
        setCommentState("");
        resetSelectedComment();
      } catch (err) {
        console.error(err);
      }
    } else {
      getData();
      setCommentState("");
      resetSelectedComment();
    }
  }

  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    const maxHeight = 144;
    if (scrollHeight > maxHeight) {
      textareaRef.current.style.overflowY = "auto";
      textareaRef.current.style.height = maxHeight + "px";
    } else {
      textareaRef.current.style.overflowY = "hidden";
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [commentState]);

  return (
    <>
      <div className="w-[840px] flex flex-col -bg--color-neutral0 rounded-[12px] break-words mb-[16px] border border-solid -border--color-neutral6">
        <div className="border-b border-solid -border--color-neutral6 flex flex-row gap-[8px] items-center px-[24px] pt-[16px] pb-[8px]">
          <span className="text-B14B -text--color-neutral50">댓글</span>
          <span className="text-B12R -text--color-primary-main">{`총 ${data?.comment?.length}개`}</span>
        </div>
        {data &&
          data?.comment?.map((arr, num) => {
            return (
              <div
                className="w-full px-[16px] py-[8px] border-b border-solid -border--color-neutral6 text-B14R"
                key={num}
              >
                <div className="flex flex-row justify-between py-[8px]">
                  <div className="flex flex-row gap-[4px] items-center">
                    <span className="text-B14B">{arr.admin_name}</span>
                    <span className=" text-B12R -text--color-neutral34">
                      {handleTimeToKr(arr.comment_datetime)}
                    </span>
                  </div>
                  <div className="flex flex-row justify-center items-center gap-[12px] -text--color-neutral50">
                    {arr.is_comment && arr.comment_idx === selectedComment ? (
                      <button
                        className="text-B14R"
                        onClick={() => reviseData()}
                      >
                        저장
                      </button>
                    ) : (
                      <>
                        {arr.admin_name ===
                          localStorage.getItem("admin_name") && (
                          <>
                            <button
                              onClick={() => {
                                setSelectedComment(arr.comment_idx);
                                setCommentState(arr.comment_detail);
                                setState("fix");
                              }}
                              className="text-B14R"
                            >
                              수정
                            </button>
                            <button
                              onClick={() => {
                                setSelectedComment(arr.comment_idx);
                                showPortal(
                                  setModal,
                                  true,
                                  "councel-delete-comment-web"
                                );
                                setState("delete");
                              }}
                              className="text-B14R"
                            >
                              삭제
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="text-B16R">
                  {arr.comment_idx === selectedComment && state === "fix" ? (
                    <>
                      <textarea
                        className="resize-none focus:outline-none px-[16px] py-[10px] custom-scrollbar h-auto w-full break-words -bg--color-neutral0 rounded-[20px] placeholder:text-B16R placeholder:-text--color-neutral50 border border-solid -border--color-neutral6"
                        autoComplete="off"
                        onChange={(e) => setCommentState(e.target.value)}
                        defaultValue={arr.comment_detail}
                        value={commentState}
                      ></textarea>
                    </>
                  ) : (
                    <>
                      <div
                        className="min-h-[50px] whitespace-normal break-words text-B16R -text--color-neutral100 scrollbar-[8px] -scrollbar-thumb--color-danger-main -scrollbar-track--color-neutral3 ptb-12"
                        dangerouslySetInnerHTML={{
                          __html: arr.comment_detail.replace(/\n/g, "<br>"),
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        <form className="px-[16px] py-[10px] w-full flex flex-row gap-[6px]">
          <textarea
            ref={textareaRef}
            rows={1}
            className="resize-none focus:outline-none px-[16px] py-[10px] custom-scrollbar h-auto w-full break-words -bg--color-neutral0 rounded-[20px] placeholder:text-B14R placeholder:-text--color-neutral50 border border-solid -border--color-neutral6"
            placeholder="댓글을 입력해 주세요."
            autoComplete="off"
            onChange={(e) => setCommentState(e.target.value)}
            value={commentState.length === 0 ? "" : undefined}
          ></textarea>
          <button
            onClick={(e) => addComment(e)}
            disabled={!(commentState.length > 0)}
          >
            <span
              className={`_sprite_ ${
                commentState.length > 0 ? "_sprite_16_7_" : "_sprite_7_24_"
              }`}
            ></span>
          </button>
        </form>
      </div>
    </>
  );
};

export default Comment;
