import React from "react";

const ScrollUpButton = () => {
  return (
    <button
      className="fixed right-0 bottom-0 mb-[12px] mr-[12px] p-[10px] border border-solid -bg--color-neutral0 rounded-[4px]"
      style={{ boxShadow: "0px 2px 6px 0px rgba(68, 68, 68, 0.08)" }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <span className="_sprite_ _sprite_17_1_"></span>
    </button>
  );
};

export default ScrollUpButton;
