import React from "react";

import { useRecoilState, useSetRecoilState } from "recoil";

import moment from "moment";

import position_icon from "@/Assets/Images/position_icon.svg";

import { showPortal } from "@/utils/func/common/modalController";

import {
  copiedResponseCalendar,
  selectedResponseCalendar,
} from "@/stores/page/calendar";

import { modalInfo } from "@/stores/common/modal";

const ScheduleList = () => {
  const [dataList, setDataList] = useRecoilState(copiedResponseCalendar);

  const setTempData = useSetRecoilState(selectedResponseCalendar);

  const setModal = useSetRecoilState(modalInfo);

  // 캘린더 list 보여주는 창에 날짜 별로 분류하기 위해 생성한 변수
  let filteredEvents = [];

  const filteringEvents = () => {
    const groupedEvents = {};

    for (const event of dataList) {
      const dataKey = moment(event.start).format("MM-DD");

      if (groupedEvents[dataKey]) {
        groupedEvents[dataKey].push(event);
      } else {
        groupedEvents[dataKey] = [event];
      }
    }

    filteredEvents = Object.values(groupedEvents);
  };

  filteringEvents();

  return (
    <>
      <div className="plr-16 ptb-12 overflow-y-auto custom-scrollbar">
        <div className="pt-[6px] mb-[12px] -text--color-neutral100 text-H18B">
          상세보기
        </div>
        <div className="flex flex-col gap-[8px]">
          {filteredEvents &&
            filteredEvents.map((idx, num) => {
              return (
                <React.Fragment key={num}>
                  <div className="text-B16B -text--color-neutral100 ">
                    {moment(idx[0].start).format("MM.DD")}
                  </div>
                  {idx.map((i, num) => {
                    const key = i.calender_idx;

                    let colorClass;
                    let bgClass;

                    if (i.calender_category === "서울사무소") {
                      colorClass = "-border--color-office-seoul";
                      bgClass = "-bg--color-office-seoul";
                    } else if (i.calender_category === "여주전시관") {
                      colorClass = "-border--color-showroom-yeoju";
                      bgClass = "-bg--color-showroom-yeoju";
                    } else if (i.calender_category === "여주공장") {
                      colorClass = "-border--color-factory-yeoju";
                      bgClass = "-bg--color-factory-yeoju";
                    } else if (i.calender_category === "제주전시관") {
                      colorClass = "-border--color-showroom-jeju";
                      bgClass = "-bg--color-showroom-jeju";
                    } else if (i.calender_category === "기타") {
                      colorClass = "-border--color-etc";
                      bgClass = "-bg--color-etc";
                    } else {
                      colorClass = "-border--color-etc";
                      bgClass = "-bg--color-etc";
                    }

                    return (
                      <React.Fragment key={num}>
                        <div className="flex flex-row gap-[8px]" id={key}>
                          <div className="text-B12R">
                            {moment(i.start).format("HH:mm")}
                          </div>
                          <button
                            onClick={() => {
                              setTempData(i);
                              showPortal(
                                setModal,
                                true,
                                "calendar-show-schedule-web"
                              );
                            }}
                            className={`flex flex-col w-[288px] border-l-[6px] border-solid rounded-[6px] ${colorClass} ${bgClass} bg-opacity-10 plr-16 ptb-12 hover:border hover:border-l-[6px]`}
                          >
                            <p className="h-[24px] flex flex-row items-center gap-[6px] mb-[4px]">
                              <span className="text-B16B -text--color-neutral100 ">
                                {i.calender_name}
                              </span>
                              <span className="text-B14M -text--color-neutral100 ">
                                {i.calender_phone.replace(
                                  /(\d{3})(\d{4})(\d{4})/,
                                  "$1-$2-$3"
                                )}
                              </span>
                            </p>
                            <p className="h-[24px] flex flex-row items-center text-B14R mb-[6px] -text--color-neutral50">
                              <img
                                src={position_icon}
                                alt=""
                                className="mr-[4px]"
                              />

                              <span className="">
                                {i.calender_category === "서울사무소"
                                  ? i.calender_category
                                  : i.calender_category === "여주전시관"
                                  ? i.calender_category
                                  : i.calender_category === "여주공장"
                                  ? i.calender_category
                                  : i.calender_category === "제주전시관"
                                  ? i.calender_category
                                  : i.calender_location}
                              </span>
                            </p>
                            <p className="h-[24px] flex flex-row items-center text-B14R mb-[6px] -text--color-neutral50">
                              <span className="_sprite_ _sprite_7_8_ mr-[4px]"></span>
                              <span>
                                {i.admin_list.length > 1
                                  ? `${i.admin_list[0].admin_name} +
                          ${i.admin_list.length - 1}`
                                  : i.admin_list[0].admin_name}
                              </span>
                            </p>

                            <p className="h-[24px] flex items-center text-B14R -text--color-neutral50">
                              <span className="_sprite_ _sprite_7_20_ mr-[4px]"></span>
                              <span className="w-[230px] text-ellipsis whitespace-nowrap overflow-hidden text-start">
                                {i.calender_detail === ""
                                  ? "추가메시지입니다."
                                  : i.calender_detail}
                              </span>
                            </p>
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ScheduleList;
