import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedResponseCalendar } from "@/stores/page/calendar";
import { modalInfo, modalInModalInfo } from "@/stores/common/modal";
import { showPortal } from "@/utils/func/common/modalController";

const ModalFooterFixType = () => {
  const tempData = useRecoilValue(selectedResponseCalendar);
  const [modal, setModal] = useRecoilState(modalInfo);
  const [modal2, setModal2] = useRecoilState(modalInModalInfo);

  return (
    <React.Fragment>
      {tempData.calender_check && (
        <section className="w-[100%] border-t border-solid -border--color-neutral3 flex items-center justify-end pt-[8px] pb-[12px] pr-[16px] h-[56px] gap-[8px]">
          <button
            className="w-[128px] h-[44px] rounded-[6px] -bg--color-neutral6 text-B16R"
            onClick={() => {
              // 호출 방식 수정 해야 함
              setModal2((prev) => {
                return {
                  ...prev,
                  value: "calendar-delete-schedule-web",
                  isOpen: true,
                };
              });
            }}
          >
            삭제
          </button>
          <button
            className="w-[128px] h-[44px] rounded-[6px] border border-solid -border--color-primary-main -text--color-primary-main text-B16R"
            onClick={() => {
              showPortal(setModal, true, "calendar-fix-schedule-web");
            }}
          >
            수정
          </button>
        </section>
      )}
    </React.Fragment>
  );
};

export default ModalFooterFixType;
