import React from "react";

interface propsType {
  data: any;
}

const MobileCustomerInfo = ({ data }: propsType) => {
  return (
    <article className="ptb-16 plr-16 -bg--color-neutral0 gap-[16px] flex flex-col mb-[12px]">
      <header className="-text--color-primary-main -text--header-16b font-700">
        고객 정보
      </header>
      <p className="flex flex-col gap-[4px]">
        <span className="-text--color-neutral50 -text--label-12b font-700">
          기본정보
        </span>
        <div className="flex flex-row gap-[8px]">
          <span className="-text--body-16m font-500">
            {data?.user_name ? data?.user_name : "내용없음"}
          </span>
          <span className="-text--body-16m font-500">
            {data?.user_phone ? data?.user_phone : "내용없음"}
          </span>
        </div>
        <span className="-text--body-14r font-400 -text--color-neutral50">
          {data?.user_id ? data?.user_id : "내용없음"}
        </span>
      </p>
      <p className="flex flex-col gap-[4px]">
        <span className="-text--color-neutral50 -text--label-12b font-700">
          문의유형
        </span>
        <span className="-text--body-16m font-500">
          {data?.detail_type ? data?.detail_type : "내용없음"}
        </span>
      </p>
      <p className="flex flex-col gap-[4px]">
        <span className="-text--color-neutral50 -text--label-12b font-700">
          건축 규모
        </span>
        <span className="-text--body-16m font-500">
          {data?.detail_size ? data?.detail_size : "내용없음"}
        </span>
      </p>
      <p className="flex flex-col gap-[4px]">
        <span className="-text--color-neutral50 -text--label-12b font-700">
          건축 용도
        </span>
        <span className="-text--body-16m font-500">
          {data?.detail_use ? data?.detail_use : "내용없음"}
        </span>
      </p>
    </article>
  );
};

export default MobileCustomerInfo;
