import { useRecoilState } from "recoil";
import { councelParameter } from "@/stores/common/parameter";
import { mycheckController } from "@/utils/func/common/filter";
import React from "react";

interface myCouncelButtonType {
  callback: any;
}

const MyCouncelButton = ({ callback }: myCouncelButtonType) => {
  const [parameter, setParameter] = useRecoilState(councelParameter);

  return (
    <form className="flex justify-center items-center gap-[8px] text-B16R ptb-6">
      <input
        id="my_councel"
        type="checkbox"
        name="filter_councel"
        value="my_councel"
        className="w-[18px] h-[18px] m-[2px]"
        onChange={(e) => mycheckController(e, callback)}
        checked={parameter.mycheck}
      />
      <label htmlFor="my_councel" className="-text--body-14m font-500">
        내 상담만 보기
      </label>
    </form>
  );
};

export default MyCouncelButton;
