import React from "react";

const InquiryHeader = () => {
  return (
    <div className="fixed top-[98px] w-[1128px] h-[48px] flex flex-row items-center text-B12R -text--color-neutral50 border border-solid -border--color-neutral6 -bg--color-neutral0 rounded-t-[12px] z-20 px-[16px]">
      <div className="h-[48px] flex flex-row items-center">
        <div className="w-[126px] pl-[8px] h-[48px] flex items-center text-B12R">
          <li>번호</li>
        </div>
        <div className="w-[298px] pl-[8px] h-[48px] flex items-center text-B12R">
          <li>제목</li>
        </div>
        <div className="w-[158px] pl-[8px] h-[48px] flex items-center justify-center text-B12R">
          <li>신청자</li>
        </div>
        <div className="w-[158px] pl-[8px] h-[48px] flex items-center justify-center text-B12R">
          <li>연락처</li>
        </div>
        <div className="w-[158px] pl-[8px] h-[48px] flex items-center justify-center text-B12R">
          <li>답변상태</li>
        </div>
        <div className="w-[158px] pl-[8px] h-[48px] flex items-center text-B12R">
          <li>신청일</li>
        </div>
      </div>
    </div>
  );
};

export default InquiryHeader;
