import React from "react";

import wait_icon from "@/Assets/Images/wait_icon.svg";

// react-query isloading 시 보여줄 메세지

const LoadingMsg = () => {
  return (
    <div className="z-20 -bg--color-neutral0 transform flex flex-col items-center justify-center">
      <img
        src={wait_icon}
        alt=""
        className="animate-spin mb-[16px] w-[24px] h-[24px]"
      />
      <span>처리 중입니다. 잠시만 기다려주세요.</span>
    </div>
  );
};

export default LoadingMsg;
