import React from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";

import moment from "moment";

import "moment/locale/ko";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "@/styles/calendar.css";

import { useRecoilState, useSetRecoilState } from "recoil";

import { modalInfo } from "@/stores/common/modal";
import {
  parameterCalendar,
  selectedResponseCalendar,
} from "@/stores/page/calendar";

import { showPortal } from "@/utils/func/common/modalController";

const Schedule = ({
  selectTap,
  setSelectTap,
  ctrlList,
  events,
  setScrollState,
}) => {
  const localizer = momentLocalizer(moment);

  const setTempData = useSetRecoilState(selectedResponseCalendar);

  const [parameters, setParameters] = useRecoilState(parameterCalendar);

  const setModal = useSetRecoilState(modalInfo);

  const Toolbar = (props) => {
    const { date } = props;

    const navigate = (action) => {
      props.onNavigate(action);

      if (action === "PREV") {
        handleDateChange(
          moment(date).subtract(1, "month").add(9, "hours").toDate()
        );
      } else if (action === "NEXT") {
        handleDateChange(moment(date).add(1, "month").add(9, "hours").toDate());
      }
    };

    const handleDateChange = (newDate) => {
      setParameters((prev) => ({
        ...prev,
        select_month: newDate,
      }));
    };

    return (
      <>
        <div className="h-[44px] flex items-center mb-[8px] flex-row justify-between">
          <div className="flex flex-row items-center">
            <div className="flex flex-row">
              <button
                className="w-[44px] h-[44px] flex items-center justify-center"
                onClick={navigate.bind(null, "PREV")}
              >
                <span className="_sprite_ _sprite_3_3_"></span>
              </button>
              <div className="w-[118px] h-[44px] flex items-center justify-center text-H18B -text--color-neutral100 ">
                {moment(date).format("YYYY.MM")}
              </div>
              <button
                className="w-[44px] h-[44px] flex items-center justify-center"
                onClick={navigate.bind(null, "NEXT")}
              >
                <span className="_sprite_ _sprite_3_4_"></span>
              </button>
            </div>
          </div>
          <div className="gap-[16px] flex flex-row items-center">
            <form className="h-[44px] flex justify-center items-center gap-[10px] text-B16R">
              <input
                id="my_councel"
                type="checkbox"
                name="filter_councel"
                value="my_councel"
                className="custom_checkbox"
                onChange={() => {
                  setParameters((prev) => ({
                    ...prev,
                    mycheck: !prev.mycheck,
                  }));
                }}
                checked={parameters.mycheck}
              />
              <label className="-text--color-neutral100 " htmlFor="my_councel">
                내 상담만 보기
              </label>
            </form>
            <div>
              <button
                className="h-[36px] flex flex-row items-center pt-[6px] pb-[6px] pl-[3px] pr-[3px]"
                onClick={() => setSelectTap(!selectTap)}
              >
                <span className="text-B14R -text--color-neutral100 ">
                  {parameters.category === "" ? "전체" : parameters.category}
                </span>
                <div className="w-[24px] h-[24px] flex items-center justify-center">
                  <span
                    className={`_sprite_ ${
                      selectTap ? "_sprite_4_1_" : "_sprite_4_4_"
                    }`}
                  ></span>
                </div>
              </button>
              {selectTap && (
                <div className="absolute right-0 border border-solid -border--color-neutral50 rounded-l-[6px] rounded-r-[4px] -bg--color-neutral0 -text--color-neutral100 z-10">
                  {ctrlList()}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const eventStyleGetter = (event) => {
    let backgroundColor = "#ffffff";

    if (event.calender_category === "서울사무소") {
      backgroundColor = "#fae64d32";
    } else if (event.calender_category === "여주전시관") {
      backgroundColor = "#34c75930";
    } else if (event.calender_category === "여주공장") {
      backgroundColor = "#ff3b3030";
    } else if (event.calender_category === "제주전시관") {
      backgroundColor = "#1185f230";
    } else if (event.calender_category === "기타") {
      backgroundColor = "#940de726";
    } else {
      backgroundColor = "#940de726";
    }

    const style = {
      backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
      height: "20px",
      marginBottom: "4px",
    };

    return { style };
  };

  const handleScroll = (event) => {
    setScrollState(event.calender_idx);

    const temp = event.calender_idx;

    const scrollToTop = (temp) => {
      const element = document.getElementById(temp);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToTop(temp);

    setTempData(event);

    showPortal(setModal, true, "calendar-show-schedule-web");
  };

  return (
    <div className="flex flex-col justify-between">
      <Calendar
        localizer={localizer}
        style={{ height: 960 }}
        events={events}
        eventPropGetter={eventStyleGetter}
        components={{ toolbar: Toolbar }}
        onSelectEvent={handleScroll}
        showAllEvents={true}
      />
    </div>
  );
};

export default Schedule;
