import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { tempUserResponseData } from "@/stores/common/Mobile";
import { responseData } from "@/stores/common/data";

const CouncelUserinfoMobile = () => {
  const [resultData, setResultData] = useRecoilState(responseData);

  const [tempData, setTempData] = useRecoilState(tempUserResponseData);

  const [openSizeTap, setOpenSizeTap] = useState(false);

  useEffect(() => {
    setTempData((prev) => ({
      ...prev,
      ...resultData,
    }));

    setTempData((prev) => ({
      ...prev,
      ...{
        user: {
          ...prev.user,
          process_name: resultData.process.process_name,
          important_check: resultData.process.important_check,
        },
      },
    }));
  }, []);

  // 이름 변경
  const handleUserName = (e) => {
    let inputValue = e.target.value;
    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        user_name: inputValue,
      },
    }));
  };

  // 휴대폰
  const handleUserPhone = (e) => {
    let inputValue = e.target.value;
    setTempData((prev) => ({
      ...prev,
      user: { ...prev.user, user_phone: inputValue },
    }));
  };

  const handleUserMail = (e) => {
    let inputValue = e.target.value;
    setTempData((prev) => ({
      ...prev,
      user: { ...prev.user, user_id: inputValue },
    }));
  };

  const handleProjectName = (e) => {
    let inputValue = e.target.value;
    setTempData((prev) => ({
      ...prev,
      process: { ...prev.process, process_name: inputValue },
    }));
  };

  const handleImportantCouncel = (e) => {
    setTempData((prev) => ({
      ...prev,
      process: {
        ...prev.process,
        process_important: e.target.checked,
      },
    }));
  };

  // 건축 용도
  const handleUserPurpose = (e) => {
    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        detail_use: e.target.textContent,
      },
    }));
  };

  // 건축 규모
  const handleUserSize = (e) => {
    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        detail_size: e.target.value,
      },
    }));
  };

  // 고객 적극도
  const handleUserPositive = (e) => {
    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        detail_positive: Number(e.target.value),
      },
    }));
  };

  // 고객 대출 연계
  const handleUserLoan = (e) => {
    const value = e.target.value === "true"; // 문자열 "true"를 true로 변환

    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        detail_loans: value,
      },
    }));
  };

  // 예산액 변경
  const handleUserBudget = (e) => {
    let inputValue = e.target.value;
    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        detail_price: inputValue,
      },
    }));
  };

  // 희망 연면적
  const handleUserPy = (e) => {
    let inputValue = e.target.value;
    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        detail_needs: inputValue,
      },
    }));
  };

  const handleUserContents = (e) => {
    let inputValue = e.target.value;
    setTempData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        detail_appendix: inputValue,
      },
    }));
  };

  return (
    <div className="-bg--color-neutral3 flex flex-col">
      <div className="w-full ptb-16 plr-16 -bg--color-neutral0 mb-[12px]">
        <div className="pb-[16px] -text--color-primary-main font-700 -text--body-16">
          고객 정보
        </div>
        <div className="flex flex-col gap-[16px]">
          {tempData?.user?.user_privilege === 0 && (
            <>
              <div className="flex flex-col gap-[10px]">
                <div className="-text--body-14m font-500">
                  <span>이름</span>
                  <span className="-text--color-danger-main">*</span>
                </div>
                <input
                  type="text"
                  name=""
                  value={tempData.user?.user_name}
                  className="border border-solid -border--color-neutral12 ptb-12 plr-16 rounded-[4px]"
                  placeholder="홍길동 (필수)"
                  onChange={(e) => {
                    handleUserName(e);
                  }}
                />
              </div>

              <div className="flex flex-col gap-[10px]">
                <div className="-text--body-14m font-500">
                  <span>휴대폰</span>
                  <span className="-text--color-danger-main">*</span>
                </div>
                <input
                  type="text"
                  name=""
                  value={tempData.user?.user_phone}
                  className="border border-solid -border--color-neutral12 ptb-12 plr-16 rounded-[4px]"
                  placeholder="010-0000-0000 (필수)"
                  onChange={(e) => {
                    handleUserPhone(e);
                  }}
                />
              </div>

              <div className="flex flex-col gap-[10px]">
                <div className="-text--body-14m font-500">
                  <span>이메일</span>
                </div>
                <input
                  type="text"
                  name=""
                  value={tempData.user?.user_id}
                  className="border border-solid -border--color-neutral12 ptb-12 plr-16 rounded-[4px]"
                  placeholder="example@naver.com (선택)"
                  onChange={(e) => {
                    handleUserMail(e);
                  }}
                />
              </div>
            </>
          )}
          <div className="flex flex-row gap-[10px]">
            <div className="-text--body-14m font-500">
              <span>중요 상담 여부</span>
            </div>
            <input
              type="checkbox"
              name="important-councel"
              className="custom_checkbox_rounded_18px mr-[8px]"
              checked={tempData.user?.important_check === true}
              onChange={(e) => {
                setTempData((prev) => ({
                  ...prev,
                  user: {
                    ...prev.user,
                    important_check: e.target.checked,
                  },
                }));
              }}
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className="-text--body-14m font-500">
              <span>프로젝트명</span>
            </div>
            <input
              type="text"
              name=""
              value={tempData?.process?.process_name}
              className="border border-solid -border--color-neutral12 ptb-12 plr-16 rounded-[4px]"
              placeholder="직접 입력"
              onChange={(e) => {
                handleProjectName(e);
              }}
            />
          </div>

          <div className="flex flex-col relative">
            <div className="mb-[10px] -text--body-14m font-500">
              <span>건축 용도</span>
            </div>
            <button
              className="border border-solid -border--color-neutral12 flex flex-row justify-between rounded-[4px]"
              onClick={() => setOpenSizeTap(!openSizeTap)}
            >
              <span className="inline-block plr-16 ptb-12">
                {tempData.user?.detail_use === null
                  ? "선택 안 함"
                  : tempData.user?.detail_use}
              </span>
              <div className=" inline-block ptb-12 plr-16">
                <span
                  className={`_sprite_ ${
                    openSizeTap ? "_sprite_17_1_" : "_sprite_17_2_"
                  }`}
                ></span>
              </div>
            </button>
            {/* detail_use */}
            {openSizeTap && (
              <div
                className="absolute z-20 w-full top-[92px] -bg--color-neutral0 flex flex-col ptb-8 plr-8 border border-solid -border--color-neutral6"
                onClick={(e) => {
                  handleUserPurpose(e);
                  setOpenSizeTap(false);
                }}
              >
                <li className="inline-block plr-16 ptb-12">선택 안 함</li>
                <li className="inline-block plr-16 ptb-12">단독주택</li>
                <li className="inline-block plr-16 ptb-12">기숙사</li>
                <li className="inline-block plr-16 ptb-12">타운하우스</li>
                <li className="inline-block plr-16 ptb-12">기타</li>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-[10px]">
            <div className="-text--body-14m font-500">
              <span>건축 규모</span>
              {/* detail_size */}
            </div>
            <input
              type="text"
              name=""
              value={tempData.user?.detail_size}
              className="border border-solid -border--color-neutral12 ptb-12 plr-16 rounded-[4px]"
              placeholder="직접 입력"
              onChange={(e) => {
                handleUserSize(e);
              }}
            />
          </div>
        </div>
      </div>

      <div className="w-full ptb-16 plr-16 -bg--color-neutral0">
        <div className="pb-[16px] -text--color-primary-main font-700 -text--body-16">
          상담 정보
        </div>
        <div className="flex flex-col gap-[16px]">
          <div className="flex flex-col gap-[10px]">
            <div className="-text--body-14m font-500">
              <span>고객적극도</span>
            </div>
            <div
              className="flex flex-row items-center"
              onChange={(e) => {
                handleUserPositive(e);
              }}
            >
              <input
                type="radio"
                name="user_positive"
                value="1"
                // className="mr-[8px] w-[18px] h-[18px] border"
                className="mr-[8px] w-[18px] h-[18px] -accent--color-neutral100 "
                checked={tempData.user?.detail_positive === 1}
              />
              <label for="user_positive" className="mr-[16px]">
                상
              </label>
              <input
                type="radio"
                name="user_positive"
                value="2"
                // className="mr-[8px] w-[18px] h-[18px]"
                className="mr-[8px] w-[18px] h-[18px] -accent--color-neutral100 "
                checked={tempData.user?.detail_positive === 2}
              />
              <label for="user_positive" className="mr-[16px]">
                중
              </label>
              <input
                type="radio"
                name="user_positive"
                value="3"
                // className="mr-[8px] w-[18px] h-[18px]"
                className="mr-[8px] w-[18px] h-[18px] -accent--color-neutral100 "
                checked={tempData.user?.detail_positive === 3}
              />
              <label for="user_positive" className="mr-[16px]">
                하
              </label>
            </div>
          </div>

          <div className="flex flex-col gap-[10px]">
            <div className="-text--body-14m font-500">
              <span>대출 연계</span>
            </div>
            <div
              className="flex flex-row items-center"
              onChange={(e) => {
                handleUserLoan(e);
              }}
            >
              <input
                type="radio"
                name="user_loan"
                value="true"
                className="mr-[8px] w-[18px] h-[18px] -accent--color-neutral100 "
                checked={tempData.user?.detail_loans === true}
              />
              <label for="user_loan" className="mr-[16px]">
                필요
              </label>
              <input
                type="radio"
                name="user_loan"
                value="false"
                className="mr-[8px] w-[18px] h-[18px] -accent--color-neutral100 "
                checked={tempData.user?.detail_loans === false}
              />
              <label for="user_loan" className="mr-[16px]">
                불필요
              </label>
            </div>
          </div>

          <div className="flex flex-col gap-[10px]">
            <div className="-text--body-14m font-500">
              <span>예산액 (원)</span>
            </div>
            <input
              type="text"
              name=""
              value={tempData.user?.detail_price}
              className="border border-solid -border--color-neutral12 ptb-12 plr-16 rounded-[4px]"
              placeholder="숫자로 입력"
              onChange={(e) => {
                handleUserBudget(e);
              }}
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className="-text--body-14m font-500">
              <span>희망 연면적 (평)</span>
            </div>
            <input
              type="text"
              name=""
              value={tempData.user?.detail_needs}
              className="border border-solid -border--color-neutral12 ptb-12 plr-16 rounded-[4px]"
              placeholder="숫자로 입력"
              onChange={(e) => {
                handleUserPy(e);
              }}
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className="flex flex-row justify-between -text--body-14m font-500">
              <span>접수 내용</span>
              <span className="-text--body-14r font-400 -text--color-neutral50">
                {tempData.user?.detail_appendix === null
                  ? 0
                  : tempData.user?.detail_appendix.length}
                /600
              </span>
            </div>

            <textarea
              className="custom-scrollbar resize-none focus:outline-none min-h[86px] ptb-12 plr-16 overflow-auto border border-solid -border--color-neutral12 rounded-[4px]"
              placeholder="추가 정보를 입력"
              autoComplete="off"
              value={tempData.user?.detail_appendix}
              onChange={(e) => {
                handleUserContents(e);
              }}
            ></textarea>
          </div>
        </div>
      </div>

      <div className="h-[65px]"></div>
    </div>
  );
};

export default CouncelUserinfoMobile;
