import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { clickedPage } from "@/stores/common/Pagination";
import { inquiryParameter } from "@/stores/common/parameter";
import { filterController, searchController } from "@/utils/func/common/filter";

const InquiryFilter = (props) => {
  const { data } = props;

  const [parameter, setParameter] = useRecoilState(inquiryParameter);

  const [, setSelectPage] = useRecoilState(clickedPage);

  const [categoriesValue, setCategoriesValue] = useState({
    service: null,
    product: null,
    construct: null,
  });

  useEffect(() => {
    function handleCategory(value) {
      let categoryType;

      if (value === "service") {
        categoryType = 1;
      } else if (value === "product") {
        categoryType = 2;
      } else if (value === "construct") {
        categoryType = 3;
      }

      const handleCategoryObj = data?.data.data.count.find(
        (obj) => obj.question_category === categoryType
      );

      const categoryValue = handleCategoryObj
        ? handleCategoryObj.question_count
        : null;

      setCategoriesValue((prev) => ({
        ...prev,
        [value]: categoryValue,
      }));
    }

    handleCategory("service");
    handleCategory("product");
    handleCategory("construct");
  }, [data]);

  const handleCategoryValue = (val) => {
    filterController({ value: "inq", e: val, callback: setParameter });
  };

  return (
    <React.Fragment>
      <div className="fixed top-[0px] w-[1128px] h-[24px] flex items-center justify-between z-[3] -bg--color-neutral0"></div>
      <div className="fixed top-[24px] w-[1128px] h-[50px] flex items-center justify-between z-[3] -bg--color-neutral0">
        <div className="h-[44px] flex flex-row items-center gap-[16px]">
          <li
            className={
              parameter.category === 1
                ? "h-[44px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : "h-[44px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => handleCategoryValue(1)}
          >
            <p className="text-H18B flex flex-row gap-[8px] items-center">
              <span className="mr-[4px]">서비스</span>
              {categoriesValue.service && (
                <span
                  className={`-text--color-primary-main text-B12B px-[6px] py-[2px] rounded-full -bg--color-primary-surface`}
                >
                  {categoriesValue.service}
                </span>
              )}
            </p>
          </li>
          <li
            className={
              parameter.category === 2
                ? "h-[44px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : "h-[44px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => handleCategoryValue(2)}
          >
            <p className="text-H18B flex flex-row gap-[8px] items-center">
              <span className="mr-[4px]">제품</span>
              {categoriesValue.product && (
                <span
                  className={`-text--color-primary-main text-B12B px-[6px] py-[2px] rounded-full -bg--color-primary-surface`}
                >
                  {categoriesValue.product}
                </span>
              )}
            </p>
          </li>
          <li
            className={
              parameter.category === 3
                ? "h-[44px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : "h-[44px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => handleCategoryValue(3)}
          >
            <p className="text-H18B flex flex-row gap-[8px] items-center">
              <span className="mr-[4px]">건축</span>
              {categoriesValue.construct && (
                <span
                  className={`-text--color-primary-main text-B12B px-[6px] py-[2px] rounded-full -bg--color-primary-surface`}
                >
                  {categoriesValue.construct}
                </span>
              )}
            </p>
          </li>
        </div>
        <div className="relative w-[335px] h-[50px]">
          <input
            type="textarea"
            defaultValue=""
            placeholder="제목 또는 내용 검색"
            className="border border-solid -border--color-neutral12 rounded-full w-[335px] h-[50px] -placeholder--color-neutral20 focus:outline-none placeholder:text-B16R py-[15px] pl-[16px]"
            onChange={(e) => {
              setSelectPage(1);
              searchController(e, setParameter);
            }}
          />
          <button className="absolute right-0 pr-[16px] h-full">
            <span className="_sprite_ _sprite_7_13_"></span>
          </button>
        </div>
      </div>
      <div className="fixed w-[1128px] h-[24px] top-[74px] -bg--color-neutral0 z-30 "></div>
    </React.Fragment>
  );
};

export default InquiryFilter;
