import React from "react";

import moment from "moment";

import { regexPhoneNumber } from "@/utils/func/common/regExpression";

interface propsTypeCommon {
  callback: (arg: any) => void;
}

interface propsTypeToDashboard extends propsTypeCommon {
  data: {
    idx: number;
    process_idx: number;
    process_name: string;
    process_type: number;
    user_idx: number;
    user_name: string;
    user_phone: string;
  };
}

interface propsTypeToCouncel extends propsTypeCommon {
  data: {
    address_idx: number;
    address_idx_list: string[];
    address_jibun: string;
    admin_name: null | string;
    detail_size: null | string;
    detail_type: null | number;
    detail_use: null | string;
    important_check: null | boolean;
    process_check: number;
    process_datetime: string;
    process_idx: number;
    process_name: string;
    process_type: number;
    user_idx: number;
    user_name: string;
    user_phone: string;
  };
}

type mixedPropsType = propsTypeToCouncel | propsTypeToDashboard;

const MobileTypeOne = ({ data, callback }: mixedPropsType) => {
  return (
    <button
      className="pt-[14px] pb-[12px] px-[12px] w-full"
      style={{
        boxShadow: "0px 3px 8px 0px rgba(108, 108, 108, 0.12)",
      }}
      onClick={() => {
        callback(`councel/detail/${data.user_idx}/${data.process_idx}`);
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <span
            className={`block rounded-[4px] py-[4px] px-[6px] -text--color-neutral0 text-B12B mr-[8px] ${
              data.process_type === 1 && "-bg--color-etc-1 "
            } ${data.process_type === 2 && "-bg--color-etc-2 "}
                    ${data.process_type === 3 && "-bg--color-etc-3 "}
                    `}
          >
            {data.process_type === 1 && "상담"}
            {data.process_type === 2 && "계약"}
            {data.process_type === 3 && "공사"}
          </span>
          <span className="mr-[4px] text-B14B">담당자</span>
          <span className={`text-B14R ${"-text--color-danger-main"}`}>
            미배정
          </span>
        </div>
        {"process_check" in data && (
          <div
            className={`py-[6px] px-[10px] text-B12B rounded-[999px] ${
              data.process_check === 0 &&
              "-bg--color-primary-surface -text--color-primary-main"
            } ${
              data.process_check === 1 &&
              "-bg--color-success-surface -text--color-success-main"
            } ${
              data.process_check === 2 &&
              "-bg--color-neutral6 -text--color-neutral50"
            } ${
              data.process_check === 9 &&
              "-bg--color-danger-surface -text--color-danger-main"
            }`}
          >
            <span>{data.process_check === 0 && "상담대기"}</span>
            <span>{data.process_check === 1 && "상담 중"}</span>
            <span>{data.process_check === 2 && "상담완료"}</span>
            <span>{data.process_check === 9 && "상담취소"}</span>
          </div>
        )}
      </div>
      <div>
        <span className="flex -text--body-16m font-500">
          {data.process_name}
        </span>
      </div>
      <div className="flex flex-col items-start">
        <div className="-text--color-neutral50 -text--body-14r font-400 flex gap-[4px]">
          <span>{data.user_name === null ? "이름없음" : data.user_name}</span>
          <span>
            {data.user_phone === null
              ? "연락처없음"
              : regexPhoneNumber(data.user_phone)}
          </span>
          {"detail_use" in data && <span>{data.detail_use}</span>}
          {"detail_size" in data && <span>{data.detail_size}</span>}
        </div>
        {"process_datetime" in data && (
          <span className="-text--color-neutral50 text-B12R">
            {moment(data.process_datetime)
              .add(9, "hours")
              .format("YYYY.MM.DD HH:mm:ss")}
          </span>
        )}
      </div>
    </button>
  );
};

export default MobileTypeOne;
