import React from "react";

const CalendarDeleteScheduleWeb = () => {
  return (
    <section className="flex flex-col px-[16px] py-[24px] items-center justify-center">
      <span className="text-H21B mb-[8px] -text--color-neutral100 ">
        삭제하시겠습니까?
      </span>
      <span className="text-B16R">일정을 삭제하면 복구가 불가능합니다</span>
    </section>
  );
};

export default CalendarDeleteScheduleWeb;
