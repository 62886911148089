import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { modalInModalInfo } from "@/stores/common/modal";
import {
  calendarAttendee,
  defaultCalendarData,
  selectedResponseCalendar,
} from "@/stores/page/calendar";

import cancel_attendee from "@/Assets/Images/cancel_attendee.svg";

import {
  calendarCategoryList,
  ctrlEndDatePicker,
  ctrlStartDatePicker,
} from "@/utils/func/page/schedule";
import http from "@/utils/api/http/http";
import moment from "moment";

const CalendarFixScheduleWeb = () => {
  // 하나로 묶기
  const [selectBox, setSelectBox] = useState({
    category: false,
    startDate: false,
    endDate: false,
  });

  const [selectedData, setSelectedData] = useRecoilState(
    selectedResponseCalendar
  );

  const [emptyData, setEmptyData] = useRecoilState(defaultCalendarData);

  const [modal2, setModal2] = useRecoilState(modalInModalInfo);

  useEffect(() => {
    setEmptyData(selectedData);
  }, []);

  let colorClass;
  if (emptyData.calender_category === "서울사무소") {
    colorClass = "-bg--color-office-seoul";
  } else if (emptyData.calender_category === "여주전시관") {
    colorClass = "-bg--color-showroom-yeoju";
  } else if (emptyData.calender_category === "여주공장") {
    colorClass = "-bg--color-factory-yeoju";
  } else if (emptyData.calender_category === "제주전시관") {
    colorClass = "-bg--color-showroom-jeju";
  } else if (emptyData.calender_category === "기타") {
    colorClass = "-bg--color-etc";
  } else {
    colorClass = "-bg--color-etc";
  }

  // 회원 검색 시 고객정보
  // 분류 기타일 시 카카오 api를 통해 주소 리스트 받아오는 용도
  const [tempAddress, setTempAddress] = useState("");
  // 참석자 정보 체크한 것들만
  const [tempAttendee, setTempAttendee] = useRecoilState(calendarAttendee);

  // 카카오 주소받아오는 api
  async function getAddressData(e) {
    let config = {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API}`,
      },
    };

    let params = {
      query: e.target.value,
    };

    if (e.target.value.length > 0) {
      try {
        await http.get(`/kakao`, { params }, config).then((res) => {
          setTempAddress(res.data.documents);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  return (
    <>
      <div className="overflow-y-auto max-h-[calc(100vh-216px+60px+27px-85px-165px)] custom-scrollbar w-full">
        {/* 분류 부분 */}
        <div className="border-b border-solid -border--color-neutral6 ptb-16 plr-32 flex flex-row items-center relative">
          <p className="mr-[16px]">
            <span className="text-B16B">분류 </span>
            <span className="-text--color-danger-main">*</span>
          </p>
          <button
            className="h-[36px] plr-3 flex flex-row items-center"
            onClick={() =>
              setSelectBox((prev) => {
                return {
                  ...prev,
                  category: !prev.category,
                };
              })
            }
          >
            <div
              className={`w-[20px] h-[20px] rounded-[4px] ${colorClass} mr-[8px]`}
            ></div>
            <span className="flex text-B14R -text--color-neutral100 mr-[8px] items-center">
              {emptyData.calender_category}
            </span>
            <span
              className={`_sprite_ ${
                selectBox.category ? "_sprite_4_1_" : "_sprite_4_4_"
              }`}
            ></span>
          </button>
          {selectBox.category && (
            <div className="absolute top-[55px] left-[80px] border border-solid -border--color-neutral50 rounded-l-[6px] rounded-r-[4px] -bg--color-neutral0 -text--color-neutral100 z-10">
              {calendarCategoryList(setEmptyData, setSelectBox)}
            </div>
          )}
          {emptyData.calender_category === "기타" && (
            <div>
              <input
                type="text"
                className="w-[378px] h-[36px] border-b border-solid -border--color-neutral50 pt-[6px] pb-[6px]"
                onChange={(e) => {
                  setEmptyData((prev) => {
                    return {
                      ...prev,
                      calender_location: e.target.value,
                    };
                  });
                  getAddressData(e);
                }}
                value={emptyData.calender_location}
              />
              {tempAddress && (
                <div className="absolute border border-solid -border--color-neutral50 w-[378px] -bg--color-neutral0 max-h-[248px] overflow-y-auto custom-scrollbar z-10">
                  {tempAddress &&
                    tempAddress.map((idx) => {
                      return (
                        <>
                          <div
                            className="ptb-12 pl-[16px] hover:-bg--color-neutral3"
                            onClick={() => {
                              setEmptyData((prev) => {
                                return {
                                  ...prev,
                                  calender_location: idx.address_name,
                                };
                              });
                              setTempAddress("");
                            }}
                          >
                            {idx.address_name}
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
            </div>
          )}
        </div>
        {/* 일정 부분 */}
        <div className="border-b border-solid -border--color-neutral6 ptb-16 plr-32 flex flex-col justify-center">
          <p className="mb-[16px]">
            <span className="text-B16B">일정 </span>
            <span className="-text--color-danger-main">*</span>
          </p>
          <div className="flex flex-row justify-between">
            <div className="gap-[4px] relative">
              <span className="text-B14M">시작일</span>
              <button
                className="mt-[4px] w-[260px] h-[48px] border border-solid -border--color-neutral50 rounded-[6px] ptb-12 plr-16 flex flex-row items-center justify-between"
                onClick={() => {
                  setSelectBox((prev) => {
                    return { ...prev, startDate: !prev.startDate };
                  });
                }}
              >
                <span>
                  {moment(emptyData?.calender_start).format(
                    "YYYY-MM-DD (dd) hh:mm"
                  )}
                </span>
                <span
                  className={`_sprite_ ${
                    selectBox.startDate ? "_sprite_4_1_" : "_sprite_4_4_"
                  }`}
                ></span>
              </button>
              {selectBox.startDate && (
                <div className="absolute mt-[4px] w-[324px] border border-solid -border--color-neutral50 rounded-l-[6px] rounded-r-[4px] -bg--color-neutral0 -text--color-neutral100 ">
                  {ctrlStartDatePicker({
                    emptyData,
                    setEmptyData,
                  })}
                </div>
              )}
            </div>
            <div className="gap-[4px] relative">
              <span className="text-B14M">마감일</span>
              <button
                className="mt-[4px] w-[260px] h-[48px] border border-solid -border--color-neutral50 rounded-[6px] ptb-12 plr-16 flex flex-row items-center justify-between"
                onClick={() => {
                  setSelectBox((prev) => {
                    return { ...prev, endDate: !prev.endDate };
                  });
                }}
              >
                <span>
                  {moment(emptyData?.calender_end).format(
                    "YYYY-MM-DD (dd) hh:mm"
                  )}
                </span>
                <span
                  className={`_sprite_ ${
                    selectBox.endDate ? "_sprite_4_1_" : "_sprite_4_4_"
                  }`}
                ></span>
              </button>
              {selectBox.endDate && (
                <div className="absolute right-0 mt-[4px] w-[324px] border border-solid -border--color-neutral50 rounded-l-[6px] rounded-r-[4px] -bg--color-neutral0 -text--color-neutral100 ">
                  {ctrlEndDatePicker({
                    emptyData,
                    setEmptyData,
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* 고객 정보 */}
        <div className="border-b border-solid -border--color-neutral6 ptb-16 plr-32 flex flex-col justify-center gap-[16px]">
          <p className="text-B16B">고객 정보</p>
          <button
            className="ptb-14 -bg--color-neutral6 rounded-[6px]"
            onClick={() => {
              setModal2((prev) => {
                return {
                  ...prev,
                  value: "calendar-searchuser-web",
                  isOpen: true,
                };
              });
            }}
          >
            <span className="-text--color-neutral50">회원 검색하기</span>
          </button>
          <div className="flex flex-row justify-between gap-[16px]">
            <div className="flex flex-col w-[260px]">
              <span className="mb-[4px]">이름</span>
              <input
                className="outline-none w-[260px] h-[48px] plr-16 ptb-12 box-border border border-solid -border--color-neutral50 rounded-[6px] text-B16R placeholder:text-B16R placeholder:-text--color-neutral50 focus:-border--color-primary-main"
                type="text"
                placeholder="예) 홍길동"
                onChange={(e) => {
                  setEmptyData((prev) => {
                    return {
                      ...prev,
                      calender_name: e.target.value,
                    };
                  });
                }}
                value={emptyData.calender_name}
              />
            </div>
            <div className="flex flex-col w-[260px] h-[70px]">
              <span className="mb-[4px]">휴대폰</span>
              <input
                className="outline-none w-[260px] h-[48px] plr-16 ptb-12 box-border border border-solid -border--color-neutral50 rounded-[6px] text-B16R placeholder:text-B16R placeholder:-text--color-neutral50 focus:-border--color-primary-main"
                type="text"
                placeholder="010-0000-0000"
                onChange={(e) => {
                  setEmptyData((prev) => {
                    return { ...prev, calender_phone: e.target.value };
                  });
                }}
                value={emptyData && emptyData.calender_phone}
              />
            </div>
          </div>
        </div>
        {/* 참석자 */}
        <div className="border-b border-solid -border--color-neutral6 ptb-16 plr-32 flex flex-col justify-center gap-[16px] overflow-x-auto custom-scrollbar">
          <span className="text-B16B">참석자</span>
          <div className="flex flex-row  max-w-[535px]">
            <div>
              <button
                className="w-[44px] h-[44px] flex items-center justify-center mr-[8px]"
                onClick={() => {
                  setModal2((prev) => {
                    return {
                      ...prev,
                      value: "calendar-search-attendee-web",
                      isOpen: true,
                    };
                  });
                }}
              >
                <span className="_sprite_ _sprite_11_8_"></span>
              </button>
            </div>
            <div className="flex flex-row gap-[8px]">
              {/* 여기 수정 */}
              {emptyData?.admin_list?.map((idx, num) => {
                return (
                  <React.Fragment key={num}>
                    <button
                      className="flex flex-row h-[44px] w-[133px] border border-solid -border--color-neutral50 rounded-[30px] items-center justify-around"
                      onClick={() => {
                        const updatedAttendees = emptyData?.admin_list.filter(
                          (item) => item.admin_idx !== idx.admin_idx
                        );
                        setEmptyData((prev) => {
                          return {
                            ...prev,
                            admin_list: updatedAttendees,
                          };
                        });
                        setTempAttendee(updatedAttendees);
                      }}
                    >
                      <span>{idx.admin_name}</span>
                      <img src={cancel_attendee} alt="" />
                    </button>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        {/* 추가 메시지 */}
        <div className="ptb-16 plr-32 flex flex-col justify-center gap-[16px]">
          <span className="text-B16B">추가 메시지</span>
          <textarea
            className="custom-scrollbar resize-none focus:outline-none h-[204px] -bg--color-neutral3 pl-[16px] pt-[12px] pr-[8px] pb-[12px] overflow-auto text-B16R -text--color-neutral100 placeholder:-text--color-neutral50 rounded-tl-[6px] rounded-tr-[4px] rounded-br-[4px] rounded-bl-[6px]"
            placeholder="추가 정보를 입력"
            autoComplete="off"
            onChange={(e) => {
              setEmptyData((prev) => {
                return {
                  ...prev,
                  calender_detail: e.target.value,
                };
              });
            }}
            value={emptyData.calender_detail}
          ></textarea>
          <span className="flex flex-row justify-end -text--color-neutral50 text-B14R"></span>
        </div>
      </div>
    </>
  );
};

export default CalendarFixScheduleWeb;
