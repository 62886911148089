import axios from "@/utils/api/default/default";

/**
 * @description 로그인
 *
 * @param {string} username - 아이디
 * @param {string} userpassword - 비밀번호
 * @param {function} setSuccessLogin - 로그인 상태 변경 함수
 * @param {object} location - 현재 페이지 정보
 **/
export const login = async (props) => {
  const { username, userpassword, setIsLogin, location, queryParams } = props;

  if (queryParams === undefined) {
    try {
      const res = await axios.post(`/api/jwt/login`, {
        admin_id: username,
        admin_pw: userpassword,
      });

      localStorage.setItem("admin_idx", res.data.data.admin_idx);
      localStorage.setItem("admin_name", res.data.data.admin_name);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      localStorage.setItem("access_token", res.data.access_token);

      return (window.location.href =
        location.pathname !== "/" ? location.pathname : "/dashboard");
    } catch (err) {
      console.error(err);
      return setIsLogin(false);
    }
  } else {
    try {
      const res = await axios.post(`/api/jwt/login`, {
        admin_id: username,
        admin_pw: userpassword,
      });

      localStorage.setItem("admin_idx", res.data.data.admin_idx);
      localStorage.setItem("admin_name", res.data.data.admin_name);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      localStorage.setItem("access_token", res.data.access_token);

      return (window.location.href = `${queryParams}`);
    } catch (err) {
      console.error(err);
      return setIsLogin(false);
    }
  }
};

/**
 * @description 로그아웃
 */
export const logout = () => {
  localStorage.removeItem("admin_name");
  localStorage.removeItem("refresh_token");

  localStorage.removeItem("persistAtom");
  localStorage.removeItem("access_token");

  window.location.href = "/";
};

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");

  const config = {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
    retry: true,
  };

  return new Promise((resolve) => {
    axios
      .get(`/api/jwt/refresh`, config)
      .then((response) => {
        const access_token = response.data["access_token"];
        const refresh_token = response.data["refresh_token"];

        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("access_token", access_token);

        resolve();
      })
      .catch((err) => {
        console.error(err);
        alert("로그인 세션이 만료되었습니다. 재로그인 해주세요.");

        localStorage.removeItem("admin_name");
        localStorage.removeItem("refresh_token");

        localStorage.removeItem("persistAtom");
        localStorage.removeItem("access_token");

        window.location.href = "/";
      });
  });
};

/**
 *
 * @description 비밀번호 변경
 *
 * @param {string} password - 변경할 비밀번호
 * @param {string} passwordConfirm - 변경할 비밀번호 확인
 * @param {function} setModal - 모달 상태 변경 함수
 **/
export const changePassword = async ({ password, setModal }) => {
  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=*])[A-Za-z\d@#$%^&+=*]{8,}$/;

  if (password.value !== password.confirmValue) {
    alert("입력하신 비밀번호가 일치하지 않습니다");

    return "error";
  } else if (
    !regex.test(password.value) &&
    !regex.test(password.confirmValue)
  ) {
    alert("비밀번호 양식 준수해주세요");
    return "error";
  } else {
    try {
      const response = await axios.put(`/api/jwt/passwd`, {
        admin_pw: password.confirmValue,
      });

      alert("비밀번호 변경 완료되었습니다");
      setModal(false);

      return response;
    } catch (err) {
      console.error(err);
    }
  }
};

/**
 *
 * @description 인증 확인
 **/
export const checkAthenticated = () => {
  return !!localStorage.getItem("access_token");
};
