import React from "react";

import { useNavigate, Outlet } from "react-router-dom";

import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import { device } from "@/stores/common/index";
import { navValue } from "@/stores/common/navigation";
import { modalInfo } from "@/stores/common/modal";
import { clickedPage } from "@/stores/common/Pagination";
import { responseData } from "@/stores/common/data";
import { councelParameter } from "@/stores/common/parameter";

import { showPortal } from "@/utils/func/common/modalController";
import { logout } from "@/utils/api/auth/auth";

import "@/index.css";
import { editorState } from "@/stores/page/councelDetail";

const SideBar = () => {
  const navigate = useNavigate();

  const [navState, setNavState] = useRecoilState(navValue);

  const [councelParamsState, setCouncelParamsState] =
    useRecoilState(councelParameter);

  const resetCouncelParamsState = useResetRecoilState(councelParameter);

  const setData = useSetRecoilState(responseData);

  const [, setSelectPage] = useRecoilState(clickedPage);
  const resetSelectPage = useResetRecoilState(clickedPage);

  const currentDevice = useRecoilValue(device);

  const [, setModal] = useRecoilState(modalInfo);

  const [state, _] = useRecoilState(editorState);

  /**
   * 네브바 클릭 이벤트 / state 값 참조하여 예외 처리
   * 이벤트 발생 시 selectPage 초기화, councel param 값 초기화
   *
   * @param {*} e  값에 따라 라우팅 변경
   * @return router 또는 router + parameter 변경
   */
  function handleNavState(e: number | string | undefined) {
    if (state === "fix" || state === "create") {
      showPortal(setModal, true, "alert-texteditor-case");
    } else {
      resetSelectPage();

      if (e === 0) {
        setNavState(0);
        navigate("/inquiry");
      } else if (e === 1) {
        setNavState(1);
        navigate("/councel");

        resetCouncelParamsState();

        setSelectPage(1);

        setData("");
      } else if (e === 2) {
        setNavState(2);
        navigate("/calendar");
        setData("");
      } else if (e === 3) {
        setNavState(3);
        navigate("/dashboard");
        setData("");
      } else {
        navigate("/councel");

        if (e === "important") {
          resetCouncelParamsState();
          setCouncelParamsState((prev) => {
            return {
              ...prev,
              important_check: true,
              pageno: 1,
            };
          });
          setSelectPage(1);
        } else {
          resetCouncelParamsState();
          setCouncelParamsState((prev) => {
            return { ...prev, councel_type: e, pageno: 1 };
          });
          setSelectPage(1);
        }
      }
    }
  }

  let sidebarMenuList = [
    { title: "대시보드", menu_idx: 3 },
    { title: "고객문의", menu_idx: 0 },
    { title: "상담노트", menu_idx: 1 },
    { title: "캘린더", menu_idx: 2 },
  ];

  let sidebarMenuCouncelList = [
    { title: "전체", detail_idx: undefined },
    { title: "★ 중요", detail_idx: "important" },
    { title: "1차 - 상담", detail_idx: "1" },
    { title: "2차 - 계약", detail_idx: "2" },
    { title: "3차 - 공사", detail_idx: "3" },
  ];

  return (
    <>
      {currentDevice === "web" && (
        <aside
          className="fixed left-0 top-[0px] w-[248px] h-[calc(100%)] flex flex-col -bg--color-neutral3 text-B16R z-[2]"
          style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}
        >
          <button
            className="pl-[24px] py-[20px] w-full flex flex-row items-start gap-[4px] cursor-pointer"
            onClick={() => handleNavState(3)}
          >
            <span className="cursor-pointer -text--color-primary-main md:text-H24B text-H21B">
              BUILD SIMPLY
            </span>
            <span className="cursor-pointer -text--color-neutral20 text-B10B">
              ADMIN
            </span>
          </button>
          <div className="pl-[24px] py-[14px] text-H18B pointer-events-none">
            {localStorage.getItem("admin_name")}
          </div>
          <div className="flex items-center justify-center py-[8px]">
            <li className="inline-block border border-solid -border--color-neutral6 w-[calc(100%-16px)] mb-[2px]"></li>
          </div>
          {sidebarMenuList.map((idx, num) => {
            const selected = idx.menu_idx === navState;

            return (
              <React.Fragment key={num}>
                <div
                  className={`relative flex flex-row w-[calc(100%-16px)] ml-[8px] px-[24px] py-[13px] text-H18B gap-[16px] -text--color-neutral50 cursor-pointer
                ${
                  selected &&
                  "-bg--color-primary-surface -text--color-primary-main"
                }`}
                  onClick={() => {
                    if (selected) {
                      return null;
                    } else {
                      handleNavState(idx.menu_idx);
                    }
                  }}
                >
                  <span
                    className={`_sprite_ 
                  ${idx.menu_idx === 3 && "_sprite_7_16_"}
                  ${idx.menu_idx === 3 && selected && "_sprite_16_2_"}
                  ${idx.menu_idx === 0 && "_sprite_7_17_"}
                  ${idx.menu_idx === 0 && selected && "_sprite_16_3_"}
                  ${idx.menu_idx === 1 && "_sprite_7_20_"} 
                  ${idx.menu_idx === 1 && selected && "_sprite_16_8_"}
                  ${idx.menu_idx === 2 && "_sprite_7_21_"}
                  ${idx.menu_idx === 2 && selected && "_sprite_16_5_"}`}
                  ></span>
                  <span>{idx.title}</span>
                  {idx.menu_idx === 1 && (
                    <span
                      className={`absolute right-0 _sprite_ ${
                        selected ? "_sprite_17_7_" : "_sprite_4_4_"
                      }`}
                    ></span>
                  )}
                </div>
                {idx.menu_idx === 1 && selected && (
                  <div
                    className={`flex flex-col ${
                      idx.menu_idx === 1 && selected
                        ? "animate_slide_down"
                        : "animate_slide_up"
                    }`}
                  >
                    {sidebarMenuCouncelList.map((idx, num) => {
                      let selected;

                      if (idx?.detail_idx === "important") {
                        selected = councelParamsState?.important_check === true;
                      } else if (idx?.detail_idx === undefined) {
                        selected =
                          councelParamsState?.important_check === true
                            ? false
                            : idx?.detail_idx ===
                              councelParamsState?.councel_type;
                      } else {
                        selected =
                          idx?.detail_idx === councelParamsState?.councel_type;
                      }

                      return (
                        <div
                          className={`flex flex-row relative w-[calc(100%-16px)] ml-[8px] px-[24px] py-[13px] text-B16M gap-[16px] -text--color-neutral50 cursor-pointer ${
                            selected && "-text--color-primary-main"
                          }`}
                          onClick={() => {
                            handleNavState(idx.detail_idx);
                          }}
                          key={num}
                        >
                          <span
                            className={`_sprite_ _sprite_4_3_ ${
                              selected && "_sprite_17_9_"
                            }`}
                          ></span>
                          <span>{idx.title}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            );
          })}
          <section className="absolute bottom-0 w-full pb-[20px]">
            <div
              className={`relative flex flex-row items-center w-[calc(100%-16px)] ml-[8px] px-[24px] py-[13px] text-H18B gap-[16px] -text--color-neutral50 mb-[4px] cursor-pointer`}
              onClick={() => {
                showPortal(setModal, true, "entity-password-web");
              }}
            >
              <span className="_sprite_ _sprite_7_19_"></span>
              <span>정보 수정</span>
            </div>
            <div
              className={`relative flex flex-row items-center w-[calc(100%-16px)] ml-[8px] px-[24px] py-[13px] text-H18B gap-[16px] -text--color-neutral50 mb-[4px] cursor-pointer`}
              onClick={() => {
                logout();
              }}
            >
              <span className="_sprite_ _sprite_7_11_"></span>
              <span>로그아웃</span>
            </div>
          </section>
        </aside>
      )}
      {<Outlet />}
    </>
  );
};

export default SideBar;
