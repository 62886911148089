import React, { useEffect, useRef } from "react";

import { useParams } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";

import { closePortal } from "@/utils/func/common/modalController";

import UserInfo from "@/components/councelDetail/UserInfo";
import CouncelInfo from "@/components/councelDetail/CouncelInfo";
import CouncelList from "@/components/councelDetail/CouncelList";
import TextEditor from "@/components/councelDetail/TextEditor";
import Comment from "@/components/councelDetail/Comment";
import FileUpload from "@/components/councelDetail/FileUpload";

import "@/styles/makePdf.css";

import PdfHeader from "@/template/pdf/PdfHeader";
import PdfBodyInfo from "@/template/pdf/PdfBodyInfo";
import PdfBodyContent from "@/template/pdf/PdfBodyContent";

import { additionalResponseData, responseData } from "@/stores/common/data";
import { modalInfo, tempReferVal } from "@/stores/common/modal";
import {
  councelNoteAddressIdx,
  selectedDetailCouncelNote,
} from "@/stores/page/councelDetail";
import { urlParameterCouncel } from "@/stores/page/councelDetail";
import {
  getCouncelDetailList,
  getCouncelDetailResponse,
} from "@/utils/api/pages/councelDetail";

const CouncelDetail = () => {
  const refHeader = useRef();
  const refBody1 = useRef();
  const refBody2 = useRef();

  const id = useParams();

  const setUrlId = useSetRecoilState(urlParameterCouncel);

  const [, setModal] = useRecoilState(modalInfo);

  // pnu 리스트 식별 정보
  const [, setAddressIdxList] = useRecoilState(councelNoteAddressIdx);

  // 상담 관련 데이터
  const [additionalData, setAdditionalData] = useRecoilState(
    additionalResponseData
  );

  // 상담 제외 나머지 데이터
  const [data, setData] = useRecoilState(responseData);

  // 고객 정보 수정 시 기존 값 호출하여 담고, 변경된 값 적용 전에 데이터 담는 상태
  const [, setReferVal] = useRecoilState(tempReferVal);

  const [, setSelectedCard] = useRecoilState(selectedDetailCouncelNote);

  /**
   * @description getCouncelDetailList api를 통해 값을 받아온 후 getCouncelDetailResponse 파라미터로 전달
   *
   * @param id 특정 상담 id 값
   * @param address_idx_list 특정 상담의 부지 정보
   */
  useEffect(() => {
    setUrlId(id);

    async function getCouncelDetail() {
      try {
        const getCouncelListPromise = await getCouncelDetailList(id);

        setAddressIdxList(getCouncelListPromise.data.data.address_idx_list);

        const getCouncelDetailData = await getCouncelDetailResponse(
          id,
          getCouncelListPromise.data.data.address_idx_list
        );

        setAdditionalData({
          councel: [...getCouncelListPromise.data.data.councel],
        });

        setData(getCouncelDetailData.data.data);

        if (
          getCouncelListPromise.data.data.councel.length === 0 &&
          getCouncelDetailData.data.data.user.detail_appendix
        ) {
          setSelectedCard({
            additional_councel: "user_info",
            councel_datetime:
              getCouncelDetailData.data.data?.process?.process_datetime,
            councel_detail:
              getCouncelDetailData.data.data?.user?.detail_appendix,
          });
        } else if (
          getCouncelListPromise.data.data.councel.length === 0 &&
          !getCouncelDetailData.data.data.user.detail_appendix
        ) {
          setSelectedCard("");
        } else {
          setSelectedCard(getCouncelListPromise.data.data.councel[0]);
        }

        setReferVal((prev) => ({
          ...prev,
          ...getCouncelDetailData.data.data.user,
        }));

        setReferVal((prev) => ({
          ...prev,
          ...{
            process_name: getCouncelDetailData.data.data.process.process_name,
          },
        }));

        setReferVal((prev) => ({
          ...prev,
          ...{
            important_check:
              getCouncelDetailData.data.data.process.important_check,
          },
        }));

        if (getCouncelDetailData.status === 200) {
          closePortal(setModal);
        }
      } catch (err) {
        console.error(err);
      }
    }

    getCouncelDetail();
  }, []);

  return (
    <>
      <div className="custom-scrollbar relative w-full h-full flex flex-row overflow-x-hidden overflow-y-auto">
        <div className="h-full flex w-[1128px] relative max-[1696px]:mt-0 max-[1696px]:mb-0 max-[1696px]:ml-[calc(248px+36px)] max-[1696px]:mr-[36px] min-[1697px]:my-0 min-[1697px]:mx-auto flex-row justify-between gap-[24px]">
          <div className="fixed top-[0px] w-[1128px] h-[24px] flex items-center justify-between z-[3] -bg--color-neutral0"></div>
          <UserInfo
            refHeader={refHeader}
            refBody1={refBody1}
            refBody2={refBody2}
          />
          <div className="absolute top-[24px] flex flex-col ml-[288px]">
            <CouncelInfo />
            <CouncelList />
            <TextEditor />
            <Comment />
            <FileUpload />

            <div className="absolute left-[5000px] flex flex-row">
              <PdfHeader props={data} ref={refHeader} />;
              <PdfBodyInfo props={data} ref={refBody1} />
              <PdfBodyContent
                data={data}
                additionalData={additionalData}
                ref={refBody2}
              />
            </div>
          </div>
          <div className="none fixed w-[1128px] h-[24px] bottom-[0px] -bg--color-neutral0"></div>
        </div>
      </div>
    </>
  );
};

export default CouncelDetail;
