import React from "react";

const CouncelNoteDeleteFileWeb = () => {
  return (
    <div className="flex flex-col gap-[4px] py-[24px] px-[100px] items-center">
      <span className="text-H21B">파일을 삭제할까요?</span>
      <span className="text-B14R -text--color-neutral50">
        삭제하면 복구가 불가능해요.
      </span>
    </div>
  );
};

export default CouncelNoteDeleteFileWeb;
