import React from "react";
import { useRecoilState } from "recoil";
import { responseData } from "@/stores/common/data";

const CouncelFieldMobile = () => {
  const [resultData, setResultData] = useRecoilState(responseData);

  return (
    <>
      <div className="overflow-y-auto px-[10px] ptb-16 flex flex-col gap-[8px] overflow-x-hidden">
        {resultData?.user?.wfs.map((idx, num) => (
          <a
            key={num}
            className="block w-full rounded-[6px] pt-[15px] pb-[15px] plr-16 shadow-custom-shadow"
            href={`https://www.eum.go.kr/web/ar/lu/luLandDet.jsp?selSido=11&selSgg=710&selUmd=0101&selRi=00&landGbn=1&bobn=175&bubn=1&pnu=${idx.wfs_pnu}&scale=&isNoScr=script&chk=0&mode=search&selGbn=umd`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="w-[134px] h-[26px] flex items-center justify-center -bg--color-neutral6 rounded-[30px] text-B14R mb-[8px]">
              {idx.wfs_area_name}
            </div>
            <div className="text-B16M mb-[8px]">{idx.wfs_jibun}</div>
            <div className="-text--color-neutral50 gap-[4px]">
              <div>
                <span className="text-B14R mr-[4px]">대지면적</span>
                <span className="text-B14B">
                  {idx.wfs_lndpcl}m<sup>2</sup>
                </span>
              </div>
              <div className="flex flex-row gap-[16px]">
                <div>
                  <span className="text-B14R mr-[4px]">최대 건폐율</span>
                  <span className="text-B14R">{idx.wfs_blr}%</span>
                </div>
                <div className="gap-[4px]">
                  <span className="text-B14R mr-[4px]">최대 용적률</span>
                  <span className="text-B14R">{idx.wfs_far}%</span>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
      <div className="w-full px-[10px] pb-[24px]">
        <div className="border border-solid -border--color-neutral12 pt-[14px] pb-[14px] pl-[30.5px] pr-[30.5px] text-B16B -text--color-neutral50">
          주소를 누르면 토지이용계획 열람이 가능해요.
        </div>
      </div>
    </>
  );
};

export default CouncelFieldMobile;
