import React, { useEffect, useState } from "react";

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { pageState } from "@/stores/common/navigation";
import {
  councelParameter,
  tempCouncelParameter,
} from "@/stores/common/parameter";
import { responseDataInfinite } from "@/stores/common/data";

import { useNavigate } from "react-router-dom";

import http from "@/utils/api/http/http";

import { debounceFunction } from "@/utils/func/common/debounce";
import { filterController } from "@/utils/func/common/filter";
import { pageHandler } from "@/utils/func/common/navigation";

import MyCouncelButton from "@/components/buttons/MyCouncelButton";
import ScrollUpButton from "@/components/buttons/ScrollUpButton";
import FilterButton from "@/components/buttons/FilterButton";

import MobileCategory from "@/components/filter/MobileCategory";
import MobileSearch from "@/components/filter/MobileSearch";

import MobileTypeOne from "@/components/cards/MobileTypeOne";

interface dataType {
  data: {
    address_idx: number;
    address_idx_list: string[];
    address_jibun: string;
    admin_name: null | string;
    detail_size: null | string;
    detail_type: null | number;
    detail_use: null | string;
    important_check: null | boolean;
    process_check: number;
    process_datetime: string;
    process_idx: number;
    process_name: string;
    process_type: number;
    user_idx: number;
    user_name: string;
    user_phone: string;
  };
}

const CouncelMobile = () => {
  // 상담 노트 리스트 호출 파라미터
  const [parameter, setParameter] = useRecoilState(councelParameter);

  // 상담 노트 리스트 데이터
  const [data, setData] = useRecoilState(responseDataInfinite);
  const resetData = useResetRecoilState(responseDataInfinite);

  // 페이지 값 무한 스크롤 사용 목적
  const selectPage = useRecoilValue(pageState);

  const [tempMobileFilter, setTempMobileFilter] =
    useRecoilState(tempCouncelParameter);

  /**
   * @description 아래의 의존성 배열의 변경 감지 시 전역변수 초기화 및 api 호출
   *
   * @param location 지역
   * @param detail_type 유입 경로
   * @param councel_type 상담 단계
   * @param start_datetime 상담 시작일
   * @param end_datetime 상담 종료일
   * @param mycheck 내 상담
   * @param pageno 페이지 수
   * @param process_type 상담 상태
   * @param important_check 중요 상담
   */
  useEffect(() => {
    resetData();
    setParameter((prev) => ({ ...prev, pageno: 1 }));

    if (selectPage === "councel") {
      http
        .get(`/api/councel`, {
          params: {
            location: String(parameter.location),
            // 홈페이지 유선상담 기타...
            detail_type: parameter.detail_type,
            // 상담, 계약, 공사...
            councel_type: parameter.councel_type,
            start_datetime: parameter.start_datetime,
            end_datetime: parameter.end_datetime,
            mycheck: parameter.mycheck,
            search: parameter.search,
            pageno: parameter.pageno,
            // 상담대기, 진행, 상담완료, 상담취소...
            process_type: parameter.process_type,
            important_check: parameter.important_check,
          },
        })
        .then((res) => {
          setData(res.data.data.councel);
        });
    }
  }, [
    selectPage,
    parameter.location,
    parameter.detail_type,
    parameter.councel_type,
    parameter.start_datetime,
    parameter.end_datetime,
    parameter.mycheck,
    parameter.search,
    parameter.process_type,
    parameter.important_check,
  ]);

  /**
   * @description 창에 이벤트 추가
   *
   * @return handleScroll 실행 및 debounce 설정 / scrollTop + clientHeight >= scrollHeight - 20 감지 시 pageno 값 추가하여 api 호출
   */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /**
   * @description 무한 스크롤 이벤트
   *
   * @param pageno 하단 스크롤 감지 시 페이지 추가
   * @return api 결과 값을 상태 값에 복사가 아닌 구조분해를 통한 추가
   */
  useEffect(() => {
    // 상세 페이지 접근 후 뒤로가기 시 같은 데이터 중복 표현
    if (parameter.pageno > 1) {
      if (selectPage === "councel" && parameter.important_check !== true) {
        http
          .get(`/api/councel`, {
            params: parameter,
          })
          .then((res) => {
            setData((prev: any) => [...prev, ...res.data.data.councel]);
          });
      }
    }
  }, [parameter.pageno]);

  let debounceTimer: any;

  const handleScroll = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setParameter((prev) => {
          return { ...prev, pageno: prev.pageno + 1 };
        });
      }
    }, 200);
  };

  const navigate = useNavigate();

  const [, setSelectedPage] = useRecoilState(pageState);
  const resetParameter = useResetRecoilState(councelParameter);

  function navigateTo(e: string) {
    pageHandler(e, navigate);

    setSelectedPage(e);
    resetParameter();
  }

  const [categoryData, setCategoryData] = useState([
    {
      title: "전체",
      params: "전체",
    },
    {
      title: "홈페이지",
      params: "홈페이지",
    },
    {
      title: "유선상담",
      params: "유선상담",
    },
    {
      title: "기타",
      params: "기타",
    },
  ]);

  const handleSearchDebounced = debounceFunction((e: any) => {
    let value = e.target.value;

    setParameter((prev) => {
      return {
        ...prev,
        search: value,
        pageno: 1,
      };
    });
  }, 300);

  const ctrlParameter = (e: any) => {
    filterController({ value: "coun", e, callback: setParameter });
  };

  return (
    <main className="absolute -bg--color-neutral0 w-full mt-[48px] flex flex-col min-h-[calc(100%-48px)]">
      <section className="fixed flex flex-col w-full">
        <MobileCategory
          title="councel"
          data={categoryData}
          parameter={parameter}
          callback={ctrlParameter}
        />
        <MobileSearch callback={handleSearchDebounced} />
        <div className="w-full flex flex-row items-center justify-between px-[16px] pb-[12px] -bg--color-neutral0">
          <MyCouncelButton callback={setParameter} />
          <FilterButton />
        </div>
      </section>
      <section className="w-full px-[16px] pt-[192px] pb-[16px] flex flex-col gap-[12px]">
        {data.map((data: dataType["data"], num: number) => {
          return <MobileTypeOne data={data} callback={navigateTo} key={num} />;
        })}
        {data.length === 0 && (
          <div className="h-[calc(100vh-192px-48px-16px)] flex flex-col items-center justify-center gap-[4px]">
            <span className="-text--title-18b font-700">
              일치하는 결과가 없어요
            </span>
            <span className="-text--body-14r font-400 -text--color-neutral50">
              검색 범위를 넓혀 보세요.
            </span>
          </div>
        )}
      </section>
      <ScrollUpButton />
    </main>
  );
};

export default CouncelMobile;
