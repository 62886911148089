/**
 * @description 입력 값 숫자 유무 판단
 * @param {*} e input 값
 */
export const filterNum = (e) => {
  const regex = /^[0-9,]*$/;

  if (regex.test(e.target.value)) {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    e.target.value = formattedValue;
  } else {
    e.target.value = e.target.value.replace(/[^0-9,]/g, "");
    alert("숫자만 입력하세요");
  }
};

/**
 * @description 휴대폰 번호 정규식
 * @param {*} e input 값
 * @returns
 */
export const regexPhoneNumber = (e) => {
  const filterNumber = e
    // 숫자 제외 모든 문자 제거
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/(\-{1,2})$/g, "");

  return filterNumber;
};

/**
 * @description 휴대폰 번호 형식
 * @param {*} val input 값
 * @returns '-' 처리된 휴대폰 번호 값
 */
export const formatPhoneNumber = (val) => {
  // value 길이가 0 이상 일 때, 숫자, 공백, -, +를 제외한 문자열이 들어올 경우 false 리턴
  if (
    val.length > 0 &&
    (typeof val !== "string" || !/^[0-9+\s\-]*$/.test(val))
  ) {
    return false;
  }

  val = val.replace(/[^0-9]/g, "");

  // 모두 제외하고 숫자 기준으로 15개 초과 시 false 리턴
  if (val.length > 15) {
    return false;
  }

  if (val.indexOf("82") === 0) {
    return val.replace(/(^82)(2|\d{2})(\d+)?(\d{4})$/, "+$1 $2-$3-$4"); // +82
  } else if (val.indexOf("1") === 0 && val.length === 8) {
    return val.replace(/(^1\d{3})(\d{4})$/, "$1-$2"); // 1588, 1566, 1677, ...
  } else {
    const match = val.match(/^(\d{3})(\d+)?(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2] || ""}-${match[3]}`;
    } else {
      return val;
    }
  }
};
