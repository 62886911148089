import { atom } from "recoil";

// 백엔드로부터 전달받는 결과 값 받는 상태 값
export const responseData = atom<any>({
  key: "responseData",
  default: "",
});

// 하나의 컴포넌트에서 두개의 결과 값이 필요한 페이지가 있어서 추가
export const additionalResponseData = atom<any>({
  key: "additionalResponseData",
  default: {
    councel: [],
  },
});

// 무한 스크롤 결과 값 받는 목적
export const responseDataInfinite = atom<any>({
  key: "responseDataInfinite",
  default: [],
});
