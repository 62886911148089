/**
 * @description 모달 닫는 함수
 * @param {*} modalcontroller
 */
export const closePortal = (callback) => {
  callback((prev) => {
    return { ...prev, isOpen: false, value: "" };
  });
};

/**
 * @description 모달 여는 함수
 * @param {*} modalcontroller
 */
export const showPortal = (callback, e, value) => {
  callback((prev) => {
    return { ...prev, isOpen: e, value: value };
  });
};
