import React, { useState } from "react";

import { useNavigate, Outlet } from "react-router-dom";

import { useRecoilState, useResetRecoilState } from "recoil";

import { pageState } from "@/stores/common/navigation";
import { councelParameter } from "@/stores/common/parameter";
import { responseData } from "@/stores/common/data";

import { logout } from "@/utils/api/auth/auth";

import { pageHandler } from "@/utils/func/common/navigation";

// import "../../styles/sprite.css";
import "@/styles/sprite.css";

const SideBarMobile = () => {
  // 사이드바 오픈 여부
  const [sidebarState, setSidebarState] = useState<boolean>(false);

  // 페이지 이동 함수
  const navigate = useNavigate();

  // 페이지 이동 시 상담 노트에서 사용하던 parameter 초기화
  const [, setParameter] = useRecoilState(councelParameter);
  const resetParameter = useResetRecoilState(councelParameter);

  // 선택한 페이지
  const [selectedPage, setSelectedPage] = useRecoilState(pageState);
  // 선택한 페이지가 상담노트일 때 상담 단계 (전체, 중요, 1, 2, 3차)
  const [councelStep, setCouncelStep] = useState<undefined | number>(undefined);
  // 상담노트 누르면 상담 단계에 대한 select 영역 활성화
  const [councelTap, setCouncelTap] = useState<boolean>(false);

  const resetData = useResetRecoilState(responseData);

  function navigateTo(e: any) {
    resetData();

    if (typeof e === "string") {
      pageHandler(e, navigate);
      setSelectedPage(e);
      resetParameter();
      setSidebarState(false);
    } else if (typeof e === "number") {
      pageHandler("councel", navigate);
      setSelectedPage("councel");
      resetParameter();
      setSidebarState(false);
    }

    if (e === "dashboard" || e === "inquiry" || e === "changepassword") {
      setCouncelStep(undefined);
      setCouncelTap(false);
    }

    if (typeof e === "number") {
      setCouncelStep(e);

      // e가 4일 때 중요 상담 노트 클릭
      if (e === 4) {
        // 중요 상담 노트 클릭 시 councel_type가 0 (전체), important_check가 true (중요)
        setParameter((prev) => {
          return { ...prev, councel_type: 0, important_check: true, pageno: 1 };
        });
      } else {
        setParameter((prev) => {
          return {
            ...prev,
            councel_type: e,
            important_check: false,
            pageno: 1,
          };
        });
      }
    }
  }

  return (
    <React.Fragment>
      <header className="fixed w-full flex flex-row items-center justify-end pt-[2px] pb-[2px] pl-[10px] pr-[10px] -bg--color-neutral0 z-50">
        <span
          className={`absolute left-1/2 -translate-x-1/2  text-H21B ${
            selectedPage === "dashboard" && "_sprite_ _sprite_12_1_"
          }`}
        >
          {selectedPage === "dashboard" && null}
          {selectedPage === "inquiry" && "고객문의"}
          {selectedPage === "councel" && "상담노트"}
        </span>
        <button
          className="right-0 w-[44px] h-[44px] flex items-center justify-center"
          onClick={() => {
            setSidebarState(true);
          }}
        >
          <span className="_sprite_ _sprite_11_4_"></span>
        </button>
      </header>
      {sidebarState && (
        <aside
          className="fixed right-0 h-screen w-[248px] -bg--color-neutral3 flex flex-col justify-between z-50"
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.20)" }}
        >
          <div>
            <div className="pl-[10px] pr-[10px] pt-[2px] pb-[2px] flex flex-row justify-between items-center">
              <span className="pl-[24px] text-H18B">
                {localStorage.getItem("admin_name")}
              </span>
              <button
                onClick={() => {
                  setSidebarState(false);
                }}
                className="h-[44px] w-[44px]"
              >
                <span className="_sprite_ _sprite_11_1_"></span>
              </button>
            </div>
            <div className="flex flex-col p-[8px] gap-[4px] -text--color-neutral50">
              <button
                className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                  selectedPage === "dashboard" &&
                  `-bg--color-primary-surface -text--color-primary-main`
                }`}
                onClick={() => {
                  navigateTo("dashboard");
                }}
              >
                <span
                  className={`${
                    selectedPage === "dashboard"
                      ? "_sprite_ _sprite_16_2_"
                      : "_sprite_ _sprite_7_16_"
                  }`}
                ></span>
                <span className="-text--title-18b">대시보드</span>
              </button>
              <button
                className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                  selectedPage === "inquiry" &&
                  `-bg--color-primary-surface -text--color-primary-main`
                }`}
                onClick={() => {
                  navigateTo("inquiry");
                }}
              >
                <span
                  className={`${
                    selectedPage === "inquiry"
                      ? "_sprite_ _sprite_16_3_"
                      : "_sprite_ _sprite_7_17_"
                  }`}
                ></span>
                <span className="-text--title-18b">고객문의</span>
              </button>
              <button
                className={`relative plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                  selectedPage === "councel" &&
                  `-bg--color-primary-surface -text--color-primary-main`
                }`}
                onClick={() => {
                  setCouncelTap(!councelTap);
                }}
              >
                <span
                  className={`${
                    selectedPage === "councel"
                      ? "_sprite_ _sprite_16_8_"
                      : "_sprite_ _sprite_7_20_"
                  }`}
                ></span>
                <span className="-text--title-18b">상담노트</span>
                <span
                  className={`absolute right-[24px] _sprite_ ${
                    councelTap === true ? "_sprite_4_1_" : "_sprite_4_4_"
                  }`}
                ></span>
              </button>
              {councelTap && (
                <>
                  <button
                    className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                      councelStep === 0 && `-text--color-primary-main`
                    }`}
                    onClick={() => {
                      navigateTo(0);
                    }}
                  >
                    <span
                      className={`_sprite_ ${
                        councelStep === 0 ? "_sprite_17_9_" : "_sprite_4_3_"
                      }`}
                    ></span>
                    <span className="-text--body-16m">전체</span>
                  </button>
                  <button
                    className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                      councelStep === 4 && `-text--color-primary-main`
                    }`}
                    onClick={() => {
                      navigateTo(4);
                    }}
                  >
                    <span
                      className={`_sprite_ ${
                        councelStep === 4 ? "_sprite_17_9_" : "_sprite_4_3_"
                      }`}
                    ></span>
                    <span className="-text--body-16m">★ 중요</span>
                  </button>
                  <button
                    className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                      councelStep === 1 && `-text--color-primary-main`
                    }`}
                    onClick={() => {
                      navigateTo(1);
                    }}
                  >
                    <span
                      className={`_sprite_ ${
                        councelStep === 1 ? "_sprite_17_9_" : "_sprite_4_3_"
                      }`}
                    ></span>
                    <span className="-text--body-16m">1차 - 상담</span>
                  </button>
                  <button
                    className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                      councelStep === 2 && `-text--color-primary-main`
                    }`}
                    onClick={() => {
                      navigateTo(2);
                    }}
                  >
                    <span
                      className={`_sprite_ ${
                        councelStep === 2 ? "_sprite_17_9_" : "_sprite_4_3_"
                      }`}
                    ></span>
                    <span className="-text--body-16m">2차 - 계약</span>
                  </button>
                  <button
                    className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px] ${
                      councelStep === 3 && `-text--color-primary-main`
                    }`}
                    onClick={() => {
                      navigateTo(3);
                    }}
                  >
                    <span
                      className={`_sprite_ ${
                        councelStep === 3 ? "_sprite_17_9_" : "_sprite_4_3_"
                      }`}
                    ></span>
                    <span className="-text--body-16m">3차 - 공사</span>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="fixed bottom-0">
            <div className="flex flex-col p-[8px] gap-[4px] -text--color-neutral50">
              <button
                className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px]`}
                onClick={() => {
                  navigateTo("changepassword");
                }}
              >
                <span className="_sprite_ _sprite_7_19_"></span>
                <span className="-text--title-18b">정보수정</span>
              </button>
              <button
                className={`plr-8 ptb-6 flex flex-row items-center h-[44px] gap-[16px]`}
                onClick={() => {
                  logout();
                }}
              >
                <span className="_sprite_ _sprite_7_11_"></span>
                <span className="-text--title-18b">로그아웃</span>
              </button>
            </div>
          </div>
        </aside>
      )}
      {<Outlet />}
    </React.Fragment>
  );
};

export default SideBarMobile;
