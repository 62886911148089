import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";

import { PDFDocument, rgb } from "pdf-lib";

import moment from "moment";

import "@/styles/makePdf.css";

function downloadFile(data, filename, mimeType) {
  const blob = new Blob([data], { type: mimeType });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      throw new Error("File download is not supported in this browser.");
    }
  }
}

const MakePdf = async (refHeader, refBody1, refBody2, fileName) => {
  const createPDFFromHTML = async (
    html,
    header = null,
    margin = [116, 0, 26, 0]
  ) => {
    const canvasOpt = {
      scrollX: 0,
      scrollY: 0,
      width: 595,
      windowWidth: 842,
      windowHeight: 595,
      scale: 4,
      useCORS: true,
      allowTaint: false,
      logging: true,
    };

    const mainOpt = {
      pagebreak: {
        mode: ["css", "legacy"],
        avoid: [".trigger"],
      },
      margin: margin,
      html2canvas: canvasOpt,
      jsPDF: {
        orientation: "portrait",
        unit: "px",
        format: [595, 842],
        compressPDF: true,
      },
    };

    const pdf = await html2pdf().set(mainOpt).from(html).toPdf().get("pdf");

    let reportHeader;
    if (header) reportHeader = await createPDFHeader(header);

    const totalReport = pdf.internal.getNumberOfPages();

    for (let i = 1; i <= totalReport; i++) {
      pdf.setPage(i);

      if (header) pdf.addImage(reportHeader, "jpeg", 0, 0, 595, 90);
    }

    return pdf.output("arraybuffer");
  };

  const createPDFHeader = async (html) => {
    const canvasOpt = {
      scrollX: 0,
      scrollY: 0,
      width: 595,
      windowWidth: 595,
      windowHeight: 842,
      scale: 2,
      useCORS: true,
      allowTaint: false,
      logging: true,
    };
    const headerOpt = {
      pagebreak: {
        mode: ["css", "legacy"],
        avoid: [""],
      },
      html2canvas: { ...canvasOpt, width: 595 },
    };

    const headerCanvas = await html2canvas(html, headerOpt.html2canvas);
    const headerImage = headerCanvas.toDataURL("image/png");

    return headerImage;
  };

  // 여러 개의 PDF를 병합하는 함수
  const mergePDFs = async (pdfs) => {
    const mergedPdf = await PDFDocument.create();
    for (const pdfBytes of pdfs) {
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    const mergedPdfBytes = await mergedPdf.save();
    return mergedPdfBytes;
  };

  // pdf 생성
  const generateMergedPDF = async () => {
    const pdfs = [];

    if (refBody1.current !== undefined) {
      const pdfMain = await createPDFFromHTML(
        refBody1.current,
        refHeader.current
      );
      pdfs.push(pdfMain);
    }

    if (refBody2.current !== undefined) {
      const pdfMain2 = await createPDFFromHTML(
        refBody2.current,
        refHeader.current
      );
      pdfs.push(pdfMain2);
    }

    const mergedPDF = await mergePDFs(pdfs);

    let pdf = await PDFDocument.load(mergedPDF);

    // 페이지 번호 추가
    const pageCount = pdf.getPageCount();

    for (let i = 0; i < pageCount; i++) {
      const page = pdf.getPage(i);
      const { width, height } = page.getSize();

      const fontSize = 16;
      const pageNumberText = String(i + 1);
      const textY = height; // 조정할 수 있는 y 좌표값
      const diagonalLineLength = 20;
      const diagonalLineWidth = 1;
      const color = rgb(0, 0, 0);

      page.drawText(pageNumberText, {
        x: width - diagonalLineLength - 20,
        y: textY - diagonalLineLength - 35,
        size: fontSize,
      });

      page.drawLine({
        start: { x: width - 760, y: height - 1088 },
        end: { x: width - 33.33, y: height - 1088 },
        thickness: diagonalLineWidth,
        color,
      });
    }

    // const createTime = moment().format("YYYY-MM-DD");
    const createTime = moment().format("YYMMDD");

    pdf = await pdf.save();

    downloadFile(
      pdf,
      `${createTime}_건축상담보고서_${fileName}.pdf`,
      "application/pdf"
    );

    return pdf;
  };

  return generateMergedPDF();
};

export default MakePdf;
