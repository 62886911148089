import React from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { checkAthenticated } from "@/utils/api/auth/auth";

import PagesLayout from "@/layout/PagesLayout";
import PagesMobileLayout from "@/layout/PagesMobileLayout";
import PagesLayoutSummary from "@/layout/PagesLayoutSummary";

import Dashboard from "@/pages/web/Dashboard";
import Schedular from "@/pages/web/Schedular";
import Login from "@/pages/web/Login";
import Inquiry from "@/pages/web/Inquiry";
import Councel from "@/pages/web/Councel";
import CouncelDetail from "@/pages/web/CouncelDetail";

import CouncelSummary from "@/pages/mobile/CouncelSummary";
import LoginMobile from "@/pages/mobile/LoginMobile";
import DashboardMobile from "@/pages/mobile/DashboardMobile";
import CouncelMobile from "@/pages/mobile/CouncelMobile";
import CouncelDetailMobile from "@/pages/mobile/CouncelDetailMobile";
import InquiryMobile from "@/pages/mobile/InquiryMobile";

import Error from "@/pages/common/Error";

import { useRecoilState } from "recoil";

import { device } from "@/stores/common";

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const Router = () => {
  const [deviceState, _] = useRecoilState(device);

  return (
    <BrowserRouter>
      <Routes>
        {checkAthenticated() && deviceState === "web" && (
          <Route element={<PagesLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/inquiry" element={<Inquiry />} />
            <Route path="/councel" element={<Councel />} />
            <Route
              path="/councel/detail/:user/:process"
              element={isMobile ? <CouncelSummary /> : <CouncelDetail />}
            />
            <Route path="/calendar" element={<Schedular />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
        )}

        {checkAthenticated() && deviceState === "mobile" && (
          <Route element={<PagesMobileLayout />}>
            <Route path="/dashboard" element={<DashboardMobile />} />
            <Route path="/inquiry" element={<InquiryMobile />} />
            <Route path="/councel" element={<CouncelMobile />} />
            <Route
              path="/councel/detail/:user/:process"
              element={<CouncelDetailMobile />}
            />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
        )}

        {!checkAthenticated() && deviceState === "mobile" && (
          <Route>
            <Route path="*" element={<LoginMobile />} />
          </Route>
        )}

        {!checkAthenticated() && deviceState === "web" && (
          <Route>
            <Route path="*" element={<Login />} />
          </Route>
        )}

        {!checkAthenticated() && deviceState === "mobile" && (
          <Route>
            <Route element={<PagesLayoutSummary />}>
              <Route
                path="/councel/detail/:user/:process"
                element={<CouncelSummary />}
              />
            </Route>
          </Route>
        )}

        <Route path="/error" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
