import React, { useEffect, useState } from "react";

import { Outlet, useParams } from "react-router-dom";

import { useRecoilState } from "recoil";

import moment from "moment";

import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";
import "@/styles/textEditor.css";

import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";

import {
  handleTimeToKr,
  handleTimeToUtc,
} from "@/utils/func/common/convertTime";
import { showPortal } from "@/utils/func/common/modalController";

import { additionalResponseData, responseData } from "@/stores/common/data";
import { modalInfo } from "@/stores/common/modal";

import {
  councelNoteAddressIdx,
  editorState,
  pickedCouncelNoteDate,
  selectedDetailCouncelNote,
  selectedDetailCouncelNoteIndex,
} from "@/stores/page/councelDetail";

import write_button from "@/Assets/Images/write_button.svg";

import http from "@/utils/api/http/http";

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/ImageCompress", ImageCompress);

const TextEditor = () => {
  const [data, setData] = useRecoilState(responseData);

  const [additionalData, setAdditionalData] = useRecoilState(
    additionalResponseData
  );

  const [state, setState] = useRecoilState(editorState);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (state === "create" || state === "fix") {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [state]);

  const [selectedCard, setSelectedCard] = useRecoilState(
    selectedDetailCouncelNote
  );
  const [selectedCardIdx, setSelectedCardIdx] = useRecoilState(
    selectedDetailCouncelNoteIndex
  );

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  const defaultValue = `<h1><strong>제목 (24 bold)</strong></h1><p><br></p><h2>대분류</h2><p><br></p><ul><li>구분점 목록</li></ul><p><br></p><ol><li>번호 목록</li></ol><p><br></p><p>텍스트 스타일을 참고하여 작성해 주세요.</p>`;

  const [value, setValue] = useState(defaultValue);

  const handleChange = (v) => {
    setValue(v);
  };

  const [, setModal] = useRecoilState(modalInfo);

  const [pickedDate, setPickedDate] = useRecoilState(pickedCouncelNoteDate);

  const id = useParams();

  useEffect(() => {
    if (state === "fix") {
      setValue(selectedCard.councel_detail);
      setPickedDate(selectedCard.councel_datetime);
    }
  }, [state]);

  // 데이터 받아오는 함수
  async function getData() {
    try {
      const res = await http.get(`/api/councel/detail/list`, {
        params: { process_idx: id.process },
      });

      setAdditionalData({
        councel: [...res.data.data.councel],
      });

      const userRes = await http.post(`/api/councel/detail`, {
        user_idx: id.user,
        process_idx: id.process,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      });

      setData(userRes.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  // 상담일지 작성 시
  async function submitEditor() {
    return await http
      .post(`/api/councel`, {
        process_idx: data.process.process_idx,
        councel_type: data.process.process_type,
        councel_detail: value,
        councel_datetime:
          pickedDate === ""
            ? moment().subtract(9, "hours").format("YYYY-MM-DD HH:mm:ss")
            : handleTimeToUtc(pickedDate),
      })
      .then((res) => {
        setValue(defaultValue);

        getData();

        setPickedDate("");
        setState("read");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // 상담일지 수정 시
  async function fixData() {
    try {
      const response = await http.put(`/api/councel`, {
        councel_idx: selectedCard.councel_idx,
        councel_detail: value,
        councel_datetime: moment(pickedDate)
          .subtract(9, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      });

      const res = await http.get(`/api/councel/detail/list`, {
        params: { process_idx: id.process },
      });

      setAdditionalData({
        councel: [...res.data.data.councel],
      });

      const userRes = await http.post(`/api/councel/detail`, {
        user_idx: id.user,
        process_idx: id.process,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      });

      setData(userRes.data.data);

      setSelectedCard(
        ...(res &&
          res.data.data.councel.filter(
            (v) => v.councel_idx === response.data.data.councel_idx
          ))
      );

      setState("read");
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <React.Fragment>
      {/* 상담 내용도 없고, 상담 노트도 없을 때 */}
      {state === "read" && !selectedCard && (
        <div className="min-h-[522px] flex flex-col justify-center items-center plr-24 -bg--color-neutral0 gap-[10px] mb-[16px] rounded-b-[12px] border-x border-b border-solid -border--color-neutral6">
          <img src={write_button} alt="" />
          <p className="text-H21B">작성된 접수내용, 상담노트가 없습니다.</p>
          <p className="text-B14R">
            상담노트를 작성하시려면 좌측 상단의 +버튼을 클릭해주세요
          </p>
        </div>
      )}
      {state === "read" && selectedCard && (
        <div className="w-[840px] h-[522px] rounded-b-[12px] -bg--color-neutral0 mb-[16px] border-x border-b border-solid -border--color-neutral6">
          <div className="w-[840px] h-[56px] flex flex-row items-center pl-[24px] pr-[32px] justify-between border-b border-solid -border--color-neutral6">
            <div className="flex flex-row">
              {selectedCard && selectedCard.admin_name && (
                <div className="flex justify-between gap-[8px] mr-[16px] items-center">
                  <span className="text-B14B">담당자</span>
                  <span className="text-B14R">
                    {selectedCard && selectedCard.admin_name}
                  </span>
                </div>
              )}

              <div className="flex justify-between gap-[8px] mr-[16px] items-center">
                {selectedCard &&
                selectedCard.additional_councel === "user_info" ? (
                  <span className="text-B14B">접수내용</span>
                ) : (
                  <>
                    <span className="text-B14B">상담 진행일</span>
                    <span className="text-B14R">
                      {handleTimeToKr(
                        selectedCard && selectedCard.councel_datetime
                      ).slice(0, 10)}
                    </span>
                  </>
                )}
              </div>
            </div>
            {data?.process?.process_check !== 2 &&
              data?.process?.process_check !== 9 &&
              !selectedCard?.additional_councel && (
                <button
                  className={`right-0 py-[8px] px-[17px] border border-solid -border--color-neutral50 rounded-[6px] text-B14B -text--color-neutral50`}
                  onClick={() => {
                    setState("fix");
                  }}
                >
                  수정
                </button>
              )}
          </div>

          {selectedCard && selectedCard.additional_councel === "user_info" ? (
            <div className="py-[8px] h-[calc(522px-56px)]">
              <div className="flex justify-start gap-[8px] items-center -text--color-neutral50 px-[24px]">
                {selectedCard &&
                selectedCard.additional_councel === "user_info" ? (
                  <span className="text-B14B">접수일자</span>
                ) : (
                  <span className="text-B14B">최종 수정일</span>
                )}
                <span className="text-B14R">
                  {handleTimeToKr(selectedCard?.councel_datetime)}
                </span>
              </div>
              <pre className="break-words whitespace-pre-wrap w-[calc(100%)] h-[430px] py-[16px] px-[24px] overflow-y-auto custom-scrollbar">
                {selectedCard && selectedCard.councel_detail}
              </pre>
            </div>
          ) : (
            <div className="py-[8px] h-[calc(522px-56px)]">
              <div className="flex justify-start gap-[8px] items-center -text--color-neutral50 px-[24px]">
                <span className="text-B14B">최종 수정일</span>
                <span className="text-B14R">
                  {handleTimeToKr(selectedCard?.last_datetime)}
                </span>
              </div>
              <div
                className="w-[calc(100%)] h-[430px] px-[24px] py-[16px] whitespace-normal break-words overflow-y-auto custom-scrollbar react-quill-contents"
                dangerouslySetInnerHTML={{
                  __html: selectedCard && selectedCard.councel_detail,
                }}
              />
            </div>
          )}
        </div>
      )}
      {state === "create" && (
        <React.Fragment>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={modules}
          />

          <div className="flex w-[840px] flex-row justify-between items-center h-[60px] plr-32 -bg--color-neutral0 mb-[16px] rounded-b-[12px] border-t border-x border-b border-solid -border--color-neutral6">
            <div className="flex flex-row gap-[8px] items-center">
              <span className="text-B16B">상담 진행일</span>
              <button
                onClick={() => {
                  showPortal(setModal, true, "councelnote-detail-calendar-web");
                }}
                className={`w-[116px] h-[40px] border border-solid -border--color-neutral50 rounded-[4px] flex justify-center items-center text-B16R ${
                  !pickedDate && "-text--color-neutral20"
                }`}
              >
                {pickedDate
                  ? pickedDate.format("YY. MM. DD")
                  : moment().format("YY. MM. DD")}
              </button>
            </div>
            <div className="flex flex-row gap-[8px]">
              <button
                className="px-[8px] py-[9px] -text--color-neutral50 text-B18R font-700"
                onClick={() => {
                  setState("read");
                  setValue(defaultValue);
                  setPickedDate("");
                }}
              >
                취소
              </button>
              <button
                className="px-[8px] py-[9px] -text--color-primary-main text-B18R font-700"
                onClick={() => {
                  submitEditor();
                }}
              >
                저장
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
      {state === "fix" && (
        <React.Fragment>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={modules}
          />

          <div className="flex w-[840px] flex-row justify-between items-center h-[60px] plr-32 -bg--color-neutral0 mb-[16px] rounded-b-[12px] border-t border-x border-b border-solid -border--color-neutral6">
            <div className="flex flex-row gap-[8px] items-center">
              <span className="text-B16B">상담 진행일</span>
              <button
                onClick={() => {
                  showPortal(setModal, true, "councelnote-detail-calendar-web");
                }}
                className={`w-[116px] h-[40px] border border-solid -border--color-neutral50 rounded-[4px] flex justify-center items-center text-B16R ${
                  !pickedDate && "-text--color-neutral20"
                }`}
              >
                {pickedDate
                  ? moment(pickedDate).add(9, "hours").format("YY. MM. DD")
                  : moment().format("YY. MM. DD")}
              </button>
            </div>
            <div className="flex flex-row gap-[8px]">
              <button
                className="px-[8px] py-[9px] -text--color-neutral50 text-B18R font-700"
                onClick={() => {
                  setState("read");
                  setValue(defaultValue);
                  setPickedDate("");
                }}
              >
                취소
              </button>
              <button
                className="px-[8px] py-[9px] -text--color-primary-main text-B18R font-700"
                onClick={() => {
                  fixData();
                }}
              >
                저장
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
      {<Outlet />}
    </React.Fragment>
  );
};

export default TextEditor;

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      [],
      [],
      ["bold"],
      ["underline"],
      ["strike"],
      [],
      [],
      [{ align: "" }],
      [{ align: "center" }],
      [{ align: "right" }],
      [],
      [],
      [{ list: "ordered" }],
      [{ list: "bullet" }],
      [],
      [],
      [
        {
          color: [
            "#ffa1a1",
            "#feff40",
            "#c3ec00",
            "#9ee7ff",
            "#ff002e",
            "#ff900e",
            "#00be57",
            "#2d81ff",
            "#ca34ff",
            "#c1c1c1",
            "#888888",
            "#000000",
          ],
        },
        [],
        {
          background: [
            "#ffa1a1",
            "#feff40",
            "#c3ec00",
            "#9ee7ff",
            "#ff002e",
            "#ff900e",
            "#00be57",
            "#2d81ff",
            "#ca34ff",
            "#c1c1c1",
            "#888888",
            "#000000",
          ],
        },
      ],
      ["image"],
    ],
  },
  // ImageResize: {
  //   parchment: Quill.import("parchment"),
  // },
  ImageResize: { modules: ["Resize"] },
  ImageCompress: {
    quality: 0.5, // default
    maxWidth: 1000, // default
    maxHeight: 1000, // default
    imageType: "image/jpeg", // default
    debug: true, // default
    suppressErrorLogging: false, // default
    insertIntoEditor: undefined, // default
  },
};
