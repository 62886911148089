/**
 * @description db에 등록된 html 태그를 포함한 값에 대해 태그 제거 목적
 * @param {*} ele 상담내용
 * @returns 태그 제거된 string
 */
export function removeTag(ele) {
  // 태그 유무 판별
  const detectTag = /<\/?[a-z][\s\S]*>/i.test(ele);

  if (!!detectTag) {
    let doc = new DOMParser().parseFromString(ele, "text/html");

    // ele에 태그가 존재하는 경우 br 기준으로 split
    // let scopeBr = ele?.split("<br>")[0];

    // br 기준이 아닌 body 태그 내 첫번째 값 추출
    const filteredTitle = doc?.body?.firstElementChild?.textContent;

    return filteredTitle;
  } else if (!detectTag) {
    let doc = new DOMParser().parseFromString(ele, "text/html");

    // ele에 태그가 존재하지 않는 경우 \n 기준으로 split
    const filteredTitle = doc.body.textContent?.split("\n")[0];

    return filteredTitle;
  }
}
