import React from "react";

const CouncelHeader = () => {
  return (
    <div className="fixed top-[98px] w-[1128px] h-[48px] flex flex-row items-center text-B12R -text--color-neutral50 border border-solid -border--color-neutral6 -bg--color-neutral0 rounded-t-[12px] z-20 px-[16px]">
      <div className="h-[48px] flex flex-row items-center">
        <div className="w-[49px] pl-[8px] h-[48px] flex items-center text-B12R ">
          <li>단계</li>
        </div>
        <div className="w-[224px] pl-[8px] flex items-center text-B12R ">
          <li>프로젝트명</li>
        </div>
        <div className="w-[86px] pl-[8px] h-[48px] flex items-center text-B12R ">
          <li>용도</li>
        </div>
        <div className="w-[73px] pl-[8px] h-[48px] flex items-center text-B12R ">
          <li>규모</li>
        </div>
        <div className="w-[73px] pl-[8px] h-[48px] flex items-center  text-B12R ">
          <li>신청자</li>
        </div>
        <div className="w-[145px] pl-[8px] h-[48px] flex items-center text-B12R ">
          <li>연락처</li>
        </div>
        <div className="w-[73px] pl-[8px] h-[48px] flex items-center text-B12R ">
          <li>담당자</li>
        </div>
        <div className="w-[78px] pl-[8px] h-[48px] flex items-center text-B12R ">
          <li>진행상태</li>
        </div>
        <div className="w-[255px] pl-[8px] h-[48px] flex items-center text-B12R ">
          <li>신청일</li>
        </div>
      </div>
    </div>
  );
};

export default CouncelHeader;
