import React from "react";

import { useNavigate } from "react-router-dom";

import { useSetRecoilState } from "recoil";

import { navValue } from "@/stores/common/navigation";
import { pageController } from "@/utils/func/common/page";

const NotAssigned = (props) => {
  const {
    adjustNotAssigned,
    setAdjustNotAssigned,
    setDetailCouncelParams,
    data,
    isLoading,
    isError,
  } = props;

  const navigate = useNavigate();

  const setNavState = useSetRecoilState(navValue);

  return (
    <div className="h-[622px] w-[360px] -bg--color-neutral0 rounded-[12px] border border-solid -border--color-neutral6">
      <div className="plr-16 ptb-10 text-H18B flex flex-row justify-between items-center">
        {/* <p>미배정 상담</p> */}
        <p className="flex flex-row gap-[8px] items-center">
          {adjustNotAssigned === false ? (
            <span>미배정 상담</span>
          ) : (
            <span>내 상담</span>
          )}
          <span className="text-B12R -text--color-primary-main">{`총 ${data?.length}개`}</span>
        </p>
        <div className="w-[156px] h-[36px]">
          <label className="inline-flex relative items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={adjustNotAssigned}
              readOnly
            />
            <div
              onClick={() => setAdjustNotAssigned(!adjustNotAssigned)}
              className="w-[156px] h-[36px] -bg--color-neutral3 rounded-[12px] peer peer-checked:after:translate-x-full peer-checked:after:border-white after:absolute  after:bg-white after:border-white after:border after:rounded-[12px] after:shadow-md after:h-[30px] after:w-[75px] after:transition-all peer-checked:-bg--color-neutral3 z-20 flex items-center"
              style={{ padding: "3px" }}
            >
              <span
                className={
                  adjustNotAssigned
                    ? "-text--color-neutral50 absolute z-30 text-B14M left-[21px]"
                    : "-text--color-neutral100 absolute z-30 text-B14M left-[21px]"
                }
              >
                미배정
              </span>
              <span
                className={
                  !adjustNotAssigned
                    ? "-text--color-neutral50 absolute z-30 text-B14M right-[21px]"
                    : "-text--color-neutral100 absolute z-30 text-B14M right-[21px]"
                }
              >
                내상담
              </span>
            </div>
          </label>
        </div>
      </div>
      <div className="h-[480px] plr-16 flex flex-col justify-start mb-[27px]">
        {data?.length > 0 &&
          data.map((idx, num) => (
            <div
              className="flex flex-row h-[48px] justify-between text-B16R -text--color-neutral50 cursor-pointer break-words list-none ptb-12  hover:-bg--color-neutral3"
              key={num}
            >
              <div
                className="flex flex-row items-center"
                onClick={() => {
                  let sidebarChecked = 1;
                  let url = `/councel/detail/${idx.user_idx}/${idx.process_idx}`;
                  let func = {
                    func1: (e) => setNavState(e),
                    func2: (v) => navigate(v),
                  };

                  pageController(sidebarChecked, url, func);
                }}
              >
                <div
                  className={`py-[4px] px-[6px] flex items-center justify-center  border-solid rounded-[6px] -text--color-neutral0 ml-[8px] -text--label-12r ${
                    idx.process_type === 1 && "-bg--color-etc-1"
                  } ${idx.process_type === 2 && "-bg--color-etc-2"} ${
                    idx.process_type === 3 && "-bg--color-etc-3"
                  }`}
                >
                  <li>
                    {idx.process_type === 1
                      ? "상담"
                      : idx.process_type === 2
                      ? "계약"
                      : "공사"}
                  </li>
                </div>
                <div className="w-[127px] text-B16B -text--color-neutral100 ml-[8px] truncate">
                  <li>{idx.process_name}</li>
                </div>
                <div className="w-[60px] flex h-[48px] items-center -text--color-neutral100 ml-[8px] justify-start">
                  <li>
                    {idx.user_name === null ? "-" : idx.user_name.slice(0, 4)}
                  </li>
                </div>
                <div className="absolute right-0 w-[24px] h-[24px] flex items-center justify-center ml-[2px] mr-[16px]">
                  <span className="_sprite_ _sprite_17_4_"></span>
                </div>
              </div>
            </div>
          ))}
        {data?.length === 0 && (
          <div className="flex flex-row -text--color-neutral100 h-full items-center justify-center text-H21B">
            현재 상담이 없어요
          </div>
        )}
      </div>
      <div className="w-[360px] h-[44px] flex items-center justify-center">
        <button
          className="flex flex-row gap-[2px] py-[8px]"
          onClick={() => {
            let sidebarChecked = 1;
            let url = `/councel`;

            if (adjustNotAssigned === true) {
              let func = {
                func1: (e) => setNavState(e),
                func2: (v) => navigate(v),
                func3: () =>
                  setDetailCouncelParams((prev) => {
                    return {
                      ...prev,
                      mycheck: adjustNotAssigned,
                      process_type: 1,
                    };
                  }),
              };
              pageController(sidebarChecked, url, func);
            }
            if (adjustNotAssigned === false) {
              let func = {
                func1: (e) => setNavState(e),
                func2: (v) => navigate(v),
                func3: () =>
                  setDetailCouncelParams((prev) => {
                    return {
                      ...prev,
                      mycheck: adjustNotAssigned,
                      process_type: 1,
                    };
                  }),
              };
              pageController(sidebarChecked, url, func);
            }
          }}
        >
          <span className="-text--color-primary-main text-B16B">더보기</span>
          <span className="_sprite_ _sprite_3_11_"></span>
        </button>
      </div>
    </div>
  );
};

export default NotAssigned;
