import { childrenType } from "@/types/common";
import React from "react";

const LoginLayout = ({ children }: childrenType) => {
  return (
    <div className="w-full h-screen  flex items-center justify-center -bg--color-neutral0">
      {children}
    </div>
  );
};

export default LoginLayout;
