import React, { useState } from "react";

import { useRecoilState, useResetRecoilState } from "recoil";

import ReactQuill, { Quill } from "react-quill";

import http from "@/utils/api/http/http";

import { modalInModalInfo, modalInfo } from "@/stores/common/modal";
import { moveCouncelNoteList, selectDate } from "@/stores/common/Mobile";

import { closePortal, showPortal } from "@/utils/func/common/modalController";

import "react-quill/dist/quill.snow.css";
import "@/styles/textEditor.css";

import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
import moment from "moment";
import { responseData } from "@/stores/common/data";
import { useParams } from "react-router-dom";
import { selectedDetailCouncelNote } from "@/stores/page/councelDetail";

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/ImageCompress", ImageCompress);

const CouncelAddCouncelnoteMobile = () => {
  const [resultData, setResultData] = useRecoilState(responseData);

  const [showNote, setShowNote] = useRecoilState(moveCouncelNoteList);

  const [selectedCouncelNote, setSelectedCouncelNote] = useRecoilState(
    selectedDetailCouncelNote
  );

  // 상담 내용 담는 변수
  const [contentStatus, setContentStatus] = useState(null);

  const [dateState, setDateState] = useRecoilState(selectDate);
  const resetDateState = useResetRecoilState(selectDate);

  const handleChange = (content) => {
    setContentStatus(content);
  };

  async function submitContent() {
    try {
      return await http
        .post("/api/councel", {
          process_idx: resultData.process.process_idx,
          councel_type: resultData.process.process_type,
          councel_detail: contentStatus,
          councel_datetime: dateState
            ? moment(dateState).subtract(9, "hours").format()
            : moment().subtract(9, "hours").format(),
        })
        .then((res) => {
          if (res.status === 200) {
            closePortal(setModalStatus);

            resetDateState();

            setShowNote(true);
            setSelectedCouncelNote(res.data.data);
          } else {
            alert("상담 내용 저장에 실패하였습니다.");
          }
        });
    } catch (err) {
      console.error(err);
    }
  }

  const [modalStatus, setModalStatus] = useRecoilState(modalInfo);
  const [modalinModalStatus, setModalinModalStatus] =
    useRecoilState(modalInModalInfo);

  return (
    <div className="plr-16 ptb-16 flex flex-col items-start gap-[16px]">
      <span className="-text--body-14b font-700">상담 진행일</span>
      <button
        className={
          dateState
            ? "border border-solid ptb-12 plr-16 font-400 inline-block w-full"
            : "border border-solid ptb-12 plr-16 -text--color-neutral20 font-400"
        }
        onClick={() => {
          showPortal(
            setModalinModalStatus,
            true,
            "councel-councelnote-calendar-mobile"
          );
        }}
      >
        {dateState ? moment(dateState).format("YY.MM.DD") : "YY.MM.DD"}
      </button>
      <div className="w-full border border-solid -border--color-neutral6"></div>
      <div className="w-full h-[calc(100vh-250px)]">
        <ReactQuill
          placeholder="상담 내용을 입력해주세요."
          className="quill-mobile"
          // value={value}
          onChange={handleChange}
          modules={modules}
        />
      </div>
      <div className="absolute w-full flex justify-end bottom-0 right-0 -bg--color-neutral6 pt-[2px] pb-[2px]">
        <button
          className="-text--color-primary-main ptb-8 plr-8 mr-[16px] -text--body-16b font-700"
          onClick={() => {
            submitContent();
          }}
        >
          저장
        </button>
      </div>
    </div>
  );
};

export default CouncelAddCouncelnoteMobile;

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ["bold"],
      ["underline"],
      ["strike"],
      // [{ align: "" }, { align: "center" }, { align: "right" }],
      [{ list: "ordered" }],
      [{ list: "bullet" }],
      [
        {
          color: [
            "#ffa1a1",
            "#feff40",
            "#c3ec00",
            "#9ee7ff",
            "#ff002e",
            "#ff900e",
            "#00be57",
            "#2d81ff",
            "#ca34ff",
            "#c1c1c1",
            "#888888",
            "#000000",
          ],
        },
        {
          background: [
            "#ffa1a1",
            "#feff40",
            "#c3ec00",
            "#9ee7ff",
            "#ff002e",
            "#ff900e",
            "#00be57",
            "#2d81ff",
            "#ca34ff",
            "#c1c1c1",
            "#888888",
            "#000000",
          ],
        },
      ],
    ],
  },
};
