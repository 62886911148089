import React, { useEffect, useRef, useState } from "react";

import moment from "moment";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "@/index.css";

import { useRecoilState } from "recoil";

import { handleTimeToKr } from "@/utils/func/common/convertTime";

import { additionalResponseData, responseData } from "@/stores/common/data";
import {
  editorState,
  selectedDetailCouncelNote,
  selectedDetailCouncelNoteIndex,
} from "@/stores/page/councelDetail";

import { removeTag } from "@/utils/func/common/removeTag";
import { showPortal } from "@/utils/func/common/modalController";
import { modalInfo } from "@/stores/common/modal";

const CouncelList = () => {
  const [modal, setModal] = useRecoilState(modalInfo);

  // 깊은 복사
  const [additionalData, setAdditionalData] = useRecoilState(
    additionalResponseData
  );

  const tempCardList = JSON.parse(JSON.stringify(additionalData));

  const [data, setData] = useRecoilState(responseData);

  const [selectedCard, setSelectedCard] = useRecoilState(
    selectedDetailCouncelNote
  );

  const [, setSelectedCardIdx] = useRecoilState(selectedDetailCouncelNoteIndex);

  const summary_councel = {
    additional_councel: "user_info",
    councel_datetime: data?.process?.process_datetime,
    councel_detail: data?.user?.detail_appendix,
  };

  const cardList = tempCardList && [
    ...tempCardList?.councel
      .filter((item) => [3, 2, 1].includes(item.councel_type))
      .sort((a, b) => {
        if (a.councel_type !== b.councel_type) {
          return b.councel_type - a.councel_type;
        } else {
          return a.idx - b.idx;
        }
      })
      .map((item) => {
        item.councel_datetime = handleTimeToKr(item.councel_datetime);

        return item;
      }),

    ...(data?.user?.detail_appendix !== null &&
    data?.user?.detail_appendix !== ""
      ? [summary_councel]
      : []),
  ];

  const handleCardClick = (id) => {
    if (state === "fix" || state === "create") {
      return;
    }

    if (id === "user_info") {
      setSelectedCard({
        additional_councel: "user_info",
        councel_datetime: data?.process?.process_datetime,
        councel_detail: data?.user?.detail_appendix,
      });
    } else {
      setSelectedCardIdx(id);

      setSelectedCard(
        additionalData.councel.filter((v) => v.councel_idx === id)[0]
      );
    }
  };

  const [_, setInit] = useState();

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const swiperRef = useRef(null);

  const [state, setState] = useRecoilState(editorState);

  const [slideBtnHoverState, setSlideBtnHoverState] = useState(false);
  const [slideBtnState, setSlideBtnState] = useState({
    leftBtn: false,
    rightBtn: true,
  });

  const [hiddenButton, setHiddenButton] = useState(false);

  useEffect(() => {
    setSlideBtnState({
      leftBtn: false,
      rightBtn: true,
    });
  }, []);

  const handleSlideChange = (swiper) => {
    if (swiper.isBeginning && !swiper.isEnd) {
      setHiddenButton(false);
    } else if (swiper.isBeginning && swiper.isEnd) {
      setHiddenButton(true);
      setSlideBtnState({
        leftBtn: true,
        rightBtn: true,
      });
    } else if (!swiper.isBeginning && !swiper.isEnd) {
      setHiddenButton(true);
      setSlideBtnState({
        leftBtn: true,
        rightBtn: true,
      });
    } else if (!swiper.isBeginning && swiper.isEnd) {
      if (cardList.length > 4) {
        setHiddenButton(true);

        setSlideBtnState({
          leftBtn: true,
          rightBtn: false,
        });
      } else {
      }
    }
  };

  const handleReachBeginning = (swiper) => {
    if (swiper.isBeginning) {
      setSlideBtnState({
        leftBtn: false,
        rightBtn: true,
      });
    }
  };

  const handleReachEnd = (swiper) => {
    if (swiper.isEnd && cardList.length > 3) {
      setSlideBtnState({
        leftBtn: true,
        rightBtn: false,
      });
    }
  };

  const handlePrevButtonClick = (swiper) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const currentIndex = swiperRef.current.swiper.activeIndex;

      const newIndex = currentIndex - 3;

      swiperRef.current.swiper.slideTo(newIndex, 400);

      if (swiperRef.current.swiper.isBeginning) {
        setSelectedCard(cardList[0]);
        setSelectedCardIdx(cardList[0].councel_idx);
      } else {
        setSelectedCard(additionalData.councel[newIndex]);
        setSelectedCardIdx(additionalData.councel.councel_idx);
      }
    }
  };

  const handleNextButtonClick = (swiper) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const currentIndex = swiperRef.current.swiper.activeIndex;

      const newIndex = currentIndex + 3;

      swiperRef.current.swiper.slideTo(newIndex, 400);

      if (swiperRef.current.swiper.isEnd) {
        setSelectedCard(cardList[cardList.length - 4]);
        setSelectedCardIdx(cardList[cardList.length - 4].councel_idx);
      } else {
        setSelectedCard(additionalData.councel[newIndex]);
        setSelectedCardIdx(additionalData.councel.councel_idx);
      }
    }
  };

  const openTextEditor = () => {
    if (state === "fix" || state === "create") {
      showPortal(setModal, true, "alert-texteditor-case");
    } else {
      setState("create");
    }
  };

  return (
    <div className="h-[120px] flex flex-row items-center w-[840px] border border-solid -bg--color-neutral3 -border--color-neutral6">
      {cardList.length === 0 && (
        <div
          className={`relative  h-full flex flex-row items-center w-full  py-[16px] `}
        >
          <button
            className={`p-[16px] cursor-pointer -bg--color-neutral12 rounded-[8px] flex justify-center items-center ml-[16px]`}
            onClick={() => {
              openTextEditor();
            }}
          >
            <span className="_sprite_ _sprite_15_7_"></span>
          </button>
        </div>
      )}
      {cardList.length === 1 &&
        cardList[0].additional_councel === "user_info" && (
          <div
            className={`relative  h-full flex flex-row items-center w-full  py-[16px] `}
          >
            <button
              className={`p-[16px] cursor-pointer -bg--color-neutral12 rounded-[8px] flex justify-center items-center ml-[16px]`}
              onClick={() => {
                openTextEditor();
              }}
            >
              <span className="_sprite_ _sprite_15_7_"></span>
            </button>
            <div
              className={`relative  h-full flex flex-row items-center w-full`}
            >
              {cardList &&
                cardList.map((idx, num) => {
                  let count = cardList.filter((item, index) => {
                    return (
                      item.councel_type === idx.councel_type && index <= num
                    );
                  }).length;

                  let reverseCount =
                    cardList.filter((item, index) => {
                      return item.councel_type === idx.councel_type;
                    }).length -
                    count +
                    1;

                  return (
                    <SwiperSlide
                      style={{
                        boxShadow: "0px 3px 36px 0px rgba(108, 108, 108, 0.12)",
                        width: "200px",
                        userSelect: "none",
                      }}
                      key={num}
                      className={`border-solid border-l-[6px] z-2 ml-[16px]
                     ${idx.councel_type === 1 && "-border--color-etc-1"}
                     ${idx.councel_type === 2 && "-border--color-etc-2"}
                     ${idx.councel_type === 3 && "-border--color-etc-3"}
                     ${
                       idx.additional_councel === "user_info" &&
                       "-border--color-success-main"
                     }
                     ${
                       selectedCard &&
                       selectedCard.councel_idx === idx.councel_idx &&
                       "border-[2px]"
                     }

                     `}
                      onClick={() => {
                        if (state === "fix" || state === "create") {
                          openTextEditor();
                        } else if (idx.councel_idx !== undefined) {
                          handleCardClick(idx.councel_idx);
                        } else if (idx.councel_idx === undefined) {
                          handleCardClick(idx.additional_councel);
                        }
                      }}
                    >
                      <div className="flex flex-col h-full justify-start py-[4px] gap-[4px]">
                        <p className="flex flex-row justify-between items-center">
                          <span className="text-B12B">
                            {idx.councel_type === 1 && `상담 - ${reverseCount}`}
                            {idx.councel_type === 2 && `계약 - ${reverseCount}`}
                            {idx.councel_type === 3 && `공사 - ${reverseCount}`}
                            {idx.additional_councel === "user_info" && `접수`}
                          </span>
                          <span className="text-B12R -text--color-neutral34">
                            {moment(idx.councel_datetime)
                              .add(9, "hours")
                              .format("YYYY.MM.DD")}
                          </span>
                        </p>
                        <span className="text-B14M text-nowrap overflow-hidden">
                          {removeTag(idx.councel_detail)}
                        </span>
                      </div>
                    </SwiperSlide>
                  );
                })}
              <div className="h-full w-full flex flex-col items-center justify-center gap-[4px]">
                <span className="text-H21B">작성된 상담노트가 없어요.</span>
                <span className=" text-B16R -text--color-neutral50">
                  좌측의 + 버튼을 눌러 새 노트를 생성하세요.
                </span>
              </div>
            </div>
          </div>
        )}
      {cardList.length === 1 &&
        cardList[0].additional_councel !== "user_info" && (
          <div
            className={`relative  h-full flex flex-row items-center w-full  py-[16px]`}
          >
            <button
              style={{
                display:
                  ((data && data?.process?.process_check === 2) ||
                    (data && data?.process?.process_check === 9)) &&
                  "none",
              }}
              className={`p-[16px] cursor-pointer -bg--color-neutral12 rounded-[8px] flex justify-center items-center ml-[16px]`}
              onClick={() => {
                openTextEditor();
              }}
            >
              <span className="_sprite_ _sprite_15_7_"></span>
            </button>
            <Swiper
              className="h-full w-[790px]"
              ref={swiperRef}
              slidesPerView={5.09}
              spaceBetween={8}
              modules={[Navigation]}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onInit={() => setInit(true)}
            >
              {cardList &&
                cardList.map((idx, num) => {
                  let count = cardList.filter((item, index) => {
                    return (
                      item.councel_type === idx.councel_type && index <= num
                    );
                  }).length;

                  let reverseCount =
                    cardList.filter((item, index) => {
                      return item.councel_type === idx.councel_type;
                    }).length -
                    count +
                    1;

                  return (
                    <SwiperSlide
                      style={{
                        boxShadow: "0px 3px 36px 0px rgba(108, 108, 108, 0.12)",
                        userSelect: "none",
                      }}
                      key={num}
                      className={`border-solid border-l-[6px] w-[170px] ml-[16px]
                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      ${
                        selectedCard &&
                        selectedCard.councel_idx === idx.councel_idx &&
                        "border-[2px]"
                      }
                      `}
                      onClick={() => {
                        if (state === "fix" || state === "create") {
                          openTextEditor();
                        } else if (idx.councel_idx !== undefined) {
                          handleCardClick(idx.councel_idx);
                        } else {
                          handleCardClick(idx.additional_councel);
                        }
                      }}
                    >
                      <div className="flex flex-col h-full justify-between py-[4px] gap-[4px]">
                        <p className="flex flex-row justify-between items-center">
                          <span
                            className={`text-B12B
                            ${idx.councel_type === 1 && "-text--color-etc-1"}
                            ${idx.councel_type === 2 && "-text--color-etc-2"}
                            ${idx.councel_type === 3 && "-text--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-text--color-success-main"
                            }`}
                          >
                            {idx.councel_type === 1 && `상담 - ${reverseCount}`}
                            {idx.councel_type === 2 && `계약 - ${reverseCount}`}
                            {idx.councel_type === 3 && `공사 - ${reverseCount}`}
                            {idx.additional_councel === "user_info" && `접수`}
                          </span>
                          <span className="text-B12R -text--color-neutral34">
                            {moment(idx.councel_datetime).format("YYYY.MM.DD")}
                          </span>
                        </p>
                        <p className="text-B14M text-nowrap overflow-hidden">
                          {removeTag(idx.councel_detail)}
                        </p>
                        {idx.additional_councel !== "user_info" && (
                          <p className="flex flex-row gap-[4px]">
                            <span className="text-B14B -text--color-neutral50">
                              담당자
                            </span>
                            <span className="text-B14M -text--color-neutral50">
                              {idx.admin_name}
                            </span>
                          </p>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        )}
      {(data?.process?.process_check === 2 ||
        data?.process?.process_check === 9) &&
        cardList.length > 1 && (
          <div
            className={`relative  h-full flex flex-row items-center w-full`}
            onMouseEnter={() => {
              if (cardList.length > 3) {
                setSlideBtnHoverState(true);
              }
            }}
            onMouseLeave={() => setSlideBtnHoverState(false)}
          >
            <button
              ref={prevRef}
              className={`w-[36px] h-full cursor-pointer -bg--color-neutral50 opacity-45 flex justify-center items-center z-20 absolute left-0 rounded-tr-[999px] rounded-br-[999px] ${
                !slideBtnState.leftBtn && "hidden"
              }
              ${
                slideBtnState.leftBtn && slideBtnHoverState ? "block" : "hidden"
              }
              `}
              onClick={handlePrevButtonClick}
            >
              <span className="_sprite_ _sprite_3_9_"></span>
            </button>

            <Swiper
              onSlideChange={(swiper) => handleSlideChange(swiper)}
              onReachBeginning={(swiper) => handleReachBeginning(swiper)}
              onReachEnd={(swiper) => handleReachEnd(swiper)}
              className="h-[calc(100%-32px)]"
              ref={swiperRef}
              // slidesPerView={3.9}
              slidesPerView={3.95}
              // slidesPerView={3}
              spaceBetween={8}
              modules={[Navigation]}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              onInit={() => setInit(true)}
              style={{ margin: 0 }}
            >
              {cardList &&
                cardList.map((idx, num) => {
                  let count = cardList.filter((item, index) => {
                    return (
                      item.councel_type === idx.councel_type && index <= num
                    );
                  }).length;

                  let reverseCount =
                    cardList.filter((item, index) => {
                      return item.councel_type === idx.councel_type;
                    }).length -
                    count +
                    1;

                  if (idx.type === "button") {
                    return (
                      <SwiperSlide
                        style={{
                          boxShadow: "0px 3px 8px rgba(108, 108, 108, 0.12)",
                          userSelect: "none",
                        }}
                        key={num}
                        className={`border-solid border-l-[6px] w-[170px] group relative
                          ${idx.councel_type === 1 && "-border--color-etc-1"}
                          ${idx.councel_type === 2 && "-border--color-etc-2"}
                          ${idx.councel_type === 3 && "-border--color-etc-3"}
                          ${
                            idx.additional_councel === "user_info" &&
                            "-border--color-success-main"
                          }
                          ${num === 0 && " ml-[16px]"}
                        `}
                        onClick={() => {
                          if (idx.councel_idx !== undefined) {
                            handleCardClick(idx.councel_idx);
                          } else {
                            handleCardClick(idx.additional_councel);
                          }
                        }}
                      ></SwiperSlide>
                    );
                  } else {
                    return (
                      <SwiperSlide
                        style={{
                          boxShadow: "0px 3px 8px rgba(108, 108, 108, 0.12)",
                          userSelect: "none",
                        }}
                        key={num}
                        className={`border-solid border-l-[6px] w-[170px] group relative 
                          ${idx.councel_type === 1 && "-border--color-etc-1"}
                          ${idx.councel_type === 2 && "-border--color-etc-2"}
                          ${idx.councel_type === 3 && "-border--color-etc-3"}
                          ${
                            idx.additional_councel === "user_info" &&
                            "-border--color-success-main"
                          }
                          ${num === 0 && " ml-[16px]"}                          
                        `}
                        onClick={() => {
                          if (idx.councel_idx !== undefined) {
                            handleCardClick(idx.councel_idx);
                          } else {
                            handleCardClick(idx.additional_councel);
                          }
                        }}
                      >
                        <span
                          className={`absolute border-solid border-t-[2px] transition-all rounded-tr-full top-0 left-0  ${
                            selectedCard &&
                            selectedCard.councel_idx === idx.councel_idx
                              ? "right-0 delay-0"
                              : "right-[calc(100%)] delay-150"
                          }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                        ></span>
                        <span
                          className={`absolute border-solid border-r-[2px] rounded-r-full transition-all top-0 right-0  ${
                            selectedCard &&
                            selectedCard.councel_idx === idx.councel_idx
                              ? "bottom-0 delay-75"
                              : "bottom-[calc(100%)] delay-100"
                          }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                        ></span>
                        <span
                          className={`absolute border-solid border-b-[2px] rounded-br-full transition-all bottom-0 right-0  ${
                            selectedCard &&
                            selectedCard.councel_idx === idx.councel_idx
                              ? "left-0 delay-100"
                              : "left-[calc(100%)] delay-75"
                          }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                        ></span>

                        <div className="flex flex-col h-full py-[4px] gap-[4px]">
                          <p className="flex flex-row justify-between items-center">
                            <span
                              className={`text-B12B
                            ${idx.councel_type === 1 && "-text--color-etc-1"}
                            ${idx.councel_type === 2 && "-text--color-etc-2"}
                            ${idx.councel_type === 3 && "-text--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-text--color-success-main"
                            }`}
                            >
                              {idx.councel_type === 1 &&
                                `상담 - ${reverseCount}`}
                              {idx.councel_type === 2 &&
                                `계약 - ${reverseCount}`}
                              {idx.councel_type === 3 &&
                                `공사 - ${reverseCount}`}
                              {idx.additional_councel === "user_info" && `접수`}
                            </span>
                            <span className="text-B12R -text--color-neutral34">
                              {moment(idx.councel_datetime).format(
                                "YYYY.MM.DD"
                              )}
                            </span>
                          </p>
                          <p className="text-B14M text-nowrap overflow-hidden">
                            {removeTag(idx.councel_detail)}
                          </p>
                          {idx.additional_councel !== "user_info" && (
                            <p className="flex flex-row gap-[4px]">
                              <span className="text-B14B -text--color-neutral50">
                                담당자
                              </span>
                              <span className="text-B14M -text--color-neutral50">
                                {idx.admin_name}
                              </span>
                            </p>
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  }
                })}
            </Swiper>
            <button
              ref={nextRef}
              className={`w-[36px] h-full cursor-pointer -bg--color-neutral50 opacity-45 flex justify-center items-center z-20 absolute right-0 rounded-tl-[999px] rounded-bl-[999px] 
                ${!slideBtnState.rightBtn && "hidden"}
                ${
                  slideBtnState.rightBtn && slideBtnHoverState
                    ? "block"
                    : "hidden"
                }
              `}
              onClick={handleNextButtonClick}
            >
              <span className="_sprite_ _sprite_3_10_"></span>
            </button>
          </div>
        )}
      {data?.process?.process_check !== 2 &&
        data?.process?.process_check !== 9 &&
        5 > cardList.length &&
        cardList.length > 1 && (
          <div
            className={`relative  h-full flex flex-row items-center w-full`}
            onMouseEnter={() => {
              if (cardList.length > 3) {
                setSlideBtnHoverState(true);
              }
            }}
            onMouseLeave={() => setSlideBtnHoverState(false)}
          >
            <div
              className={`relative  h-full flex flex-row items-center w-[calc(100%)]  transition-all`}
            >
              <button
                ref={prevRef}
                className={`w-[36px] h-full cursor-pointer -bg--color-neutral50 opacity-45 flex justify-center items-center z-20 absolute left-0 rounded-tr-[999px] rounded-br-[999px]
                ${!slideBtnState.leftBtn && "hidden"}
                ${
                  slideBtnState.leftBtn && slideBtnHoverState
                    ? "block"
                    : "hidden"
                }
              `}
                onClick={handlePrevButtonClick}
              >
                <span className="_sprite_ _sprite_3_9_"></span>
              </button>

              <Swiper
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                onReachBeginning={handleReachBeginning}
                onReachEnd={handleReachEnd}
                ref={swiperRef}
                className="h-[calc(100%-32px)]"
                slidesPerView={4.55}
                spaceBetween={8}
                modules={[Navigation]}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                onInit={() => setInit(true)}
                style={{ margin: 0 }}
              >
                <SwiperSlide
                  className="custom_swiper_button"
                  // onClick={() => {
                  //   setState("create");
                  // }}
                  onClick={() => {
                    if (state === "fix" || state === "create") {
                      openTextEditor();
                    } else {
                      setState("create");
                    }
                  }}
                  disabled={state === "fix" || state === "create"}
                >
                  <span className="_sprite_ _sprite_15_7_"></span>
                </SwiperSlide>

                {cardList &&
                  cardList.map((idx, num) => {
                    let count = cardList.filter((item, index) => {
                      return (
                        item.councel_type === idx.councel_type && index <= num
                      );
                    }).length;

                    let reverseCount =
                      cardList.filter((item, index) => {
                        return item.councel_type === idx.councel_type;
                      }).length -
                      count +
                      1;

                    if (idx.type === "button") {
                      return (
                        <SwiperSlide
                          style={{
                            boxShadow: "0px 3px 8px rgba(108, 108, 108, 0.12)",
                            userSelect: "none",
                          }}
                          key={num}
                          className={`border-solid border-l-[6px] w-[170px] relative py-[16px]
                            ${idx.councel_type === 1 && "-border--color-etc-1"}
                            ${idx.councel_type === 2 && "-border--color-etc-2"}
                            ${idx.councel_type === 3 && "-border--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-border--color-success-main"
                            }
                            ${
                              num === 0 && " ml-[16px]"
                            }                            
                          `}
                          onClick={() => {
                            if (idx.councel_idx !== undefined) {
                              handleCardClick(idx.councel_idx);
                            } else {
                              handleCardClick(idx.additional_councel);
                            }
                          }}
                        ></SwiperSlide>
                      );
                    } else {
                      return (
                        <SwiperSlide
                          style={{
                            boxShadow: "0px 3px 8px rgba(108, 108, 108, 0.12)",
                            userSelect: "none",
                          }}
                          key={num}
                          className={`border-solid border-l-[6px] w-[170px] group relative 
                            ${idx.councel_type === 1 && "-border--color-etc-1"}
                            ${idx.councel_type === 2 && "-border--color-etc-2"}
                            ${idx.councel_type === 3 && "-border--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-border--color-success-main"
                            }
                            ${num === 0 && " ml-[16px]"}
                          `}
                          onClick={() => {
                            if (state === "fix" || state === "create") {
                              openTextEditor();
                            } else if (idx.councel_idx !== undefined) {
                              handleCardClick(idx.councel_idx);
                            } else {
                              handleCardClick(idx.additional_councel);
                            }
                          }}
                        >
                          <span
                            className={`absolute border-solid border-t-[2px] transition-all rounded-tr-full top-0 left-0  ${
                              selectedCard &&
                              selectedCard.councel_idx === idx.councel_idx
                                ? "right-0 delay-0"
                                : "right-[calc(100%)] delay-150"
                            }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                          ></span>
                          <span
                            className={`absolute border-solid border-r-[2px] rounded-r-full transition-all top-0 right-0  ${
                              selectedCard &&
                              selectedCard.councel_idx === idx.councel_idx
                                ? "bottom-0 delay-75"
                                : "bottom-[calc(100%)] delay-100"
                            }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                          ></span>
                          <span
                            className={`absolute border-solid border-b-[2px] rounded-br-full transition-all bottom-0 right-0  ${
                              selectedCard &&
                              selectedCard.councel_idx === idx.councel_idx
                                ? "left-0 delay-100"
                                : "left-[calc(100%)] delay-75"
                            }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                          ></span>

                          <div className="flex flex-col h-full py-[4px] gap-[4px]">
                            <p className="flex flex-row justify-between items-center">
                              <span
                                className={`text-B12B
                            ${idx.councel_type === 1 && "-text--color-etc-1"}
                            ${idx.councel_type === 2 && "-text--color-etc-2"}
                            ${idx.councel_type === 3 && "-text--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-text--color-success-main"
                            }`}
                              >
                                {idx.councel_type === 1 &&
                                  `상담 - ${reverseCount}`}
                                {idx.councel_type === 2 &&
                                  `계약 - ${reverseCount}`}
                                {idx.councel_type === 3 &&
                                  `공사 - ${reverseCount}`}
                                {idx.additional_councel === "user_info" &&
                                  `접수`}
                              </span>
                              <span className="text-B12R -text--color-neutral34">
                                {moment(idx.councel_datetime).format(
                                  "YYYY.MM.DD"
                                )}
                              </span>
                            </p>
                            <p className="text-B14M text-nowrap overflow-hidden">
                              {removeTag(idx.councel_detail)}
                            </p>
                            {idx.additional_councel !== "user_info" && (
                              <p className="flex flex-row gap-[4px]">
                                <span className="text-B14B -text--color-neutral50">
                                  담당자
                                </span>
                                <span className="text-B14M -text--color-neutral50">
                                  {idx.admin_name}
                                </span>
                              </p>
                            )}
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })}
              </Swiper>

              <button
                ref={nextRef}
                className={`w-[36px] h-full cursor-pointer -bg--color-neutral50 opacity-45 flex justify-center items-center z-20 absolute right-0 rounded-tl-[999px] rounded-bl-[999px] 
                  ${!slideBtnState.rightBtn && "hidden"}
                  ${
                    slideBtnState.rightBtn && slideBtnHoverState
                      ? "block"
                      : "hidden"
                  }
                `}
                onClick={handleNextButtonClick}
              >
                <span className="_sprite_ _sprite_3_10_"></span>
              </button>
            </div>
          </div>
        )}
      {data?.process?.process_check !== 2 &&
        data?.process?.process_check !== 9 &&
        cardList.length > 4 && (
          <div
            className={`relative  h-full flex flex-row items-center w-full`}
            onMouseEnter={() => {
              if (cardList.length > 3) {
                setSlideBtnHoverState(true);
              }
            }}
            onMouseLeave={() => setSlideBtnHoverState(false)}
          >
            {!hiddenButton && (
              <button
                className={`p-[16px] cursor-pointer -bg--color-neutral12 rounded-[8px] flex justify-center items-center ml-[16px]`}
                onClick={() => {
                  openTextEditor();
                }}
              >
                <span className="_sprite_ _sprite_15_7_"></span>
              </button>
            )}
            <div
              className={`relative  h-full flex flex-row items-center ${
                !hiddenButton
                  ? "w-[calc(100%-72px)] transition-all"
                  : "w-[calc(100%)]  transition-all"
              }`}
            >
              <button
                ref={prevRef}
                className={`w-[36px] h-full cursor-pointer -bg--color-neutral50 opacity-45 flex justify-center items-center z-20 absolute left-0 rounded-tr-[999px] rounded-br-[999px]
                ${!slideBtnState.leftBtn && "hidden"}
                ${
                  slideBtnState.leftBtn && slideBtnHoverState
                    ? "block"
                    : "hidden"
                }
              `}
                onClick={handlePrevButtonClick}
              >
                <span className="_sprite_ _sprite_3_9_"></span>
              </button>

              <Swiper
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                onReachBeginning={handleReachBeginning}
                onReachEnd={handleReachEnd}
                ref={swiperRef}
                className="h-[calc(100%-32px)]"
                slidesPerView={!hiddenButton ? 3.5 : 4}
                spaceBetween={8}
                modules={[Navigation]}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                onInit={() => setInit(true)}
                style={{ margin: 0 }}
              >
                {cardList &&
                  cardList.map((idx, num) => {
                    let count = cardList.filter((item, index) => {
                      return (
                        item.councel_type === idx.councel_type && index <= num
                      );
                    }).length;

                    let reverseCount =
                      cardList.filter((item, index) => {
                        return item.councel_type === idx.councel_type;
                      }).length -
                      count +
                      1;

                    if (idx.type === "button") {
                      return (
                        <SwiperSlide
                          style={{
                            boxShadow: "0px 3px 8px rgba(108, 108, 108, 0.12)",
                            userSelect: "none",
                          }}
                          key={num}
                          className={`border-solid border-l-[6px] w-[170px] relative py-[16px]
                            ${idx.councel_type === 1 && "-border--color-etc-1"}
                            ${idx.councel_type === 2 && "-border--color-etc-2"}
                            ${idx.councel_type === 3 && "-border--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-border--color-success-main"
                            }
                            ${num === 0 && " ml-[16px]"}
                          `}
                          onClick={() => {
                            if (idx.councel_idx !== undefined) {
                              handleCardClick(idx.councel_idx);
                            } else {
                              handleCardClick(idx.additional_councel);
                            }
                          }}
                        ></SwiperSlide>
                      );
                    } else {
                      return (
                        <SwiperSlide
                          style={{
                            boxShadow: "0px 3px 8px rgba(108, 108, 108, 0.12)",
                            userSelect: "none",
                          }}
                          key={num}
                          className={`border-solid border-l-[6px] w-[170px] group relative 
                            ${idx.councel_type === 1 && "-border--color-etc-1"}
                            ${idx.councel_type === 2 && "-border--color-etc-2"}
                            ${idx.councel_type === 3 && "-border--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-border--color-success-main"
                            }
                            ${num === 0 && " ml-[16px]"}
                          `}
                          onClick={() => {
                            if (state === "fix" || state === "create") {
                              openTextEditor();
                            } else if (idx.councel_idx !== undefined) {
                              handleCardClick(idx.councel_idx);
                            } else {
                              handleCardClick(idx.additional_councel);
                            }
                          }}
                        >
                          <span
                            className={`absolute border-solid border-t-[2px] transition-all rounded-tr-full top-0 left-0  ${
                              selectedCard &&
                              selectedCard.councel_idx === idx.councel_idx
                                ? "right-0 delay-0"
                                : "right-[calc(100%)] delay-150"
                            }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                          ></span>
                          <span
                            className={`absolute border-solid border-r-[2px] rounded-r-full transition-all top-0 right-0  ${
                              selectedCard &&
                              selectedCard.councel_idx === idx.councel_idx
                                ? "bottom-0 delay-75"
                                : "bottom-[calc(100%)] delay-100"
                            }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                          ></span>
                          <span
                            className={`absolute border-solid border-b-[2px] rounded-br-full transition-all bottom-0 right-0  ${
                              selectedCard &&
                              selectedCard.councel_idx === idx.councel_idx
                                ? "left-0 delay-100"
                                : "left-[calc(100%)] delay-75"
                            }

                      ${idx.councel_type === 1 && "-border--color-etc-1"}
                      ${idx.councel_type === 2 && "-border--color-etc-2"}
                      ${idx.councel_type === 3 && "-border--color-etc-3"}
                      ${
                        idx.additional_councel === "user_info" &&
                        "-border--color-success-main"
                      }
                      `}
                          ></span>

                          <div className="flex flex-col h-full py-[4px] gap-[4px]">
                            <p className="flex flex-row justify-between items-center">
                              <span
                                className={`text-B12B
                            ${idx.councel_type === 1 && "-text--color-etc-1"}
                            ${idx.councel_type === 2 && "-text--color-etc-2"}
                            ${idx.councel_type === 3 && "-text--color-etc-3"}
                            ${
                              idx.additional_councel === "user_info" &&
                              "-text--color-success-main"
                            }`}
                              >
                                {idx.councel_type === 1 &&
                                  `상담 - ${reverseCount}`}
                                {idx.councel_type === 2 &&
                                  `계약 - ${reverseCount}`}
                                {idx.councel_type === 3 &&
                                  `공사 - ${reverseCount}`}
                                {idx.additional_councel === "user_info" &&
                                  `접수`}
                              </span>
                              <span className="text-B12R -text--color-neutral34">
                                {moment(idx.councel_datetime).format(
                                  "YYYY.MM.DD"
                                )}
                              </span>
                            </p>
                            <p className="text-B14M text-nowrap overflow-hidden">
                              {removeTag(idx.councel_detail)}
                            </p>
                            {idx.additional_councel !== "user_info" && (
                              <p className="flex flex-row gap-[4px]">
                                <span className="text-B14B -text--color-neutral50">
                                  담당자
                                </span>
                                <span className="text-B14M -text--color-neutral50">
                                  {idx.admin_name}
                                </span>
                              </p>
                            )}
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })}
              </Swiper>

              <button
                ref={nextRef}
                className={`w-[36px] h-full cursor-pointer -bg--color-neutral50 opacity-45 flex justify-center items-center z-20 absolute right-0 rounded-tl-[999px] rounded-bl-[999px]
                  ${!slideBtnState.rightBtn && "hidden"}
                  ${
                    slideBtnState.rightBtn && slideBtnHoverState
                      ? "block"
                      : "hidden"
                  }
                `}
                onClick={handleNextButtonClick}
              >
                <span className="_sprite_ _sprite_3_10_"></span>
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default CouncelList;
