import React, { useEffect, useState } from "react";

import { useRecoilState, useResetRecoilState } from "recoil";

import ReactQuill, { Quill } from "react-quill";

import http from "@/utils/api/http/http";

import { modalInModalInfo, modalInfo } from "@/stores/common/modal";
import {
  moveCouncelNoteList,
  selectDate,
  tempSelectedDetailCouncelNote,
} from "@/stores/common/Mobile";

import { closePortal, showPortal } from "@/utils/func/common/modalController";

import "react-quill/dist/quill.snow.css";
import "@/styles/textEditor.css";

import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
import moment from "moment";

import { responseData } from "@/stores/common/data";

import {
  selectedDetailCouncelNote,
  selectedNote,
} from "@/stores/page/councelDetail";

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/ImageCompress", ImageCompress);

const CouncelFixCouncelnoteMobile = () => {
  const [selectedCouncelNote, setSelectedCouncelNote] = useRecoilState(
    selectedDetailCouncelNote
  );

  const [modalStatus, setModalStatus] = useRecoilState(modalInfo);
  const [modalinModalStatus, setModalinModalStatus] =
    useRecoilState(modalInModalInfo);

  const [dateState, setDateState] = useRecoilState(selectDate);
  const resetDateState = useResetRecoilState(selectDate);

  const [tempState, setTempState] = useRecoilState(
    tempSelectedDetailCouncelNote
  );

  const [, setResultCouncelData] = useRecoilState(responseData);

  const [selectedNoteInfo, setSelectedNoteInfo] = useRecoilState(selectedNote);

  const [showNote, setShowNote] = useRecoilState(moveCouncelNoteList);

  useEffect(() => {
    setTempState(selectedCouncelNote);
  }, []);

  const handleChange = (e) => {
    setTempState((prev) => {
      return { ...prev, councel_detail: e };
    });
  };

  async function submitContent() {
    try {
      return await http
        .put("/api/councel", {
          councel_idx: tempState.councel_idx,
          councel_detail: tempState.councel_detail,
          councel_datetime: dateState
            ? moment(dateState).subtract(9, "hours").format()
            : moment().subtract(9, "hours").format(),
        })
        .then((res) => {
          if (res.status === 200) {
            closePortal(setModalStatus);

            setShowNote(true);

            resetDateState();

            // setSelectedCouncelNote(res.data.data);

            // 이거 말고 get를 다시 해줘야 할 듯
            // get을 한번 더 해주고
            // 결과값에서 councel_idx가 같은 것을 찾아서 담아줘야 할 듯
            http
              .get(`/api/councel/detail/list`, {
                params: { process_idx: selectedNoteInfo.process },
              })
              .then((res) => {
                res.data.data.councel.filter((idx) => {
                  if (idx.councel_idx === tempState.councel_idx) {
                    return setSelectedCouncelNote(idx);
                  }
                });

                setResultCouncelData(res.data.data);
              });
          } else {
            alert("상담 내용 저장에 실패하였습니다.");
          }
        });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="plr-16 ptb-16 flex flex-col items-start gap-[16px]">
      <span className="-text--body-14b font-700">상담 진행일</span>
      <button
        className="border border-solid ptb-12 plr-16 font-400 inline-block w-full"
        onClick={() => {
          showPortal(
            setModalinModalStatus,
            true,
            "councel-councelnote-calendar-mobile"
          );
        }}
      >
        {dateState !== null
          ? dateState.slice(0, 10)
          : tempState.councel_datetime.slice(0, 10)}
      </button>
      <div className="w-full border border-solid -border--color-neutral6"></div>
      <div className="w-full h-[calc(100vh-250px)]">
        <ReactQuill
          placeholder="상담 내용을 입력해주세요."
          className="quill-mobile"
          value={tempState.councel_detail}
          onChange={(e) => handleChange(e)}
          modules={modules}
        />
      </div>
      <div className="absolute w-full flex justify-end bottom-0 right-0 -bg--color-neutral6 pt-[2px] pb-[2px]">
        <button
          className="-text--color-primary-main ptb-8 plr-8 mr-[16px] -text--body-16b font-700"
          onClick={() => {
            submitContent();
          }}
        >
          저장
        </button>
      </div>
    </div>
  );
};

export default CouncelFixCouncelnoteMobile;

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ["bold"],
      ["underline"],
      ["strike"],
      // [{ align: "" }, { align: "center" }, { align: "right" }],
      [{ list: "ordered" }],
      [{ list: "bullet" }],
      [
        {
          color: [
            "#ffa1a1",
            "#feff40",
            "#c3ec00",
            "#9ee7ff",
            "#ff002e",
            "#ff900e",
            "#00be57",
            "#2d81ff",
            "#ca34ff",
            "#c1c1c1",
            "#888888",
            "#000000",
          ],
        },
        {
          background: [
            "#ffa1a1",
            "#feff40",
            "#c3ec00",
            "#9ee7ff",
            "#ff002e",
            "#ff900e",
            "#00be57",
            "#2d81ff",
            "#ca34ff",
            "#c1c1c1",
            "#888888",
            "#000000",
          ],
        },
      ],
    ],
  },
};
