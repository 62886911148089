import React, { useEffect, useState } from "react";

import { selector, useRecoilState, useRecoilValue } from "recoil";

import { modalInfo } from "@/stores/common/modal";

import { showPortal } from "@/utils/func/common/modalController";

import { clickedPage } from "@/stores/common/Pagination";
import { councelParameter, paramSelector } from "@/stores/common/parameter";
import {
  filterController,
  mycheckController,
  searchController,
} from "@/utils/func/common/filter";

const CouncelFilter = () => {
  // 모달 창 제어
  const [modal, setModal] = useRecoilState(modalInfo);

  const [parameter, setParameter] = useRecoilState(councelParameter);

  const [selectPage, setSelectPage] = useRecoilState(clickedPage);

  const scopeParameter = useRecoilValue(paramSelector);

  const handleMyCouncel = (e) => {
    mycheckController(e, setParameter);
  };

  const handleCategoryValue = (val) => {
    filterController({ value: "coun", e: val, callback: setParameter });
  };

  return (
    <>
      <div className="fixed top-[0px] w-[1128px] h-[24px] flex items-center justify-between z-[3] -bg--color-neutral0"></div>
      <div className="fixed top-[24px] w-[1128px] h-[50px] flex items-center justify-between -bg--color-neutral0 z-[3]">
        <div className="w-[322px] h-[px] flex flex-row items-center justify-between gap-[8px]">
          <li
            className={
              parameter.detail_type === "전체" ||
              parameter.detail_type === undefined
                ? "w-[58px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : "w-[58px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => handleCategoryValue("전체")}
          >
            <span className="text-H18B">전체</span>
          </li>
          <li
            className={
              parameter.detail_type === "홈페이지"
                ? "w-[91px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : "w-[91px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => handleCategoryValue("홈페이지")}
          >
            <span className="text-H18B">홈페이지</span>
          </li>
          <li
            className={
              parameter.detail_type === "유선상담"
                ? "w-[91px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : "w-[91px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => handleCategoryValue("유선상담")}
          >
            <span className="text-H18B">유선상담</span>
          </li>
          <li
            className={
              parameter.detail_type === "기타"
                ? "w-[58px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral100 cursor-pointer"
                : "w-[58px] h-[50px] flex items-center justify-center text-H18B -text--color-neutral50 cursor-pointer"
            }
            onClick={() => handleCategoryValue("기타")}
          >
            <span className="text-H18B">기타</span>
          </li>
        </div>
        <div className="flex flex-row gap-[24px]">
          <form className="h-[50px] flex justify-center items-center gap-[10px] text-B16R">
            <input
              id="my_councel"
              type="checkbox"
              name="filter_councel"
              value="my_councel"
              className="w-[18px] h-[18px]"
              onClick={(e) => {
                handleMyCouncel(e);
              }}
              defaultChecked={parameter.mycheck}
            />
            <label htmlFor="my_councel">내 상담만 보기</label>
          </form>
          <div className="flex justify-center items-center text-B16R">
            <button
              // className="px-[16px] py-[10px] rounded-[6px] border border-solid -border--color-neutral12 -text--color-neutral50 text-B16R"
              className={`px-[16px] py-[10px] rounded-[6px] border border-solid -border--color-neutral12 -text--color-neutral50 text-B16R ${
                scopeParameter === true &&
                "-border--color-primary-border -text--color-primary-main"
              }`}
              onClick={() => {
                showPortal(setModal, true, "councel-filtering-web");
              }}
            >
              필터
            </button>
          </div>
          <div className="relative w-[335px] h-[50px]">
            <input
              type="textarea"
              defaultValue=""
              placeholder="상담번호, 이름 또는 휴대폰 검색"
              className="border border-solid -border--color-neutral12 rounded-full w-[335px] h-[50px] -placeholder--color-neutral20 focus:outline-none placeholder:text-B16R py-[15px] pl-[16px]"
              onChange={(e) => {
                setSelectPage(1);
                searchController(e, setParameter);
              }}
            />
            <button className="absolute right-0 pr-[16px] h-full">
              <span className="_sprite_ _sprite_7_13_"></span>
            </button>
          </div>
        </div>
      </div>
      <div className="fixed w-[1128px] h-[24px] top-[74px] -bg--color-neutral0 z-30 "></div>
    </>
  );
};

export default CouncelFilter;
