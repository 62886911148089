import React from "react";

import NavBarReport from "@/components/common/NavBarReport";
import SideBarReport from "@/components/common/SideBarReport";

// 요약 보고서 작업 분
export default function PagesLayoutSummary() {
  return (
    <div className="h-screen">
      <NavBarReport />
      <SideBarReport />
    </div>
  );
}
