import { atom } from "recoil";

// 5단위로 묶은 페이지
export const bindPage = atom({
  key: "bindPage",
  default: 1,
});

// 선택한 페이지
export const clickedPage = atom({
  key: "clickedPage",
  default: 1,
});
