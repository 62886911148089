import { useRecoilState } from "recoil";
import { closePortal } from "@/utils/func/common/modalController";

import React from "react";
import { modalInfo } from "@/stores/common/modal";
import http from "@/utils/api/http/http";
import { responseData } from "@/stores/common/data";
import {
  commentContent,
  councelNoteAddressIdx,
  selectedCommentIndex,
} from "@/stores/page/councelDetail";

const CouncelAddCommentMobile = () => {
  const [modal, setModal] = useRecoilState(modalInfo);

  const [resultCouncelData, setResultCouncelData] =
    useRecoilState(responseData);

  const [selectedComment, setSelectedComment] =
    useRecoilState(selectedCommentIndex);

  const [commentState, setCommentState] = useRecoilState(commentContent);

  const [addressIdxList, setAddressIdxList] = useRecoilState(
    councelNoteAddressIdx
  );

  async function deleteComment() {
    try {
      await http.delete(`/api/councel/comment`, {
        params: { comment_idx: selectedComment },
      });
      await http
        .get(`/api/councel/detail/list`, {
          params: { process_idx: resultCouncelData.process.process_idx },
        })
        .then((res) => {
          http
            .post(`/api/councel/detail`, {
              user_idx: resultCouncelData.user.user_idx,
              process_idx: resultCouncelData.process.process_idx,
              address_idx_list: addressIdxList,
              admin_idx: localStorage.getItem("admin_idx"),
            })
            .then((res) => {
              setResultCouncelData(res.data.data);
              setCommentState("");
            });
        });
      closePortal(setModal);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div
      className="absolute left-1/2 top-1/2 -bg--color-neutral0 -translate-x-1/2 -translate-y-1/2"
      style={{
        boxShadow: "0px 3px 36px 0px rgba(108, 108, 108, 0.12)",
      }}
    >
      <div className="px-[16px] py-[24px] flex flex-col gap-[4px] items-center justify-center">
        <p className="-text--title-18b font-700 -text--color-neutral100">
          댓글을 삭제할까요?
        </p>
        <p className="-text--body-14r font-400 -text--color-neutral50">
          삭제하면 복구가 불가능해요.
        </p>
      </div>
      <div className="flex flex-row items-center w-[328px] justify-around">
        <button
          className="py-[10px] w-1/2 -text--body-16b font-700 -text--color-neutral50"
          onClick={() => closePortal(setModal)}
        >
          닫기
        </button>
        <button
          className="py-[10px] w-1/2 -text--body-16b font-700 -text--color-danger-main"
          onClick={() => deleteComment()}
        >
          삭제
        </button>
      </div>
    </div>
  );
};

export default CouncelAddCommentMobile;
