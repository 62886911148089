/**
 * @description 모바일 사용 시 브라우저 주소창 높이로 인해 모달이 잘리는 이슈를 해결
 */
export function detectHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

/**
 * @description 브라우저 너비에 따라 상태 값 변경
 */
export function detectResize(props: any) {
  const { setDeviceState } = props;

  if (document.documentElement.clientWidth > 640) {
    setDeviceState("web");
  }

  if (document.documentElement.clientWidth <= 640) {
    setDeviceState("mobile");
  }
}
