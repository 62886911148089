import React from "react";

import error_description from "@/Assets/Images/error_description.svg";

const Error = () => {
  return (
    <div className="h-screen -bg--color-neutral0 flex items-center justify-center">
      <img src={error_description} alt="" />
    </div>
  );
};

export default Error;
