import moment from "moment";

/**
 *
 * @param {*} setEmptyData 캘린더 페이지 기본 호출 parameter 담는 상태 값
 * @param {*} setCategoryModal 모달 창 제어
 * @returns
 */
export const calendarCategoryList = (setEmptyData, setCategoryModal) => {
  let categoryList = [
    { name: "서울사무소" },
    { name: "여주전시관" },
    { name: "여주공장" },
    { name: "제주전시관" },
    { name: "기타" },
  ];

  const ctrlSelectOpt = (name) => {
    if (name === "전체") {
      setEmptyData((prev) => {
        return { ...prev, calender_category: "" };
      });
      setCategoryModal(false);
    } else {
      setEmptyData((prev) => {
        return { ...prev, calender_category: name };
      });
      setCategoryModal(false);
    }
  };

  return categoryList.map((idx, num) => {
    let colorClass;

    if (idx.name === "서울사무소") {
      colorClass = "office-seoul";
    } else if (idx.name === "여주전시관") {
      colorClass = "showroom-yeoju";
    } else if (idx.name === "여주공장") {
      colorClass = "factory-yeoju";
    } else if (idx.name === "제주전시관") {
      colorClass = "showroom-jeju";
    } else if (idx.name === "기타") {
      colorClass = "etc";
    }

    return (
      <li
        className="w-[125px] h-[44px] flex ptb-12 plr-16 items-center hover:-bg--color-neutral3 gap-[8px]"
        onClick={() => ctrlSelectOpt(idx.name)}
        key={num}
      >
        <span
          className={`w-[20px] h-[20px] rounded-[4px] -bg--color-${colorClass}`}
        ></span>
        <span className="text-B14R">{idx.name}</span>
      </li>
    );
  });
};

/**
 *
 * @param {*} e input checked
 * @param {*} v checked index 값
 * @param {*} tempAttendee 기존 참석자 명단
 * @param {*} setTempAttendee 참석자 명단 handle callback
 */
export const ctrlCheckedAttendee = (e, v, tempAttendee, setTempAttendee) => {
  if (e.target.checked) {
    setTempAttendee((prev) => {
      return [...prev, v];
    });
  } else {
    // 체크 박스가 체크 해제되었을 때 항목 제거
    const updatedAttendees = tempAttendee.filter(
      (item) => item.admin_idx !== v.admin_idx
    );

    setTempAttendee(updatedAttendees);
  }
};

/**
 *
 * @param {emptyData, setEmptyData} 캘린더 페이지 기본 호출 parameter 담는 상태 값, handle 함수
 * @returns
 */
export const ctrlStartDatePicker = ({ emptyData, setEmptyData }) => {
  function nextMonth() {
    setEmptyData((prev) => {
      return {
        ...prev,
        calender_start: moment(emptyData.calender_start)
          .add(1, "months")
          .format("YYYY-MM-DD (dd)"),
      };
    });
  }

  function prevMonth() {
    setEmptyData((prev) => {
      return {
        ...prev,
        calender_start: moment(emptyData.calender_start)
          .subtract(1, "months")
          .format("YYYY-MM-DD (dd)"),
      };
    });
  }

  function renderMonth() {
    const startDay = moment(emptyData.calender_start)
      .clone()
      .startOf("month")
      .startOf("week");

    const endDay = moment(emptyData.calender_start)
      .clone()
      .endOf("month")
      .endOf("week");

    const day = startDay.clone().subtract(1, "day");

    const calendar = [];

    while (day.isBefore(endDay, "day")) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone())
      );
    }

    function handleDateClick(day) {
      const updateStartDate = moment(emptyData.calender_start)
        .set("year", day.year())
        .set("month", day.month())
        .set("date", day.date());

      setEmptyData((prev) => ({
        ...prev,
        calender_start: updateStartDate.format(),
      }));
    }

    return (
      <div className="w-full">
        <div className="w-full h-[44px] flex flex-row justify-between items-center">
          <button
            onClick={prevMonth}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="_sprite_ _sprite_17_3_"></span>
          </button>
          <h2>{moment(emptyData.calender_start).format("YYYY.MM")}</h2>
          <button
            onClick={nextMonth}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="_sprite_ _sprite_17_4_"></span>
          </button>
        </div>
        <div>
          <div className="grid grid-cols-7 h-[40px]">
            <span className="flex items-center justify-center">일</span>
            <span className="flex items-center justify-center">월</span>
            <span className="flex items-center justify-center">화</span>
            <span className="flex items-center justify-center">수</span>
            <span className="flex items-center justify-center">목</span>
            <span className="flex items-center justify-center">금</span>
            <span className="flex items-center justify-center">토</span>
          </div>
          {calendar.map((row, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-7 h-[40px] items-center"
              >
                {row.map((day, index) => {
                  const isPreviousMonth = day.isBefore(
                    emptyData.calender_start,
                    "month"
                  );
                  const isNextMonth = day.isAfter(
                    emptyData.calender_start,
                    "month"
                  );

                  let classNames = "flex items-center justify-center";

                  if (isPreviousMonth) {
                    classNames += " opacity-40";
                  } else if (isNextMonth) {
                    classNames += " opacity-40";
                  }

                  return (
                    <button
                      className="relative w-[44px] h-[44px] flex flex-col items-center justify-center"
                      onClick={() => {
                        handleDateClick(day);
                      }}
                    >
                      <span key={index} className={classNames}>
                        {day.format("D")}
                      </span>
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col ptb-16 gap-[8px] border-b border-solid -border-b--color-neutral6">
        <div className="flex items-center justify-center plr-16">
          {renderMonth(emptyData.caledner_start)}
        </div>
      </div>
      <div className="flex flex-row h-[64px] items-center justify-between plr-16">
        <span>Time</span>
        <div>
          <input
            className="-bg--color-neutral3 w-[44px] h-[36px] text-center"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            value={moment(emptyData.calender_start).format("HH")}
            onInput={(e) => {
              const inputValue = e.target.value.replace(/\D/g, ""); // 숫자만 추출
              const value = Math.min(parseInt(inputValue, 10), 24); // 최대값 24로 제한

              const updatedStartDate = moment(emptyData.calender_start).hours(
                value
              );
              setEmptyData((prev) => ({
                ...prev,
                calender_start: updatedStartDate.format(),
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();

                const increment = e.key === "ArrowUp" ? 1 : -1;

                const updatedStartDate = moment(emptyData.calender_start).add(
                  increment,
                  "hours"
                );

                setEmptyData((prev) => ({
                  ...prev,
                  calender_start: updatedStartDate.format(),
                }));
              } else {
                return;
              }
            }}
          />
          <span> : </span>
          <input
            className="-bg--color-neutral3 w-[44px] h-[36px] text-center"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            value={moment(emptyData.calender_start).format("mm")}
            onInput={(e) => {
              const inputValue = e.target.value.replace(/\D/g, ""); // 숫자만 추출
              const value = Math.min(parseInt(inputValue, 10), 60); // 최대값 24로 제한

              const updatedStartDate = moment(emptyData.calender_start).minutes(
                value
              );
              setEmptyData((prev) => ({
                ...prev,
                calender_start: updatedStartDate.format(),
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();

                const increment = e.key === "ArrowUp" ? 1 : -1;

                const updatedStartDate = moment(emptyData.calender_start).add(
                  increment,
                  "minutes"
                );

                setEmptyData((prev) => ({
                  ...prev,
                  calender_start: updatedStartDate.format(),
                }));
              } else {
                return;
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

/**
 *
 * @param {emptyData, setEmptyData} 캘린더 페이지 기본 호출 parameter 담는 상태 값, handle 함수
 * @returns
 */
export const ctrlEndDatePicker = ({ emptyData, setEmptyData }) => {
  function nextMonth() {
    setEmptyData((prev) => {
      return {
        ...prev,
        calender_end: moment(emptyData.calender_end)
          .add(1, "months")
          .format("YYYY-MM-DD (dd)"),
      };
    });
  }

  function prevMonth() {
    setEmptyData((prev) => {
      return {
        ...prev,
        calender_end: moment(emptyData.calender_end)
          .subtract(1, "months")
          .format("YYYY-MM-DD (dd)"),
      };
    });
  }

  function renderMonth() {
    const startDay = moment(emptyData.calender_end)
      .clone()
      .startOf("month")
      .startOf("week");

    const endDay = moment(emptyData.calender_end)
      .clone()
      .endOf("month")
      .endOf("week");

    const day = startDay.clone().subtract(1, "day");

    const calendar = [];

    while (day.isBefore(endDay, "day")) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone())
      );
    }

    function handleDateClick(day) {
      if (emptyData.start > day.format()) {
        alert("시작일보다 이전 달은 선택할 수 없습니다.");
      } else {
        const updateEndDate = moment(emptyData.calender_end)
          .set("year", day.year())
          .set("month", day.month())
          .set("date", day.date());

        setEmptyData((prev) => ({
          ...prev,
          calender_end: updateEndDate.format(),
        }));
      }
    }

    return (
      <div className="w-full">
        <div className="w-full h-[44px] flex flex-row justify-between items-center">
          <button
            onClick={prevMonth}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="_sprite_ _sprite_17_3_"></span>
          </button>

          <h2>{moment(emptyData.calender_end).format("YYYY.MM")}</h2>

          <button
            onClick={nextMonth}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="_sprite_ _sprite_17_4_"></span>
          </button>
        </div>
        <div>
          <div className="grid grid-cols-7 h-[40px]">
            <span className="flex items-center justify-center">일</span>
            <span className="flex items-center justify-center">월</span>
            <span className="flex items-center justify-center">화</span>
            <span className="flex items-center justify-center">수</span>
            <span className="flex items-center justify-center">목</span>
            <span className="flex items-center justify-center">금</span>
            <span className="flex items-center justify-center">토</span>
          </div>
          {calendar.map((row, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-7 h-[40px] items-center"
              >
                {row.map((day, index) => {
                  const isPreviousMonth = day.isBefore(
                    emptyData.calender_end,
                    "month"
                  );
                  const isNextMonth = day.isAfter(
                    emptyData.calender_end,
                    "month"
                  );

                  let classNames = "flex items-center justify-center";

                  if (isPreviousMonth) {
                    classNames += " opacity-40";
                  } else if (isNextMonth) {
                    classNames += " opacity-40";
                  }

                  return (
                    <button
                      className="relative w-[44px] h-[44px] flex flex-col items-center justify-center"
                      onClick={() => {
                        handleDateClick(day);
                      }}
                    >
                      <span key={index} className={classNames}>
                        {day.format("D")}
                      </span>
                    </button>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col ptb-16 gap-[8px] border-b border-solid -border-b--color-neutral6">
        <div className="flex items-center justify-center plr-16">
          {renderMonth(emptyData.calender_end)}
        </div>
      </div>
      <div className="flex flex-row h-[64px] items-center justify-between plr-16">
        <span>Time</span>
        <div>
          <input
            className="-bg--color-neutral3 w-[44px] h-[36px] text-center"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            value={moment(emptyData.calender_end).format("HH")}
            onInput={(e) => {
              const inputValue = e.target.value.replace(/\D/g, ""); // 숫자만 추출
              const value = Math.min(parseInt(inputValue, 10), 24); // 최대값 24로 제한

              const updatedEndDate = moment(emptyData.calender_end).hours(
                value
              );
              setEmptyData((prev) => ({
                ...prev,
                calender_end: updatedEndDate.format(),
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();

                const increment = e.key === "ArrowUp" ? 1 : -1;

                const updatedEndDate = moment(emptyData.calender_end).add(
                  increment,
                  "hours"
                );

                setEmptyData((prev) => ({
                  ...prev,
                  calender_end: updatedEndDate.format(),
                }));
              } else {
                return;
              }
            }}
          />
          <span> : </span>
          <input
            className="-bg--color-neutral3 w-[44px] h-[36px] text-center"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            value={moment(emptyData.calender_end).format("mm")}
            onInput={(e) => {
              const inputValue = e.target.value.replace(/\D/g, ""); // 숫자만 추출
              const value = Math.min(parseInt(inputValue, 10), 60); // 최대값 24로 제한

              const updatedEndDate = moment(emptyData.calender_end).minutes(
                value
              );
              setEmptyData((prev) => ({
                ...prev,
                calender_end: updatedEndDate.format(),
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();

                const increment = e.key === "ArrowUp" ? 1 : -1;

                const updatedEndDate = moment(emptyData.calender_end).add(
                  increment,
                  "minutes"
                );

                setEmptyData((prev) => ({
                  ...prev,
                  calender_end: updatedEndDate.format(),
                }));
              } else {
                return;
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
