import React, { useEffect } from "react";

import { useRecoilState } from "recoil";

import ModalBackgroundLayout from "@/layout/ModalBackgroundLayout";
import ModalLayout from "@/layout/ModalLayout";
import ModalInModalLayout from "@/layout/ModalInModalLayout";

import Portal from "@/layout/Portal";
import PortalInPortal from "@/layout/PortalInPortal";

import { modalInfo, modalInModalInfo } from "@/stores/common/modal";

import { device } from "@/stores/common/index";
import { detectHeight, detectResize } from "@/utils/func/common/mobile";

import Router from "@/components/router/Router";

function App() {
  const [modal, setModal] = useRecoilState(modalInfo);
  const [modalInModal, setModalInModal] = useRecoilState(modalInModalInfo);

  const [, setDeviceState] = useRecoilState(device);

  useEffect(() => {
    detectResize({ setDeviceState });

    window.addEventListener("resize", () => detectResize({ setDeviceState }));

    return () => {
      window.removeEventListener("resize", () =>
        detectResize({ setDeviceState })
      );
    };
  }, []);

  useEffect(() => {
    detectHeight();

    window.addEventListener("resize", detectHeight);

    return () => {
      window.removeEventListener("resize", detectHeight);
    };
  }, []);

  return (
    <React.Fragment>
      <Router />

      <Portal>
        {modal.isOpen && (
          <ModalBackgroundLayout value={modal.value} callback={setModal}>
            <ModalLayout />
          </ModalBackgroundLayout>
        )}
      </Portal>

      <PortalInPortal>
        {modalInModal.isOpen && (
          <ModalBackgroundLayout value={modal.value} callback={setModalInModal}>
            <ModalInModalLayout />
          </ModalBackgroundLayout>
        )}
      </PortalInPortal>
    </React.Fragment>
  );
}

export default App;
