import React from "react";

import { useRecoilState } from "recoil";

import { modalInfo } from "@/stores/common/modal";

import { showPortal } from "@/utils/func/common/modalController";

import moment from "moment";

interface propsType {
  data: any;
  state: boolean;
  callback: (arg: any) => void;
}

const MobileSelectedCouncelNote = ({ data, state, callback }: propsType) => {
  const [modal, setModal] = useRecoilState(modalInfo);

  const fixCouncelNote = () => {
    showPortal(setModal, true, "councel-fix-councelnote-mobile");
  };

  return (
    <div
      className="plr-16 ptb-16 flex flex-col"
      onTouchStart={() => callback(!state)}
    >
      <div className="relative">
        <span
          className={`-text--body-16b font-700 mb-[8px]
  ${data?.councel_type === 1 && "-text--color-etc-1"} 
  ${data?.councel_type === 2 && "-text--color-etc-2"} 
  ${data?.councel_type === 3 && "-text--color-etc-3"}`}
        >
          {data?.councel_type === 1 && "상담"}
          {data?.councel_type === 2 && "계약"}
          {data?.councel_type === 3 && "공사"}
        </span>
        <div className="flex flex-row gap-[24px]">
          <p className="flex flex-row gap-[8px] items-center">
            <span className="-text--body-14b font-700">작성자</span>
            <span className="-text--body-14r font-400">{data?.admin_name}</span>
          </p>
          <p className="flex flex-row gap-[8px] items-center">
            <span className="-text--body-14b font-700">상담 진행일</span>
            <span className="-text--body-14r font-400">
              {moment(data?.councel_datetime)
                .add(9, "hours")
                .format("YYYY-MM-DD")}
            </span>
          </p>
        </div>
        <button
          className="absolute bottom-0 right-0 border border-solid ptb-8 pl-[17.5px] pr-[17.5px] rounded-[4px] -text--body-14b -text--color-neutral50 font-700"
          onClick={() => {
            fixCouncelNote();
          }}
        >
          수정
        </button>
      </div>
      <div className="border-t border-solid -border--color-neutral12 mb-[16px]  mt-[16px]"></div>
      <div className="mb-[76px]">
        <p className="flex flex-row items-center gap-[8px] mb-[16px]">
          <span className="-text--body-14b font-700 -text--color-neutral50">
            최종 수정일
          </span>
          <span className="-text--body-14r -text--color-neutral50 font-400">
            {moment(data?.last_datetime)
              .add(9, "hours")
              .format("YYYY-MM-DD HH:mm:ss")}
          </span>
        </p>
        <div>
          <div
            className="whitespace-normal break-words overflow-y-auto custom-scrollbar react-quill-contents"
            dangerouslySetInnerHTML={{
              __html: data?.councel_detail,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileSelectedCouncelNote;
