import { childrenType } from "@/types/common";
import ReactDOM from "react-dom";

const PortalInPortal = ({ children }: childrenType) => {
  const ele: any = document.querySelector("#portal-in-portal");

  return ReactDOM.createPortal(children, ele);
};

export default PortalInPortal;
