import { childrenType } from "@/types/common";
import React from "react";

const MobileUserInfoLayout = ({ children }: childrenType) => {
  return (
    <main className="absolute -bg--color-neutral3 w-full mt-[48px] flex flex-col min-h-[calc(100%-48px-76px)] pb-[76px]">
      {children}
    </main>
  );
};

export default MobileUserInfoLayout;
