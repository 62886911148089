import React, { useEffect, useState } from "react";

import { useRecoilState, useSetRecoilState } from "recoil";

import { modalInfo } from "@/stores/common/modal";
import {
  responseCalendar,
  copiedResponseCalendar,
  parameterCalendar,
} from "@/stores/page/calendar";

import http from "@/utils/api/http/http";
import { showPortal } from "@/utils/func/common/modalController";

import moment from "moment";

import Schedule from "@/components/calendar/Schedule";
import ScheduleList from "@/components/calendar/ScheduleList";

const Schedular = () => {
  // 모달 창 제어
  const setModal = useSetRecoilState(modalInfo);

  // 카테고리, 선택 달, 내 상담만 보기
  const [parameter, setParameter] = useRecoilState(parameterCalendar);

  // 카테고리 선택 셀렉트 박스 제어
  const [selectTap, setSelectTap] = useState(false);

  const [, setScrollState] = useState(null);

  const [, setData] = useRecoilState(responseCalendar);

  const [copiedData, setCopiedData] = useRecoilState(copiedResponseCalendar);

  const copingDataFunc = (e) => {
    let array = JSON.parse(JSON.stringify(e.calender));

    array.forEach((element) => {
      // Calendar event에서 key값을 start, end로 받아야 해서 추가
      element.start = moment.utc(element.calender_start).local().format();
      element.end = moment.utc(element.calender_end).local().format();

      element.title = `${moment(element.start).format("HH:mm")} ${
        element.admin_list.length > 0 ? element.admin_list[0].admin_name : ""
      } ${
        element.admin_list.length > 1 ? `+${element.admin_list.length - 1}` : ""
      }`;
    });

    setCopiedData(array);
  };

  /**
   * @description calendar 페이지 결과 값 호출
   *
   * @param category 약속 장소
   * @param select_month 특정 달
   * @param mycheck 내 상담
   */
  useEffect(() => {
    const params = { ...parameter };

    http.get(`/api/calender`, { params }).then((res) => {
      setData(res.data.data);
      copingDataFunc(res.data.data);
    });
  }, [parameter]);

  const ctrlList = () => {
    let spotList = [
      { name: "전체" },
      { name: "서울사무소" },
      { name: "여주전시관" },
      { name: "여주공장" },
      { name: "제주전시관" },
      { name: "기타" },
    ];

    return spotList.map((idx, num) => {
      const ctrlSelectOpt = (name) => {
        if (name === "전체") {
          setParameter((prev) => ({
            ...prev,
            category: "",
          }));
          setSelectTap(false);
        } else {
          setParameter((prev) => ({
            ...prev,
            category: name,
          }));
          setSelectTap(false);
        }
      };

      let colorClass;

      if (idx.name === "서울사무소") {
        colorClass = "-bg--color-office-seoul";
      } else if (idx.name === "여주전시관") {
        colorClass = "-bg--color-showroom-yeoju";
      } else if (idx.name === "여주공장") {
        colorClass = "-bg--color-factory-yeoju";
      } else if (idx.name === "제주전시관") {
        colorClass = "-bg--color-showroom-jeju";
      } else if (idx.name === "기타") {
        colorClass = "-bg--color-etc";
      }

      return (
        <li
          className="w-[125px] h-[44px] flex ptb-12 plr-16 items-center hover:-bg--color-neutral3 gap-[8px]"
          onClick={() => ctrlSelectOpt(idx.name)}
          key={num}
        >
          <span
            className={`w-[20px] h-[20px] rounded-[4px] ${colorClass}`}
          ></span>
          <span className="text-B14R">{idx.name}</span>
        </li>
      );
    });
  };

  return (
    <div className="custom-scrollbar relative w-full h-full flex flex-row overflow-auto">
      <div className="h-full flex w-[1128px] relative max-[1696px]:mt-0 max-[1696px]:mb-0 max-[1696px]:ml-[calc(248px+36px)] max-[1696px]:mr-[36px] min-[1697px]:my-0 min-[1697px]:mx-auto">
        <div className="fixed top-[0px] w-[1128px] h-[24px] flex items-center justify-between z-[3] -bg--color-neutral0"></div>
        <div className="absolute flex flex-row text-B12R -text--color-neutral50 min-h-[calc(100%-32px)] top-[24px] gap-[24px]">
          <div className="relative h-[986px] -bg--color-neutral0 rounded-[12px] plr-16 pt-[6px] pb-[16px] w-[744px] border border-solid -border--color-neutral6">
            <Schedule
              selectTap={selectTap}
              setSelectTap={setSelectTap}
              ctrlList={ctrlList}
              events={copiedData}
              setScrollState={setScrollState}
            />
            <button
              className="absolute bottom-0 right-0 m-[24px] -bg--color-neutral100 w-[64px] h-[64px] rounded-[34px] items-center justify-center flex z-10"
              style={{ boxShadow: "0px 3px 1px rgba(0, 0, 0, 0.03)" }}
              onClick={() => {
                showPortal(setModal, true, "calendar-add-schedule-web");
              }}
            >
              <span className="_sprite_ _sprite_18_1_"></span>
            </button>
          </div>
          <div className="h-[986px] w-[360px] -bg--color-neutral0 rounded-[12px] custom-scrollbar overflow-y-auto border border-solid -border--color-neutral6">
            <ScheduleList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedular;
