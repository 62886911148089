import http from "@/utils/api/http/http";
import { closePortal, showPortal } from "@/utils/func/common/modalController";

/**
 * @description 상담노트 내 지역 필터 기능
 * @param {*} tempFilter 상담노트 필터 영역 (location, process_type, start_datetime, end_datetime)
 * @param {*} setTempFilter filter handler callback
 */
export const makeSiteList = (tempFilter, setTempFilter) => {
  let siteList = [
    "전체",
    "서울",
    "부산",
    "대구",
    "인천",
    "광주",
    "대전",
    "울산",
    "세종",
    "경기",
    "강원",
    "충북",
    "충남",
    "전북",
    "전남",
    "경북",
    "경남",
    "제주",
  ];

  const handleCheckedChange = (event, value) => {
    if (event.currentTarget.checked && value === "전체") {
      setTempFilter((prev) => ({
        ...prev,
        location: [""],
      }));
    }

    if (event.currentTarget.checked && value !== "전체") {
      setTempFilter((prev) => {
        const nextChecked = prev.location.filter((item) => item !== "");

        if (value !== "") {
          nextChecked.push(value);
        }

        return {
          ...prev,
          location: nextChecked,
        };
      });
    }

    if (!event.currentTarget.checked) {
      setTempFilter((prev) => {
        const nextChecked = prev.location.filter((item) => item !== value);

        return {
          ...prev,
          location: nextChecked.length ? nextChecked : [""],
        };
      });
    }
  };

  return siteList.map((v, num) => {
    let checked;

    if (tempFilter.location[0] === "" && v === "전체") {
      checked = true;
    } else {
      checked = tempFilter.location.indexOf(v) !== -1;
    }

    return (
      <label
        key={num}
        htmlFor={v}
        className={`py-[10px] px-[16px] flex items-center justify-center border border-solid rounded-[24px] text-B16M cursor-pointer ${
          checked
            ? "-text--color-primary-main -bg--color-primary-surface  -border--color-primary-border"
            : "-border--color-neutral12 -text--color-neutral50"
        }`}
      >
        {v}
        <input
          id={v}
          type="checkbox"
          value={v}
          checked={checked}
          className="hidden"
          onChange={(e) => handleCheckedChange(e, v)}
        />
      </label>
    );
  });
};

/**
 *
 * @description 상담노트 내 상담 단계 필터 기능
 * @param {*} tempFilter
 * @param {*} setTempFilter
 */
export const makeStepList = (tempFilter, setTempFilter) => {
  const handleCheckedChange = (event, value) => {
    if (event.target.value === "전체") {
      setTempFilter((prev) => ({
        ...prev,
        step: null,
      }));
    }
    if (event.target.value === "대기") {
      setTempFilter((prev) => ({
        ...prev,
        step: 0,
      }));
    }
    if (event.target.value === "진행") {
      setTempFilter((prev) => ({
        ...prev,
        step: 1,
      }));
    }
    if (event.target.value === "완료") {
      setTempFilter((prev) => ({
        ...prev,
        step: 2,
      }));
    }
    if (event.target.value === "취소") {
      setTempFilter((prev) => ({
        ...prev,
        step: 9,
      }));
    }
  };

  return (
    <>
      <label
        className={
          tempFilter.step === null || tempFilter.step === undefined
            ? "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-primary-main -border--color-primary_border -bg--color-primary-surface"
            : "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-neutral50   -border--color-neutral12"
        }
      >
        전체
        <input
          type="radio"
          value="전체"
          checked={tempFilter.step === null || tempFilter.step === undefined}
          className="hidden"
          onChange={(e) => handleCheckedChange(e)}
        />
      </label>
      <label
        className={
          tempFilter.step === 0
            ? "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-primary-main -border--color-primary_border -bg--color-primary-surface"
            : "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-neutral50   -border--color-neutral12"
        }
      >
        대기
        <input
          type="radio"
          value="대기"
          checked={tempFilter.step === 0}
          className="hidden"
          onChange={(e) => handleCheckedChange(e)}
        />
      </label>
      <label
        className={
          tempFilter.step === 1
            ? "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-primary-main -border--color-primary_border -bg--color-primary-surface"
            : "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-neutral50   -border--color-neutral12"
        }
      >
        진행
        <input
          type="radio"
          value="진행"
          checked={tempFilter.step === 1}
          className="hidden"
          onChange={(e) => handleCheckedChange(e)}
        />
      </label>
      <label
        className={
          tempFilter.step === 2
            ? "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-primary-main -border--color-primary_border -bg--color-primary-surface"
            : "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-neutral50   -border--color-neutral12"
        }
      >
        완료
        <input
          type="radio"
          value="완료"
          checked={tempFilter.step === 2}
          className="hidden"
          onChange={(e) => handleCheckedChange(e)}
        />
      </label>
      <label
        className={
          tempFilter.step === 9
            ? "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-primary-main -border--color-primary_border -bg--color-primary-surface"
            : "flex items-center justify-center border border-solid rounded-[24px] py-[10px] px-[16px] text-B16M cursor-pointer -text--color-neutral50   -border--color-neutral12"
        }
      >
        취소
        <input
          type="radio"
          value="취소"
          checked={tempFilter.step === 9}
          className="hidden"
          onChange={(e) => handleCheckedChange(e)}
        />
      </label>
    </>
  );
};

/**
 * @description 상담노트 상세 페이지 상담 고객 추가 정보
 * @param {*} type user, type, grade, price, needs, size, loans, project, appendix
 * @param {*} e input 태그 이벤트
 * @param {*} setReferVal 상담 고객 추가 정보 컨트롤러
 */
export const handleDetailTypeChange = (type, e, setReferVal) => {
  switch (type) {
    // 건축용도
    case "user":
      setReferVal((prev) => ({
        ...prev,
        detail_use: e,
      }));
      break;
    // 유입경로
    case "type":
      setReferVal((prev) => ({
        ...prev,
        detail_type: e,
      }));
      break;
    // 고객적극도
    case "grade":
      setReferVal((prev) => ({
        ...prev,
        detail_positive: e,
      }));
      break;
    // 예산액
    case "price":
      setReferVal((prev) => ({
        ...prev,
        detail_price: e.target.value.replace(/,/g, ""),
      }));
      break;
    // 희망 연면적
    case "needs":
      setReferVal((prev) => ({
        ...prev,
        detail_needs: e.target.value.replace(/,/g, ""),
      }));
      break;
    // 건축규모
    case "size":
      setReferVal((prev) => ({
        ...prev,
        detail_size: e,
      }));
      break;
    // 대출 연계
    case "loans":
      setReferVal((prev) => ({
        ...prev,
        detail_loans: e,
      }));
      break;
    // 프로젝트 명
    case "project":
      setReferVal((prev) => ({
        ...prev,
        process_name: e.target.value,
      }));

      break;
    // 추가 메세지
    case "appendix":
      if (e.target.value.length <= 600) {
        setReferVal((prev) => ({
          ...prev,
          detail_appendix: e.target.value,
        }));
      } else {
        e.target.value = e.target.value.slice(0, 601);
        alert("600자를 초과할 수 없습니다");
      }
      break;
    default:
      break;
  }
};

/**
 * @description 상담 진행 다음 단계 api 함수
 * @param {*} userData 선택한 상세 상담노트 내 고객 정보
 * @param setUserData 고객정보 변경 함수
 * @param addressIdxList 상담 진행 api 내 parameter
 * @param setModal 모달 컨트롤러
 */
export async function ctrlNextStep({
  userData,
  setUserData,
  addressIdxList,
  setModal,
}) {
  try {
    showPortal(setModal, true, "entity-progress-common");

    await http.put(`/api/councel/process/next`, null, {
      params: { process_idx: userData.process.process_idx },
    });

    await http
      .post(`/api/councel/detail`, {
        user_idx: userData.user.user_idx,
        process_idx: userData.process.process_idx,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      })
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data);

          closePortal(setModal);
        }
      });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description 상담 진행 다음 단계 api 함수
 * @param {*} userData 선택한 상세 상담노트 내 고객 정보
 * @param setUserData 고객정보 변경 함수
 * @param addressIdxList 상담 진행 api 내 parameter
 * @param setModal 모달 컨트롤러
 */
export async function ctrlPrevStep({
  userData,
  setUserData,
  addressIdxList,
  setModal,
}) {
  try {
    showPortal(setModal, true, "entity-progress-common");

    await http.put(`/api/councel/process/prev`, null, {
      params: { process_idx: userData.process.process_idx },
    });

    await http
      .post(`/api/councel/detail`, {
        user_idx: userData.user.user_idx,
        process_idx: userData.process.process_idx,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      })
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data);

          closePortal(setModal);
        }
      });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description 상담 취소 함수
 * @param {*} userData 선택한 상세 상담노트 내 고객 정보
 * @param setUserData 고객정보 변경 함수
 * @param addressIdxList 상담 진행 api 내 parameter
 * @param setModal 모달 컨트롤러
 */
export async function ctrlCancelStep({
  userData,
  setUserData,
  addressIdxList,
  setModal,
}) {
  try {
    showPortal(setModal, true, "entity-progress-common");

    await http.put(`/api/councel/process/cancel`, null, {
      params: { process_idx: userData.process.process_idx },
    });

    await http
      .post(`/api/councel/detail`, {
        user_idx: userData.user.user_idx,
        process_idx: userData.process.process_idx,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      })
      .then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data);

          closePortal(setModal);
        }
      });
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description 고객 정보 변경 함수
 * @param {*} e 엔터 키 입력 감지 목적
 * @param {*} referVal 기존 고객 정보 담는 빈 값
 * @param {*} userData db에서 가져온 고객 정보
 * @param {*} setUserData 고객 정보 변경 함수
 * @param {*} addressIdxList 상담 저장 api 내 parameter
 * @param {*} setModal 모달 컨트롤러
 * @param {*} setEssentialInfo 기본 필수 정보 변경 함수
 */
export async function submitDetailUserData(
  e,
  referVal,
  userData,
  setUserData,
  addressIdxList,
  setModal,
  setEssentialInfo
) {
  if (e.keyCode === 13) {
    if (referVal.user_name === null || referVal.user_name.length === 0) {
      return setEssentialInfo(1);
    } else if (
      referVal.user_name !== null ||
      referVal.user_name.length !== 0 ||
      referVal.user_phone === null ||
      referVal.user_phone.length === 0
    ) {
      // return setEssentialInfo(2);
      return setEssentialInfo(1);
    } else if (
      referVal.user_name === null ||
      referVal.user_phone === null ||
      referVal.user_name.length === 0 ||
      referVal.user_phone.length < 10
    ) {
      alert("숫자만 입력이 가능하며 최대 15자리까지 입력 가능합니다.");
    } else {
      if (userData.user.user_privilege === 0) {
        const responseMain = await http.put(`/api/councel/temp`, {
          process_idx: userData.process.process_idx,
          temp_name: referVal.user_name,
          temp_phone: referVal.user_phone,
          temp_email: referVal.user_id,
        });
      }

      const responseRefresh = await http.post(`/api/councel/detail`, {
        user_idx: userData.user.user_idx,
        process_idx: userData.process.process_idx,
        address_idx_list: addressIdxList,
        admin_idx: localStorage.getItem("admin_idx"),
      });

      setUserData(responseRefresh.data.data);

      closePortal(setModal);
    }
  }
}

// 상담보고서 작성 중 다른 페이지 혹은 다른 글 클릭 시 뜨는 모달 창에서 계속하기 버튼 누르면 발생하는 이벤트
/**
 * @description 상담 노트 작성 중 페이지 이동 시 뜨는 경고 창 닫는 함수
 * @param {*} setModal 모달 컨트롤러
 */
export const continueWriting = ({
  setModal,
  selectedCard,
  setSelectedCard,
}) => {
  closePortal(setModal);
};

export const continueNavigate = ({
  setModal,
  selectedCard,
  setSelectedCard,
  selectedCardIdx,
  resDataCouncel,
  setShowEditorTap,
}) => {
  setSelectedCard(
    resDataCouncel.councel.filter((v) => v.councel_idx === selectedCardIdx)[0]
  );
  setShowEditorTap(false);
  closePortal(setModal);
};
