interface PageHandlerType {
  (
    e: string | number, // 이벤트
    callback: (arg: string) => void // navigate 함수
  ): void;
}

/**
 * @description
 * @param e sidebar state (css처리용)
 * @param callback react-router-dom 내장함수 navigate
 */
export const pageHandler: PageHandlerType = (e, callback) => {
  if (typeof e === "number") {
    // 상담노트 내 단계 클릭 시
    callback(`/councel`);
  } else if (e.includes("/")) {
    // 상담노트 클릭 시 상세 페이지 이동
    callback(`/${e}`);
  } else if (typeof e === "string") {
    // 대시보드, 건축 문의... 등등
    callback(`/${e}`);
  }
};
