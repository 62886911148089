import { atom, selector } from "recoil";

interface inquiryParameterType {
  category?: number;
  search?: string | null;
  pageno?: number;
}

// 1:1 문의 상담 기본 파라미터
export const inquiryParameter = atom<inquiryParameterType>({
  key: "inquiryParameter",
  default: {
    category: 1,
    search: "",
    pageno: 1,
  },
});

interface CouncelParameterType {
  location: string[];
  process_type: string | null;
  start_datetime: undefined | string | Date; // start_datetime로 전달
  end_datetime: undefined | string | Date; // end_datetime로 전달
}

interface ExtCouncelParameterType extends CouncelParameterType {
  detail_type: string;
  councel_type: number | undefined | string;
  mycheck: boolean;
  search: string;
  pageno: number;
  important_check: boolean;
}

// 건축상담 & 상담노트 기본 파라미터
export const councelParameter = atom<ExtCouncelParameterType>({
  key: "councelParameter",
  default: {
    location: [""],
    // 홈페이지 유선상담 기타...
    detail_type: "전체",
    // 상담, 계약, 공사...
    councel_type: undefined,
    start_datetime: undefined,
    end_datetime: undefined,
    mycheck: false,
    search: "",
    pageno: 1,
    process_type: null,
    // 상담대기, 진행, 상담완료, 상담취소...
    important_check: false,
  },
});

export const paramSelector = selector({
  key: "paramSelector",
  get: ({ get }) => {
    const currentParameter = get(councelParameter);

    // 사이드바 전체 외 클릭 시 (중요, 1차...)
    const diffDetailType =
      currentParameter.councel_type === undefined &&
      currentParameter.important_check === false;
    // 상단필터 영역 전체 외 클릭 시 (홈페이지, 유선...)
    const diffCouncelType = currentParameter.detail_type === "전체";
    // 내 상담 보기 클릭 시
    const diffMycheck = currentParameter.mycheck === false;
    // 지역 선택 시
    const diffSite =
      currentParameter.location.length === 0 ||
      currentParameter.location.length === 1;
    // 진행 상태 선택 시 (대기, 진행, 완료, 취소)
    const diffProcess =
      currentParameter.process_type === null ||
      currentParameter.process_type === undefined;
    // 기간
    const diffStartDate = currentParameter.start_datetime === undefined;
    const diffEndDate = currentParameter.end_datetime === undefined;

    if (
      !diffDetailType ||
      !diffCouncelType ||
      !diffMycheck ||
      !diffSite ||
      !diffProcess ||
      !diffStartDate ||
      !diffEndDate
    ) {
      return true;
    }

    return false;
  },
});

// 건축상담 & 상담노트 임시 파라미터
export const tempCouncelParameter = atom<CouncelParameterType>({
  key: "tempCouncelParameter",
  default: {
    location: [""],
    process_type: "전체",
    start_datetime: undefined, // start_datetime로 전달
    end_datetime: undefined, // end_datetime로 전달
  },
});
