import React from "react";

import { useNavigate } from "react-router-dom";

import { handleTimeToKr } from "@/utils/func/common/convertTime";

const CouncelBody = (props) => {
  const { data, isError, isLoading } = props;

  const navigate = useNavigate();

  return (
    <div className="absolute w-[1128px] flex flex-col items-center text-B12R -text--color-neutral50 top-[147px] min-h-[calc(100%-24px-50px-24px-48px-1px)] -bg--color-neutral0 pb-[81px] z-[2] border-l border-r border-solid -border--color-neutral6">
      {data?.data.data.councel.length !== 0 ? (
        data?.data.data.councel.map((idx, num) => (
          <div
            key={num}
            className={`w-[1128px] h-[48px] flex flex-row items-center justify-between text-B16R -text--color-neutral100 cursor-pointer break-words hover:-bg--color-neutral3 px-[16px] ${
              idx.process_check === 9 && "opacity-50"
            }`}
            onClick={() => {
              navigate(`/councel/detail/${idx.user_idx}/${idx.process_idx}`);
            }}
          >
            <div className="h-[48px] flex flex-row items-center">
              <div className="w-[49px] pl-[8px] h-[48px] flex items-center ">
                <li
                  className={
                    idx.process_type === 1
                      ? "flex items-center justify-center rounded-[6px] border border-solid -bg--color-etc-1 -text--color-neutral0 text-B12B px-[6px] py-[4px]"
                      : idx.process_type === 2
                      ? "flex items-center justify-center rounded-[6px] border border-solid -bg--color-etc-2 -text--color-neutral0 text-B12B px-[6px] py-[4px]"
                      : "flex items-center justify-center rounded-[6px] border border-solid -bg--color-etc-3 -text--color-neutral0 text-B12B px-[6px] py-[4px]"
                  }
                >
                  {idx.process_type === 1
                    ? "상담"
                    : idx.process_type === 2
                    ? "계약"
                    : "공사"}
                </li>
              </div>
              <div className="w-[224px] pl-[8px] h-[48px] flex items-center -text--color-primary-main text-B16B text-ellipsis whitespace-nowrap overflow-hidden">
                <li>{idx.process_name}</li>
              </div>
              <div
                className={
                  idx.user_name === "탈퇴유저"
                    ? "w-[86px] pl-[8px] h-[48px] flex items-center  -text--color-neutral50 text-B16R text-e text-ellipsis whitespace-nowrap overflow-hidden"
                    : "w-[86px] pl-[8px] h-[48px] flex items-center  text-B16R text-ellipsis whitespace-nowrap overflow-hidden"
                }
              >
                <span className="">
                  {idx.detail_use === null ? "-" : idx.detail_use.slice(0, 4)}
                </span>
              </div>
              <div
                className={
                  idx.user_name === "탈퇴유저"
                    ? "w-[73px] pl-[8px] h-[48px] flex items-center  -text--color-neutral50 text-B16R text-ellipsis whitespace-nowrap overflow-hidden"
                    : "w-[73px] pl-[8px] flex items-center  text-B16R text-ellipsis whitespace-nowrap overflow-hidden"
                }
              >
                <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                  {idx.detail_size === null
                    ? "-"
                    : idx.detail_size === "선택없음"
                    ? "-"
                    : idx.detail_size.slice(0, 4)}
                </span>
              </div>
              <div
                className={
                  idx.user_name === "탈퇴유저"
                    ? "w-[73px] pl-[8px] h-[48px] flex items-center -text--color-neutral50 text-B16R"
                    : "w-[73px] pl-[8px] flex items-center text-B16R"
                }
              >
                <li className="whitespace-nowrap overflow-x-hidden">
                  {idx.user_name === "탈퇴유저"
                    ? "탈퇴회원"
                    : idx.user_name.slice(0, 4)}
                </li>
              </div>
              <div
                className={
                  idx.user_name === "탈퇴유저"
                    ? "w-[145px] pl-[8px] h-[48px] flex items-center  -text--color-neutral50 text-B16R"
                    : "w-[145px] pl-[8px] flex items-center -center text-B16R"
                }
              >
                <li>
                  {idx.user_phone.indexOf("정") !== -1
                    ? "-"
                    : idx.user_phone.substr(0, 13)}
                </li>
              </div>
              <div
                className={
                  idx.user_name === "탈퇴유저"
                    ? "w-[73px] pl-[8px] h-[48px] flex items-center -text--color-neutral50 text-B16R"
                    : "w-[73px] pl-[8px] h-[48px] flex items-center text-B16R"
                }
              >
                <li
                  className={
                    idx.admin_name === null
                      ? "-text--color-danger-main"
                      : undefined
                  }
                >
                  {idx.admin_name !== null ? idx.admin_name : "미배정"}
                </li>
              </div>
              <div className="w-[78px] h-[48px] flex text-B12R items-center justify-center">
                {idx.process_check === 0 && (
                  <li className="py-[6px] px-[10px] flex items-center justify-center rounded-[30px] -bg--color-primary-surface -text--color-primary-main text-center">
                    상담대기
                  </li>
                )}
                {idx.process_check === 1 && (
                  <li className="py-[6px] px-[10px] flex items-center justify-center rounded-[30px] -bg--color-success-surface -text--color-success-main text-center">
                    상담 중
                  </li>
                )}
                {idx.process_check === 2 && (
                  <li className="py-[6px] px-[10px] flex items-center justify-center rounded-[30px] -bg--color-neutral6 -text--color-neutral50 text-center">
                    상담완료
                  </li>
                )}
                {idx.process_check === 9 && (
                  <li className="py-[6px] px-[10px] flex items-center justify-center rounded-[30px] -bg--color-danger-surface -text--color-danger-main text-center">
                    상담취소
                  </li>
                )}
              </div>
              <div className="w-[255px] pl-[8px] h-[48px flex] items-center -text--color-neutral50 text-B16R">
                <li>{handleTimeToKr(idx.process_datetime).slice(0, 19)}</li>
              </div>
            </div>
            <div className="w-[40px] flex items-center justify-center">
              <span className="_sprite_ _sprite_17_4_"></span>
            </div>
          </div>
        ))
      ) : (
        <div className="w-[1128px] h-[832px] flex flex-col justify-center items-center gap-[4px]">
          <div className="w-[223px] flex flex-col items-center justify-center">
            <span className="_sprite_ _sprite_7_13_"></span>
            <p className="text-H21B -text--color-neutral100 mb-[4px] pt-[14px]">
              일치하는 결과가 없습니다
            </p>
            <p className="text-B14R">검색 범위를 넓혀 보세요</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CouncelBody;
