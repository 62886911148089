import { useRecoilState } from "recoil";
import { modalInfo } from "@/stores/common/modal";
import React from "react";

import {
  continueNavigate,
  continueWriting,
} from "@/utils/func/common/modalFunc";

import { additionalResponseData } from "@/stores/common/data";
import {
  selectedDetailCouncelNote,
  selectedDetailCouncelNoteIndex,
} from "@/stores/page/councelDetail";

const CouncelAlertDangerWeb = () => {
  const [modal, setModal] = useRecoilState(modalInfo);

  const [selectedCard, setSelectedCard] = useRecoilState(
    selectedDetailCouncelNote
  );
  const [selectedCardIdx, setSelectedCardIdx] = useRecoilState(
    selectedDetailCouncelNoteIndex
  );

  const [resDataCouncel, setResDataCouncel] = useRecoilState(
    additionalResponseData
  );

  return (
    <>
      <div className="overflow-y-auto max-h-[calc(100vh-216px+60px+27px-85px-165px)] custom-scrollbar">
        <div className="flex flex-col plr-16 pt-[24px] pb-[16px] justify-center items-center">
          <span className="text-H21B pb-[8px]">작성 중인 글이 있습니다</span>
          <span className="text-B16R">저장하지 않고 페이지를 벗어날 경우,</span>
          <span className="text-B16R pb-[24px]">
            기존에 작성 중인 내용을 잃게 됩니다.
          </span>
          <div className="flex flex-row gap-[16px]">
            <button
              className="h-[52px] w-[145px] -bg--color-neutral6 rounded-[6px] -text--color-neutral50 text-B16M"
              onClick={() =>
                continueNavigate({
                  setModal,
                  selectedCard,
                  setSelectedCard,
                  selectedCardIdx,
                  resDataCouncel,
                })
              }
            >
              이동하기
            </button>
            <button
              className="h-[52px] w-[145px] -bg--color-primary-main rounded-[6px] -text--color-neutral0 text-B16M"
              onClick={() =>
                continueWriting({ setModal, selectedCard, setSelectedCard })
              }
            >
              계속 작성
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouncelAlertDangerWeb;
