import React, { useEffect, useState } from "react";

interface propsType {
  name: string;
  callback: (arg: string | number) => void;
}

interface cardDataType {
  title: string | undefined;
  description: string | undefined;
  url: string | undefined;
}

interface dataType {
  title: string | undefined;
  description: string | undefined;
  url: string | undefined;
}

// 대시보드 페이지 상담 처리 현황에서 사용되는 카드 타입
const MobileTypeTwo = ({ name, callback }: propsType) => {
  const cardData: cardDataType[] = [
    {
      title: "상담노트",
      description: "건축 상담의 내용을 기록해요",
      url: "councel",
    },
    {
      title: "고객문의",
      description: "고객과 1:1 문의로 소통해요.",
      url: "inquiry",
    },
  ];

  const [data, setData] = useState<dataType>({
    title: undefined,
    description: undefined,
    url: undefined,
  });

  useEffect(() => {
    if (name === "councel") {
      setData((prev) => {
        return {
          ...prev,
          title: cardData[0].title,
          description: cardData[0].description,
          url: cardData[0].url,
        };
      });
    } else if (name === "inquiry") {
      setData((prev) => {
        return {
          ...prev,
          title: cardData[1].title,
          description: cardData[1].description,
          url: cardData[1].url,
        };
      });
    }
  }, []);

  return (
    <button
      className="py-[16px] px-[16px] flex flex-col gap-[16px]"
      style={{
        boxShadow: "0px 3px 8px 0px rgba(108, 108, 108, 0.12)",
      }}
      onClick={() => {
        callback(`${data.url}`);
      }}
    >
      <div className="flex flex-col gap-[4px]">
        <div className="flex flex-row gap-[8px]">
          <span className="_sprite_ _sprite_16_10_"></span>
          <span className="-text--title-18b font-700 -text--color-neutral100">
            {data.title}
          </span>
        </div>
        <span className="-text--body-14r font-400 -text--color-neutral50">
          {data.description}
        </span>
      </div>
    </button>
  );
};

export default MobileTypeTwo;
