import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { responseData } from "@/stores/common/data";
import { inquiryParameter } from "@/stores/common/parameter";
import { modalInfo } from "@/stores/common/modal";

import { getInquiry, postInquiry } from "@/utils/api/pages/inquiry";

import { closePortal, showPortal } from "@/utils/func/common/modalController";
import { debounceFunction } from "@/utils/func/common/debounce";
import { filterController } from "@/utils/func/common/filter";

import MobileCategory from "@/components/filter/MobileCategory";
import MobileSearch from "@/components/filter/MobileSearch";

import MobileTypeInquiry from "@/components/cards/MobileTypeInquiry";

interface dataType {
  file_list: string[];
  idx: number;
  question_board: string;
  question_category: number;
  question_datetime: string;
  question_idx: number;
  question_title: string;
  question_type: boolean;
  user_idx: number;
  user_name: string;
  user_phone: string;
  admin_board?: string;
  admin_datetime?: string | null;
  admin_name?: string | null;
}

const InquiryMobile = () => {
  const [data, setData] = useRecoilState(responseData);

  const [parameter, setParameter] = useRecoilState(inquiryParameter);

  const [, setModal] = useRecoilState(modalInfo);

  /**
   * @description 고객문의 필터 적용 시 api 재호출
   *
   * @param category 서비스, 제품, 건축
   * @param search 검색창 입력 값
   * @param pageno 페이지 번호
   */
  useEffect(() => {
    try {
      getInquiry(parameter).then((res) => {
        setData(res.data.data);
      });
    } catch (err) {
      console.error(err);
    }
  }, [parameter]);

  /**
   * @description 상담 내용 답변 등록 시 실행
   *
   * @param idx 상담 데이터 idx 값
   * @param word 답변 내용
   */
  const addInquiry = async (idx: number, word: string) => {
    try {
      showPortal(setModal, true, "entity-progress-common");

      await postInquiry({ idx: idx, contents: word });

      await getInquiry(parameter).then((res) => {
        setData(res.data.data);

        closePortal(setModal);
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @description 검색창에 값 입력 시 debounce
   *
   * @param value 검색 input 창 입력 값
   */
  const handleSearchDebounced = debounceFunction((e: any) => {
    let value = e.target.value;

    setParameter((prev) => {
      return {
        ...prev,
        search: value,
        pageno: 1,
      };
    });
  }, 300);

  /**
   * @description 카테고리 필터
   * @param e filter 영역에서 각각의 값 (서비스, 제품, 건축)
   */
  const ctrlParameter = (e: number) => {
    filterController({ value: "inq", e, callback: setParameter });
  };

  const [categoryData, setCategoryData] = useState([
    {
      title: "서비스",
      params: 1,
      count: null,
    },
    {
      title: "제품",
      params: 2,
      count: null,
    },
    {
      title: "건축",
      params: 3,
      count: null,
    },
  ]);

  useEffect(() => {
    function ctrlCount() {
      const updatedCategoryData = categoryData.map((category: any) => {
        const idx = data?.count?.find(
          (item: any) => item.question_category === category.params
        );
        if (idx) {
          return { ...category, count: idx.question_count };
        }
        return category;
      });
      setCategoryData(updatedCategoryData);
    }

    ctrlCount();
  }, [data]);

  return (
    <main className="absolute -bg--color-neutral0 w-full mt-[48px] flex flex-col min-h-[calc(100%-48px)]">
      <section className="fixed flex flex-col w-full">
        <MobileCategory
          title="inquiry"
          data={categoryData}
          parameter={parameter}
          callback={ctrlParameter}
        />
        <MobileSearch callback={handleSearchDebounced} />
      </section>
      <section className="pt-[152px] w-full px-[16px] pb-[24px] flex flex-col gap-[12px]">
        {data?.question?.map((data: dataType, num: number) => {
          return (
            <MobileTypeInquiry data={data} callback={addInquiry} key={num} />
          );
        })}
        {data?.question?.length === 0 && (
          <div className="h-[calc(100vh-48px-16px-128px-16px-16px)] flex items-center justify-center flex-col gap-[4px]">
            <span className="-text--body-16b font-700">
              일치하는 결과가 없어요
            </span>
            <span className="-text--body-14r -text--color-neutral50 font-400">
              검색 범위를 넓혀 보세요.
            </span>
          </div>
        )}
      </section>
    </main>
  );
};

export default InquiryMobile;
